/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import i18n from 'i18n-react';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { renderErrorMessage } from '../components/ErrorMessage'

import { sendAccount100EPSubscribe, sendAccount100EPList, sendAccountInfo, sendAccountMyEthHistory, API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Eth100Create extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      subscriptionName: "",
      subscriptionAmount: 1,
      errorMessage: "",
      canRequest: true,
    }

  }

  componentDidMount() {
    //console.log("ych Eth100Create");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Create New - 100ETH Plan"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleSubscriptionNameChange(event)  {
    //console.log("handleSubscriptionNameChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      this.setState({subscriptionName: event.target.value})
      //console.log(event.target.value);
    }

  }

/*
  handleSubscriptionAmountChange(event)  {
    //console.log("handleSubscriptionAmountChange");
    //console.log(event.target.value);
    this.setState({errorMessage: ""})

    if ((event!==undefined)&&(event.target.value!="")){
      this.setState({subscriptionAmount: parseFloat(event.target.value)})
      //console.log(event.target.value);
    }

  }
*/
  handleSubscriptionAmountChange(event)  {
    //console.log("handleSubscriptionAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    var accountMyeth = {value: 0};

    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        accountMyeth.value = parseFloat(account.myeth.value);
      }
    }

    if (event!==undefined){
      this.setState({subscriptionAmount: event.target.value})
      if (event.target.value!=="") {
        const transferAmount = parseFloat(event.target.value);
        if (transferAmount>accountMyeth.value) {
          //this.setState({canRequest: false})
        } else {
          this.setState({canRequest: true})
        }
      } else {
        this.setState({canRequest: false})
      }
    }
  }


  handleAccount100EPSubscribe(subscriptionName,subscriptionAmount) {
    //console.log("handleAccount100EPSubscribe");
    //console.log(subscriptionName);
    //console.log(subscriptionAmount);
    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

    if (this.state.subscriptionName.length>40) {
      this.setState({errorMessage: i18n.translate('err_name_too_long')})
      return;
    }
    if (this.state.canRequest===false) {
      this.setState({errorMessage: i18n.translate('invalid_100eth_create_amount')})
      //console.log("can not request");
      return;
    }

    const transferAmount = parseFloat(this.state.subscriptionAmount);
//console.log(transferAmount);
    if ((transferAmount>100)||(transferAmount<1)) {
      this.setState({errorMessage: i18n.translate('invalid_100eth_create_amount')})
      //console.log("can not request");
      return;
    }


    this.props.sendAccount100EPSubscribe(token, { name: subscriptionName, amount: subscriptionAmount
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.sendAccount100EPList(token,{});
          self.props.sendAccountInfo(token,{});
          self.props.sendAccountMyEthHistory(token,{});

          self.props.history.push("/my_assets");
        }

      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }
    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  } 

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("100ETH Plan Create Error",this);
    }

    return (
      <div>
				 { /* preloader */}
										{this.props.loading && <PreLoaderWidget />}

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="invest"
                                        />




				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
							  <h6 className="f_breadcrumb"><Link to="my_assets">{i18n.translate('assets')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('createnew100ep')}</h1>
							  <h4 className="f_mode"> </h4>
							</div>
						</div>
		  

						  <div className="mdc-layout-grid">

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('namethisplan')}</span>
								  </h2>
								</div>
							</div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
                                                                                          <p className="f_formtitle">{i18n.translate('name')}</p>

											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="first-name-text-field" className="mdc-text-field__input"
                                                                                                value={this.state.subscriptionName}
                                                                                                onChange={this.handleSubscriptionNameChange.bind(this)} />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>
<div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
<div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>

											<div className="mdc-layout-grid__cell--span-12">
                                                                                          <p className="f_formtitle">{i18n.translate('amount_ep100')}</p>

											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="first-name-text-field" className="mdc-text-field__input"
                                                                                                value={this.state.subscriptionAmount}
                                                                                                onChange={this.handleSubscriptionAmountChange.bind(this)} />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>


											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                                                                            onClick={() => this.handleAccount100EPSubscribe(this.state.subscriptionName, this.state.subscriptionAmount)}
                                                                                          >
                                                                                          {i18n.translate('wrex_create')}</span>
											</div>
										  </div>
										</form>
									  </div>



									</div>
								  </div>
								</div>
							  </div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_rg_ptop mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
										<div className="crypto-widget">
											<h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
											<span className="crypto-widget__heading-text">{i18n.translate('about100ep')}</span>
											<span className="crypto-widget__heading-border"></span>
											</h2>
											<div className="crypto-widget__content">
													<ul className="li_notice">
														<li>{i18n.translate('ec1')}</li>
														<li>{i18n.translate('ec2')}</li>
														<li>{i18n.translate('ec3')}</li>
														<li>{i18n.translate('ec4')}</li>
														<li>{i18n.translate('ec5')}</li>
														<li>{i18n.translate('ec6')}</li>
													</ul>
												</div>
										</div>
								</div>
							</div>

						  </div>
					</div>
				  </main>


				</div>           
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
    globalAccount: state.entities.account
})

export default withRouter(connect(mapStateToProps, {
  sendAccount100EPSubscribe, sendAccount100EPList, sendAccountInfo, sendAccountMyEthHistory
})(Eth100Create))




