/* eslint-disable no-undef */

import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import countryList from 'react-select-country-list'

import i18n from 'i18n-react';

import passwordValidator from 'password-validator';


import { sendUserSignUp, API_SUCCESS } from '../actions'
import { renderErrorMessage } from '../components/ErrorMessage'

import { refineISOCountryList } from '../helper/countrylistHelper'

// import '@material/react-list/dist/menu.css';
// import '@material/react-menu-surface/dist/menu.css';
// import '@material/react-menu/dist/menu.css';
import '@material/react-select/dist/select.css'
import '../styles/mdc.css'
import Select, {Option} from '@material/react-select'

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();

var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(40)                                  // Maximum length 100
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                                 // Must have digits
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['password', '123123', 'abc123', '121212', 'secret', 'wrex']); // Blacklist these values
 
/*
new_password 有基本的 complexity 需求

目前是這樣

def password_complexity(email, password):
    if len(password) < 8:
        return False
    lc_password = password.lower()
    if lc_password in email.lower():
        return False
    password0 = password[0]
    if all(password0 == c for c in password[1:]): # all with same char
        return False
    if password in '01234567890':
        return False
    if password in 'abcdefghijk':
        return False
    if 'qwertyuiop'.startswith(password) or 'asdfghjkl'.startswith(password) or 'zxcvbnm'.startswith(password):
        return False
    if lc_password in ['wrex', 'password', '123123', 'abc123', '121212', 'secret']:
        return False
    return True
*/

class Register extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.countryOptions = refineISOCountryList()().getData();

    this.state = {
      emailAddress: "",
      password: "",
      confirmPassword: "",
      referralCode: "",
      countryOptions: this.countryOptions,
      countryCode: "",
      emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder'),
      passwordPlaceHolder: i18n.translate('passwordPlaceHolder'),
      confirmPasswordPlaceHolder: i18n.translate('confirmPasswordPlaceHolder'),
      referralCodePlaceHolder: i18n.translate('referralCodePlaceHolder'),
      termsChecked: false,
      registerStep: 1,
      //registerStep: 1,
      errorMessage: "",
    }

  }

/*
  hrefjs(l){
        var script   = document.createElement("script");
        script.type  = "text/javascript";
        script.src   = l;    // use this for linked script
        document.body.appendChild(script);
  }
*/

  componentDidMount() {
    //console.log("ych Register");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Register - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

/*
        this.hrefjs("//unpkg.com/material-components-web@latest/dist/material-components-web.min.js");
        var texst ="(function(){var tfs = document.querySelectorAll('.mdc-textfield:not([data-demo-no-auto-js])'); for (var i = 0, tf; tf = tfs[i]; i++) {mdc.textfield.MDCTextfield.attachTo(tf); mdc.autoInit();}})();"
        const script = document.createElement("script");
        script.text=texst;
        document.body.appendChild(script);
*/

    const cookieRef = cookies.get('affiliate_ref');
    if (cookieRef!==undefined) {
      this.setState({referralCodePlaceHolder: ""})
      this.setState({referralCode: cookieRef})
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleCountryCodeChange = (index, item) => {
    //console.log("handleCountryCodeChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (item!==undefined){
      this.setState({countryCode: item.getAttribute('data-value')})
      //console.log("country code: ", item.getAttribute('data-value'));
    }

  }
  handleEmailAddressChange(event)  {
    //console.log("handleEmailAddressChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({emailAddressPlaceHolder: ""})
      } else {
        this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({emailAddress: event.target.value})
    }

  }

  handleReferralCodeChange(event)  {
    //console.log("handleReferralCodeChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({referralCodePlaceHolder: ""})
      } else {
        this.setState({referralCodePlaceHolder: i18n.translate('referralCodePlaceHolder')})
      }
      this.setState({referralCode: event.target.value})
    }

  }

  handlePasswordChange(event)  {
    //console.log("handlePasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({passwordPlaceHolder: ""})
      } else {
        this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({password: event.target.value})

      //if (schema.validate(this.state.password)===false) {
        //this.setState({errorMessage: i18n.translate('err_password_too_simple')})
      //}
    }
  }

  handleConfirmPasswordChange(event)  {
    //console.log("handleConfirmPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({confirmPasswordPlaceHolder: ""})
      } else {
        this.setState({confirmPasswordPlaceHolder: i18n.translate('confirmPasswordPlaceHolder')})
      }
      this.setState({confirmPassword: event.target.value})
    }
  }

  handleTermsCheckedChange(event)  {
    //console.log("handleTermsCheckedChange");
    //console.log(event);
    this.setState({errorMessage: ""})

/*
    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({passwordPlaceHolder: ""})
      } else {
        this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({password: event.target.value})
    }
*/
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleStep1() {
    //console.log("handleStep1");
    this.setState({errorMessage: ""})

    if (this.state.emailAddress==="") {
      this.setState({errorMessage: i18n.translate('err_emailEmpty')})
    } else if (!this.validateEmail(this.state.emailAddress)) {
      this.setState({errorMessage: i18n.translate('err_emailFormatError')})
    } else if (this.state.referralCode==="") {
      this.setState({errorMessage: i18n.translate('err_referralCodeEmpty')})
    } else {
      this.setState({registerStep: 2})
    }
  }

  handleStep2() {
    //console.log("handleStep2");
    this.setState({errorMessage: ""})
    var self = this;

    if (this.state.password==="") {
      this.setState({errorMessage: i18n.translate('err_passwordEmpty')})
    } else if (this.state.countryCode==="") {
      this.setState({errorMessage: i18n.translate('err_countryCodeEmpty')})
    } else if (this.state.confirmPassword==="") {
      this.setState({errorMessage: i18n.translate('err_confirmPasswordEmpty')})
    } else if (this.state.confirmPassword!==this.state.password) {
      this.setState({errorMessage: i18n.translate('err_passwordMissmatch')})
    } else if (schema.validate(this.state.password)===false) {
      this.setState({errorMessage: i18n.translate('err_password_too_simple')})
    } else {

      const email = this.state.emailAddress.trim();
      this.props.sendUserSignUp({ email: email,
        password: this.state.password,
        country_code: this.state.countryCode,
        affiliate_code: this.state.referralCode,
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else { 
            self.setState({registerStep: 3})
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }
  }

  render() {
    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Register Error",this);
    }
    if (this.state.registerStep===2) {
      return this.renderRegisterStep2();
    } else if (this.state.registerStep===3) {
      return this.renderRegisterDone();
    } else {
      return this.renderRegister();
    }
  }

  renderRegister() {
    return (
      <div>
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                <img src="assets/images/logo_wrex.svg" alt="WREX" />
              </h2>
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('signup')}</span>
                <span className="crypto-widget__heading-border"></span>
              </h2>
              <form className="crypto-login-form">
                <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big">
                  <input type="text" id="email-address-text-field" className="mdc-text-field__input" 
                    value={this.state.emailAddress}
                    onChange={this.handleEmailAddressChange.bind(this)}
                  />
                  <label htmlFor="name-text-field" className="mdc-floating-label">
                    {this.state.emailAddressPlaceHolder}
                  </label>
                  <div className="mdc-line-ripple"></div>
                </div>

                <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big">
                  <input type="text" id="referral-code-text-field" className="mdc-text-field__input" 
                    value={this.state.referralCode}
                    onChange={this.handleReferralCodeChange.bind(this)}
                  />
                  <label htmlFor="name-text-field" className="mdc-floating-label">
                   {this.state.referralCodePlaceHolder}
                  </label>
                  <div className="mdc-line-ripple"></div>
                </div>

                <div className="crypto-display-flex crypto-display-flex--space-between crypto-margin-bottom-big text-left">
                  <div className="mdc-form-field">
                    <div className="wx-check">
                      <input type="checkbox" className="wx_checkbox" 
                        id="terms-checkbox"
                        checked={this.state.termsChecked} 
                        onChange={this.handleTermsCheckedChange.bind(this)}
                      />
                    </div>
                    <label htmlFor="terms-checkbox" className="crypto-word-warp">{i18n.translate('registerTermsCheck')}<a href="/UserAgreement.html">{i18n.translate('termsofuse')}</a></label>
                  </div>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }
                </div>
                <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                  onClick={() => this.handleStep1()} 
                >
                  {i18n.translate('next')}
                </span>
              </form>
              <div className="mdc-form-field">
              <p className="mdc-typography--body2 mdc-theme--on-surface">{i18n.translate('alreadyhaveaaccount')}  <a href="login">{i18n.translate('signin')}</a></p>
              </div>
            </div>
          </div>
        </div>          
      </div>

    )
  }


  renderRegisterStep2() {

//console.log(this.state.countryOptions);

    const optionNodes = this.state.countryOptions.map((country,index) => {
      return <Option key={index} value={country.value}>{country.label}</Option>
    })
    return (
      <div>
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                <img src="assets/images/logo_wrex.svg" alt="WREX" />
              </h2>
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('signup')}</span>
                <span className="crypto-widget__heading-border"></span>
              </h2>
              <form className="crypto-login-form">
                <div className="mdc-text-field mdc-text-field--fullwidth ">
                  <input required pattern=".{8,}"
                    id="register_password"
                    type="password"
                    className="mdc-text-field__input"
                    aria-controls="pw-validation-msg"
                    value={this.state.password}
                    onChange={this.handlePasswordChange.bind(this)}
                    autoComplete="current-password" />
                  <label htmlFor="register_password" className="mdc-floating-label">
                    {this.state.passwordPlaceHolder}
                  </label>
                  <div className="mdc-line-ripple"></div>
                </div>
                <p className="crypto-text-align-left crypto-word-warp mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg crypto-margin-bottom-big"
                  id="pw-validation-msg">{i18n.translate('passwordvalidate')}</p>
                <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big">
                  <input required pattern=".{8,}"
                    id="register_password_confirm"
                    type="password"
                    className="mdc-text-field__input"
                    value={this.state.confirmPassword}
                    onChange={this.handleConfirmPasswordChange.bind(this)}
                    autoComplete="current-password" />
                  <label htmlFor="register_password_confirm" className="mdc-floating-label">
                    {this.state.confirmPasswordPlaceHolder}
                  </label>
                  <div className="mdc-line-ripple"></div>
                </div>
                <div>
                    <Select className="mdc-select-box crypto-gender-select crypto-margin-bottom-big crypto-w-100 fx_select_height"
                        label={i18n.translate('nationality')} 
                        enhanced
                        value={this.state.countryCode}
                        onEnhancedChange={this.handleCountryCodeChange}
                    >
                      {optionNodes}
                    </Select>
                    <div className="mdc-line-ripple"></div>
                </div>
{/*
                <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big fx_top">
                     <label htmlFor="country-selection" className="mdc-floating-label fix1 fx_label_pos">
                      {i18n.translate('nationality')}
                    </label>
                    <div className="mdc-select mdc-select-box crypto-gender-select crypto-margin-bottom-big crypto-w-100 fx_select_height" id="gender-select">
<select className="mdc-select__native-control fix1" id="country-selection"
                      value={this.state.countryCode}
                      onChange={this.handleCountryCodeChange.bind(this)}
                    >
                      <option value="">-- {i18n.translate('selectone')}  --</option>
                      {optionNodes}
                    </select>


                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/}

                <span className="mdc-button mdc-button--outlined big-round-corner-button" onClick={() => this.setState({registerStep:1})} >{i18n.translate('back')}</span>
                <div className="space_40"></div>
                <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                  onClick={() => this.handleStep2()} 
                >
                  {i18n.translate('create')}
                </span>
              </form>
            </div>
          </div>
			  </div>
      </div>

    )
  }


  renderRegisterDone() {
    return (
      <div>

        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
              <img src="assets/images/logo_wrex.svg" alt="WREX" />
            </h2>
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
              <span className="crypto-widget__heading-text">{i18n.translate('congrates')}</span>
              <span className="crypto-widget__heading-border"></span>
            </h2>
            <p>{i18n.translate('thankyouregister')}</p>
            <form className="crypto-login-form">
              <a href="login" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('signin')}</a>
            </form>
            </div>
          </div>
			  </div>

      </div>

    )
  }

}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
  sendUserSignUp
})(Register))




