/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react'

import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import RewardEN from './News_en';
//import RewardKR from './News_kr';
//import RewardID from './News_id';

import NewsPageEN from './News_en';
import NewsPageKR from './News_kr';
import NewsPageID from './News_id';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class SupportHeader extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      LanguageSetting: "",
    }

    const cookieLanguageSetting = cookies.get('language_setting');
    if (cookieLanguageSetting !== undefined) {
      if (cookieLanguageSetting === "KR") {
        this.state.LanguageSetting = "KR";
      } else if (cookieLanguageSetting === "ID") {
        this.state.LanguageSetting = "ID";
      } else {
        this.state.LanguageSetting = "EN";
      }
    } else {
      this.state.LanguageSetting = "EN";
    }
  }

  componentDidMount() {
    //console.log("ych Support");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Support - WREX"

    document.body.className = "wrex mdc-typography mdc-theme--background crypro-theme-gradient";


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>

        <div className="f_single fx_single_tbl">
          <span className="crypto-widget__heading-text support__heading-text"> {i18n.translate('whytrust')}</span>
          <p className="crypto-word-warp">{i18n.translate('s2')}</p>

        </div>
        {/*
        <div>
          {this.state.LanguageSetting === "EN" && <RewardEN /> }
          {this.state.LanguageSetting === "KR" && <RewardKR />}
          {this.state.LanguageSetting === "ID" && <RewardID />}
        </div> */}

        <div className="support_min_table">
          {this.state.LanguageSetting === "EN" && <NewsPageEN />}
          {this.state.LanguageSetting === "KR" && <NewsPageKR />}
          {this.state.LanguageSetting === "ID" && <NewsPageID />}
        </div>
        <div className="f_single fx_single_tbl span_mt2">
          <div className="tabset">
            <label htmlFor="tab2">
              <span className="mdc-tab__content fx_titlefont">
                <span className="mdc-tab__text-label">{i18n.translate('monthlyreports')}</span>
              </span>
            </label>
          </div>
        </div>
        <div className="support_min_table">
          <div className='swanky_wrapper fx_frame'>
            <label >
              <span className="th_s1">{i18n.translate('month')}</span>
              <span className="th_s2">{i18n.translate('monthlyprofit')} %</span>
              <span className="th_s3">{i18n.translate('viewreport')}</span>

            </label>
            <label>
              <span className="th_s1">Sep. 2019</span>
              <span className="th_s2">16.52%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_Report-WOTA_201909.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Oct. 2019</span>
              <span className="th_s2">11.72%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_Report_WOTA_201910.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Nov. 2019</span>
              <span className="th_s2">10.07%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_Report_November_2019-18122019.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Dec. 2019</span>
              <span className="th_s2">6.80%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_Report_WOTA_201912.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Jan. 2020</span>
              <span className="th_s2">9.92%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_Jan_20-WOTA.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Feb. 2020</span>
              <span className="th_s2">3.94%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_Feb_20-WOTA.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Mar. 2020</span>
              <span className="th_s2">4.71%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_March_20-WOTA.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Apr. 2020</span>
              <span className="th_s2">3.31%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_April_20-WOTA.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">May 2020</span>
              <span className="th_s2">3.48%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_May_20-WOTA.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
            <label>
              <span className="th_s1">Jun. 2020</span>
              <span className="th_s2">5.29%</span>
              <span className="th_s3"><a href="/read_pdf?pdf=https://www.w-rex.com/reports/AUP_report_June_20-WOTA-1-2.pdf" target="_blank"><img className="fx_download" src="assets/images/ic_pdf.svg" alt="ic pdf" /></a></span>
            </label>
          </div>
        </div>
        <p className="fx_support_p crypto-word-warp">{i18n.translate('s1')} <a href="mailto:support@w-rex.com">support@w-rex.com</a></p>

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(SupportHeader))




