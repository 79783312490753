/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import i18n from 'i18n-react';


//const cookies = new Cookies();
class MyEthWithdrawRequest extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);


    //this.state = {
      //errorMessage: "",
    //}

  //}

  componentDidMount() {
    //console.log("ych MyEthWithdrawRequest");
    //window.addEventListener('resize', this.handleResize)
    document.title = "MyETH Wallet Withdraw Request - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";


    //isLogin(this);

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
       			  <div className="crypro-login-container rex_rg">
				<div className="crypro-error-container__box">
				  <div className="crypro-error-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('requestreceived')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<p className="crypto-word-warp">{i18n.translate('mewr1')}</p>
					<form className="crypto-login-form">
					  <Link to="myeth_wallet" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('ok')}</Link>
					</form>
				  </div>
				</div>
			  </div>
      </div>

    )
  }





}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
  }
}

export default withRouter(connect(mapStateToProps, {
})(MyEthWithdrawRequest))




