/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Chart from 'chart.js'
import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { sendAccount100EPMonthPerformance, API_SUCCESS } from '../actions'

//import { getETHDisplayString, getAdd3ETHDisplayString }  from '../helper/displayHelper'
//import { getYearMonthString, getYearMonthFromIntString, getAdd2ETHDisplayString, getETHDisplayString, getYearMonthWithoutCommaFromIntString, getAdd3ETHDisplayString }  from '../helper/displayHelper'
import { getYearMonthString, getYearMonthFromIntString, getETHDisplayString, getYearMonthWithoutCommaFromIntString }  from '../helper/displayHelper'

import { renderErrorMessage } from '../components/ErrorMessage'

import { getToken } from '../helper/loginHelper';

import i18n from 'i18n-react';


//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class Eth100Plan1 extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      subscriptionId: "",
      terminateUrl: "",
      performance: [],
      errorMessage: "",
    }

  }
	
  componentDidMount() {
    //console.log("ych Eth100Plan1");
    //window.addEventListener('resize', this.handleResize)
    document.title = "100ETH Plan - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";
    //console.log(this.props);

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const refString = params.get('ref'); // bar
    const penaltyString = params.get('p'); // bar

    if (refString===null) {
      this.props.history.push("/my_assets")
    } else if (penaltyString===null) {
      this.props.history.push("/my_assets")
    } else {
      const ref = parseInt(refString);

//console.log(penaltyString);
      this.setState({subscriptionId: ref})
      if (penaltyString==="1") {
        this.setState({terminateUrl: "100ETH_terminate_confirm_with_penalty?ref="+ref})
      } else {
        this.setState({terminateUrl: "100ETH_terminate_confirm?ref="+ref})
      }

      let token = getToken(this);

      var self = this;

      this.props.sendAccount100EPMonthPerformance(token, {
        subscription_id: ref
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            var labelArr=[];
            var dataArr=[];
            self.setState({performance: response.performance})
            for (let i in response.performance) {
              labelArr.push(getYearMonthWithoutCommaFromIntString(response.performance[i].yearmonth));
              dataArr.push(parseFloat(response.performance[i].profit)+parseFloat(response.performance[i].referralBonus));
            }

            if (response.performance.length<6) {
              labelArr.push('');
              dataArr.push(0);
            }

            labelArr.reverse();
            dataArr.reverse();

            self.myChart = new Chart(self.canvasRef.current, {
              type: 'line',
              options: {
                legend: {
                    display: false,
                }
              },
              data: {
                labels: labelArr,
                datasets: [{
                  data: dataArr,
                  label: "",
                  borderColor: "#3e95cd",
                  fill: true 
                }],
              }
            });
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
          //self.props.history.push("/my_assets")
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
        self.props.history.push("/my_assets")
      })


    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleSubscriptionEdit(id,name) {
    //console.log("handleSubscriptionEdit");

    cookies.set('subscription_edit_id', id, {path: '/'});
    cookies.set('subscription_edit_name', name, {path: '/'});

    this.props.history.push("/100ETH_Update")

  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Eth100 Plan Error",this);
    }

    var subscriptions = [];

    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.subscriptions!==undefined) {
        subscriptions = account.subscriptions;
      }
    }
    //console.log(subscriptions);
    //console.log(this.state.subscriptionId);
    var mySubscription = { id: "", name: "", monthsLeft: "", principal:"", profit: "", referralBonus:"", planBegin:""}
    for (let i in subscriptions) {
      if (subscriptions[i].id===this.state.subscriptionId) {
        mySubscription = subscriptions[i];
      } 
    }
    //console.log(mySubscription);


            //<span className="crypto-wallet__stats--up">+{getAdd2ETHDisplayString(myPerformance.profit,myPerformance.referralBonus)} ETH</span>
//console.log(this.state.performance);
    const performanceNodes = this.state.performance.map((myPerformance,index) =>
      <div key={index}>
        <input id={index} name='radio' type='checkbox' />
        <label htmlFor={index}>
          <span className="th_1">{getYearMonthFromIntString(myPerformance.yearmonth)}</span>
          <span className="th_3">
            <span className="crypto-wallet__stats--up">+{getETHDisplayString(myPerformance.userGains)} ETH</span>
          </span>
          <div className='lil_arrow'></div>
          <div className='bar'></div>
          <div className='swanky_wrapper__content'>
            <ul>
            <li>Profit Share: <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.profit)} ETH</span></li>
            <li>Affiliate Bonus: <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.referralBonus)} ETH</span></li>
            </ul>
          </div>
        </label>
      </div>
    )
								  //<h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getAdd3ETHDisplayString(mySubscription.principal,mySubscription.profit,mySubscription.referralBonus)}<span>ETH</span></h1>

    return (
      <div>
				 { /* preloader */}
										{this.props.loading && <PreLoaderWidget />}

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="invest"
                                        />

				

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="my_assets">{i18n.translate('assets')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">My Account{/*mySubscription.name*/}</h1>
							  {/* <h4 className="f_mode">{i18n.translate('ep100')} <span className="span_button" onClick={() => this.handleSubscriptionEdit(mySubscription.id,mySubscription.name)} >{i18n.translate('edit')}</span></h4>*/}
							</div>
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(mySubscription.userTotal)}<span>ETH</span></h1>
								</div>
							</div>
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
							 <div className="f_control">
								{/* <Link to={this.state.terminateUrl} className="mdc-button mdc-button--outlined">{i18n.translate('terminate')}</Link> */}
								<h4 className="f_mode"><span>{mySubscription.monthsLeft} {i18n.translate('monthstomature')}</span></h4>
							  </div>
							</div>
						</div>


		  

						  <div className="mdc-layout-grid fx_padding">
							<div className="mdc-layout-grid__inner">

							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('sixmonthperformance')}</span>
								  </h2>
									<div className="crypto-widget__content text-center f_linechart">
                		<canvas id='myChart' ref={this.canvasRef} />
            			</div>
								</div>

								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div id="chart-most-invested"></div>
								  </div>
								</div>
							  </div>




							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">

								<div className="crypto-widget">
								  <div className="mdc-layout-grid__inner">
									<div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
									  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
										<span className="crypto-widget__heading-text">{i18n.translate('accounthistory')}</span>
									  </h2>
									</div>
									<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-1-phone crypto-widget__actions"></div>

									</div>

									<div className="crypto-widget__content crypto-overflow-x-inherit">
									<div className='swanky_wrapper'>
                                                                        {performanceNodes}

      <div >
        <input id="create" name='radio' type='checkbox' />
        <label htmlFor="create">
          <span className="th_1">{getYearMonthString(mySubscription.planBegin)}</span>
          <span className="th_3">
            <span className="crypto-wallet__stats--up">+{getETHDisplayString(mySubscription.principal)} ETH</span>
          </span>
        </label>
      </div>


									</div>
								  </div>


					  
								</div>
							  </div>
                {/* 
                <div className="f_rg_ptop f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
										<div className="crypto-widget">
											<h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
											<span className="crypto-widget__heading-text">{i18n.translate('about100ep')}</span>
											<span className="crypto-widget__heading-border"></span>
											</h2>
											<div className="crypto-widget__content">
													<ul className="li_notice">
                            
														<li>{i18n.translate('ec1')}</li>
														<li>{i18n.translate('ec2')}</li>
                            <li>{i18n.translate('ec3')}</li>
                            
													</ul>
												</div>
										</div>
									</div>*/}
                  <br/><br/>
							</div>
						  </div>



					</div>

				  </main>

				</div>        
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

})

export default withRouter(connect(mapStateToProps, {
  sendAccount100EPMonthPerformance
})(Eth100Plan1))




