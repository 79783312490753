/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react';
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Google2fa11b extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych Google 2FA");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Google 2FA - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
        <div className="crypro-login-container rex_rg">
					<div className="crypro-login-container__box">
						<div className="crypro-login-container__box-inner">
						<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
							<img src="assets/images/logo_wrex.svg" alt="WREX" />
						</h2>
						<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
							<span className="crypto-widget__heading-text"></span>
							<span className="crypto-widget__heading-border"></span>
						</h2>
	
						<h2 className="crypto-widget__heading2 mdc-theme--primary">
							<span className="crypto-widget__heading-text">{i18n.translate('requestreceived')}</span>
						</h2>
            { /* 
						<p className="f_titleg">Tickt ID</p>
						<p className="f_titlec">18742</p>
            */ }
						<div className="crypto-login-form">
							<a href="myeth_wallet" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('dismiss')}</a>
						</div>


						</div>
					</div>
			  </div>              
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(Google2fa11b))




