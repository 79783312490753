/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import countryList from 'react-select-country-list'

import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'

//import { getAddressString }  from '../helper/displayHelper'

//import {getToken } from '../helper/loginHelper';

import { sendResetPassword, API_SUCCESS } from '../actions'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class ProfilePassword extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      key: "",
      user_id: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
    }

  }

  componentDidMount() {
    //console.log("ych ProfilePassword");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Profile - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const user_id = params.get('user_id'); // bar
    const key = params.get('key'); // bar

    if ((user_id!==null)&&(key!==null)) {
      this.setState({key: key})
      this.setState({user_id: user_id})
    } else {
      this.props.history.push("/")
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

/*
  handleOldPasswordChange(event)  {
    //console.log("handleOldPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({passwordPlaceHolder: ""})
      } else {
        //this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({oldPassword: event.target.value})
    }
  }
*/

  handleNewPasswordChange(event)  {
    //console.log("handleNewPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({passwordPlaceHolder: ""})
      } else {
        //this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({newPassword: event.target.value})
    }
  }

  handleConfirmPasswordChange(event)  {
    //console.log("handleConfirmPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({confirmPasswordPlaceHolder: ""})
      } else {
        //this.setState({confirmPasswordPlaceHolder: i18n.translate('confirmPasswordPlaceHolder')})
      }
      this.setState({confirmPassword: event.target.value})
    }
  }

  handleReset() {
    //console.log("handleReset");
    this.setState({errorMessage: ""})

    
    var self = this;

    if (this.state.newPassword==="") {
      this.setState({errorMessage: i18n.translate('err_newPasswordEmpty')})
    } else if (this.state.confirmPassword==="") {
      this.setState({errorMessage: i18n.translate('err_confirmPasswordEmpty')})
    } else if (this.state.confirmPassword!==this.state.newPassword) {
      this.setState({errorMessage: i18n.translate('err_passwordMissmatch')})
    } else {
      this.props.sendResetPassword({ user_id: this.state.user_id,
        key: this.state.key,
        new_password: this.state.newPassword,
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            self.props.history.push("/login");
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Forgot Password Error",this);
    }

    return (
      <div>

          <main className="crypto-main">
            <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
              
              <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">

                  <div className="f_centerbox2 mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
                    <div className="crypto-widget">
                      <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth  mdc-theme--primary">
                      <span className="crypto-widget__heading-text">{i18n.translate('resetpassword')}</span>
                      </h2>
                    </div>
                  </div>
                  <div className="f_centerbox3 f_formlarge mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
                    <div className="crypto-widget">
                      <div className="crypto-widget__content">
                        <form className="crypto-general-form">
                          <div className="mdc-layout-grid__inner">

                            <div className="mdc-layout-grid__cell--span-12">
                              <p className="f_formtitle">{i18n.translate('newpassword')}</p>
                              <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                                <input required pattern=".{8,}"
                                  id="new-password-text-field"
                                  type="password"
                                  className="mdc-text-field__input"
                                  value={this.state.newPassword}
                                  onChange={this.handleNewPasswordChange.bind(this)}
                                  autoComplete="current-password" />
                                <div className="mdc-line-ripple"></div>
                              </div>
                            </div>

                            <div className="mdc-layout-grid__cell--span-12">
                              <p className="f_formtitle">{i18n.translate('confirmpassword')}</p>
                              <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                                <input required pattern=".{8,}"
                                  id="confirm-password-text-field"
                                  type="password"
                                  className="mdc-text-field__input"
                                  value={this.state.confirmPassword}
                                  onChange={this.handleConfirmPasswordChange.bind(this)}
                                  autoComplete="current-password" />
                                <div className="mdc-line-ripple"></div>
                              </div>
                            </div>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }

                            <div className="mdc-layout-grid__cell--span-12 f_btncenter">
                              <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                onClick={() => this.handleReset()}

                              >{i18n.translate('reset')}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
    )
  }





}



const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalLogin: state.entities.login,

})


export default withRouter(connect(mapStateToProps, {
  sendResetPassword
})(ProfilePassword))




