/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//import { Route , withRouter} from 'react-router-dom';
import { withRouter} from 'react-router-dom';
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { sendAccountWREXDeposit, sendAccountInfo, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'


import {getToken } from '../helper/loginHelper';
import { getETHDisplayString }  from '../helper/displayHelper'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class WrexDepositCreate extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      reinvest: true,
      errorMessage: "",
      isClickable: true,
    }
  }

  componentDidMount() {
    //console.log("ych WrexDepositCreate");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Create - WREX Account"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

/*
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        //this.setState({amount: parseFloat(account.myeth.value)})
      }
    } else {
      let token = getToken(this);
      var self=this;
      this.props.sendAccountInfo(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.account.account;
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            // self.setState({amount: parseFloat(response.myeth.value)})
            self.setState({amount: ""})
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }
*/
  }


  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleReinvestChange(event)  {
    //console.log("handleReinvestChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      //if (event.target.value!=="") {
        //this.setState({emailAddressPlaceHolder: ""})
      //} else {
        //this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      //}
      if (event.target.value==="true") {
        this.setState({reinvest: true})
      } else {
        this.setState({reinvest: false})
      }
    }

  }

  handleAccountWREXDeposit() {
    if (this.state.isClickable===false) {
      //console.log("isClickable===false");
      return;
    }
    //console.log("handleAccountWREXDeposit");
    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

    this.setState({isClickable: false})

    this.props.sendAccountWREXDeposit(token, { amount: this.state.amount, reinvest: this.state.reinvest
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.sendAccountInfo(token,{});
          self.props.history.push("/my_assets")
        }

      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

      self.setState({isClickable: true})

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
      self.setState({isClickable: true})
    })
  }

  handleAmountChange(event)  {
    //console.log("handleAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({emailAddressPlaceHolder: ""})
      } else {
        //this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({amount: event.target.value})
    }

  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Wrex Deposit Create Error",this);
    }

    //console.log(this.props.globalAccount);

    var myeth = {value: "", valueUsd: ""}
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        //myeth = account.myeth;
        myeth.value = parseFloat(account.myeth.value);
        myeth.valueUsd = parseFloat(account.myeth.valueUsd);
      }
    }

    return (
      <div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}					
										
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="wrex"
                                        />


			

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><a href="wrex_account">{i18n.translate('wrex')}</a><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('create')}</h1>
							</div>
						</div>
		  

            <div className="mdc-layout-grid">

							<div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('availablebalance')}: {getETHDisplayString(myeth.value)} ETH</span>
								  </h2>
								</div>
							</div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_formlarge mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
												<p className="f_formtitle">{i18n.translate('amount')} ({i18n.translate('ethmin')})</p>
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="amount_text_field" className="mdc-text-field__input" 
               value={this.state.amount}
               onChange={this.handleAmountChange.bind(this)}
             />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>

											<div className="mdc-form-field mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone f_txtcenter">
												<div className="mdc-radio">
													<input className="mdc-radio__native-control" type="radio" 
                                                                                                          id="radio-1" name="radios" 
                                                                                                          checked={this.state.reinvest === true} onChange={this.handleReinvestChange.bind(this)} 
                                                                                                          value="true"
                                                                                                        />
													<div className="mdc-radio__background">
													  <div className="mdc-radio__outer-circle"></div>
													  <div className="mdc-radio__inner-circle"></div>
													</div>
												  </div>
												<label htmlFor="schedules-checkbox">{i18n.translate('autoaccumulation')}</label>
											</div>
											<div className="mdc-form-field mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone f_txtcenter">
												<div className="mdc-radio">
													<input className="mdc-radio__native-control" type="radio" 
                                                                                                          id="radio-1" name="radios" 
                                                                                                          checked={this.state.reinvest === false} onChange={this.handleReinvestChange.bind(this)} 
                                                                                                          value="false"
                                                                                                        />
													<div className="mdc-radio__background">
													  <div className="mdc-radio__outer-circle"></div>
													  <div className="mdc-radio__inner-circle"></div>
													</div>
												  </div>
												<label htmlFor="schedules-checkbox">{i18n.translate('autowithdrawal')}</label>
											</div>
                      <div></div>
											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                                                                            onClick={() => this.handleAccountWREXDeposit()}
                                                                                          >{i18n.translate('wrex_create')}</span>
											</div>
										  </div>
										</form>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }


									  </div>



									</div>
								  </div>
								</div>
							  </div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_rg_ptop mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('wrexdepositpolices')}</span>
									<span className="crypto-widget__heading-border"></span>
								  </h2>
								  <div className="crypto-widget__content">
											<ul className="li_notice">
											<li className="crypto-word-warp">{i18n.translate('wd1')} </li>
											<li className="crypto-word-warp">{i18n.translate('wd2')} </li>
											<li className="crypto-word-warp">{i18n.translate('wd3')} </li>
											<li className="crypto-word-warp">{i18n.translate('wd4')} </li>
											</ul>
										</div>
								</div>
							  </div>

							</div>

						  </div>
					</div>
				  </main>

				</div>         
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendAccountWREXDeposit, sendAccountInfo
})(WrexDepositCreate))




