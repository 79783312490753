/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

import { sendUserForgotPassword, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class ForgotPassword extends Component {
  static propTypes = {
  }

  state = {
    emailAddress: "",
    emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder'),
    errorMessage: "",
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych ForgotPassword");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Forgot password - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleEmailAddressChange(event)  {
    //console.log("handleEmailAddressChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({emailAddressPlaceHolder: ""})
      } else {
        this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({emailAddress: event.target.value})
    }

  }

  handleSend() {
    //console.log("handleSend");
    this.setState({errorMessage: ""})

    if (this.state.emailAddress==="") {
      this.setState({errorMessage: i18n.translate('err_emailEmpty')})
    } else if (!this.validateEmail(this.state.emailAddress)) {
      this.setState({errorMessage: i18n.translate('err_emailFormatError')})
    } else {
      var self=this;
      this.props.sendUserForgotPassword({ email: this.state.emailAddress
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            self.props.history.push("/forgot-password-result")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }

  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Forgot Password Error",this);
    }

    return (
      <div>

        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                <img src="assets/images/logo_wrex.svg" alt="WREX" />
              </h2>
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('forgotpassword')}</span>
                <span className="crypto-widget__heading-border"></span>
              </h2>
              <p className="crypto-word-warp">{i18n.translate('fp1')} </p>
              <form className="crypto-login-form">
                <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big">
                <input required
                  id="email-address-text-field"
                  type="email"
                    value={this.state.emailAddress}
                    onChange={this.handleEmailAddressChange.bind(this)}
                  className="mdc-text-field__input"/>
                <label htmlFor="email" className="mdc-floating-label">
                    {this.state.emailAddressPlaceHolder}
                </label>
                <div className="mdc-line-ripple"></div>
                </div>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }
                <div className="crypto-display-flex crypto-display-flex--space-between">
                <div className="mdc-form-field">
                  <span className="wrex_button mdc-button mdc-button--unelevated big-round-corner-button"
                    onClick={() => this.handleSend()}
                  > 
                    {i18n.translate('send')}
                  </span>
                </div>
                <div className="mdc-form-field">
                  <Link to="login">{i18n.translate('backtologin')}</Link>
                </div>
                </div>
              </form>
            </div>

          </div>
			  </div>



      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
  sendUserForgotPassword
})(ForgotPassword))




