/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

//import { getETHDisplayString, getTimeDisplayString }  from '../helper/displayHelper'
import { getTimeDisplayString, getStatusDisplayString }  from '../helper/displayHelper'

import i18n from 'i18n-react';

//const cookies = new Cookies();
class APStatus extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      ref: "all",
    }
  }

  componentDidMount() {
    //console.log("ych APStatus");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Affiliate Program Status - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const ref = params.get('ref'); // bar
    if (ref!==null) {
      this.setState({ref: ref})
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {

    //console.log(this.props.globalUser);
    var affiliate_detail = [];
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if (user.detail!==undefined) {
        affiliate_detail = user.detail;
      }
    }

//console.log(affiliate_detail);
//console.log(this.state.ref);

    const affiliateDetailNodes = affiliate_detail.map((myDetail,index) =>
      <tbody key={index}>
        <tr className="crypto-transactions-list__item">
          <td className="mdl-data-table__cell--non-numeric" width="20">{index+1}.</td>
          <td className="mdl-data-table__cell--non-numeric">{myDetail.email}</td>
          { /* 
          <td className="mdl-data-table__cell--non-numeric">{getETHDisplayString(myDetail.wotaAmount)} ETH</td>
          <td className="mdl-data-table__cell--non-numeric">{getETHDisplayString(myDetail.ep100Amount)} ETH</td>
          */}
          <td className="mdl-data-table__cell--non-numeric">{getTimeDisplayString(myDetail.signupDate)}</td>
          <td className="mdl-data-table__cell--non-numeric">
            <span className="crypto-wallet__statsxx crypto-wallet__stats--up mdc-typography--caption fx_valign">
            {getStatusDisplayString(myDetail.invest,this.state.ref)}
            </span>
          </td>
        </tr>
      </tbody>
    )

    return (
      <div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	
					
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="referral"
                                        />


				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="affiliate_program">{i18n.translate('affiliateprogram')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('status')}</h1>
							</div>
						</div>
		  

					  <div className="mdc-layout-grid">
						<div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet"></div>
						  <div className="f_centerbox mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-phone">

							<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('myaffiliatestatus')}</span>
								  </h2>

							  <div className="crypto-widget__content crypto-overflow-x-auto">
								<table className="mdl-data-table mdc-typography--overline f_flag">
              <tbody>
              <tr className="crypto-transactions-list__item">
                <td className="mdl-data-table__cell--non-numeric" width="20"></td>
                <td className="mdl-data-table__cell--non-numeric">{i18n.translate('email')}</td>
                { /* <td className="mdl-data-table__cell--non-numeric">{i18n.translate('referralwrexamount')}</td>
                <td className="mdl-data-table__cell--non-numeric">{i18n.translate('referal100epamount')}</td>
                */}
                <td className="mdl-data-table__cell--non-numeric">{i18n.translate('since')}</td>
                <td className="mdl-data-table__cell--non-numeric">{i18n.translate('status')}</td>
              </tr>
              </tbody>
              {affiliateDetailNodes}
            </table>								
							  </div>

    <div className="crypto-widget__content">
      <ul className="li_notice">
      <li>{i18n.translate('as1')}</li>
      </ul>
    </div>



							  <div className="mdc-snackbar mdc-elevation--z24"
								  aria-live="assertive"
								  aria-atomic="true"
								  aria-hidden="true">
								<div className="mdc-snackbar__text"></div>
								<div className="mdc-snackbar__action-wrapper">
								  <button type="button" className="mdc-snackbar__action-button"></button>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>



					</div>

				  </main>

				</div>	
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
})

export default withRouter(connect(mapStateToProps, {
})(APStatus))




