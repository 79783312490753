/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react'

//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class WrexWithdrawRequest extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych WrexWithdrawRequest");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Withrequest Request - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
     <div className="crypro-login-container rex_rg">
				<div className="crypro-login-container__box">
				  <div className="crypro-login-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('requestreceived')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<p className="crypto-word-warp">{i18n.translate('wwr1')}</p>
					<br/>
					<form className="crypto-login-form">
					  <a href="wrex_account" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('ok')}</a>
					</form>
				  </div>
				</div>
			  </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(WrexWithdrawRequest))




