import React, { Component } from 'react';
//import i18n from 'i18n-react';

// import LastEarns from './LastEarns';


/**
 * Renders the preloader
 */
class FaqPageID extends Component {

  render() {
    return (
      <div>


        <div className="f_single fx_single_tbl">
          <div className="tabset">
            <label htmlFor="tab2">
              <span className="mdc-tab__content fx_titlefont">
                <span className="mdc-tab__text-label">FAQ</span>
              </span>
            </label>
          </div>
        </div>

        <div className="faq_tag">
          <a href="#general">Umum</a>
          <a href="#subscription">Berlangganan & Registrasi</a>
          <a href="#wrex">Tentang Platform WREX </a>
          <a href="#affiliate">Tentang Program Afiliasi</a>
          <a href="#withdraw">Tentang Penarikan dan Biaya</a>
        </div>


        <p id="general" className="faq_tit">Umum</p>
        <div className='swanky_wrapper faq'>
          <input id='pr1' name='radio' type='checkbox' />
          <label htmlFor='pr1'>
            <span className="th_faq">1. Jelaskan kepada saya cara kerjanya WREX!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>Kami mengelola Ethereum Anda. Setiap Ethereum yang Anda tempatkan kepada kami melalui WREX Platform, kami akan menggunakan Ethereum Anda untuk membeli dan menjual koin Alt apapun di pasar pairing Ethereum di bursa utama di dunia untuk menambah jumlah Ethereum Anda.</li>
                    <li>Kami telah mengembangkan Algoritma ETH WREX 3-Layered dan algoritma ini akan memberi tahu trader kami yang ahli dan berpengalaman berapa alt coin yang harus dibeli dengan harga Ethereum tertentu.</li>
                    <li>Kegiatan kami bukan perdagangan bot, trader nyata membeli koin alt berdasarkan algoritma dan penjualan akan tergantung pada keahlian dan pengalaman trader.</li>
                    <li>Dengan algoritma kami, kami tidak pernah mengalami kerugian, kami telah mencatat laba antara 5 ~ 80% setiap bulan sejak Januari 2018. Kami tidak pernah mengalami bulan yang merugi, karena kami tidak pernah menjual satupun perdagangan dengan kerugian.</li>
                    <li>Baker Tilly, salah satu dari sepuluh firma akuntansi utama di dunia, akan datang ke kantor kami setiap bulan dan secara fisik memverifikasi semua perdagangan dan menyelesaikan persentase keuntungan bulanan yang akan tersedia untuk semua pelanggan dari situs web kami. Hal ini untuk memberikan transparansi maksimum.</li>
                    <li>Setelah persentase laba bulanan diverifikasi oleh Baker Tilly, kami akan mendistribusikan laba per skema bagi hasil kami:<br />45% - Pelanggan<br />5% - Program Afiliasi<br />50% - WREX (Perdagangan, Operasi Platform, Baker Tilly, Hukum, Pemasaran dll)</li>
                    <li>Karena kami hanya mendistribusikan dari keuntungan bulanan dan kami tidak akan pernah mengalami kerugian dalam perdagangan, modal dasar Anda dari Ethereum yang ditempatkan bersama kami tidak akan pernah menyusut. Mekanisme ini akan memastikan keberlanjutan WREX untuk waktu yang lama.</li>
                    <li>Kami memiliki dua program di mana Anda dapat menempatkan Ethereum Anda; yaitu WREX Account dan 100 ETH PLAN</li>
                  </ol>
                  <h3>WREX Account</h3>
                  <ul>
                    <li>Anda dapat menempatkan dari 1 Ethereum hingga 100 Ethereum di Akun WREX.(pembatasan 100 Ethereum hanya untuk penempatan, jika Anda melebihi batas 100 Ethereum karena laba bulanan ditambahkan setiap bulan, tidak apa-apa. Jika Anda ingin menyetor lebih dari 100 Ethereum, silahkan tulis permintaan Anda ke <a href="mailto:support@w-rex.com">support@w-rex.com</a>) </li>
                    <li>Anda perlu mempertahankan minimum 1 Ethereum di Akun WREX dari hari pertama bulan itu hingga hari terakhir bulan itu untuk mendapatkan Akun WREX - Bonus Afiliasi untuk bulan itu.</li>
                    <li>Anda harus mempertahankan minimal 1 Ethereum di Akun WREX untuk membuka hingga sepuluh 100 ETH PLAN.</li>
                    <li>
                      Anda dapat memilih antara [AUTO WITHDRAWAL] atau [AUTO RE-ACCUMULATE] untuk pembagian keuntungan bulanan. (Anda juga dapat melakukan penarikan manual kapan saja)<br />
                      * [AUTO WITHDRAWAL] - 45% dari setiap laba bulanan disetorkan ke WREX My ETH Wallet  - Anda dapat menarik ke dompet Ethereum eksternal Anda.<br />
                      * [AUTO RE-ACCUMULATION] - 45% dari setiap laba bulanan disetorkan ke Akun WREX Anda, sehingga modal dasar Anda akan tumbuh setiap bulan.
                          </li>
                    <li>Ada perbedaan dalam biaya penarikan untuk [AUTO WITHDRAWAL] dan penarikan manual. Silahkan lihat bagian ‘penarikan dan biaya’ dalam FAQ ini untuk informasi lebih lanjut.</li>
                    <li>Jika Anda menarik dan meninggalkan kurang dari 1 Ethereum di Akun WREX, semua dari 100 ETH PLAN Anda akan diakhiri secara otomatis dan semua saldo ditransfer ke WREX My ETH Wallet.</li>
                  </ul>
									<br/>
									<p>
										<strong>PERUBAHAN SYARAT dan KETENTUAN</strong><br/>
										Mulai 1 September 2020, anggota baru yang mendaftar di WREX dan membuka Akun Wrex, hanya diperbolehkan untuk menyetor 1 ETHEREUM di Akun WREX mereka.<br/>
										<br/>
										Anggota yang ada saat ini dan memiliki lebih dari 1 ETHEREUM di Akun Wrex mereka, diizinkan untuk mempertahankan ETHEREUM mereka apa adanya dan dapat menikmati manfaat penuh dari yang kami tawarkan. Namun, bahkan anggota yang ada tidak diizinkan untuk menyetor lebih banyak ETHEREUM di Akun Wrex mereka.
									</p>
                  <h3>100 ETH PLAN</h3>
                  <ul>
                    <li>Setelah Anda memiliki lebih dari 1 Ethereum yang ditempatkan di Akun WREX, Anda berhak membuat hingga sepuluh 100 ETH PLAN.</li>
                    <li>Hanya 1 Ethereum yang dapat ditempatkan di setiap 100 ETH PLAN dari WREX My ETH Wallet.</li>
                    <li>Anda harus membuat setidaknya satu 100 ETH PLAN dan mempertahankannya selama sebulan penuh untuk menerima 100 ETH PLAN - Program Afiliasi. Semua Bonus Program Afiliasi 100 ETH PLAN Anda akan disetorkan ke 100 ETH PLAN pertama Anda</li>
                    <li>Setiap 100 ETH PLAN - diset di [AUTO RE-ACCUMULATION], dan juga WREX akan mencocokkan dengan persentase pembagian keuntungan bulanan Anda, jadi di Akun WREX, 45% dari laba bulanan diakumulasikan ulang sedangkan di 100 ETH PLAN, total 90% dari laba bulanan diakumulasikan ulang, sehingga ia akan tumbuh jauh lebih cepat.</li>
                    <li>100 ETH PLAN memiliki waktu 40 bulan hingga jatuh tempo.</li>
                    <li>Anda tidak dapat menarik sebagian aset Ethereum Anda dalam 100 ETH PLAN. Namun, jika mau, Anda dapat mengakhiri setiap 100 ETH PLAN kapan saja. Setelah Anda mengakhiri satu atau beberapa 100 ETH PLAN, semua ETH Anda akan disimpan ke dalam WREX My ETH Wallet. Tidak ada penalti untuk penghentian dini setiap 100 ETH PLAN.</li>
                  </ul>
									<br/>
                  <p>* CATATAN: Tidak ada Biaya Transfer yang dibebankan untuk mentransfer dari WREX my ETH Wallet ke WREX Account / 100 ETH PLAN.</p>
									<br/>
									<p>
										<strong>PERUBAHAN SYARAT dan KETENTUAN</strong><br/>
										Mulai 1 Agustus 2020, semua 100 ETH PLAN yang dibuat setelah tanggal tersebut harus mengikuti syarat dan ketentuan yang baru.<br/>
										<br/>
										<strong>SEBELUM:</strong><br/>
										Hanya 1 ETHEREUM yang diizinkan untuk disimpan ketika 100 ETH PLAN baru dibuat.<br/>
										Tidak ada penalti untuk pemberhentian dini.<br/>
										<strong>SESUDAH:</strong><br/>
										Anda sekarang dapat menyetor dari 1 ETHEREUM hingga 100 ETHEREUM dalam satu 100 ETH PLAN<br/>
										Kematangan berkurang dari 40 bulan menjadi 24 bulan. Denda untuk pemberhentian dini berlaku.<br/>
										Penalti: pembayaran keuntungan 100% dikurangi menjadi 25%.
									</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2' name='radio' type='checkbox' />
          <label htmlFor='pr2'>
            <span className="th_faq">2.	Jelaskan kepada saya bagaimana cara berlangganan dan memulai!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>Anda membutuhkan minimum 1 Ethereum + 1 Ethereum biaya network (GAS)</li>
                    <li>Masuk ke <a href="www.w-rex.com">www.w-rex.com</a>, daftar, dan dapatkan Alamat WREX Ethereum Anda</li>
                    <li>Transfer Ethereum Anda menggunakan Alamat WREX Ethereum Anda.</li>
                    <li>Setelah Anda memiliki lebih dari 1 Ethereum di WREX Ethereum Wallet, sekarang Anda dapat mentransfer 1 - 100 Ethereum ke Akun WREX untuk membuka Akun WREX.</li>
                    <li>Jika Anda ingin mendaftar dalam satu atau beberapa (hingga sepuluh) 100 ETH PLAN, buat sebanyak sepuluh 100 ETH PLAN dan transfer masing-masing 1 Ethereum untuk setiap 100 ETH PLAN.<br />* CATATAN: Tidak ada Biaya Transfer yang dibebankan untuk mentransfer Ethereum Anda dari WREX my ETH Wallet ke Akun WREX / 100 ETH PLAN.</li>
                    <li>Periksa akun Anda, laporan bulanan, status aset Anda dari WEB atau APP.<br />* Laporan Bulanan tersedia pada tanggal 8 setiap bulan. Pada tanggal 10 bulan itu, status aset akun Anda akan diperbarui untuk mencerminkan laba bulan sebelumnya, dan juga pada tanggal 10, semua AUTO WITHDRAWALS akan dijalankan. Bagi mereka yang memilih [AUTO RE-ACCUMULATION] dan aset dalam 100 ETH PLAN akan mulai diperdagangkan sejak hari pertama pada bulan.</li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3' name='radio' type='checkbox' />
          <label htmlFor='pr3'>
            <span className="th_faq">3. Jelaskan kepada saya bagaimana Program Afiliasi bekerja!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>Setelah Anda mendaftar di WREX, Anda akan mendapatkan Kode Afiliasi eksklusif Anda. Silahkan berikan kode ini kepada teman, keluarga, dan orang lain saat mendaftar. Mereka harus memasukkan Kode Afiliasi untuk menyelesaikan pendaftaran.</li>
                    <li>Dalam menu afiliasi, Anda dapat melihat berapa banyak afiliasi yang Anda miliki, berapa banyak Ethereum yang direferensikan terdaftar di WREX dan berapa banyak Ethereum yang Anda peroleh melalui Program Afiliasi.</li>
                    <li>
                      Contoh Kasus<br />
                      Pelanggan A mendaftarkan 2 Ethereum (1 ETH dalam Akun WREX & 1 ETH dalam 100 ETH PLAN) di WREX.<br />
                      Registrasi B / C / D / E Pelanggan di WREX menggunakan Kode Afiliasi Pelanggan.<br />
                      Pelanggan B menyetor 11 Ethereum (1 ETH di Akun WREX & 10 ETH dalam sepuluh 100 ETH PLAN)<br />
                      Pelanggan C mendepositkan 5 Ethereum (1 ETH dalam Akun WREX & 4 ETH dalam empat 100 ETH PLAN)<br />
                      Pelanggan D menyetor 100 Ethereum (90 ETH dalam Akun WREX & 10 ETH dalam 	sepuluh 100 ETH PLAN)<br />
                      Pelanggan E menyetor 2 Ethereum (1 ETH dalam Akun WREX & 1 ETH dalam satu 100 ETH PLAN)<br />
                      Ini berarti, Pelanggan A memiliki total 93 Ethereum yang dirujuk dalam Akun WREX dan 25 Ethereum yang dirujuk dalam 100 ETH PLAN.<br />
                      Jadi, setiap bulan, setelah persentase laba bulanan dikonfirmasi oleh laporan bulanan Baker Tilly ("MP%"),<br />
                      Bonus Afiliasi Akun WREX: [93 Ethereum] x [MP%] x 5% akan disetorkan ke Akun WREX Anda (jika Anda telah memilih [AUTO WITHDRAW], maka Bonus Afiliasi ini akan dimasukkan ke My ETH Wallet)<br />
                      Bonus Afiliasi 100 ETH PLAN: [25 Ethereum] x [MP%] x 5% akan dimasukkan ke dalam 100 ETH PLAN pertama Anda.<br />
                    </li>
                    <li>
                      4. Mengapa Program Afiliasi kami sangat kuat?<br />
                      * Kesinambungan - pembayaran program afiliasi kami adalah 5% dari laba setiap bulan. Ini akan bertahan selamanya! <br />
                      * Setiap bulan, jika Akun WREX Anda yang terafiliasi menggunakan [AUTO RE-ACCUMULATION], berarti setiap laba bulanan ditambahkan ke masing-masing Akun WREX mereka. Ini juga berarti setiap bulan, Ethereum yang Anda rujuk akan bertambah, sehingga keseluruhan Akun WREX Anda - Bonus Afiliasi juga akan bertambah.<br />
                      * Formula: [Ethereum yang Anda rujuk di Akun WREX] x [MP%] x 5%<br />
                      * EverySetiap bulan, Ethereum yang Anda rujuk dalam 100 ETH PLAN akan tumbuh lebih cepat daripada rujukkan pada Akun WREX. Bonus Afiliasi Anda juga akan bertambah karena didasarkan pada jumlah Ethereum yang dirujuk dalam 100 ETH PLAN. Kecuali jika Ethereum yang Anda rujuk dalam 100 ETH PLAN dihentikan, Anda akan menerima 100 ETH PLAN Anda - Bonus Afiliasi setiap bulan.<br />
                      * Formula: [Ethereum yang Anda rujuk dalam 100 ETH PLAN] x [MP%] x 5%<br />
                      * Setiap orang yang terdaftar di Platform WREX akan menikmati 45% dari Laba Bulanan untuk Akun WREX yang sama berdasarkan jumlah Ethereum mereka dan 90% dari Laba Bulanan untuk 100 ETH PLAN terlepas dari berapa banyak Ethereum afiliasi yang mereka miliki. Jadi, semua orang akan mengapresiasi Anda yang telah memperkenalkan platform kami kepada mereka. Jadi, jangan malu memberi tahu keluarga, teman, dan jaringan terdekat Anda.<br />
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="subscription" className="faq_tit">Berlangganan & Registrasi</p>
        <div className='swanky_wrapper faq'>
          <input id='pr2_1' name='radio' type='checkbox' />
          <label htmlFor='pr2_1'>
            <span className="th_faq">1.	Bagaimana cara saya membeli Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ada beberapa cara untuk membeli Ethereum di wilayah Anda. Salah satunya adalah menemukan tempat penukaran mata uang kripto lokal yang menerima fiat (mata uang lokal Anda). Buat akun, tautkan dengan akun bank Anda, setor tunai ke akun Anda, lalu beli Ethereum.<br />
                    Cara lain adalah menemukan Over The Counter. Ini disebut broker Cryptocurrency yang membeli dan menjual cryptocurrency offline. Cobalah untuk google mereka dan cari satu di dekat wilayah Anda. Temui mereka secara pribadi dan bertukar. Namun, perlu diketahui bahwa Anda harus bertemu langsung dengan mereka dan melakukan transaksi, jika tidak, Anda mungkin akan jatuh ke tangan yang salah.<br />
                    Jika Anda tidak terbiasa dengan cara membeli Ethereum, solusi terbaik adalah selalu bertanya kepada orang yang memperkenalkan Anda ke WREX Platform.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_2' name='radio' type='checkbox' />
          <label htmlFor='pr2_2'>
            <span className="th_faq">2.	Apa yang dimaksud dengan biaya network Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Blockchain Ethereum adalah network/jaringan. Eter (ETH) adalah bahan bakar untuk jaringan itu. Saat Anda mengirim token, berinteraksi dengan kontrak, mengirim token ETH atau ERC20, atau melakukan hal lain di blockchain, Anda harus membayar perhitungan itu. Pembayaran itu dihitung dalam gas, dan gas selalu dibayar dalam ETH.<br />
                    [Referensi: Google.com]<br />
                    Jadi, ketika Anda mengirim Ethereum dari dompet eksternal Anda (pertukaran) ke WREX My ETH Wallet, Anda harus membayar GAS. Pastikan Anda memiliki cukup GAS sebelum mengirim Ethereum ke WREX. Jika Anda membeli 1 atau 2 Ethereum untuk mendaftar di WREX itu berarti Anda tidak memiliki cukup GAS untuk mengirim Ethereum kepada kami. Periksa biaya GAS terbaru dan pastikan untuk membeli 2 Ethereum + GAS untuk mentransfer 2 Ethereum penuh ke WREX.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_3' name='radio' type='checkbox' />
          <label htmlFor='pr2_3'>
            <span className="th_faq">3.	Apa yang dimaksud dengan kode afiliasi? Saya tidak memiliki kode afiliasi, bagaimana saya bisa mendaftar?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Setelah Anda menjadi pelanggan WREX, Anda akan menerima kode afiliasi pribadi Anda. Kode afiliasi ini akan digunakan oleh teman Anda ketika mereka mendaftar di situs kami. Begitu mereka memasukkan kode afiliasi Anda, semua bonus afiliasi akan masuk ke akun Anda. <br />
                    Jika kode afiliasi telah dimasukkan, mereka tidak dapat mengubah kode afiliasi. Anda juga perlu memasukkan kode afiliasi untuk menyelesaikan pendaftaran.<br />
                    Hal ini untuk memastikan semua pelanggan kami yang menyediakan waktu dan upaya untuk mempromosikan WREX mendapatkan kredit mereka. WREX tidak memulai 	promosi kami sendiri untuk menarik pelanggan baru setiap saat, jadi Anda pasti pernah mendengar tentang situs kami dari seseorang dan kami merasa bahwa orang tersebut harus diberi penghargaan.<br />
                    Jika Anda benar-benar secara tidak sengaja mengetahui tentang kami dengan menjelajahi internet, dan karena itu tidak memiliki kode afiliasi untuk dimasukkan, silahkan menulis kepada kami di <a href="mailto:support@w-rex.com">support@w-rex.com</a><br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_4' name='radio' type='checkbox' />
          <label htmlFor='pr2_4'>
            <span className="th_faq">4.	Saya kehilangan akses ke alamat email yang saya gunakan saat mendaftar, apa yang harus saya lakukan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    ID Anda di WREX adalah alamat email Anda. Terkadang Anda dapat kehilangan hak istimewa untuk mengakses akun email yang Anda gunakan untuk mendaftar di WREX terutama jika Anda telah menggunakan akun email perusahaan. <br />
                    Dalam kasus apa pun, Anda kehilangan akses ke akun email Anda, silahkan tulis kepada kami di <a href="mailto:support@w-rex.com">support@w-rex.com</a> untuk bantuan.<br />
                    Berhati-hatilah saat mendaftar dan jangan menggunakan akun email perusahaan Anda.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_5' name='radio' type='checkbox' />
          <label htmlFor='pr2_5'>
            <span className="th_faq">5.	Apa itu otentikasi 2 faktor google (2FA)?  </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ini mirip dengan perangkat OTP perbankan Anda, hanya saja Anda dapat mengunduhnya dari google playstore. Unduh Google OTP. Setelah menambahkan WREX 2FA QR CODE, Anda akan melihat 6 digit angka di aplikasi Google OTP Anda. Jumlahnya diperbarui setiap 30 detik.<br />
                    2 FA ini adalah fitur keamanan tambahan saat melakukan penarikan.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_6' name='radio' type='checkbox' />
          <label htmlFor='pr2_6'>
            <span className="th_faq">6.	Saya kehilangan ponsel dan kehilangan google 2FA, bagaimana cara mereset 2FA?  </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ya, Anda dapat mengatur ulang 2-FA Anda. Ketika Anda mendapatkan telepon baru, unduh Google OTP lagi dan tambahkan dengan cara membaca QR CODE Anda menggunakan aplikasi. Namun, saat Anda mereset 2FA, email verifikasi perlu dikonfirmasi.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_7' name='radio' type='checkbox' />
          <label htmlFor='pr2_7'>
            <span className="th_faq">7.	Saya kehilangan password, bagaimana saya bisa mendapatkannya kembali?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Kehilangan password bisa menjadi proses yang sulit untuk mengembalikannya jadi pastikan untuk tidak kehilangan password Anda. Jika Anda kehilangan password dan tidak bisa masuk ke WREX, silakan tulis kepada kami di <a href="mailto:support@w-rex.com">support@w-rex.com</a> <br />
                    Kami harus melalui proses pengaturan ulang password yang dapat memakan waktu karena perlu menghubungi referrer Anda untuk memastikan akun itu milik Anda. Ini adalah prosedur standar kami untuk memastikan tidak ada orang lain selain Anda yang dapat mengubah password.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_8' name='radio' type='checkbox' />
          <label htmlFor='pr2_8'>
            <span className="th_faq">8.	Saya ingin menyetor lebih dari 100 Ethereum, sepertinya Anda telah menetapkan batas deposit 100 Ethereum. Bisakah saya menyimpan lebih dari 100 Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ya, Anda dapat menyimpan lebih dari 100 Ethereum setelah Anda menyelesaikan KYC (Know Your Customer) Level 2 kami. Ini melibatkan pengiriman salinan paspor Anda dan dokumentasi lain yang diperlukan. Silakan tulis kepada kami di <a href="mailto:support@w-rex.com">support@w-rex.com</a> untuk bantuan lebih lanjut.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_9' name='radio' type='checkbox' />
          <label htmlFor='pr2_9'>
            <span className="th_faq">9.	Saya mendepositkan 10 Ethereum di Akun WREX, tetapi saya hanya mendapat setengah dari persentase laba bulanan sebagai imbalan, bagaimana bisa?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    WREX adalah platform yang sangat sensitif terhadap waktu, dan apakah Anda berhak menerima 100%, 50%, 25% atau 0% dari laba bulanan pada bulan pertama tergantung pada hari apa Anda mengaktifkan Akun WREX. Ini hanya terjadi pada bulan pertama pada Ethereum yang baru disimpan. Setelah bulan pertama berlalu, bulan-bulan berikutnya Anda akan menerima 100% dari persentase keuntungan bulanan.<br />
                    Jadi, jika Anda hanya mendapatkan 50% dari laba bulanan pada bulan itu, kemungkinan besar karena ketika Anda menyimpan Ethereum di WREX, ini terjadi antara hari pertama hingga hari kesepuluh pada bulan itu. Jika demikian, Anda berhak menerima 50% dari laba bulanan. Silahkan merujuk ke tabel di bawah ini untuk informasi Anda.<br /><br />
                    Disetor antara	% Laba Bulanan<br />
                    1st Day ~ 10th Day	50%<br />
                    11th Day ~ 20th Day	25%<br />
                    21st Day ~ Last Day	0%<br /><br />
                    Untuk 100 ETH PLAN, semua setoran hanya berlaku pada hari pertama bulan berikutnya. Apakah Anda menyetor dan membuat 100 ETH PLAN pada hari pertama atau hari terakhir dalam sebulan, 100 ETH PLAN yang baru akan dimulai pada bulan berikutnya.
                        </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="wrex" className="faq_tit">Tentang Platform WREX </p>
        <div className='swanky_wrapper faq'>
          <input id='pr3_1' name='radio' type='checkbox' />
          <label htmlFor='pr3_1'>
            <span className="th_faq">1.	Saya telah melihat situs dan produk yang sangat mirip, apa yang membuatnya berbeda dari yang lain? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ya, industri telah melihat banyak situs serupa dengan produk dan penawaran yang serupa.<br />
                    For Contohnya,
                            </p>
                  <ol>
                    <li>
                      Menawarkan "Principle Guarantee" (Garansi Modal Dasar)<br />
                      Kami secara teknis tidak menjamin modal dasar, ini karena melanggar hukum di beberapa negara untuk menjamin modal dasar tersebut. Jadi, jika Anda mendengar produk apa pun yang menawarkan "Principle Guarantee", itu tidak benar.<br />
                      Namun, bagi hasil kami didasarkan pada KEUNTUNGAN pada bulan tersebut. Jika kami menghasilkan keuntungan, kami membebankan 50% dari keuntungan sebagai biaya perdagangan kami. Jika kami melakukan "0"%, maka tidak ada biaya. Jika kami melakukan negatif, yang tidak pernah kami miliki sejak awal proyek, kami akan memulihkan jumlah Ethereum yang hilang dari celengan modal kami dengan biaya yang telah kami kumpulkan di masa lalu.
                              </li>
                    <li>
                      Menawarkan “a specific return a month on principle" (laba spesifik satu bulan pada modal dasar)<br />
                      Sekali lagi, di beberapa negara, menyatakan persentase tertentu sebagai jaminan keuntungan adalah ilegal. Karena itu, kami tidak menjamin laba spesifik sebulan. Dari pengalaman dan kinerja kami di masa lalu, kami telah menghasilkan antara 5% ~ 80% laba pada modal dasar sebulan, dan tidak pernah mencatat bulan yang merugi.
                              </li>
                    <li>
                      Proses Verifikasi Bulanan Dilakukan oleh Baker Tilly<br />
                      Setiap bulan, antara hari pertama hingga hari ke-5, Baker Tilly akan mengunjungi kantor kami dan melakukan verifikasi manual pada semua perdagangan tunggal yang telah kami selesaikan pada bulan sebelumnya, verifikasi pada persentase keuntungan bulanan dan status akun. Pada tanggal 8 bulan itu, laporan bulanan tersebut harus dipublikasikan dan dapat dilihat di situs web untuk pelanggan kami. Menambahkan transparansi maksimum.
                              </li>
                    <li>
                      Biaya Kami - 50% dari laba bulanan!<br />
                      Ya, kami juga merasa biaya kami SANGAT TINGGI. Tetapi dengan 5% ~ 80% catatan kinerja sebulan, kami sangat yakin bahwa LABA ANDA DALAM SEBULAN ADALAH YANG TERTINGGI yang dapat ditawarkan siapapun atau organisasi manapun kepada Anda. Juga, biaya tinggi kami adalah untuk keberlanjutan kami.
                              </li>
                    <li>
                      Kami tidak pernah menyentuh MODAL DASAR Anda, MODAL DASAR Anda hanya akan TUMBUH.<br />
                      Di mana sebagian besar situs serupa GAGAL, untuk mengumpulkan dana, mereka memberikan komisi kepada mereka yang mempromosikan situs mereka dan mendapatkan dana. Mereka menggunakan MODAL DASAR untuk membayar komisi itu.<br />
                      Kami di WREX, 100% MODAL DASAR digunakan untuk berdagang demi keuntungan. Kami tidak akan pernah menyentuh satupun Ethereum dari MODAL DASAR. Ini juga akan diverifikasi dalam VERIFIKASI AKUN oleh Baker Tilly setiap bulan.<br />
                      Jadi, kecuali kami berkinerja negatif, MODAL DASAR kami tidak akan pernah menyusut. Itu hanya akan TUMBUH karena kami tidak pernah gagal untuk mencatat 5% ~ 80% sebulan sejak layanan kami dimulai. <br />
                      Seburuk-buruknya, kami melakukan "0"% sebulan, jika tidak ada yang dibeli dan tidak ada yang dijual.
                              </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_2' name='radio' type='checkbox' />
          <label htmlFor='pr3_2'>
            <span className="th_faq">2. Bagaimana WREX memastikan untuk tidak menghilangkan Ethereum saya?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ini karena ATURAN DASAR # 1 dari pedagang kami yang ahli dan berpengalaman, ‘tidak pernah menjual dengan kerugian’. Kami tidak akan menjual jika merugikan. Tidak pernah melakukannya di masa lalu, dan tidak pernah berniat mengubah aturan # 1 kami di masa depan.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_3' name='radio' type='checkbox' />
          <label htmlFor='pr3_3'>
            <span className="th_faq">3.	Apakah ada jaminan pada modal dasar saya?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Secara resmi tidak, kami tidak dapat menjamin modal dasar Anda. Namun, seperti yang disebutkan di atas, kami mengambil biaya perdagangan kami jika hanya ada keuntungan bulanan.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_4' name='radio' type='checkbox' />
          <label htmlFor='pr3_4'>
            <span className="th_faq">4.	Berapa banyak laba sebulan yang bisa saya harapkan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Dari awal 2018 hingga hari ini, kami telah mencatat laba 5% ~ 80% sebulan pada modal dasarnya. Namun ini hanya merupakan indikasi dan tidak dapat dijamin.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_5' name='radio' type='checkbox' />
          <label htmlFor='pr3_5'>
            <span className="th_faq">5.	Apa saja produk yang ditawarkan WREX?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Saat ini, kami menawarkan dua program, satu adalah WREX Account dan yang lainnya adalah 100 ETH PLAN.<br />
                    WREX Account (Akun WREX)<br />
                  </p>
                  <ol>
                    <li>Anda dapat melakukan deposit dari 1 Ethereum hingga 100 Ethereum di Akun WREX. Jika Anda ingin menyimpan lebih dari 100 Ethereum di Akun WREX, silahkan hubungi kami di <a href="mailto:support@w-rex.com">support@w-rex.com</a></li>
                    <li>Anda dapat memilih [AUTO WITHDRAW] atau [AUTO RE-ACCUMULATE] untuk keuntungan bulanan di Akun WREX. (Anda bisa memilih AUTO RE-ACCUMULATE dan melakukan penarikan manual kapan saja)</li>
                    <li>Untuk Akun WREX, [# dari Ethereum di Akun WREX] x 45% x Keuntungan Bulanan% akan ditarik / WITHDRAWN ke My ETH Wallet atau diakumulasi kembali / RE-ACCUMULATED ke dalam Akun WREX Anda tergantung pada opsi yang dipilih.</li>
                    <li>Anda harus mempertahankan minimal 1 Ethereum di Akun WREX dari hari pertama bulan itu hingga hari terakhir bulan itu untuk menerima bonus afiliasi bulan itu untuk semua Akun WREX yang telah Anda referensikan, jika ada.</li>
                    <li>Anda harus mempertahankan minimal 1 Ethereum di Akun WREX untuk membuka dan memelihara hingga sepuluh 100 ETH PLAN.</li>
                  </ol>
                  <p>100 ETH PLAN</p>
                  <ol>
                    <li>Anda dapat membuka hingga sepuluh 100 ETH PLAN per akun.</li>
                    <li>Anda harus mempertahankan minimum 1 Ethereum di Akun WREX.</li>
                    <li>Anda dapat menyetorkan HANYA 1 Ethereum per 100 ETH PLAN</li>
                    <li>Semua Bonus Afiliasi 100 ETH PLAN Anda, jika ada, akan masuk ke dalam 100 ETH PLAN pertama Anda</li>
                    <li>Untuk 100 ETH PLAN, [# of Ethereum dalam 100 ETH PLAN] x 90% x Laba Bulanan% akan menjadi saldo baru Anda di bulan berikutnya.</li>
                    <li>Jatuh tempo 100 ETH PLAN adalah setelah bulan ke-40.</li>
                    <li>Anda tidak dapat menarik jumlah berapapun dari 100 ETH PLAN. Anda hanya dapat melakukan penghentian dini. Tidak ada penalti untuk penghentian dini.</li>
                  </ol>
                  <p>Silahkan lihat pertanyaan berikutnya yang menjelaskan perbedaan antara kedua akun.</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_6' name='radio' type='checkbox' />
          <label htmlFor='pr3_6'>
            <span className="th_faq">6.	Apa perbedaan antara Akun WREX dan 100 ETH Plan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>Anda harus memiliki minimal 1 Ethereum di Akun WREX, dan Anda akan menerima, <br /></p>
                  <ol>
                    <li>Layak untuk membuka hingga sepuluh 100 ETH PLAN</li>
                    <li>Layak untuk menerima Akun WREX - Bonus Afiliasi, jika ada</li>
                  </ol>
                  <p>
                    Anda harus memiliki minimal satu 100 ETH PLAN, dan Anda akan menerima, Layak untuk menerima 100 ETH PLAN - Bonus Afiliasi, jika ada.
                          Perbedaan dalam Akun WREX dan 100 ETH PLAN adalah tingkat pertumbuhannya. Sebagaimana dijelaskan di atas 1 Ethereum dalam Akun WREX akan tumbuh menjadi 15,6 Ethereum setelah bulan ke-40 jika kita mencapai persentase laba bulananrata-rata 15,8%. Pada kondisi yang sama, 100 ETH PLAN akan tumbuh menjadi 112 Ethereum. Jadi, mengapa berbeda?<br /><br />
                    Karena dalam Akun WREX, 45% dari laba bulanan% diakumulasikan kembali ke Akun WREX Anda, sedangkan, dalam 100 ETH PLAN, tidak hanya 45% tetapi WREX akan menyamakan 45% Anda, jadi alih-alih 45%, total 90 % dari laba bulanan akan diakumulasikan ulang sehingga pertumbuhannya jauh lebih cepat dibandingkan dengan Akun WREX.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_7' name='radio' type='checkbox' />
          <label htmlFor='pr3_7'>
            <span className="th_faq">7.	Jika saya menyetor 2 Ethereum, berapa laba yang dapat saya harapkan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Dengan 2 Ethereum disimpan di kami, 1 Ethereum di Akun WREX dan 1 Ethereum dalam satu 100 ETH PLAN untuk memaksimalkan laba dan juga memenuhi syarat untuk Bonus Afiliasi keduanya;<br />
                    Jika Perdagangan WREX mencapai rata-rata 15,8% laba bulanan setiap bulan dan Anda memilih opsi [AUTO RE-ACCUMULATE] untuk Akun WREX Anda<br />
                    Laba yang diharapkan pada akhir bulan ke-40 untuk Akun WREX adalah 15,6 Ethereum dan 112 Ethereum untuk 100 ETH PLAN. Jadi total adalah 117,6 Ethereum.<br />
                    Perhitungan atau ramalan di atas ini bahkan tanpa adanya satupun bonus afiliasi.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_8' name='radio' type='checkbox' />
          <label htmlFor='pr3_8'>
            <span className="th_faq">8.	Apa peran Baker Tilly?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ini semua tentang transparansi. Baker Tilly adalah salah satu dari sepuluh firma akuntansi terkemuka dan terbesar di dunia.<br />
                    Setiap bulan, antara hari pertama hingga hari ke-5, Baker Tilly akan mengunjungi kantor kami dan melakukan verifikasi manual pada semua perdagangan tunggal yang telah kami selesaikan pada bulan sebelumnya, verifikasi pada persentase keuntungan bulanan dan status akun. Pada tanggal 8 bulan itu, laporan bulanan tersebut harus dipublikasikan dan dapat dilihat di situs web untuk pelanggan kami. Menambahkan maksimum transparansi.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="affiliate" className="faq_tit">Tentang Program Afiliasi </p>
        <div className='swanky_wrapper faq'>
          <input id='pr4_1' name='radio' type='checkbox' />
          <label htmlFor='pr4_1'>
            <span className="th_faq">1.	Sebagai influencer, apakah saya mendapatkan hasil atas upaya yang saya lakukan? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Ya, kamu pasti akan mendapatkan hasil. Siapapun yang ingin menjadi pelanggan di WREX, saat mendaftar setiap orang harus memasukkan kode afiliasi untuk menyelesaikan pendaftaran. Kami ingin memastikan siapapun yang melakukan upaya ekstra dan menghabiskan waktu untuk mempromosikan WREX harus mendapatkan manfaat penuh.
                          </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_2' name='radio' type='checkbox' />
          <label htmlFor='pr4_2'>
            <span className="th_faq">2. Apa bedanya dengan Program Afiliasi lainnya?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Dalam banyak lingkungan terkait crypto, Anda akan menemukan banyak jenis program afiliasi mereka sendiri. Kebanyakan dari mereka adalah;
                        </p>
                  <ol>
                    <li>1. Anda dapat menghilangkan kode afiliasi dan mereka masih menerima pendaftaran, sehingga Anda akan kehilangan beberapa rujukan hanya karena mereka lupa memasukkan kode afiliasi atau mereka hanya tidak ingin memasukkannya</li>
                    <li>2. Jika referrer Anda berhenti menggunakan layanan untuk alasan apapun, Anda tidak akan mendapatkan bonus</li>
                  </ol>
                </li>
              </ul>
              <p>
                Di WREX, anggota baru harus memasukkan kode afiliasi untuk menyelesaikan pendaftaran sehingga semua orang mendapat manfaat dan tidak ada yang terjadi tanpa imbalan. Selain itu, dalam sifat bisnis kami, referrer Anda tidak boleh meninggalkan layanan kami karena menghasilkan keuntungan bagi setiap orang setiap bulan. Jadi, referrer Anda akan tetap selamanya dan karenanya Anda akan menerima bonus afiliasi Anda setiap bulan selamanya.<br />
                Pada akhirnya, setiap bulan, jumlah Ethereum yang Anda referensikan akan tumbuh per persentase keuntungan bulanan. Ini berarti bahwa bonus afiliasi Anda juga akan tumbuh karena didasarkan pada Ethereum yang Anda referensikan ke sistem.
                    </p>
            </div>
          </label>
          <input id='pr4_3' name='radio' type='checkbox' />
          <label htmlFor='pr4_3'>
            <span className="th_faq">3.	Apakah ini multi level marketing?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Tidak, ini bukan multi level marketing. Anda hanya akan mendapat bonus afiliasi dari referensi langsung Anda.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_4' name='radio' type='checkbox' />
          <label htmlFor='pr4_4'>
            <span className="th_faq">4.	Jika saya memiliki 100 teman yang terdaftar, berapa banyak penghasilan dari Program Afiliasi yang dapat saya harapkan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Studi Kasus:<br />
                    Jika Anda merujuk 100 teman ke WREX, semua yang terdaftar dan semua orang menyetor masing-masing 2 Ethereum (1 Ethereum di Akun WREX dan 1 Ethereum di 100 ETH PLAN)<br />
                    Jadi, ini berarti, total Ethereum yang Anda referensikan adalah 200 Ethereum; 100 Ethereum dalam Akun WREX, dan 100 Ethereum dalam 100 ETH PLAN.<br />
                    Jika WREX mencapai 15,8% bulan ini sebagai persentase keuntungan bulanan, <br /><br />
                    BONUS ANDA dihitung sebagai berikut:<br />
                  </p>
                  <ol>
                    <li>Akun WREX - Bonus Afiliasi<br />[# dari Ethereum yang dirujuk dalam Akun WREX] x 5% x [Laba Bulanan%][100 Ethereum] x 5% x 15,8% = 0,79 Ethereum</li>
                    <li>100 ETH PLAN - Bonus Afiliasi<br />[# dari Ethereum yang dirujuk dalam 100 ETH PLAN] x 5% x [Laba Bulanan%][100 Ethereum] x 5% x 15,8% = 0,79 Ethereum</li>
                  </ol>
                  <p>	Jadi, total Ethereum yang diperoleh dari kedua akun adalah 1.58 Ethereum. Jika Anda mengakumulasikan bonus ini selama 40 bulan, bonus afiliasi Anda pada 100 teman Anda akan berada dalam 1.000an Ethereum.</p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="withdraw" className="faq_tit">Tentang Penarikan dan Biaya</p>
        <div className='swanky_wrapper faq'>
          <input id='pr5_1' name='radio' type='checkbox' />
          <label htmlFor='pr5_1'>
            <span className="th_faq">1.	Bagaimana cara kerja penarikan, dan apakah ada biaya penarikan?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Bagaimana cara kerja penarikan, dan apakah ada biaya penarikan?
                          </p>
                          <ul>
                            <li>Jika Anda menjalankan [AUTO WITHDRAW] dari Akun WREX Anda, keuntungan bulanan Anda akan ditransfer langsung ke WREX My ETH Wallet Anda pada tanggal 10 setiap bulan dan siap untuk ditransfer ke dompet eksternal Anda sekaligus. [AUTO WITHDRAW] Ada biaya penarikan 0,5% dari total Ethereum. </li>
                    <li>Jika Anda melakukan penarikan manual dari Akun WREX Anda, jumlah yang Anda minta akan ditransfer ke WREX My ETH Wallet Anda dalam waktu minimum 72 jam hingga maksimum 4 minggu dari permintaan Anda.
                              <br />*Keluar paksa yang mendesak karena permintaan penarikan dapat merusak persentase keuntungan bulanan. Jadi perubahan kebijakan ini adalah untuk meningkatkan protokol untuk meminimalkan kerusakan dan memaksimalkan keuntungan bagi semua orang dalam komunitas WREX.
                              <br />[Manual Withdraw] Ada biaya penarikan 1,0% dari total Ethereum yang diminta.</li>
                    <li>Anda tidak dapat menarik jumlah berapapun dari 100 ETH PLAN Anda. <br />Anda hanya dapat menghentikan 100 ETH PLAN. Setelah dihentikan, Ethereum Anda akan ditransfer ke WREX My ETH Wallet Anda pada hari ke 10 bulan berikutnya. Tidak ada penalti untuk penghentian dini. Hanya biaya penarikan yang berlaku. <br />[100 ETH PLAN] Biaya Penghentian / Jatuh tempo 1,0% dari total Ethereum yang diminta.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </label>
        </div>


      </div>

    )
  }
}

export default FaqPageID;
