import React, { Component } from 'react';

/**
 * Renders the preloader
 */
class NewsEN extends Component {

	render() {
		return (
			<div>
				<div className="f_single fx_single_tbl">
					<div className="tabset">
						<label htmlFor="tab2">
							<span className="mdc-tab__content fx_titlefont">
								<span className="mdc-tab__text-label">Announcements & News</span>
							</span>
						</label>
					</div>
				</div>

				<div className='swanky_wrapper faq'>
				<input id='nr16' name='radio' type='checkbox' />
					<label htmlFor='nr16'>
						<span className="th_faq">Aug 20th, 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								Announcement for August
								</p>

								<ol>
									<li>
									Our July performance was recorded to be 3.71% for the monthly profit. Since COVID19, we have been recording much below expected ‘double digit’ for the monthly profit level due to large sums of withdrawals. Since April, some members have been withdrawing larger ETH, their principles and their profits. We suspect that due to COVID19 their monthly income had been reduced and by cashing out WREX investment to support living costs was one of the major reasons for such withdrawals.
									<br />
									This trend is supported by the fact that prior to COVID19, all monthly withdrawals combined was less than 1% of the total ETH under management but since COVID19 for the past four consecutive months, we had more withdrawals then deposits.
									<br/>
									<table className="simpeltable">
											<tbody>
												<tr>
													<td colspan="2" scope="Ranking">PRE COVID 19</td>
													<td colspan="2" scope="Prize">DURING COVID 19</td>
												</tr>
												<tr>
													<td data-label="Ranking">Sep 2019</td>
													<td data-label="Prize">16.52%</td>
													<td data-label="Ranking">Feb 2020</td>
													<td data-label="Prize">3.94%</td>	
												</tr>
												<tr>
													<td data-label="Ranking">Oct 2019</td>
													<td data-label="Prize">11.72%</td>
													<td data-label="Ranking">Mar 2020</td>
													<td data-label="Prize">4.71%</td>	
												</tr>
												<tr>
													<td data-label="Ranking">Nov 2019</td>
													<td data-label="Prize">10.07%</td>
													<td data-label="Ranking">Apr 2020</td>
													<td data-label="Prize">3.31%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Dec 2019</td>
													<td data-label="Prize">6.80%</td>
													<td data-label="Ranking">May 2020</td>
													<td data-label="Prize">3.48%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Jan 2020</td>
													<td data-label="Prize">9.92</td>
													<td data-label="Ranking">June 2020</td>
													<td data-label="Prize">5.29%</td>
												</tr>
												<tr>
													<td data-label="Ranking"></td>
													<td data-label="Prize"></td>
													<td data-label="Ranking">July 2020</td>
													<td data-label="Prize">3.71%</td>
												</tr>
											</tbody>
										</table><br/>
									</li>
									<li>
									Rest assured, to our members, our system is designed so that we distribute only the PROFITs that we earn through our algorithm trading. Unlike many other platforms we do NOT payout from the PRINCIPLES to attract more investments nor we use the PRINCIPLE to motivate members to attract more investments. Purely, the monthly profit sharing is only from our earnings, meaning every month our ASSETs (ETHEREUM NUMBER) shall grow as the PRINCIPLE is secured and out growth is based on the monthly profit. Every Month!
									<br /><br/>
									</li>
									<li>
									However, such large number of withdrawals are impacting our existing portfolio and our monthly performance. Most of the time when we have to meet our promise to our members who requests withdrawals, we may have to exit prematurely from a position and therefore our profits are the direct results of such ‘sums of pre-matured exits’.
									<br /><br/>
									</li>
									<li>
									In order to protect our existing clients and our portfolio, and prepare for ‘double digit’ monthly profit starting September 2020 and to maintain our profitability, we have internally made a few changes. <font className="emphsize2 boldfont">The change however will not impact on the EXISTING MEMBERS and its assets!</font> Only to those who are setting up their accounts starting August 1st, 2020 shall be applied with the new terms and conditions:
									<br />
									<ol>
									<li>
									Wrex Account
									<table className="simpeltable">
											<tbody>
												<tr>
													<td className="emphsize boldfont" scope="Ranking">BEFORE</td>
													<td className="emphsize boldfont" scope="Ranking">AFTER</td>
												</tr>	
												<tr>
													<td scope="Ranking">Minimum 1 ETH in WREX ACCOUNT</td>
													<td scope="Ranking">ONLY 1 ETH in WREX ACCOUNT</td>
												</tr>	
										</tbody>
									</table>
									** IMPORTANT: Existing members, from September 1st, 2020, you shall not be able to deposit new ETHEREUM in WREX ACCOUNT. You can maintain as is and you shall enjoy the same benefit as before. 

									</li>
									<li>
									100 ETH PLAN
									<table className="simpeltable">
											<tbody>
												<tr>
													<td className="emphsize boldfont" scope="Ranking">BEFORE</td>
													<td className="emphsize boldfont" scope="Ranking">AFTER (as of August 1st, 2020)</td>
												</tr>	
												<tr>
													<td scope="Ranking">Can only start with 1 ETH deposit per 100 ETH PLAN</td>
													<td scope="Ranking">Can start with 1 ETH up to 100 ETH per 100 ETH PLAN</td>
												</tr>	
												<tr>
													<td scope="Ranking">Maturity 40 month</td>
													<td scope="Ranking">Maturity 24 month</td>
												</tr>	
												<tr>
													<td scope="Ranking">No early termination</td>
													<td scope="Ranking">Penalty on early termination<br/> (Penalty: 75% of YOUR PROFIT) </td>
												</tr>	
										</tbody>
									</table>
									</li>
									</ol>
									<br/>
									With those changes, we are trying to achieve to maximize monthly profit by locking the invested ETH in 100 ETH PLAN to 24 months so we can strategize and execute long term plan with our portfolio which shall tend to give us more profit.	
									</li>
								</ol>
								<br/>
								Thank you.
								<br />
								<br />
								Management WREX Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr15' name='radio' type='checkbox' />
					<label htmlFor='nr15'>
						<span className="th_faq">June 10th, 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								Dear Wrex Members:
								</p>

								<ul>
									<li>
									Since Monday, our auditors are back in our office conducting the monthly audits on our deposit/withdrawal accounts, trade records and generating the monthly profits. Because of COVID19 – lockdown we have not been able to do our monthly routine so we realize we have a lot to cover to generate the reports for March, April and May.
									<br />
									</li>
									<li>
									We apologize that we may be one day late for distributing our May profit which is suppose to take place today. All the past reports also shall be available for our members this week on our Support Page.
									<br />
									</li>
								</ul>
								Thank you.
								<br />
								Best Regards,
								<br />
								Management WREX Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr14' name='radio' type='checkbox' />
					<label htmlFor='nr14'>
						<span className="th_faq">Announcement May, 2020-05-11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Dear W-Rex Members:
								</p>

								<ul>
									<li>
										We wish you and your family all well during this COVID 19 that has affected our life style. As per our last announcement, due to COVID 19 outbreak, and “total lock out” happening in Malaysia, we had no choice to delay our monthly audit by the auditors. Since this Government order of lock out still continues we are unable to execute our monthly audit for April performance as well. Good news is that the Government has ordered some reliefs on the lock-out and It may take a few more weeks to a total withdrawal of the lock out and our life would be back to normal.
									<br />
									</li>
									<li>
										Currently, we are working closely with the auditors, when we could actually do our audits for our books for March and April. Once it is completed, we shall announce it and the audited reports shall be uploaded for you to see.
									<br />
									</li>
									<li>
										For the April report, we managed to gain 3.31%. It is far short of an average target we set in the beginning which is 15%. Pre COVID19, we were able to do average of 10% for months, and after the outbreak that profitability dropped to 3~5% per month. It tells you that even WREX has been affected by COVID19 as our traders and analysts were not able to come to work and also the market volume dropped significantly for us to be more efficient and profitable at the same time. Once the lock out is lifted off and we are back to our normal cycles, and also more importantly the market finds life again, we promise to do our best to come back with better profitability.
									<br />
									</li>
									<li>
										Your support has been enormous and we ask you to show the support to us during these very difficult times.
									<br />
									</li>
								</ul>
								Thank you.
								<br />
								<br />
								WREX Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr13' name='radio' type='checkbox' />
					<label htmlFor='nr13'>
						<span className="th_faq">Urgent Announcement, 2020-04-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								</p>

								<ol>
									<li>
										Hope this announcement finds you and your family well during this very difficult time with Corvid 19 spreading throughout the world.
									<br />
									</li>
									<li>
										As we had mentioned in our previous announcement, unfortunately, the auditors could not visit our office to verify our accounts and all the monthly trade data in order to produce our March report.
										We would like to use our internal calculation tonight to distribute the profit we had made in March.
										Once the country lock down is relieved by the Government, we shall carry the audit process and upload the monthly report.
									<br />
									</li>
									<li>
										The monthly profit for March stands at 4.71%.
									<br />
									Along with last month’s performance, March performance is relatively low to our standards. We don’t want to blame the Corvid19 resulted in our “lower than expected” performance,
									but it definitely had an impact in our regular trading. The traders who are our key assets in running this operation and the staffs who are analyzing and feeding data into our AI
									system are very much influenced by this unforeseeable event. Now, with the lock down continued everything is from home-based trading which makes us follow extra protocols because
									of security reasons makes our operation even harder to be very effective as normal.
									We are continuously working around the clock to improve our working environment as we speak to perform better even with the handicaps we have today.
									<br />
									Nevertheless, when you look back to other markets (eg, securities, stock markets, gold, other commodities etc) they have lost quite a lot of value in the past two months.
									Whereas we, WRex has performed positive growth and compared to the other commodities our 4.71% growth is actually bigger than just numbers.
									<br />
									We ensure you that we are trying in our best abilities to improve our efficiency everyday even during this difficult time.
									<br />
									</li>
								</ol>
								Thank you in advance for your understanding.
								<br />
								Best Regards,
								<br />
								WREX Management Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr12' name='radio' type='checkbox' />
					<label htmlFor='nr12'>
						<span className="th_faq">April W-REX Announcement, 2020-04-06</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Dear Members,
								</p>
								<br />
									I hope this announcement find you and your family well.
								<ol>
									<li>
										Corona Virus (CORVID19) has impacted us in many ways and it did to WREX as well. Currently, Malaysian Government has been ordered two-week lockdown and recently extended such order for another two weeks.
										During the lock down, no business is open other than the Hospitals, Pharmacies and Super Markets. All other businesses are closed down and left all the office buildings empty as well.
									<br />

									</li>
									<li>
										Because of the strict Government Orders, it has an impact on us on the scheduled audits. The audits involve physical presence of the auditors in our office to check all the accounts and our monthly trade records,
										all such activities are postponed until further notice. So for the month of March performance, we shall use our internal data to announce and award the profit sharing on the 10th of April as scheduled,
										and once the proper audits are completed on a later date, we shall adjust the distribution if necessary.
									<br />
									We all are experiencing a bit of discomforts due to the Corona issue but we are hoping that we all can overcome such pan epidemic in the near future.
									<br />
									</li>
									<li>
										We sincerely wish all the best to you and your family.
									<br />
									Below is a link on Malaysian Lock Down for your reference.
									<br />
										<a href="https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown">
											https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown</a>
										<br />
									</li>
								</ol>
								<br />
								WREX Management Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr11' name='radio' type='checkbox' />
					<label htmlFor='nr11'>
						<span className="th_faq">March W-REX Announcement, 2020-03-17</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Dear WREX Members!
								</p>
								<ol>
									<li>
										Our service has recorded a 3.94% return on our investment for the month of February. Since our services started in September 2019, six months have past so far and we have recorded 9.828% profit on average per month.
									<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">WREX Service</td>
													<td scope="Prize">Monthly Profit % </td>
												</tr>
												<tr>
													<td data-label="Ranking">September 2019</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">October 2019</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">November 2019</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">December 2019</td>
													<td data-label="Prize">6.80%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Janurary 2020</td>
													<td data-label="Prize">9.92</td>
												</tr>
												<tr>
													<td data-label="Ranking">February 2020</td>
													<td data-label="Prize">3.94%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
										</p>
									</li>
									<li>
										If you have had 1 Ethereum maintained in WREX Account from September 2019, with average monthly return of 9.828%, your 1 Ethereum shall grow to 7.6 Ethereum after 48th month (4 years) – ROI of 760%)
									<br />
										If you have had 1 Ethereum maintained in 100 ETH PLAN, your accumulative growth percentage can be as much as 2,100% for the same period.
									<br />
										These numbers are incomparable with any other investment plans in any industry.
									<br />
									</li>
									<li>
										Our monthly performance for February was below average in our standards and we hope to return with a much improved figures for March.
									<br />
									</li>
								</ol>
								Thank you for your support! <br />
								<br />
								WREX Management Team<br />
							</li></ul>
						</div>
					</label>

					<input id='nr10' name='radio' type='checkbox' />
					<label htmlFor='nr10'>
						<span className="th_faq">Announcement, 2020-02-12</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear Members!<br />
									</p>
									<ol>
										<li>
											The monthly profit was finally recorded to be at 9.92% for January. This results came back this morning from Baker Tilly audits.
											We once again apologize for the small delay in announcing the monthly profit. The official report shall be posted as soon as we get the report from Baker Tilly.
										<br /><br />
										</li>
										<li>
											First drop of our HK Trust Fund was executed in the first week of February! So yes now we are managing not only our valued members assests but also assests from a Trust Fund based out of Hong Kong.
											The drops are being deposited into our account on weekly basis upto 100 million USD worth of Ethereum!
										<br /><br />
										</li>
										<li>
											This is a policy change announcements! It is very important that you understand this as well. Since the launch of WREX we have had only 6 seperate withdrawal requests!
											They were all executed within 72 hours as our policy is written. However we have received concerns from our trading department that those withdrawals may hurt the monthly performance as in some cases we have to exit with less profit.
											We would like to make sure such withdrawals does not affect negatively on the overall monthly performance. Therefore internally we have discussed and have to rewrite the withdrawal policy.
										<br />
										</li>
									</ol>
									<br />Before: Any withdrawal requests either from your WREX Account or 100 Eth Plan into your wallet may take up to 72 hours.<br />
									<br />After: Any withdrawal requests either from your WREX Account or 100 Eth Plan into your wallet may take up to 4 weeks.<br />
									<br />But I would like to emphasize that if your withdrawal amount is less than 50 Ethereum it should clear within 72 hours.
									If your single request is above 50 ethereum withdrawal or aggreggated withdrawals in a week is more than 100 eth it may take upto 4 weeks.
									However in normal cases it should be ready within a week or so but I have to be honest with you all in a case like the first two weeks of February,
									urgent forced exit due to withdrawal request may hurt the monthly profit percentage.
									So this policy change is to enhance the protocol to minimize damages and maximize profit for everyone within the WREX community.
									<br />
									<br />Our customer support will contact you via email when you request a withdrawal request to inform you on daily and weekly updated so you are not blacked
									out and wondering when you will get back your Ethereum. Trust is the most important value between our members and us.
									<br />
									<br />Thanks again!<br />
									<br />
									W-REX Management
									<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr9' name='radio' type='checkbox' />
					<label htmlFor='nr9'>
						<span className="th_faq">Announcement, 2020-02-11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear Members of WREX!<br />
									</p>
									We are truly sorry that the monthly report is delayed a few days. Currently, the auditors are reviewing the numbers and the monthly percentage shall be announced by tomorrow. We again apologize for the small delay.
									We are hereby delighted to bring us a GREAT NEWS happened just days ago!
									WOTA/WREX signed an [ASSET MANAGEMENT and CUSTODIAL AGREEMENT] with a TRUST FUND ASSET MANAGEMENT COMPANY based in Hong Kong. The deal includes;
									<br />
									W-REX Management<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr8' name='radio' type='checkbox' />
					<label htmlFor='nr8'>
						<span className="th_faq">January W-REX Announcement, 2020-01-15</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									ADIEU 2019! HERE WE GO 2020!<br /><br />
									Well, on behalf of W-REX team, we would like to wish you a Happy New Year! Our 2019 has been exciting time for us as W-REX was pre-launched on September 1st and carried out our tasks in the following months. We had internally ups and downs but managed to be where we are today.<br />
									Needless to say, we are also super excited about 2020 as we are excited to host a Trust Fund starting first week of February and we are also discussing with many other VCs to manage their funds and many other potentials that would benefit our members in a long term. <br />
								</p>
								<ol>
									<li>
										Our RECORDS, <br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">Month</td>
													<td scope="Prize">Monthly Profit % </td>
												</tr>
												<tr>
													<td data-label="Ranking">September</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">October</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">November</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">December</td>
													<td data-label="Prize">6.80%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											This means if you had 1 ETHEREUM deposit in [WREX ACCOUNT] since September 1st, your 1 ETH grew to 1.182252 ETH (18% GAIN)<br />
											If you had 1 ETHERUM deposit in [100 ETH PLAN] since September 1st, your 1 ETH grew to 1.384929 (38.49% GAIN)<br />
										</p>
									</li>
									<li>
										Mobile Apps NOW AVAILABLE to Download<br />
										Please go to Google Play Store (ANDROID) and App Store (Apple Iphone) and search with WREX to download our Mobile application.<br />
									</li>
									<li>
										In 2020, WREX shall manage more than 750,000 ETHEREUM and based on that our HOLDING company WOTA shall launch WOTA COIN<br />
										In mid 2020, W-REX.com shall service WOTA wallet and start limited sales only to our MEMBERS! This sale would be a pre-listing sale as WOTA shall be listed on top 3 major Cryptocurrency Exchange in the World. W-REX.com shall also service WOTA investment programs (such as 369 PLAN) within W-REX. Be EXCITED! It is only available to WREX members only!<br />
									</li>
								</ol>
								More detailed news shall become available to our members in April. <br />
								<br />
								WOTA/WREX Team<br />
							</li></ul>
						</div>
					</label>


					<input id='nr7' name='radio' type='checkbox' />
					<label htmlFor='nr7'>
						<span className="th_faq">WREX LIST - OPENING EVENT, 2020-01-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>

								<div className="row result-wrap">
									<div className="col-full">
										<div className="result">
											<p className="crypto-word-warp"><strong>[EVENT_one]</strong> Refer friends and receive free Ethereum 420 Ethereum for GIVEAWAY!<br /><span>* Actual payouts for the Opening Event Bonus shall commence on the 10th of January 2020 instead of the December 24th. This is due to payout capability within the platform is limited due to online security issues. Thank you for your understanding.</span></p>

											<table className="simpeltable">
												<thead>
													<tr>
														<th scope="col">Ranking </th>
														<th scope="col">Prize </th>
														<th scope="col">Winner (Referral) </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td data-label="Ranking ">TOP 1</td>
														<td data-label="Prize ">100 Ethereum</td>
														<td data-label="Winner (Referral) ">mil***************@y****.com (51)</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 2</td>
														<td data-label="Prize ">50 Ethereum</td>
														<td data-label="Winner (Referral) ">pau*******@g****.com (48)</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 3</td>
														<td data-label="Prize ">30 Ethereum</td>
														<td data-label="Winner (Referral) ">chs***@g****.com (46)</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 4~5</td>
														<td data-label="Prize ">20 Ethereum each</td>
														<td data-label="Winner (Referral) ">
															biz********@n****.com (42)<br />
															yse****@g****.com (42)
								</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 6~10</td>
														<td data-label="Prize ">10 Ethereum each</td>
														<td data-label="Winner (Referral) ">
															sea*******@s*******.ca (40)<br />
															jju****@h******.net (39)<br />
															mif*****@y****.com (37)<br />
															sal*******@g****.com (37)<br />
															sam*****@h******.com (37)
								</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 11~25</td>
														<td data-label="Prize ">5 Ethereum each</td>
														<td data-label="Winner (Referral) ">
															coo*******@g****.com	(36)<br />
															mid*********@y****.com	(30)<br />
															ste******@a**.com	(28)<br />
															seb***********@h******.com	(26)<br />
															pat*********@y****.com	(26)<br />
															nes*******@y****.com	(25)<br />
															pat**********@y****.com	(25)<br />
															rjh*******@y****.ca	(24)<br />
															rya*****@l****.com	(24)<br />
															s_h****@h******.com	(24)<br />
															uni******@g****.com	(23)<br />
															ost*****@y****.com	(22)<br />
															pic*********@g****.com	(22)<br />
															des*********@g****.com	(22)<br />
															sar*@h******.com	(21)
								</td>
													</tr>
													<tr>
														<td data-label="Ranking ">TOP 26~100</td>
														<td data-label="Prize ">1 Ethereum each</td>
														<td data-label="Winner (Referral) ">
															myf*****@h******.com	(19)<br />
															raz*@a**********.com	(18)<br />
															mic********@y****.com	(18)<br />
															chu*********@n****.com	(17)<br />
															por*******@y****.com	(17)<br />
															raq*@y****.com	(15)<br />
															ozc*****@n****.com	(15)<br />
															bli*********@y****.com	(14)<br />
															pau*****@h******.com	(14)<br />
															sdw*****@h******.com	(14)<br />
															air******@h******.net	(13)<br />
															ea-****@d***.net	(13)<br />
															dms*****@n****.com	(13)<br />
															mig************@y****.com	(13)<br />
															ren**********@g****.com	(12)<br />
															jrk**@m**.com	(12)<br />
															jes*********@g****.com	(11)<br />
															sno******@g****.com	(11)<br />
															Lil*********@g****.com	(10)<br />
															jhl**@g****.com	(10)<br />
															jom*****@d***.net 	(9)<br />
															kws****@n****.com	(8)<br />
															ssd******@g****.com	(7)<br />
															pro**************@g****.com	(7)<br />
															han*********@h******.net	(7)<br />
															sha********@g****.com	(7)<br />
															kig*******@n****.com	(6)<br />
															jin*****@n****.com	(6)<br />
															mia******@g****.com	(6)<br />
															mil*******@a**.com	(6)<br />
															sen***@y****.com	(6)<br />
															esp*******@g****.com	(6)<br />
															mot*******@g****.com	(6)<br />
															rit**********@h******.com	(6)<br />
															sal***********@y****.com	(5)<br />
															lsi**@n***.com	(5)<br />
															hk6***@n***.com	(5)<br />
															dec****@n****.com	(5)<br />
															pen*******@g****.com	(5)<br />
															mie*******@y****.com	(5)<br />
															rba*******@p******.net	(5)<br />
															sai*********@c******.net	(5)<br />
															pel******@g****.com	(5)<br />
															bor*******@n****.com	(5)<br />
															suu****@n***.com	(4)<br />
															hap*******@n***.com	(4)<br />
															ste******@a**.com	(4)<br />
															seb****@m**.com	(4)<br />
															ray********@m**.com	(4)<br />
															sam********@g****.com	(4)<br />
															win****@n****.com	(4)<br />
															kim****@g****.com	(4)<br />
															yom*******@g****.com	(4)<br />
															spa******@k****.ac.kr	(4)<br />
															sha********@g****.com	(4)<br />
															jam************@y****.com	(4)<br />
															mil************@m**.com	(3)<br />
															dba******@d***.net 	(3)<br />
															tnz*******@d***.net	(3)<br />
															osc**********@h******.com	(3)<br />
															sfl*****@y****.com	(3)<br />
															mik********@h******.com	(3)<br />
															zin***********@g****.com	(3)<br />
															ton********@n****.com	(3)<br />
															mid*****@g****.com	(3)<br />
															jho*****@h******.net	(3)<br />
															sal******@g****.com	(3)<br />
															sc5***@h******.net	(3)<br />
															snu***@r*****.com	(3)<br />
															nel*****@s********.net	(3)<br />
															reg*********@a**.com	(3)<br />
															nam******@h******.net	(3)<br />
															seo*****@n****.com	(3)<br />
															bro*********@n****.com	(3)<br />
															h-p*********@h******.net	(3)
								</td>
													</tr>
												</tbody>
											</table>
											<p className="lead animate-this"><strong>[EVENT_two]</strong> Top 3 Ethereum holders in WREX Account from Top 3 countries with registered Ethereum<br /><span>* Details of the event are disclosed to the invitees only</span></p>
										</div>
									</div>
								</div>


							</ul>
						</div>
					</label>

					<input id='nr6' name='radio' type='checkbox' />
					<label htmlFor='nr6'>
						<span className="th_faq">Announcement - WREX Mobile App, 2020-01-02</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear Members<br />
									</p>
									WREX has launched our App and now you can download it from Playstore and Appstore. You can visit these sites and search under WREX and download or click the links below;
<br />
									App Store (IOS)
<br />
									<a href='https://apps.apple.com/us/app/wrex/id1491276520?ls=1'>https://apps.apple.com/us/app/wrex/id1491276520?ls=1</a>
									<br />
									Google Play (Android)
<br />
									<a href='https://play.google.com/store/apps/details?id=com.wrex'>https://play.google.com/store/apps/details?id=com.wrex</a>
									<br />
									The apps are available only in English at the moment. Korean and Bahasa Indonesia shall be available toward the end of January.
<br />
									Thank you and we wish you all a Happy New Year!
<br />
									WOTA/WREX Management Team
<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr5' name='radio' type='checkbox' />
					<label htmlFor='nr5'>
						<span className="th_faq">Major Announcement, 2019-12-24</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear Members of WREX!<br />
									</p>
									We are hereby delighted to bring us a GREAT NEWS happened just days ago!
									WOTA/WREX signed an [ASSET MANAGEMENT and CUSTODIAL AGREEMENT] with a TRUST FUND ASSET MANAGEMENT COMPANY based in Hong Kong. The deal includes;
<ul>
										<li>WOTA/WREX to manage up to 100 million United States Dollars worth of ETHEREUM on behalf of the TRUST FUND within our platform (That is up to 740,000 ETHEREUM in TOTAL)</li>
										<li>The Trust Fund is to transfer the fund to us to manage starting in February 2020</li>
										<li>WOTA/WREX shall manage the funds for 24 months (could be extended)</li>
									</ul>
									<br />
									<br />
									With this institutional investment decision and agreement shall bring the WREX platform to the next
									level as it indirectly indicates that WREX platform management and WOTA trading algorithm now officially recognized by institutional investors.
<br />
									<br />
									This means though, WREX shall soon will suspend new member registration once we hit 1,000,000 ETHEREUM under management. This is to ensure that WREX to continue to bring double digit percentage profit every month as growing Ethereum number shall make it difficult for us to keep up the profit level. With this Trust Fund on board, following couple months would be the last phase of registration to our platform unless we come back with new BTC algorithm to broaden our products and opportunities.
<br />
									<br />
									Thank you for your support!
<br />
									<br />
									W-REX Management
<br />
								</li>
							</ul>
						</div>
					</label>


					<input id='nr4' name='radio' type='checkbox' />
					<label htmlFor='nr4'>
						<span className="th_faq">December Announcement, 2019-12-05</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											November Monthly Profit Percentage <br />
											We have completed the audit for our November Monthly Profit Percentage – 10.07%
											The final audit report shall be uploaded on the 17th of December due to early holidays by our auditors.
<br />
											<br />
										</li>
										<li>
											Opening Event ETHEREUM GIVEAWAY!<br />
											The event results shall be announced in 2 weeks!
<br />
											<br />
										</li>
										<li>
											Delay in Ethereum Deposits<br />
											We may experience a slower deposit process due to upgrade in our system to accommodate the Istanbul Hardfork, during December 6 to December 9. You can safely execute deposits and withdrawals but due to the Hardfork, the actual process may be slowed compare to the regular transfer.
<br />
											<br />
										</li>
										<li>
											369 Products<br />
											We are developing new products for W-REX by introducing 369 PLAN. These new products should be available starting 1Q of 2020.
<br />
											The products are just like 100 ETH PLAN, but with much shorter exit, like 3 months, 6 months, and 9 months that you can choose compared to 40 months. Also, in 100 ETH PLAN, company and investors share 50:50 upon termination or maturity, however in our new 369 PLAN, to make it beneficial to our investors, the split would be 70:30 (Investor: Company). You can open only one 369 PLAN, and can choose only one between 3m, 6m or 9m and deposit any ETH amount.
<br />
											There shall be an official announcement with detailed information on our new product soon.
<br />
											<br />
											W-REX Management
<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>


					<input id='nr3' name='radio' type='checkbox' />
					<label htmlFor='nr3'>
						<span className="th_faq">November 2019 Announcement, 2019-11-08</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											Audit Report <br />
											On September 30th, a day before PWC supposed to audit our accounts, PWC has sent us an official Email stating that they could not audit our accounts due to International PWC advised them not to perform any service to crypto-related industry as it may appear that PWC is promoting the industry. Although it is not illegal but yet in some countries it is still in the grey area as there are no related laws in place and enforced. <br />
											It was obviously a devastating news to us as we were engaged with PWC from the beginning but had to accept the fact that PWC no longer can serve us to provide what we would consider a ‘trustworthy’ report on our performance. However, since then we have been working hard to find an alternative option and we are proud to announce that we are engaged with Tilly Baker Accounting and Audit firm, known as one of the top 10 accounting firms in the world. <br />
											Tilly Baker had already conducted our first month (September Report) audit and the report is available now to download for your information. They have now completed October audit as well and the report shall be out soon.<br />
											The September report confirms that they have check the Accounts, checked on the Ethereum received and traded, all the trades happened in September and confirm the monthly profit percentage we announce is correct.<br />
											<br />Please download the report in the Support Page.<br />
											<br />
										</li>
										<li>
											October Performance<br />
											We are also announcing our official monthly performance report for October: 11.72%<br />
											Again, 11.72% is a verified number by Tilly Baker and the published October report shall be available in a few days. On the 10th of November your accounts shall be credited with 11.72% gain and updated accordingly.<br />
											<br />
											Since the launch of WREX, <br />
											<br />
											September MP%: 16.52%<br />
											October MP%: 11.72%<br />
											Average MP%: 14.12%<br />
											<br />
										</li>
										<li>
											Sample Affiliate Bonus Performance<br />
											We have asked and received a permission to publish a sample account snapshots from one of our TOP 5 performers in Affiliate Bonus. In the month of September, when everyone received maximum of 16.52% gain in their WREX Account and 100 ETH Plan, he has received 593.60% gain on his WREX Account and 88.36% gain on his 100 ETH Plan.<br />
											If you want to find out more how he did it, please visit the ‘Support Page’<br />
											<br />Download: <a href="/download/AffiliateBonusSample(ENG).pdf" target="_blank">AffiliateBonusSample(ENG).pdf</a><br />
											<br />Thank you all!<br />
											WREX Support Team<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>


					<input id='nr2' name='radio' type='checkbox' />
					<label htmlFor='nr2'>
						<span className="th_faq">WREX Announces First Payouts, 2019-10-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											Payouts are executed and credited into your accounts. <br />
											Since this is our first payout executions, we are expecting many questions. <br />
											Please bear in mind, this is all backend program executed payouts so it means “if one case is correct, then all are correct; if one is wrong it means everything is wrong”. <br />
											Common misunderstanding of payouts in the <font className="emphsize">first month</font> depends on what day of the month you set up the WREX Account and 100 ETH PLAN. <br />
											<br />
											<font className="boldfont">WREX Account</font>, when your starting date is;<br />
											Between 1st day of Sep to 10th day of Sep – you are receiving 50% of monthly profit.<br />
											Between 11th day of Sep to 20th day of Sep – you are receiving 25% of monthly profit<br />
											Between 21st day of Sep to Last day of Sep – you are receiving 0% of monthly profit<br />
											<font className="emphsize">THIS APPLIES TO YOUR FIRST MONTH ONLY!</font> (From second month and onwards, you are receiving 100% of monthly profit)<br />
											<br />
											<font className="boldfont">100 ETH PLAN</font>, when your starting date is;<br />
											Between 1st day of Sep to last day of Sep – you are receiving 0% of monthly profit<br />
											100 ETH PLAN will take effective <font className="boldfont">on the first day of the following month</font> when you set up the plan.<br />
											<br />
											If you have set up the WREX Account and 100 ETH PLANs in AUGUST, YOU WOULD BE RECEIVING 100% of the monthly profit in September.<br />
											For those who have set up the WREX Account and 100 ETH PLANs in SEPTEMBER, YOU WOULD BE eligible as described above but your accounts would be eligible to receive 100% of October’s monthly profit.<br />
										</li>
										<li>
											Affiliate Bonus<br />
											<font className="boldfont">WREX Account Affiliate Bonus</font><br />
											(1)	Your account must have minimum 1 ETH from the first day and maintain until the last day of the month to receive WREX Account Affiliate Bonus.<br />
											(2)	Your affiliates must have minimum 1 ETH from the first day and maintain until the last day of the month for you to receive WREX Account Affiliate Bonus.<br />
											<font className="boldfont">100 ETH PLAN Affiliate Bonus</font><br />
											(1)	You must have minimum one 100 ETH PLAN activated from the 1st day and maintain until the last day of the month to receive 100 ETH PLAN Affiliate Bonus.<br />
											(2)	Your affiliates must have maintain his/her one or multiple 100 ETH PLAN(s) from the first day to last day of the month for you to receive the 100 ETH PLAN Affiliate Bonus.<br />
											(3)	Please note, all 100 ETH PLAN Affiliate Bonus goes into your oldest 100 ETH PLAN if you have more than one 100 ETH PLAN.<br />
											<br />
											<font className="boldfont">Please download the GUIDEBOOK – on our website for detailed information on the eligibility.</font>
										</li>
										<li>
											Accounting Firm’s First Month REPORT<br />
											We are currently experiencing an internal problem generating the First Month Report and we do sincerely apologize for the inconvenience. However, rest assured, the monthly profits were calculated several times to avoid any mistakes. Once we expedite and publish the report, WREX shall fully explain the past internal issues we had.<br />
											Other than the delayed reports, everything is business as usual and we would like to share good news with you all, that the profit percentage of October already are looking pretty good.<br />
											<br />
											Thanks again for your support, and please do write to <a href="mailto:support@w-rex.com">support@w-rex.com</a> if there are any questions.<br />
											<br />
											Thanks everyone!<br />
											<br />
											WREX MANAGEMENT<br />

										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr1' name='radio' type='checkbox' />
					<label htmlFor='nr1'>
						<span className="th_faq">Monthly Profit Notice, 2019-10-01</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Hi WREX Members,<br />
										We are proud to announce our unofficial results for the MONTHLY PROFIT% (MP%) for September - 16.52%.<br />
										Our valued members who believed in us from the beginning shall receive this “unbelievable, but only slightly above average return for WREX” in their accounts soon.<br />
										<br />
										If you have any questions on the MP%, or any other inquiries, please do not hesitate to email to us: <a href="mailto:support@w-rex.com">support@w-rex.com</a><br /><br />
										October 1st, 2019<br />
									</p>
								</li>
							</ul>
						</div>
					</label>
				</div>
			</div>

		)
	}
}

export default NewsEN;
