import * as ActionTypes from '../actions'
import merge from 'lodash/merge'
import { combineReducers } from 'redux'

// Updates an entity cache in response to any action with response.entities.
//const entities = (state = { users: {}, repos: {}, tokens:{} }, action) => {
//const entities = (state = { tokens:{} }, action) => {
const entities = (state = {tokeninfo:{ index: -1}, orderbook:{}, myorder:{} }, action) => {
  if (action.response && action.response.entities) {
    if (action.type==="ORDER_BOOK_SUCCESS") {
      state.orderbook={};
    }  else if (action.type==="MY_ORDER_OPEN_SUCCESS") {
      state.myorder.ordersOpen={};
    }
    return merge({}, state, action.response.entities)
  }

  return state
}

const warehouse = (state = {orderPrice: 0, orderAmount: 0}, action) => {
/*
  const { type } = action

  if (type === ActionTypes.CLICK_ORDER_BOOK) {
    const { price } = action
    state.orderPrice=price;
  } else if (type === ActionTypes.UPDATE_ORDER_FORM) {
    const { price } = action
    state.orderAmount=price;
  } 
*/
  return state
}

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  } else if (error) {
    return error
  }

  return state
}

const rootReducer = combineReducers({
  entities,
  warehouse,
  errorMessage,
})

export default rootReducer
