/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import countryList from 'react-select-country-list'

import { getAddressStringByDevice }  from '../helper/displayHelper'

import i18n from 'i18n-react';

import { sendUserWithdrawAddressDelete, sendUserWithdrawAddresses, API_SUCCESS } from '../actions'

import { renderErrorMessage } from '../components/ErrorMessage'


import {getToken} from '../helper/loginHelper';

import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class AccountAddress extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      windowWith: window.innerWidth,
      isMobile: window.innerWidth < 479,
    }

  }

  handleResize = (event) => {
    this.setState({ windowWith: window.innerWidth });
    this.setState({isMobile: this.state.windowWith < 479});
  }

  componentDidMount() {
    //console.log("ych AccountAddress");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Account Address - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    window.addEventListener('resize', this.handleResize)


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.handleResize);

  }

  handleAddressEdit(name,address) {
    //console.log("handleAddressEdit");
    //console.log(address);

    cookies.set('edit_address', address, {path: '/'});
    cookies.set('edit_name', name, {path: '/'});

    this.props.history.push("/profile_address")

  }

  handleAddressDelete(address) {
    //console.log("handleAddressDelete");
    //console.log(address);
    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

    this.props.sendUserWithdrawAddressDelete(token, { address: address
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.globalUser.user.addresses = undefined;
          self.props.sendUserWithdrawAddresses(token,{});
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Account Address Error",this);
    }

    var addresses = [];
    //console.log(this.props.globalUser);
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {   
      let user = this.props.globalUser.user;
      if (user.addresses!==undefined) {
        addresses = user.addresses;
      } else {
        addresses = [];
      }
    } else {
        addresses = [];
    }

    const addressesNodes = addresses.map((myAddr,index) =>
        <tr key={index} id={index} className="crypto-transactions-list__item">
          <td className="mdl-data-table__cell--non-numeric">{myAddr.name}</td>
          <td className="mdl-data-table__cell--non-numeric">{getAddressStringByDevice(myAddr.address,this.state.isMobile)}</td>
          <td className="mdl-data-table__cell--non-numeric">
            <i className="material-icons span_button" aria-hidden="true"
              onClick={() => this.handleAddressEdit(myAddr.name,myAddr.address)}
            >edit</i>
          </td>
          <td className="mdl-data-table__cell--non-numeric">
          <i className="material-icons span_button" aria-hidden="true"
            onClick={() => this.handleAddressDelete(myAddr.address)}
          >delete</i>
          </td>
        </tr>
    )

    return (
      <div>
{ /*
        <div className="crypto-loader"></div>
*/ }
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="profile"
                                        />

			

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="profile">{i18n.translate('account')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('addressmanagement')}</h1>
							  <h4 className="f_mode"> </h4>
							</div>
						</div>
		  
					  <div className="mdc-layout-grid">
		  
						<div className="mdc-layout-grid__inner f_rfl">

								<div className="mdc-layout-grid__cell--span-2-desktop"></div>

								<div className="f_formlarge mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
									  <div className="crypto-settings-form-panelxx">
										  <div className="crypto-widget__content crypto-overflow-x-auto">
											<p><span className="f_adr"><Link to="profile_address_new" className="mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('addnew')}</Link></span></p>
											<table className="mdl-data-table mdc-typography--overline f_tbl fx_top">
				
												<thead className="crypto-transactions-list__labels">
												<tr>
													<th width="70" className="mdl-data-table__cell--non-numeric">{i18n.translate('label')}</th>
													<th width="150" className="mdl-data-table__cell--non-numeric">{i18n.translate('address')}</th>
													<th width="50" className="mdl-data-table__cell--non-numeric">{i18n.translate('edit')}</th>
													<th width="50" className="mdl-data-table__cell--non-numeric">{i18n.translate('delete')}</th>
												</tr>
												</thead>					
                          <tbody>
                              {addressesNodes}
                          </tbody>
											</table>
										  </div>



									  </div>
						</div>


					  </div>


					</div>


				</div>
				  </main>
			</div>
      </div>

    )
  }









}




const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendUserWithdrawAddressDelete, sendUserWithdrawAddresses
})(AccountAddress))




