/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//import i18n from 'i18n-react'

import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import SupportHeader from '../components/SupportHeader';
import FaqPageEN from '../components/FaqPage_en';
import FaqPageKR from '../components/FaqPage_kr';
import FaqPageID from '../components/FaqPage_id';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class SupportNotLogin extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      LanguageSetting: "",
    }

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const lang = params.get('lang'); // bar
    if (lang!==null) {
      cookies.set('language_setting',lang, {path: '/'});
    }

    const cookieLanguageSetting = cookies.get('language_setting');
    if (cookieLanguageSetting!==undefined) {
      if (cookieLanguageSetting==="KR") {
        this.state.LanguageSetting = "KR";
      } else if (cookieLanguageSetting==="ID") {
        this.state.LanguageSetting = "ID";
      } else {
        this.state.LanguageSetting = "EN";
      }
    } else {
      this.state.LanguageSetting = "EN";


    }
  }

  componentDidMount() {
    //console.log("ych Support");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Support - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
                    
				<div className="crypto-wrapper">

				  <main className="crypto-main">
					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
 
					<div className="mdc-layout-grid">
						<div className="mdc-layout-grid__inner f_rfl_num">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1-desktop"></div>
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"> 

              <div className="crypto-widget__content crypto-overflow-x-inherit fx_mb_nopadding">

                <SupportHeader />
                
                {this.state.LanguageSetting==="EN" && <FaqPageEN />}
                {this.state.LanguageSetting==="KR" && <FaqPageKR />}
                {this.state.LanguageSetting==="ID" && <FaqPageID />}


              </div>

						  </div>
						</div>
					  </div>

					</div>
				  </main>

				</div>  
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(SupportNotLogin))




