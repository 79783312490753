import React, { Component } from 'react';
//import i18n from 'i18n-react';

// import LastEarns from './LastEarns';


/**
 * Renders the preloader
 */
class FaqPageEN extends Component {

  render() {
    return (
      <div>


        <div className="f_single fx_single_tbl">
          <div className="tabset">
            <label htmlFor="tab2">
              <span className="mdc-tab__content fx_titlefont">
                <span className="mdc-tab__text-label">FAQ</span>
              </span>
            </label>
          </div>
        </div>

        <div className="faq_tag">
          <a href="#general">General</a>
          <a href="#subscription">Subscription & Registration</a>
          <a href="#wrex">About WREX Platform</a>
          <a href="#affiliate">About Affiliate Program</a>
          <a href="#withdraw">About Withdrawal and Fee</a>
        </div>


        <p id="general" className="faq_tit">General</p>
        <div className='swanky_wrapper faq'>
          <input id='pr1' name='radio' type='checkbox' />
          <label htmlFor='pr1'>
            <span className="th_faq">1.	Explain to me how it WREX works!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>We manage your Ethereum. Any Ethereum you would place to us through WREX Platform, we would utilize your placed Ethereum to buy and sell any Alt coins in the Ethereum pairing markets in major exchanges in the world to increase your number of Ethereum.</li>
                    <li>We have developed WREX 3-Layered ETH Algorithm and this algorithm shall tell our experienced and skilled traders what alt coins to buy at certain Ethereum price.</li>
                    <li>Our activities are not bot-trading, real traders buy alt coins based on the algorithm and selling would depend on the traders’ experience and skills. </li>
                    <li>With our algorithm, we never had a losing month, hence we had recorded between 5~80% profit every month since January 2018. We had never a losing month, simply because we had never sold a single trade with a loss.</li>
                    <li>Baker Tilly, one of the ten major accounting firm in the world, shall come to our office every month and physically verify all of our trades and finalize the monthly profit percentage which shall be available to all of our subscribers from our website. This is to provide the maximum transparency.</li>
                    <li>Once the monthly profit percentage is verified by Baker Tilly, we will distribute the profit per our profit sharing scheme: <br />45% - Subscribers<br />5% - Affiliate Program<br />50% - WREX (Trading, Platform Operation, Baker Tilly, Legal, Marketing etc)</li>
                    <li>Since we are distributing only from the monthly profit and we would never have a losing month in the trades, your principle number of Ethereum placed with us will never shrink. This mechanism shall ensure the sustainability of WREX for a long time.</li>
                    <li>We have two programs you can place your Ethereum; these are WREX Account and 100 ETH PLAN.</li>
                  </ol>
                  <h3>WREX Account</h3>
                  <ul>
                    <li>You can place from 1 Ethereum up to 100 Ethereum in the WREX Account.(limitation on 100 Ethereum is only for placements, if you exceed 100 Ethereum limit because of monthly profit added every month, this is ok. If you wish to deposit more than 100 Ethereum, please write your request to <a href="mailto:support@w-rex.com">support@w-rex.com</a>) </li>
                    <li>You need to maintain minimum 1 Ethereum in the WREX Account from the 1st day of the month to the last day of the month to obtain WREX Account - Affiliate Bonus for that month.</li>
                    <li>You need to maintain minimum 1 Ethereum in the WREX Account to open up to ten 100 ETH PLAN.</li>
                    <li>
                      You can choose between [AUTO WITHDRAWAL] or [AUTO RE-ACCUMULATE] for the monthly profit sharing. (You can also execute manual withdrawal at any time)<br />
                      * [AUTO WITHDRAWAL] – 45% of every monthly profit is deposited into your WREX my ETH Wallet – you can withdraw to your external Ethereum wallet<br />
                      * [AUTO RE-ACCUMULATION] – 45% of every monthly profit is deposited into your WREX Account, so your principle will grow every month.
                          </li>
                    <li>There are differences in withdrawal fee for [AUTO WITHDRAWAL] and manual withdrawals. Please refer to ‘withdrawal and fee’ section in this FAQ for further information</li>
                    <li>If you withdraw and leave less than 1 Ethereum in the WREX Account, all of your 100 ETH PLANs are auto-terminated and all the balance are transferred to your WREX my ETH Wallet.</li>
                  </ul>
									<br/>
									<p>
										<strong>CHANGE of TERMS and CONDITIONS</strong><br/>
										As of September 1st, 2020, new members who register at WREX and open Wrex Account, shall only be allowed to deposit 1 ETHEREUM in their WREX Account.<br/>
										<br/>
										Existing members who have more than 1 ETHEREUM in their Wrex Account are allowed to maintain their ETHEREUM as is and should enjoy the full benefits we had offered. However, even the existing members are not allowed to deposit more ETHEREUM in their Wrex Account.
									</p>
		
                  <h3>100 ETH PLAN</h3>
                  <ul>
                    <li>Once you have more than 1 Ethereum placed in the WREX Account, you are eligible to create up to ten 100 ETH PLAN. </li>
                    <li>Only 1 Ethereum can be placed in every 100 ETH PLAN from your WREX my ETH Wallet.</li>
                    <li>You need to create at least one 100 ETH PLAN and maintain for a full month in order to receive 100 ETH PLAN – Affiliate Program. All your 100 ETH PLAN Affiliate Program Bonus are deposited to your very first 100 ETH PLAN.</li>
                    <li>Every 100 ETH PLAN – it is set at [AUTO RE-ACCUMULATION], and also WREX will match your monthly profit-sharing percentage, so in WREX Account, 45% of monthly profit is re-accumulated whereas in 100 ETH PLAN, total of 90% of monthly profit is re-accumulated, so it grows much faster. </li>
                    <li>100 ETH PLAN has 40 months until maturity.</li>
                    <li>You cannot partially withdraw your Ethereum asset in the 100 ETH PLAN. However, if you wish you can terminate each 100 ETH PLAN at any time. Once you terminate one or multiple 100 ETH PLAN, all your ETH shall be deposited into your WREX my ETH Wallet. There is no penalty for early termination of any 100 ETH PLAN.</li>
                  </ul>
									<br/>
                  <p>* NOTE: No Transfer Fee is charged to transfer from your WREX my ETH Wallet to WREX Account/100 ETH PLAN.</p>
									<br/>
									<p>
										<strong>CHANGE of TERMS and CONDITIONS</strong><br/>
										As of August 1st, 2020, all 100 ETH PLAN created after the aforementioned date shall follow the new terms and conditions.<br/>
										<br/>
										<strong>BEFORE:</strong><br/>
										Only 1 ETHEREUM is allowed to be deposited when a new 100 ETH PLAN is created.<br/>
										No penalty for early termination.<br/>
										<strong>CHANAGE:</strong><br/>
										You now can deposit from 1 ETHEREUM to 100 ETHEREUM in one 100 ETH PLAN<br/>
										Maturity is reduced from 40 months to 24 months. Early termination penalty applies.<br/>
										Penalty: 100% profit payout is reduced to 25%.
									</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2' name='radio' type='checkbox' />
          <label htmlFor='pr2'>
            <span className="th_faq">2.	Explain to me how to subscribe and get started!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>You need minimum of 1 Ethereum + Ethereum network fee (GAS)</li>
                    <li>Log in to <a href="www.w-rex.com">www.w-rex.com</a>, register, and obtain your WREX Ethereum Address</li>
                    <li>Transfer your Ethereum using your WREX Ethereum Address. </li>
                    <li>Once you have more than 1 Ethereum in WREX Ethereum Wallet, now you can transfer 1~100 Ethereum into WREX Account to open WREX Account.</li>
                    <li>If you wish to enroll in one or multiple (up to ten) 100 ETH PLAN, create as many as ten 100 ETH PLAN and transfer 1 Ethereum each for every 100 ETH PLAN.<br />* NOTE: No Transfer Fee is charged to transfer your Ethereum from your WREX my ETH Wallet to WREX Account/100 ETH PLAN.</li>
                    <li>Check your account, monthly reports, your asset status from WEB or APP.<br />* Monthly Reports are available on the 8th of every month. By 10th of the month, your account asset status shall be updated to reflect the previous month’s profit, and also on the 10th, all the AUTO WITHDRAWALS are executed. For those who choose [AUTO RE-ACCUMULATION] and the assets in the 100 ETH PLANs shall start trading from the 1st day of the month.</li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3' name='radio' type='checkbox' />
          <label htmlFor='pr3'>
            <span className="th_faq">3.	Explain to me how Affiliate Program works!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>Once you register at WREX, you will obtain your exclusive Affiliate Code. Please give this code to your friends, families and others when registering. They have to key in the Affiliate Code in order to complete the registration.</li>
                    <li>In the affiliate menu, you can view how many affiliates you have, how many referred Ethereum is registered at WREX and how much Ethereum you have earned through the Affiliate Program.</li>
                    <li>
                      Case Example<br />
                      Subscriber A registers 2 Ethereum (1 ETH in WREX Account & 1 ETH in 100 ETH PLAN) at WREX.<br />
                      Subscriber B/C/D/E registers at WREX using Subscriber A’s Affiliate Code.<br />
                      Subscriber B deposits 11 Ethereum (1 ETH in WREX Account & 10 ETH in ten 100 ETH PLAN)<br />
                      Subscriber C deposits 5 Ethereum (1 ETH in WREX Account & 4 ETH in four 100 ETH PLAN)<br />
                      Subscriber D deposits 100 Ethereum (90 ETH in WREX Account & 10 ETH in ten 100 ETH PLAN)<br />
                      Subscriber E deposits 2 Ethereum (1 ETH in WREX Account & 1 ETH in one 100 ETH PLAN)<br />
                      This means, Subscriber A has total of 93 referred Ethereum in WREX Account and 25 referred Ethereum in 100 ETH PLAN.<br />
                      So, every month, once the monthly profit percentage is confirmed by Baker Tilly monthly report (“MP%”),<br />
                      WREX Account Affiliate Bonus: [93 Ethereum] x [MP%] x 5% shall be deposited into your WREX Account (in case if you had chosen [AUTO WITHDRAW], then this Affiliate Bonus shall be deposited into your My ETH Wallet)<br />
                      100 ETH PLAN Affiliate Bonus: [25 Ethereum] x [MP%] x 5% shall be deposited into your first 100 ETH PLAN<br />
                    </li>
                    <li>
                      Why our Affiliate Program is powerful?<br />
                      * Sustainability – our affiliate program payouts are 5% of every months’ profit. This will last forever!<br />
                      * Every month, if your affiliated WREX Account are using [AUTO RE-ACCUMULATION], it means every monthly profit are added to their respective WREX Accounts. It also means every month, your referred Ethereum will grow, so your overall WREX Account – Affiliate Bonus will also grow.<br />
                      * Formula: [Your referred Ethereum in WREX Accounts] x [MP%] x 5%<br />
                      * Every month, your referred Ethereum in 100 ETH PLAN will grow even faster than your referred WREX Accounts. Your Affiliate Bonus will also grow because it is based on the number of referred Ethereum in the 100 ETH PLAN. Unless your referred Ethereum in 100 ETH PLAN are terminated, you will receive your 100 ETH PLAN – Affiliate Bonus every month. <br />
                      * Formula: [Your referred Ethereum in 100 ETH PLAN] x [MP%] x 5%<br />
                      * Everyone registered at WREX Platform shall enjoy the same 45% of the Monthly Profit for WREX Account based on their Ethereum number and 90% of the Monthly Profit for 100 ETH PLAN regardless of how many affiliate Ethereum they have. So, everyone will appreciate you have introduced our platform to them. So, don’t be shy telling your family, friends, and your close networks.<br />
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="subscription" className="faq_tit">Subscription & Registration</p>
        <div className='swanky_wrapper faq'>
          <input id='pr2_1' name='radio' type='checkbox' />
          <label htmlFor='pr2_1'>
            <span className="th_faq">1.	How do I buy Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    There are several ways to buy Ethereum in your region. One is finding a local cryptocurrency exchange that accepts fiat (your local currency). Create an account, link it with your bank account, deposit cash into your account, and then purchase Ethereum.<br />
                    The other way is finding a Over The Counter. This is so-called the Cryptocurrency brokers who buys and sells cryptocurrency offline. Try to google them and locate one near your region. Meet them in person and exchange. However, please be aware that it is important that you meet them in person and execute the transaction otherwise you may fall into wrong hands.<br />
                    If you are not familiar how to purchase Ethereum, the best solution is always to ask the person who introduced you to WREX Platform.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_2' name='radio' type='checkbox' />
          <label htmlFor='pr2_2'>
            <span className="th_faq">2.	What is Ethereum network fee?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    The Ethereum blockchain is a network. Ether (ETH) is the fuel for that network. When you send tokens, interact with a contract, send ETH or ERC20 tokens, or do anything else on the blockchain, you must pay for that computation. That payment is calculated in gas, and gas is always paid in ETH.<br />
                    [Reference: Google.com]<br />
                    So, when you are sending Ethereum from your external wallet (exchange) to WREX My ETH Wallet, you will have to pay the GAS. Be sure to have enough GAS before you send your Ethereum to WREX. If you buy 1 or 2 Ethereum to register at WREX that means you do not have enough GAS to send the Ethereum to us. Check the recent GAS cost and be sure to purchase 2 Ethereum + GAS in order to transfer the full 2 Ethereum to WREX.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_3' name='radio' type='checkbox' />
          <label htmlFor='pr2_3'>
            <span className="th_faq">3.	What is affiliate code? I don’t have an affiliate code, how can I register?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Once you become a subscriber to WREX, you shall receive your personal affiliate code. This affiliate code shall be used by your friends when they register in our site. Once they key in your affiliate code, all the affiliate bonus shall go to your accounts. <br />
                    If the affiliate code is keyed in, they cannot change the affiliate code. Also, you need to input an affiliate code to complete the registration. <br />
                    This is to ensure all our subscribers who put time and efforts to promote WREX gets their credit. WREX does not initiate our own promotions to attract new subscribers at all time, so you must have heard of our site from someone and we feel that someone should be rewarded.<br />
                    If you really accidentally learned about us by browsing the internet, and therefore do not have an affiliate code to enter, please write to us at <a href="mailto:support@w-rex.com">support@w-rex.com</a><br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_4' name='radio' type='checkbox' />
          <label htmlFor='pr2_4'>
            <span className="th_faq">4.	I lost access to my email address I used at registering, what to do?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Your ID at WREX is your email address. Sometimes you may loose your privilege to access to your email account that you used to register at WREX especially if you had used a company email account.<br />
                    If in any case, you loose your access to your email account, please write us at <a href="mailto:support@w-rex.com">support@w-rex.com</a> for assistant. <br />
                    Be extra careful when registering and refrain from using your company email account.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_5' name='radio' type='checkbox' />
          <label htmlFor='pr2_5'>
            <span className="th_faq">5.	What is google 2 factor authentication (2FA)? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    This is similar to your banking OTP device only you can download from google playstore. Download Google OTP. Once you add WREX 2FA QR CODE, you will see 6 digit number in your Google OTP app. The number refreshes every 30 seconds. <br />
                    This 2 FA is an extra security features when executing withdrawals. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_6' name='radio' type='checkbox' />
          <label htmlFor='pr2_6'>
            <span className="th_faq">6.	I lost my phone and lost google 2FA, how can I reset my 2FA? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Yes, you can reset your 2-FA. When you get a new phone, download Google OTP again and add by reading your QR CODE with the app. However, when you initiate resetting the 2FA, a verification email needs to be confirmed. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_7' name='radio' type='checkbox' />
          <label htmlFor='pr2_7'>
            <span className="th_faq">7.	I lost my password, how can I retrieve?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Losing a password can be painful process to retrieve so be sure not to loose your password. If you had lost your password and cannot log in to WREX, please write us at <a href="mailto:support@w-rex.com">support@w-rex.com</a> <br />
                    We will have to go through a password resetting process which can take up some time as it also involves contacting your referrer to make sure the account belongs to you. This would be our standard procedure to make sure no one else other than you can change the password. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_8' name='radio' type='checkbox' />
          <label htmlFor='pr2_8'>
            <span className="th_faq">8.	I want to deposit more than 100 Ethereum, looks like you have set a cap of 100 Ethereum deposit. Can I deposit more than 100 Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Yes, you may deposit more than 100 Ethereum after you have completed our Level 2 KYC – Know your customer process. This involves in sending in a copy of your passport and other required documentations. Please write us at <a href="mailto:support@w-rex.com">support@w-rex.com</a> for further assistance.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_9' name='radio' type='checkbox' />
          <label htmlFor='pr2_9'>
            <span className="th_faq">9.	I deposited 10 Ethereum in WREX Account, but I got only half of the monthly profit percentage as my return, how come?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    WREX is a very time sensitive platform, and whether you would be eligible to receive 100%, 50%, 25% or 0% of your first month’s monthly profit depends on what day you activate your WREX Account. This happens only on the first month on the newly deposited Ethereum. Once the first month pass by, the following months you shall receive 100% of the monthly profit percentage.<br />
                    So, if you had earned only 50% of the month’s monthly profit, most likely this is because when you deposit your Ethereum at WREX it may have occurred between the first day to the tenth day of the month. In such case, you are eligible to receive 50% of the monthly profit. Please refer to the table below for your information.<br /><br />
                    Deposited between	% of Monthly Profit<br />
                    1st Day ~ 10th Day	50%<br />
                    11th Day ~ 20th Day	25%<br />
                    21st Day ~ Last Day	0%<br /><br />
                    For 100 ETH PLAN, all deposit shall be effective only on the 1st day of the following month. Whether you deposit and create 100 ETH PLAN on the 1st day or the last day of the month, the new 100 ETH PLAN shall start the following month.
                        </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="wrex" className="faq_tit">About WREX Platform</p>
        <div className='swanky_wrapper faq'>
          <input id='pr3_1' name='radio' type='checkbox' />
          <label htmlFor='pr3_1'>
            <span className="th_faq">1.	I have seen very similar sites and products, what make it different from the others?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Yes, the industry has seen many similar sites with similar products and offers. <br />
                    For examples,
                            </p>
                  <ol>
                    <li>
                      Offering “Principle Guarantee”<br />
                      We technically do not guarantee principle, this is because it is against the law in some country to guarantee the principle. So, if you heard any products that offers “Principle Guaranteed”, it is not right.<br />
                      However, our profit sharing is based on PROFITs in the month. If we make profit, we charge 50% of the profit as our trading fee. If we perform “0”%, then there will be no fee. If we perform negative, which we never had since the start of the project, we shall recover the number of Ethereum lost from our principle pot with our fee collected in the past.
                              </li>
                    <li>
                      Offering “a specific return a month on principle”<br />
                      Again, in some countries, stating a specific percentage as a profit guarantee is illegal. Therefore, we do not guarantee a specific return a month. From our past experience and performance, we had made anywhere between 5%~80% return on the principle a month, and never recorded a losing month.
                              </li>
                    <li>
                      Monthly Verification Process Conducted by Baker Tilly<br />
                      Every month, between 1st day to 5th day, Baker Tilly would visit our office and do manual verifications on all single trades we had concluded in the previous month, verifications on the monthly profit percentage and account status. On the 8th of the month such monthly report shall be published and viewable in our web site to our subscribers. Adding the maximum transparency.
                              </li>
                    <li>
                      Our Fee – 50% of the monthly profit!<br />
                      Yes, we also feel our fee is VERY HIGH. But with 5%~80% performing record a month, we are very confident that YOUR RETURN A MONTH is THE HIGHEST anyone or any organization can offer to you. Also, our high fee is for our sustainability.
                              </li>
                    <li>
                      We never touch your PRINCIPLE, your PRINCIPLE shall only GROW<br />
                      Where the most of similar sites FAIL, to raise funds, they give out commissions to those who promotes their sites and gets the funding. They use the PRINCIPLE to pay out that commissions.<br />
                      We at WREX, 100% of the PRINCIPLE is used to trade for profits. We would never touch a single Ethereum from the PRINCIPLE. This shall be also verified in the ACCOUNT VERIFICATION by Baker Tilly every month.<br />
                      So, unless we perform negatively, there is no change our PRINCIPLE will shrink. It will only GROW because we never failed to record 5%~80% a month since the start of our service.<br />
                      At worst, we would perform “0”% a month, if there is nothing to buy and nothing to sell.
                              </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_2' name='radio' type='checkbox' />
          <label htmlFor='pr3_2'>
            <span className="th_faq">2. How can WREX ensure it is not losing my Ethereum?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    This is because of our PRINCIPLE RULE #1 of our skilled and experienced traders, ‘never sell with a loss’. We simply don’t sell with a loss. Never did it in the past, and never intend to change our rule #1 in the future.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_3' name='radio' type='checkbox' />
          <label htmlFor='pr3_3'>
            <span className="th_faq">3.	Is there a guarantee on my principle?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Legally no we cannot guarantee your principle. However, as mentioned above, we take our trading fee if only there is a monthly profit.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_4' name='radio' type='checkbox' />
          <label htmlFor='pr3_4'>
            <span className="th_faq">4.	How much return a month can I expect?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    From the start of 2018 to this date, we had recorded 5% ~ 80% return a month on the principle. However, this is an indication and cannot be guaranteed.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_5' name='radio' type='checkbox' />
          <label htmlFor='pr3_5'>
            <span className="th_faq">5.	What are the products WREX offer? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Currently, we offer two programs, one is the WREX Account and the other is the 100 ETH PLAN.<br />
                    WREX Account<br />
                  </p>
                  <ol>
                    <li>You can deposit from 1 Ethereum and up to 100 Ethereum in WREX Account. If you want to deposit more than 100 Ethereum in WREX Account please write to us at <a href="mailto:support@w-rex.com">support@w-rex.com</a></li>
                    <li>You can choose [AUTO WITHDRAW] or [AUTO RE-ACCUMULATE] for the monthly profit in the WREX Account. (You can choose AUTO RE-ACCUMULATE and execute manual withdrawals at any time)</li>
                    <li>For WREX Account, [# of Ethereum in WREX Account] x 45% x Monthly Profit % shall be either WITHDRAWN to your My ETH Wallet or RE-ACCUMULATED in your WREX Account depending on the option you choose.</li>
                    <li>You have to maintain minimum 1 Ethereum in WREX Account from the first day of the month to the last day of the month to receive that month’s affiliate bonus for all WREX Accounts’ you have referred, if any.</li>
                    <li>You have to maintain minimum 1 Ethereum in WREX Account to open and maintain up to ten 100 ETH PLAN.</li>
                  </ol>
                  <p>100 ETH PLAN</p>
                  <ol>
                    <li>You can open up to ten 100 ETH PLAN per account.</li>
                    <li>You need to maintain minimum 1 Ethereum in WREX Account.</li>
                    <li>You can deposit ONLY 1 Ethereum per 100 ETH PLAN</li>
                    <li>All your 100 ETH PLAN Affiliate Bonus, if any, shall go into your first 100 ETH PLAN</li>
                    <li>For 100 ETH PLAN, [#of Ethereum in 100 ETH PLAN] x 90% x Monthly Profit % shall be your new balance towards to the next month.</li>
                    <li>Maturity of a 100 ETH PLAN is after the 40th month. </li>
                    <li>You cannot withdraw any amount from any 100 ETH PLAN. You can only execute early termination. There is no penalty for an early termination.</li>
                  </ol>
                  <p>Please see the next inquiry explaining the difference between the two accounts.</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_6' name='radio' type='checkbox' />
          <label htmlFor='pr3_6'>
            <span className="th_faq">6.	What is the difference between WREX Account and 100 ETH Plan? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>You need to have minimum 1 Ethereum in WREX Account, and you will receive,<br /></p>
                  <ol>
                    <li>Eligible to open up to ten 100 ETH PLANs</li>
                    <li>Eligible to receive WREX Account – Affiliate Bonus, if any</li>
                  </ol>
                  <p>
                    You need to have minimum one 100 ETH PLAN, and you will receive, Eligible to receive all 100 ETH PLANs – Affiliate Bonus, if any
                          The difference in WREX Account and 100 ETH PLAN is the grow rate. As explained above 1 Ethereum in the WREX Account shall grow to 15.6 Ethereum after 40th month if we reach average monthly return percentage of 15.8%. At same condition, 100 ETH PLAN shall grow to 112 Ethereum. So, why is it different? <br /><br />
                    Because in WREX Account, 45% of the monthly profit % is re-accumulated to your WREX Account, whereas, in 100 ETH PLAN, not only your 45% but WREX will match your 45%, so instead of 45%, total of 90% of monthly profit is re-accumulated thus the growth is much faster compared to WREX Account.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_7' name='radio' type='checkbox' />
          <label htmlFor='pr3_7'>
            <span className="th_faq">7.	If I deposit 2 Ethereum, how much can I expect my return?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    With 2 Ethereum deposited in us, 1 Ethereum in WREX Account and 1 Ethereum in one 100 ETH PLAN to maximize return and also to be eligible for both Affiliate Bonus;<br />
                    If WREX Trading reach average of 15.8% a month return every month and you choose [AUTO RE-ACCUMULATE] option for your WREX Account.<br />
                    Your expected return at end of the 40th month for WREX Account is 15.6 Ethereum and 112 Ethereum for 100 ETH PLAN. So total of 117.6 Ethereum.<br />
                    This above calculation or forecast is even without single affiliate bonus.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_8' name='radio' type='checkbox' />
          <label htmlFor='pr3_8'>
            <span className="th_faq">8.	What is the role of Baker Tilly?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    It is all about transparency. Baker Tilly is one of the top ten reputable and largest accounting firm in the world.<br />
                    Every month, between 1st day to 5th day, Baker Tilly would visit our office and do manual verifications on all single trades we had concluded in the previous month, verifications on the monthly profit percentage and account status. On the 8th of the month such monthly report shall be published and viewable in our web site to our subscribers. Adding the maximum transparency.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="affiliate" className="faq_tit">About Affiliate Program</p>
        <div className='swanky_wrapper faq'>
          <input id='pr4_1' name='radio' type='checkbox' />
          <label htmlFor='pr4_1'>
            <span className="th_faq">1.	As an influencer, do I get all the effort I put in?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Yes, you definitely will. Anyone who wish to become a subscriber at WREX, when registering, everyone has to key-in an affiliate code to complete the register. We want to make sure whoever put extra efforts and spend time to promote WREX should get the full benefits.
                          </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_2' name='radio' type='checkbox' />
          <label htmlFor='pr4_2'>
            <span className="th_faq">2. How does it differ from other Affiliate Programs?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    In many crypto related environments, you will encounter many different types of their own affiliate programs. Most of them are;
                        </p>
                  <ol>
                    <li>You can omit the affiliate code and they still accept the registration, so you would loose some of the referrals simply they forget to key-in the affiliate code or they simply don’t want to key-in</li>
                    <li>If your referrer stops using the service for whatever reasons, you will not get the bonus</li>
                  </ol>
                </li>
              </ul>
              <p>
                In WREX, new members must key-in the affiliate code to complete the registration so everyone gets the benefit and nothing goes without rewards. Also, in our nature of business, your referrer should never leave our service because it is making profit for everyone every month. So, your referrer will stay forever and therefore your will receive your affiliate bonus every month forever. <br />
                Lastly, every month, your referred Ethereum number shall grow per the monthly profit percentage. It means that your affiliate bonus shall also grow because it is based on the Ethereum you have referred to the system.
                    </p>
            </div>
          </label>
          <input id='pr4_3' name='radio' type='checkbox' />
          <label htmlFor='pr4_3'>
            <span className="th_faq">3.	Is this a multi level marketing?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    No, it is not multi level marketing. You would only get affiliate bonus from your direct referrals.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_4' name='radio' type='checkbox' />
          <label htmlFor='pr4_4'>
            <span className="th_faq">4.	If I have 100 friends registered, how much of an income from Affiliate Program can I expect?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    Case Study:<br />
                    If you referred 100 friends to WREX, all registered and everyone deposited 2 Ethereum each (1 Ethereum in WREX Account and 1 Ethereum in the 100 ETH PLAN)<br />
                    So, this means, your total referred Ethereum would be 200 Ethereum; 100 Ethereum in WREX Accounts, and 100 Ethereum in 100 ETH PLAN.<br />
                    If WREX reach 15.8% this month as monthly profit percentage,<br /><br />
                    YOUR BONUS is calculated as follows:<br />
                  </p>
                  <ol>
                    <li>WREX Account – Affiliate Bonus<br />[# of referred Ethereum in WREX Account] x 5% x [Monthly Profit %][100 Ethereum] x 5% x 15.8% = 0.79 Ethereum</li>
                    <li>100 ETH PLAN – Affiliate Bonus<br />[# of referred Ethereum in 100 ETH PLAN] x 5% x [Monthly Profit %][100 Ethereum] x 5% x 15.8% = 0.79 Ethereum</li>
                  </ol>
                  <p>	So, total Ethereum earned from both accounts are 1.58 Ethereum. If you accumulate this bonus for 40 months, your affiliate bonus on your 100 friends would be in 1,000s of Ethereum.</p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="withdraw" className="faq_tit">About Withdrawal and Fee</p>
        <div className='swanky_wrapper faq'>
          <input id='pr5_1' name='radio' type='checkbox' />
          <label htmlFor='pr5_1'>
            <span className="th_faq">1.	How does withdrawal work, and is there a withdrawal fee?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    How does withdrawal work, and is there a withdrawal fee?
                          </p>
                          <ul>
                            <li>If you execute [AUTO WITHDRAW] from your WREX Account, your monthly profit shall be transferred directly to your WREX My ETH Wallet on the 10th of the month and ready to be transferred out to your external wallets at once. [AUTO WITHDRAW] There is a withdraw fee of 0.5% of the total Ethereum.</li>
                    <li>If you execute manual withdrawal from your WREX Account, your requested amount is transferred to your WREX My ETH Wallet within minimum 72 hours to maximum 4weeks of your request.
                              <br />*The urgent forced exit due to withdrawal request may hurt the monthly profit percentage. So this policy change is to enhance the protocol to minimize damages and maximize profit for everyone within the WREX community.
                              <br />[Manual Withdraw] There is a withdraw fee of 1.0% of the total Ethereum requested.</li>
                    <li>You cannot withdraw any amount from your 100 ETH PLAN.<br />You can only terminate the 100 ETH PLAN. Once terminated, your Ethereum shall be transferred to your WREX My ETH Wallet on the 10th day of the following month. There is no early termination penalty. Only withdraw fee shall apply.<br />[100 ETH PLAN] Termination/Maturity fee of 1.0% of the total Ethereum requested.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </label>
        </div>


      </div>

    )
  }
}

export default FaqPageEN;
