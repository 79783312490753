import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './containers/Root'
import configureStore from './store/configureStore'
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import Modal from 'react-modal';

import Cookies from 'universal-cookie';
import * as T from './i18n/translation' 
import i18n from 'i18n-react'; 

require('./styles/uicss.css');


const cookies = new Cookies();

const cookieLanguageSetting = cookies.get('language_setting');


if (cookieLanguageSetting!==undefined) {
  i18n.setTexts(T.Translations(cookieLanguageSetting)); 
} else {
  i18n.setTexts(T.Translations("EN")); 
}

const store = configureStore()

render(
  <Router>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
)


