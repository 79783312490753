import {MAX_DECIMAL_NUMBER} from '../constants/ConfigValue.js';

import i18n from 'i18n-react';

export const getAddressString = (inputValue) => {
  //console.log("getAddressString"+inputValue);
  return inputValue.substr(0,6)+"..."+inputValue.substr(inputValue.length-4,4);
}

export const getAddressStringByDevice = (inputValue,device) => {
  //console.log("getAddressStringByDevice"+inputValue);
  if (device===true) {
    return inputValue.substr(0,6)+"..."+inputValue.substr(inputValue.length-4,4);
  } else {
    return inputValue;
  }
}

export const getAddressString2 = (inputValue) => {
  //console.log("getAddressString"+inputValue);
  return inputValue.substr(0,6)+"..."+inputValue.substr(inputValue.length-6,6);
}


export const getETHDisplayString = (inputValue) => {
  if (inputValue==="") return inputValue;
  if (inputValue===undefined) return "";
  if (inputValue===null) return "";


  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString : "+inputValue);
  
  //const outputValue = parseFloat(parseFloat(inputValue).toFixed(MAX_DECIMAL_NUMBER));

  //var s = outputValue.toString(); 
  //var rs = s.indexOf('.'); 
  var s = inputValue.toString();
  var rs = s.indexOf('.'); 
//console.log(rs);
  //return inputValue.substr(0,6)+"..."+inputValue.substr(inputValue.length-4,4);

  if (rs < 0) { 
console.log("Error");
return inputValue;
  } else {
    s = s.substr(0,rs+1+MAX_DECIMAL_NUMBER);
  }

/*
  if (rs < 0) { 
    let count=0;
    s = s+ ".";
    while (count<MAX_DECIMAL_NUMBER) {
console.log("add 0");
      s = s+"0";
      count++;
    }
  } else {
    let count = s.length - rs - 1;
    console.log(s.length);
    console.log(rs);
    console.log(count);
    while (count<MAX_DECIMAL_NUMBER) {
console.log("add 0");
      s = s+"0";
      count++;
    }
  } 
*/
//console.log(s);
  return s;

}

export const getStatusDisplayString = (invest, ref) => {

  //console.log("getStatusDisplayString"+ref);
  //console.log(invest);
  
  if (ref==="ep100") { 
    if (invest.ep100===true) {
      return i18n.translate('active');
    } else {
      return "";
    }
  } else if (ref==="wota") {
    if (invest.wota===true) {
      return i18n.translate('active');
    } else {
      return "";
    }
  } else {
    if ((invest.wota===true)&&(invest.ep100===true)) {
      return i18n.translate('active');
    } else {
      return "";
    }
  }

  //const outputValue1 = parseFloat(parseFloat(inputValue1).toFixed(MAX_DECIMAL_NUMBER));
  //const outputValue2 = parseFloat(parseFloat(inputValue2).toFixed(MAX_DECIMAL_NUMBER));
  //return getETHDisplayString(outputValue1+outputValue2);
  //return ""

}

export const getAdd2ETHDisplayString = (inputValue1, inputValue2) => {
  if ((inputValue1==="")||(inputValue1===undefined)) return "";
  if ((inputValue2==="")||(inputValue2===undefined)) return "";

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString"+inputValue);
  
  const outputValue1 = parseFloat(parseFloat(inputValue1).toFixed(MAX_DECIMAL_NUMBER));
  const outputValue2 = parseFloat(parseFloat(inputValue2).toFixed(MAX_DECIMAL_NUMBER));
  return getETHDisplayString(outputValue1+outputValue2);

}
export const getAdd3ETHDisplayString = (inputValue1, inputValue2, inputValue3) => {
  if ((inputValue1==="")||(inputValue1===undefined)) return "";
  if ((inputValue2==="")||(inputValue2===undefined)) return "";
  if ((inputValue3==="")||(inputValue3===undefined)) return "";

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString"+inputValue1);
  //console.log("getDisplayString"+inputValue2);
  //console.log("getDisplayString"+inputValue3);
  
  const outputValue1 = parseFloat(parseFloat(inputValue1).toFixed(MAX_DECIMAL_NUMBER));
  const outputValue2 = parseFloat(parseFloat(inputValue2).toFixed(MAX_DECIMAL_NUMBER));
  const outputValue3 = parseFloat(parseFloat(inputValue3).toFixed(MAX_DECIMAL_NUMBER));
  return getETHDisplayString(outputValue1+outputValue2+outputValue3);

}

export const getYearMonthFromIntString = (inputValue) => {
  if (inputValue==="") return inputValue;

  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString"+inputValue);

  //var b = inputValue.split(/\D+/);
  var year = parseInt(inputValue/100);

  var month = months[inputValue-year*100-1];

  return month+", "+year.toString();
  
}

export const getYearMonthWithoutCommaFromIntString = (inputValue) => {
  if (inputValue==="") return inputValue;

  const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString"+inputValue);

  //var b = inputValue.split(/\D+/);
  var year = parseInt(inputValue/100);

  var month = months[inputValue-year*100-1];

  return month+" "+year.toString();
  
}

export const getYearMonthString = (inputValue) => {
  if (inputValue==="") return inputValue;

  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getDisplayString"+inputValue);

  var b = inputValue.split(/\D+/);

  var month = months[parseInt(b[1]-1)];

  return month+", "+b[0];
  
}

export const getTimeDisplayString = (inputValue) => {
  if (inputValue==="") return inputValue;

  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getTimeDisplayString"+inputValue);

  var b = inputValue.split(/\D+/);

  //console.log(b);
  var month = months[parseInt(b[1]-1)];

  return b[2]+" "+month+" "+b[0]+", "+b[3]+":"+b[4]+" (UTC)";
  
}

export const getFullTimeDisplayString = (inputValue) => {
  if (inputValue==="") return inputValue;

  //console.warn("getDisplayString inputValue: "+inputValue);
  //console.log("getTimeDisplayString"+inputValue);

  var b = inputValue.split(/\D+/);

  //console.log(b);

  return b[2]+"-"+b[1]+"-"+b[0]+" "+b[3]+":"+b[4]+":"+b[5];
  
}

