//export const API_ROOT = 'https://www.w-rex.com/'
export const API_ROOT = 'https://www.devel.jvsakk.w-rex.com/'

//export const API_ROOT = 'https://staging.w-rex.com/'

export const REFERRAL_LINK_PREFIX = 'https://www.w-rex.com/?ref='

export const DEFAULT_NETWORK_FEE =  0.00042;

export const MAX_DECIMAL_NUMBER=8;

