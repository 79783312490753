import { CALL_API, Schemas } from '../middleware/api'
//import * as types from '../constants/ActionTypes'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

// Relies on the custom API middleware defined in ../middleware/api.js.
const login = (args) => ({
  [CALL_API]: {
    types: [ LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE ],
    target: `WREX API`,
    endpoint: `user/login`,
    method: `post`,
    args: args,
    schema: Schemas.LOGIN
  }
})

export const sendLogin = (args) => dispatch => {
  //console.log("sendLogin");
  //console.log(args);
/*
{
  "email": "user1@wrex.com",
  "password": "user1password",
  "device": "web"
}
*/
  return dispatch(login(args));
}

export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_FAILURE = 'API_FAILURE'

// Relies on the custom API middleware defined in ../middleware/api.js.
const sendAPIWithoutAuth = (endpoint,args) => ({
  [CALL_API]: {
    types: [ API_REQUEST, API_SUCCESS, API_FAILURE ],
    target: `WREX API`,
    endpoint: endpoint,
    method: `post`,
    args: args,
    schema: Schemas.RESPONSE
  }
})

// Relies on the custom API middleware defined in ../middleware/api.js.
const sendAPIWithAuth = (endpoint,token,args) => ({
  [CALL_API]: {
    types: [ API_REQUEST, API_SUCCESS, API_FAILURE ],
    target: `WREX API`,
    endpoint: endpoint,
    method: `post`,
    token: token,
    args: args,
    schema: Schemas.RESPONSE
  }
})

const sendUserAPI = (endpoint,token,args) => ({
  [CALL_API]: {
    types: [ API_REQUEST, API_SUCCESS, API_FAILURE ],
    target: `WREX API`,
    endpoint: endpoint,
    method: `post`,
    token: token,
    args: args,
    schema: Schemas.USER
  }
})

const sendAccountAPI = (endpoint,token,args) => ({
  [CALL_API]: {
    types: [ API_REQUEST, API_SUCCESS, API_FAILURE ],
    target: `WREX API`,
    endpoint: endpoint,
    method: `post`,
    token: token,
    args: args,
    schema: Schemas.ACCOUNT
  }
})

export const sendUserSignUp = (args) => dispatch => {
  //console.log("sendUserSignUp");
  //console.log(args);
/*
{
  "email": "user1@wrex.com",
  "password": "test1234",
  "nickname": "user1",
  "country_code": "JP",
  "affiliate_code": 12345678
}
*/
  return dispatch(sendAPIWithoutAuth("user/signup",args));
}

export const sendUserInit2FA = (token,args) => dispatch => {
  //console.log("sendUserInit2FA");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAPIWithAuth("user/init2fa",token,args));
}

export const sendUserAffiliateRanking = (token,args) => dispatch => {
  //console.log("sendUserAffiliateRanking");
  //console.log(args);
  //console.log(token);
  return dispatch(sendUserAPI("user/affiliate.ranking",token,args));

}

export const sendUserResendEmail = (token,args) => dispatch => {
  //console.log("sendUserResendEmail");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAPIWithAuth("user/resend.email",token,args));
}

export const sendUserInfo = (token,args) => dispatch => {
  //console.log("sendUserInfo");
  //console.log(args);
  //console.log(token);
  return dispatch(sendUserAPI("user/info",token,args));
}

export const sendUserLoginHistory = (token,args) => dispatch => {
  //console.log("sendUserLoginHistory");
  //console.log(args);
  //console.log(token);
  return dispatch(sendUserAPI("user/login.history",token,args));
}

export const sendUserWithdrawAddresses = (token,args) => dispatch => {
  //console.log("sendUserWithdrawAddresses");
  //console.log(args);
  //console.log(token);
  return dispatch(sendUserAPI("user/withdraw.addresses",token,args));
}

/*
export const sendUserAffiliateCodes = (token,args) => dispatch => {
  //console.log("sendUserAffiliateCodes");
  //console.log(args);
  //console.log(token);
  return dispatch(sendUserAPI("user/affiliate.codes",token,args));
}
*/
export const sendUserAffiliateStatus = (token,args) => dispatch => {
  //console.log("sendUserAffiliateStatus");
  //console.log(args);
  //console.log(token);
/*
{
  "detail": true         (optional)
}
*/
  return dispatch(sendUserAPI("user/affiliate.status",token,args));
}

export const sendUserForgotPassword = (args) => dispatch => {
  //console.log("sendUserForgotPassword");
  //console.log(args);
  return dispatch(sendAPIWithoutAuth("user/forgot.password",args));
}

export const sendUserLogout = (token,args) => dispatch => {
  //console.log("sendUserLogout");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAPIWithAuth("user/logout",token,args));
}

export const sendReset2FA = (token,args) => dispatch => {
  //console.log("sendReset2FA");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAPIWithAuth("user/reset2fa",token,args));
}

export const sendResetPassword = (args) => dispatch => {
  //console.log("sendResetPassword");
  //console.log(args);
/*
{
  "user_id": 1002,
  "key": "ASDASDGFSD",
  "new_password": "test1234"
}
*/
  return dispatch(sendAPIWithoutAuth("user/reset.password",args));
}

export const sendAccountInfo = (token,args) => dispatch => {
  //console.log("sendAccountInfo");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAccountAPI("account/info",token,args));
}

export const sendAccountMyEthHistory = (token,args) => dispatch => {
  //console.log("sendAccountMyEthHistory");
  //console.log(args);
  //console.log(token);
/*
{
  log_type: "deposit"   (optional)
}
*/
  return dispatch(sendAccountAPI("account/myeth.history",token,args));
}

export const sendAccountWrexTransfer = (token,args) => dispatch => {
  console.log("sendAccountWrexTransfer");
  console.log(args);
  console.log(token);
  return dispatch(sendAPIWithAuth("account/wrex.transfer",token,args));
}

export const sendAccountWrexMonthlyPerformance = (token,args) => dispatch => {
  //console.log("sendAccountWrexMonthlyPerformance");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAccountAPI("account/wrex.monthly.performance",token,args));
}

export const sendAccountWrexInvestHistory = (token,args) => dispatch => {
  //console.log("sendAccountWrexInvestHistory");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAccountAPI("account/wrex.invest.history",token,args));
}

export const sendAccount100EPList = (token,args) => dispatch => {
  //console.log("sendAccount100EPList");
  //console.log(args);
  //console.log(token);
  return dispatch(sendAccountAPI("account/100ep.list",token,args));
}

export const sendUserSetup2FA = (token,args) => dispatch => {
  //console.log("sendUserSetup2FA");
  //console.log(args);
  //console.log(token);
/*
{
  "otp": 123456
}
*/
  return dispatch(sendAPIWithAuth("user/setup2fa",token,args));
}

export const sendUserUpdate = (token,args) => dispatch => {
  //console.log("sendUserUpdate");
  //console.log(args);
  //console.log(token);
/*
{
  "email": "user1@wrex.com",
  "password": "user1password",
  "nickname": "user1",
  "country_code": "JP"
}
(each field is optional, only provide fields that need update)
*/
  return dispatch(sendAPIWithAuth("user/update",token,args));
}

export const sendUserWithdrawAddressNew = (token,args) => dispatch => {
  //console.log("sendUserWithdrawAddressNew");
  //console.log(args);
  //console.log(token);
/*
{
  "address": "xxxx",
  "name": "my wallet",      (optional)
  "otp": "123456"
}
*/
  return dispatch(sendAPIWithAuth("user/withdraw.address.new",token,args));
}

export const sendUserWithdrawAddressUpdate = (token,args) => dispatch => {
  //console.log("sendUserWithdrawAddressUpdate");
  //console.log(args);
  //console.log(token);
/*
{
  "address": "xxxx",
  "name": "account at xxx"
}
*/
  return dispatch(sendAPIWithAuth("user/withdraw.address.update",token,args));
}

export const sendUserWithdrawAddressDelete = (token,args) => dispatch => {
  //console.log("sendUserWithdrawAddressDelete");
  //console.log(args);
  //console.log(token);
/*
{
  "address": "xxxx",
}
*/
  return dispatch(sendAPIWithAuth("user/withdraw.address.delete",token,args));
}

/*
export const sendUserAffiliateCodeNew = (token,args) => dispatch => {
  //console.log("sendUserAffiliateCodeNew");
  //console.log(args);
  //console.log(token);
//{
  //"name": "new code"       (optional)
//}
  return dispatch(sendAPIWithAuth("user/affiliate.code.new",token,args));
}
*/

export const sendAccountTransfer = (token,args) => dispatch => {
  console.log("sendAccountTransfer");
  console.log(args);
  console.log(token);
/*
{
  "address": "0x1234567890",
  "amount": "1",
  "message": "happy birthday",  (optional)
  "otp": "123456"
}

*/
  return dispatch(sendAPIWithAuth("account/transfer",token,args));
}

export const sendAccountMyEthWithdraw = (token,args) => dispatch => {
  //console.log("sendAccountMyEthWithdraw");
  //console.log(args);
  //console.log(token);
/*
{
  "amount": "1",
  "address": "xxxxxx"
}
*/
  return dispatch(sendAPIWithAuth("account/myeth.withdraw",token,args));
}

export const sendAccountWREXDeposit = (token,args) => dispatch => {
  console.log("sendAccountWREXDeposit");
  console.log(args);
  console.log(token);
/*
{
  "amount": "1",
  "reinvest": true         (optional if not null)
}
*/
  return dispatch(sendAPIWithAuth("account/wrex.deposit",token,args));
}

export const sendAccountWREXWithdraw = (token,args) => dispatch => {
  //console.log("sendAccountWREXWithdraw");
  //console.log(args);
  //console.log(token);
/*
{
  "amount": "1"
}
*/
  return dispatch(sendAPIWithAuth("account/wrex.withdraw",token,args));
}

export const sendAccountWREXUpdate = (token,args) => dispatch => {
  //console.log("sendAccountWREXUpdate");
  //console.log(args);
  //console.log(token);
/*
{
  "reinvest": false
}
*/
  return dispatch(sendAPIWithAuth("account/wrex.update",token,args));
}

export const sendAccount100EPMonthPerformance = (token,args) => dispatch => {
  //console.log("sendAccount100EPMonthPerformance");
  //console.log(args);
  //console.log(token);
/*
{
  "subscription_id": 1
}
*/
  return dispatch(sendAPIWithAuth("account/100ep.monthly.performance",token,args));
}

export const sendAccount100EPSubscribe = (token,args) => dispatch => {
  //console.log("sendAccount100EPSubscribe");
  //console.log(args);
  //console.log(token);
/*
{
  "name": "first"       (optional)
}
*/
  return dispatch(sendAPIWithAuth("account/100ep.subscribe",token,args));
}

export const sendAccount100EPUpdate = (token,args) => dispatch => {
  //console.log("sendAccount100EPUpdate");
  //console.log(args);
  //console.log(token);
/*
{
  "subscription_id": 1,
  "name": "2019 New"
}
*/
  return dispatch(sendAPIWithAuth("account/100ep.update",token,args));
}

export const sendAccount100EPTerminate = (token,args) => dispatch => {
  //console.log("sendAccount100EPTerminate");
  //console.log(args);
  //console.log(token);
/*
{
  "subscription_id": 2
}
*/
  return dispatch(sendAPIWithAuth("account/100ep.terminate",token,args));
}



















export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'

// Resets the currently visible error message.
export const resetErrorMessage = () => ({
    type: RESET_ERROR_MESSAGE
})

