/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Chart from 'chart.js'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

//import { getYearMonthWithoutCommaFromIntString, getYearMonthFromIntString, getETHDisplayString, getAdd2ETHDisplayString }  from '../helper/displayHelper'
import { getYearMonthWithoutCommaFromIntString, getYearMonthFromIntString, getETHDisplayString }  from '../helper/displayHelper'
import { getTimeDisplayString }  from '../helper/displayHelper'

import Select, {Option} from '@material/react-select'

import { renderErrorMessage } from '../components/ErrorMessage'

import { sendAccountWrexMonthlyPerformance, API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';

import i18n from 'i18n-react';


//const cookies = new Cookies();
class WrexAccount extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      filter: "0",
      errorMessage: "",
    }

  }

  setupMyChart(reference,performance) {
    //console.log("setupMyChart");
    //console.log(performance);

    var labelArr=[];
    var dataArr=[];
//console.log(performance.length);
    for (let i in performance) {
      labelArr.push(getYearMonthWithoutCommaFromIntString(performance[i].yearmonth));
      dataArr.push(parseFloat(performance[i].profit)+parseFloat(performance[i].referralBonus));
    }
    if (performance.length<6) {
      labelArr.push("");
      dataArr.push(0);
    }
//console.log(labelArr);
//console.log(dataArr);

    labelArr.reverse();
    dataArr.reverse();
//console.log(labelArr);
//console.log(dataArr);
    reference.myChart = new Chart(reference.canvasRef.current, {
      type: 'line',
      options: {
        legend: {
            display: false,
        }
      },
      data: {
        labels: labelArr,
        datasets: [{
          data: dataArr,
          label: "",
          borderColor: "#3e95cd",
          fill: true 
        }],
      }
    });
  }

  componentDidMount() {
    //console.log("ych WrexAccount");
    //window.addEventListener('resize', this.handleResize)
    document.title = "WREX Account - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";
		

    var performance=[];
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)&&(this.props.globalAccount.account.performance!==undefined)) {  
      let account = this.props.globalAccount.account;
      performance = account.performance;
      this.setupMyChart(this,performance);
    } else {
      var self=this;
      let token = getToken(this);
      this.props.sendAccountWrexMonthlyPerformance(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.account.account;
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            self.setupMyChart(self,response.performance);
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  getReinvestDisplayString(reinvest) {
    if (reinvest===true) {
      //return "Auto Accumulation";
      return i18n.translate('auto_reinvest');
    } else {
      //return "Auto Withdrawal";
      return i18n.translate('auto_withdrawal');
    }
  }

  getHistoryTH0(amount) {
    if (parseFloat(amount)>=0) {
      return "arrow_downward";
    } else {
      return "arrow_upward";
    }
  }

  getHistoryTH1(logType,description) {
    if (logType==="deposit") {
      return "-";
    } else if (logType==="withdraw") {
      return "-";
    } else if (logType==="wota_deposit") {
      return i18n.translate('wrex_account');
    } else if (logType==="wota_withdrawal") {
      return i18n.translate('wrex_account');
    } else if (logType==="wota_profit") {
      return i18n.translate('wrex_account');
    } else if (logType==="wota_referral_bonus") {
      return i18n.translate('wrex_account');
    } else if (logType==="ep100_subscribe") {
      return description;
    } else if (logType==="ep100_profit") {
      return description;
    } else if (logType==="ep100_referral_bonus") {
      return description;
    } else if (logType==="ep100_termination") {
      return description;
    } else if (logType==="ep100_mature") {
      return description;
    } else if (logType==="fee") {
      return "-";
    } else {
      return "-";
    }
  }

/*
myethaccount
Deposit | Withdraw | Gains | Bonus | 100EP Plan | Wrex
1         2          3       4       5            6
Deposit:  deposit
Withdraw: withdraw
Gains:    wota_profit, ep100_profit
Bonus:    wota_referral_bonus, ep100_referral_bonus
100EP Plan: ep100_subscribe, ep100_termination, ep100_mature
Wrex: wota_deposit, wota_withdrawal

wrex  account 
Deposit | Withdraw | Gains | Bonus |
11        12         13      14
Deposit:  wota_deposit
Withdraw: wota_withdrawal
Gains:    wota_profit
Bonus:    wota_referral_bonus

*/


  getHistoryTH3(logType) {
    if (logType==="deposit") {
      return i18n.translate('deposit');
    } else if (logType==="withdraw") {
      return i18n.translate('ca_withdraw');
    } else if (logType==="wota_deposit") {
      return i18n.translate('deposit');
    } else if (logType==="wota_withdrawal") {
      return i18n.translate('ca_withdraw');
    } else if (logType==="wota_profit") {
      return i18n.translate('ca_gains');
    } else if (logType==="wota_referral_bonus") {
      return i18n.translate('ca_bonus');
    } else if (logType==="ep100_subscribe") {
      return i18n.translate('ca_create');
    } else if (logType==="ep100_profit") {
      return i18n.translate('ca_gains');
    } else if (logType==="ep100_referral_bonus") {
      return i18n.translate('ca_bonus');
    } else if (logType==="ep100_termination") {
      return i18n.translate('ca_terminate');
    } else if (logType==="ep100_mature") {
      return i18n.translate('ca_mature');
    } else if (logType==="fee") {
      return i18n.translate('ca_fee');
    } else {
      return "-";
    }
  }

  getHistoryAddress(address) {
     var b = address.split(/:/);
     return b[0];
  }

  getHistoryAddressUrl(address) {
     var b = address.split(/:/);
     return "https://etherscan.io/address/"+b[0];
  }

  getHistoryTXIDUrl(txid) {
     return "https://etherscan.io/tx/"+txid;
  }

  handleFilterChange = (index, item) => {
    //console.log("handleFilterChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (item!==undefined){
      const filterValue = item.getAttribute('data-value');
      this.setState({filter: filterValue})
    }

  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Wrex Account Error",this);
    }

    var self=this;

    //console.log(this.props.globalAccount);

    var accountWrex = {value:"", reinvest: true};
    var performance = [];
    var history = [];
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {  
      let account = this.props.globalAccount.account;
//console.log(account);
      if (account.wrex!==undefined) {
        accountWrex = account.wrex;
        //accountWrex.value = parseFloat(account.wrex.value);
        accountWrex.value = account.wrex.value;
        accountWrex.reinvest = account.wrex.reinvest;
      }

      if (account.performance!==undefined) { 
        performance = account.performance;
      }

/*
      if (account.myeth!==undefined) {
        //myeth = account.myeth;
        myeth.value = parseFloat(account.myeth.value);
        myeth.valueUsd = parseFloat(account.myeth.valueUsd);
        if ((account.wrex!==undefined)&&(account.ep100!==undefined)) {
          //console.log(this.myChart);
          if (this.myChart!==undefined) {
            //console.log(this.myChart.data);
            //console.log(this.myChart.data.datasets[0].data);
            this.myChart.data.datasets[0].data=[
              parseFloat(account.myeth.value),
              parseFloat(account.wrex.value),
              parseFloat(account.ep100.value)];
            this.myChart.update();
          }
        }
      }
*/
      if (account.wrexHistory!==undefined) {
        history = account.wrexHistory;
      }
    }

//console.log(history);


/*
    const performanceNodes = performance.map((myPerformance,index) =>
      <div key={index}>
        <input id={index} name='radio' type='checkbox' />
        <label htmlFor={index}>
          <span className="th_1">{getYearMonthFromIntString(myPerformance.yearmonth)}</span>
          <span className="th_2">
            <span className="crypto-wallet__stats--up">+{getAdd2ETHDisplayString(myPerformance.profit,myPerformance.referralBonus)} ETH</span>
            <span className="crypto-transactions-list__item-price">({myPerformance.change})</span>
          </span>
          <div className='lil_arrow'></div>
          <div className='bar'></div>
          <div className='swanky_wrapper__content'>
            <ul>
            <li>{i18n.translate('profitshare')} : <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.profit)} ETH</span></li>
            <li>{i18n.translate('affiliatebonus')} : <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.referralBonus)} ETH</span></li>
            </ul>
          </div>
        </label>
      </div>
    )
*/

//console.log(performance);
            //<span className="crypto-wallet__stats--up">+{getAdd2ETHDisplayString(myPerformance.profit,myPerformance.referralBonus)} ETH</span>
    const performanceNodes = performance.map((myPerformance,index) =>
      <div key={index}>
        <input id={index} name='radio' type='checkbox' />
        <label htmlFor={index}>
          <span className="th_1">{getYearMonthFromIntString(myPerformance.yearmonth)}</span>
          <span className="th_2">
            <span className="crypto-wallet__stats--up">+{getETHDisplayString(myPerformance.userGains)} ETH</span>
          </span>
          <div className='lil_arrow'></div>
          <div className='bar'></div>
          <div className='swanky_wrapper__content'>
            <ul>
            <li>{i18n.translate('profitshare')} : <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.profit)} ETH</span></li>
            <li>{i18n.translate('affiliatebonus')} : <span className="mdc-list-item__meta">{getETHDisplayString(myPerformance.referralBonus)} ETH</span></li>
            </ul>
          </div>
        </label>
      </div>
    )

    return (
      <div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	
									
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="wrex"
                                        />



				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
		  
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="assets">{i18n.translate('assets')} </Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('wrex')}</h1>
							  <h4 className="f_mode">{this.getReinvestDisplayString(accountWrex.reinvest)}<Link to="wrex_return_setting">{i18n.translate('setting')}</Link></h4>
							</div>
							<div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(accountWrex.value)}<span>ETH</span></h1>
								</div>
							</div>
							<div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
							  <div className="f_control">
                <Link to="wrex_transfer" className="f_ybtn mdc-button"
                  > {this.state.LanguageSetting !=="KR" && 
                    <span className="_small">{i18n.translate('wrextransfer')}</span>}<span>{i18n.translate('transferB')}</span></Link>
								 <Link to="wrex_withdraw" className="f_ybtn mdc-button">{i18n.translate('withdraw')}</Link>
								 <Link to="wrex_deposit" className="f_ybtn mdc-button">{i18n.translate('deposit')}</Link>
							  </div>
							</div>
						</div>





			
						  <div className="mdc-layout-grid fx_padding">
							<div className="mdc-layout-grid__inner">


							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('overview')}</span>
								  </h2>
									<div className="crypto-widget__content text-center f_linechart">
                		<canvas id='myChart' ref={this.canvasRef} />
            			</div>
								</div>
							  </div>




							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="mdc-layout-grid__inner">
									<div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
									  <h2 className="crypto-widget__heading2 mdc-theme--primary">{i18n.translate('performance')}</h2>
									</div>

									<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-1-phone crypto-widget__actions">
									  <div id="toolbar" className="toolbar mdc-menu-surface--anchor">
										<div className="mdc-menu mdc-menu-surface" id="widget-menu"></div>
									  </div>
									</div>
								  </div>

									<div className="crypto-widget__content crypto-overflow-x-inherit">
									<div className='swanky_wrapper'>
									{performanceNodes}

									</div>
								  </div>
								</div>

							  </div>
							  <div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">


								<div className="crypto-widget">
								  <div className="mdc-layout-grid__inner">
										<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-2-phone">
											<h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">{i18n.translate('accounthistory')}</h2>
										</div>
									<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-2-phone crypto-widget__actions">
									  <div id="toolbar" className="toolbar mdc-menu-surface--anchor">

            <div className="f_control4 fx_mb">
              <Select className="mdc-select-box crypto-gender-select select-color2 crypto-w-100"

                        enhanced
                        value={this.state.filter}
                        onEnhancedChange={this.handleFilterChange.bind(this)}
               > 
												<Option value="0">{i18n.translate('filter')}</Option>
												<Option value="11">{i18n.translate('ch_deposit')}</Option>
												<Option value="12">{i18n.translate('withdraw')}</Option>
												<Option value="13">{i18n.translate('gains')}</Option>
												<Option value="14">{i18n.translate('bonus')}</Option>
               </Select>
            </div>

{/*
										<div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-small crypto-w-100 f_filter">
											<select className="mdc-select__native-control fix1" id="filter"  dir="rtl"
                                                                                		value={this.state.filter}
                                                                                		onChange={this.handleFilterChange.bind(this)}
											>
												<option value="0">{i18n.translate('filter')}</option>
												<option value="11">{i18n.translate('deposit')}</option>
												<option value="12">{i18n.translate('withdraw')}</option>
												<option value="13">{i18n.translate('gains')}</option>
												<option value="14">{i18n.translate('bonus')}</option>
											</select>
										</div>
*/ }
									  </div>
									</div>
								  </div>


								  <div className="crypto-widget__content crypto-overflow-x-inherit">
									<div className='swanky_wrapper'>

                  <div>
								<label htmlFor='header'>
									<span className="th_0"><i className="material-icons mdc-button__icon"></i></span>
									<span className="th_1">{i18n.translate('externaladdress')}</span>
									<span className="th_2">{i18n.translate('datetime')}</span>
									<span className="th_3">{i18n.translate('action')}</span>
									<span className="th_4">{i18n.translate('amount')}</span>
									<div className='bar'></div>
								</label>
                </div>

    { history.map(function (myHistory,index) {
      const myKey="tr"+index.toString();

      if ((self.state.filter==="11")&&(myHistory.logType!=="wota_deposit")) {
        return (
          <div key={index}></div>
        );
      } else if ((self.state.filter==="12")&&(myHistory.logType!=="wota_withdrawal")) {
        return (
          <div key={index}></div>
        );
      } else if ((self.state.filter==="13")&&(myHistory.logType!=="wota_profit")) {
        return (
          <div key={index}></div>
        );
      } else if ((self.state.filter==="14")&&(myHistory.logType!=="wota_referral_bonus")) {
        return (
          <div key={index}></div>
        );
      }

      if ((myHistory.logType==="deposit")||(myHistory.logType==="withdraw")) {
        return (
          <div key={index}>
            <input id={myKey} name='radio' type='checkbox' />
            <label htmlFor={myKey}>
              <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
              <span className="th_1">{self.getHistoryTH1(myHistory.logType,myHistory.description)}</span>
              <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
              <span className="th_3">{self.getHistoryTH3(myHistory.logType)}</span>
              <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
              <div className='lil_arrow'></div>
              <div className='bar'></div>
              <div className='swanky_wrapper__content'>
                <ul>
                  <li>ADDRESS:<a href={self.getHistoryAddressUrl(myHistory.description)}>{self.getHistoryAddress(myHistory.description)}</a></li>
                  <li>TXID:<a href={self.getHistoryTXIDUrl(myHistory.txHash)}>{myHistory.txHash}</a></li>
                </ul>
              </div>
            </label>
          </div>
        )
      } else {
        return (
          <div key={index}>
            <input id={myKey} name='radio' type='checkbox' />
            <label htmlFor={myKey}>
              <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
              <span className="th_1">{self.getHistoryTH1(myHistory.logType,myHistory.description)}</span>
              <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
              <span className="th_3">{self.getHistoryTH3(myHistory.logType)}</span>
              <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
            </label>
          </div>
        )
      }
    }) }


{ /*
										<input id='tr1' name='radio' type='checkbox' />
										<label htmlFor='tr1'>
											<span className="th_0"><i className="material-icons mdc-button__icon">arrow_upward</i></span>
											<span className="th_1">WREX</span>
											<span className="th_2">01 JUN 2019, 22:13</span>
											<span className="th_3">WITHDRAW</span>
											<span className="th_4">-3.96ETH</span>
											<div className='lil_arrow'></div>
											<div className='bar'></div>
											<div className='swanky_wrapper__content'>
											<ul>
												<li>Amount: <span className="mdc-list-item__meta">4ETH</span></li>
												<li>Withdraw Fee: <span className="mdc-list-item__meta">0.04ETH</span></li>
											</ul>
											</div>
										</label>

										<input id='tr2' name='radio' type='checkbox' />
										<label htmlFor='tr2'>
											<span className="th_0"><i className="material-icons mdc-button__icon">arrow_upward</i></span>
											<span className="th_1">WREX</span>
											<span className="th_2">18 JUN 2017, 17:21</span>
											<span className="th_3">DEPOSIT</span>
											<span className="th_4">+1.5ETH</span>
											
										</label>

										<input id='tr3' name='radio' type='checkbox' />
										<label htmlFor='tr3'>
											<span className="th_0"><i className="material-icons mdc-button__icon">arrow_downward</i></span>
											<span className="th_1">-</span>
											<span className="th_2">10 MAY 2019, 00:00</span>
											<span className="th_3">GAINS</span>
											<span className="th_4">+4ETH</span>
											<div className='lil_arrow'></div>
											<div className='bar'></div>
											<div className='swanky_wrapper__content'>
											<ul>
											</ul>
											</div>
										</label>

										<input id='tr4' name='radio' type='checkbox' />
										<label htmlFor='tr4'>
											<span className="th_0"><i className="material-icons mdc-button__icon">arrow_downward</i></span>
											<span className="th_1">-</span>
											<span className="th_2">10 MAY 2019, 00:00</span>
											<span className="th_3">BONUS</span>
											<span className="th_4">+4ETH</span>
											<div className='lil_arrow'></div>
											<div className='bar'></div>
											<div className='swanky_wrapper__content'>
											<ul>
												<li>Affiliate Program Amount <span className="mdc-list-item__meta">581.32000000</span></li>
											</ul>
											</div>
										</label>
{ */ }
										</div>	
                    </div>

											<div className="f_rg_ptop f_centerbox mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
											<div className="crypto-widget">
												<h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
												<span className="crypto-widget__heading-text">{i18n.translate('aboutwrexaccount')}</span>
												<span className="crypto-widget__heading-border"></span>
												</h2>
												<div className="crypto-widget__content">
													<ul className="li_notice">
														<li>{i18n.translate('wa1')}</li>
														<li>{i18n.translate('wa2')}</li>
														<li>{i18n.translate('wa3')}</li>
														<li>{i18n.translate('wa4')}</li>
														<li>{i18n.translate('wa5')}</li>
														<li>{i18n.translate('wa6')}</li>
													</ul>
												</div>
											</div>
											</div>
								  

								</div>

							  </div>




							</div>
						  </div>



					</div>

				  </main>

				</div>				
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

})

export default withRouter(connect(mapStateToProps, {
  sendAccountWrexMonthlyPerformance
})(WrexAccount))




