/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
//import { sendAccountMyEthWithdraw, API_SUCCESS } from '../actions'

import '@material/react-select/dist/select.css'
import '../styles/mdc.css'
import Select, {Option} from '@material/react-select'

import i18n from 'i18n-react';

import {DEFAULT_NETWORK_FEE} from '../constants/ConfigValue.js';
import { getETHDisplayString, getAddressString2 }  from '../helper/displayHelper'

//import QRCode from 'react-qr-code';

import { renderErrorMessage } from '../components/ErrorMessage'


import SideNav from '../components/SideNav';
import PreLoaderWidget from '../components/Loader';

//import {isLogin} from '../helper/loginHelper';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class MyEthWithdraw extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      addresses : [],
      withdrawAmount: "",
      withdrawAddress: "",
      exactWithdrawAmount: "",
      canRequest: false,
      errorMessage: "",
      windowWith: window.innerWidth,
      isMobile: window.innerWidth < 479,
    }
    
  }

  componentDidMount() {
    //console.log("ych MyEthWithdraw");
    //window.addEventListener('resize', this.handleResize)
    document.title = "MyETH Withdraw - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    //console.log(this.props);

    window.addEventListener('resize', this.handleResize)

    const cookieSubmitError = cookies.get('submit_error');
    if ((cookieSubmitError!==undefined)&&(cookieSubmitError!=="")) {
        this.setState({errorMessage: i18n.translate(cookieSubmitError)})

        cookies.set('submit_error', "", {path: '/'});

        const cookieWithdrawAddress = cookies.get('withdraw_address');
        const cookieWithdrawAmount = cookies.get('withdraw_amount');

        this.setState({withdrawAddress: cookieWithdrawAddress});
        this.setState({withdrawAmount: cookieWithdrawAmount});
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.handleResize);

  }

  handleResize = (event) => {
    this.setState({ windowWith: window.innerWidth });
    this.setState({isMobile: this.state.windowWith < 479});
  }


  handleMyEthWithdrawRequest() {
    //console.log("handleMyEthWithdrawRequest");
    this.setState({errorMessage: ""})

//console.log(this.state.withdrawAddress);

    if (this.state.canRequest===false) {
      this.setState({errorMessage: i18n.translate('invalid_amount')})
      //console.log("can not request");
      return;
    } else if (this.state.withdrawAddress==="") {
      this.setState({errorMessage: i18n.translate('err_addressEmpty')})
    } else {
      if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {
        let user = this.props.globalUser.user;

        cookies.set('withdraw_address', this.state.withdrawAddress, {path: '/'});
        cookies.set('withdraw_amount', this.state.withdrawAmount, {path: '/'});

        if ((user.info!==undefined)&&(user.info.totpEnabled===0)) {
          this.props.history.push("/require2fa?ref=myeth_withdraw")
        } else {
          this.props.history.push("/to2fa?ref=myeth_withdraw")
        }
      } else {
        this.props.history.push("/myeth_wallet")
      }

    }


/*
    var self = this;

    let token = getToken(this);

    this.props.sendAccountMyEthWithdraw(token, { amount: amount, address: toAddress
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {

// add to refresh
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
*/
  }

  handleWithdrawAddressChange = (index, item) => {  
    //console.log("handleWithdrawAddressChange");
    //console.log('jamie:', item);
    this.setState({errorMessage: ""})

    if (item!==undefined){
      this.setState({withdrawAddress: item.getAttribute('data-value')})
      //console.log("dddd: ", item);
    }

  }

  handleWithdrawAmountChange(event)  {
    //console.log("handleWithdrawAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    var accountMyeth = {value: 0};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        accountMyeth.value = parseFloat(account.myeth.value);
      }
    }

    if (event!==undefined){
      this.setState({withdrawAmount: event.target.value})
      if (event.target.value!=="") {
        const withdrawAmount = parseFloat(event.target.value);
        if (withdrawAmount>accountMyeth.value) {
          //this.setState({errorMessage: i18n.translate('err_overWrexValue')})
          this.setState({exactWithdrawAmount: ""})
          this.setState({canRequest: false})
        } else if (withdrawAmount<DEFAULT_NETWORK_FEE) {
          //this.setState({errorMessage: i18n.translate('err_lessThenNetworkFee')})
          this.setState({exactWithdrawAmount: ""})
          this.setState({canRequest: false})

        } else {
          this.setState({exactWithdrawAmount: withdrawAmount-DEFAULT_NETWORK_FEE})
          this.setState({canRequest: true})
        }
      } else {
        this.setState({exactWithdrawAmount: ""})
        this.setState({canRequest: false})
      }
    }

  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("My Eth Withdraw Error",this);
    }
    let self = this;

    // console.log("j: ", this.props.globalUser);
    //console.log(this.props.globalAccount);

   
    const addresses = [];
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if (user.addresses!==undefined) {
        for (let i in user.addresses) {
          if (user.addresses[i].enabled===true) {
            addresses.push(user.addresses[i]);
          }
        }
      }
    }

    // console.log("jamie addresses: ", addresses);
    //this.state.addresses = addresses;
    let myAddresses = addresses;
    //const optionNodes = this.state.addresses.map((myAddr,index) => {
    const optionNodes = myAddresses.map((myAddr,index) => {
        if ((myAddr.enabled !== undefined) && (myAddr.enabled === true)) {
          // console.log("jamie option: ", myAddr);
          if (self.state.isMobile===true) {
            return (
               <Option key={index} value={myAddr.address}>{myAddr.name+ " : " + getAddressString2(myAddr.address)}</Option>
            );
          } else {
            return (
               <Option key={index} value={myAddr.address}>{myAddr.name+ " : " + myAddr.address}</Option>
            );
          }
        }
    })

    //var accountMyeth = {value: "", withdrawable: false};
    var accountMyeth = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        accountMyeth = account.myeth;


      }
    }
    //console.log(addresses);

    //const optionNodes = addresses.map((myAddr,index) =>
      //<option key={index} value={myAddr.address}>{myAddr.name+ ", " + getAddressString2(myAddr.address)}</option>
    //)



    return (
      <div>
						{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="myeth_wallet"
                                        />

				

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="myeth_wallet">{i18n.translate('myethwallet')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('withdraw')}</h1>
							</div>
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(accountMyeth.value)}<span>ETH</span></h1>
								</div>
							</div>
              <div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                
							</div>
						</div>
		 

						  <div className="mdc-layout-grid">
							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('mew1')}</span>
								  </h2>
								</div>
							</div>
              </div>

							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_formlarge mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
											<p className="f_formtitle">{i18n.translate('amount')}</p>
											<div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
													<input type="text" id="withdraw-amount-text-field" className="mdc-text-field__input" 
                       value={this.state.withdrawAmount}
                       onChange={this.handleWithdrawAmountChange.bind(this)}
                     />
													
											</div>

											<div className="mdc-layout-grid__cell--span-12">
											 <p className="f_formtitle">{i18n.translate('withdrawto')}</p> 

                    <Select className="mdc-select-box crypto-gender-select crypto-margin-bottom-big crypto-w-100 fx_select_height"
                        enhanced
                        value={this.state.withdrawAddress}
                        onEnhancedChange={this.handleWithdrawAddressChange}
                    >
                      <Option value="" disabled selected>-- {i18n.translate('selectaddress')} --</Option>
                      {optionNodes}
                    </Select>

											</div>
											<div className="mdc-layout-grid__cell--span-12">
                        {/* 
												<h4>{i18n.translate('networkfee')}: {getETHDisplayString(DEFAULT_NETWORK_FEE)} ETH</h4>
                        <h4>{i18n.translate('youwillget')}: {getETHDisplayString(this.state.exactWithdrawAmount)} ETH</h4>
                        */}
											</div>

{ /*
               <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }

											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span className="mdc-button mdc-button--unelevated big-round-corner-button" 
                                                                                            onClick={() => this.handleMyEthWithdrawRequest()}
                                                                                          >
                                                                                            {i18n.translate('request')}
                                                                                          </span>
											</div>
										  </div>
											</div>
										</form>
									  </div>

									</div>
								  </div>
								</div>
							  </div>
                </div>

							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_rg_ptop mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('withdrawnotices')}</span>
									<span className="crypto-widget__heading-border"></span>
								  </h2>
								  <div className="crypto-widget__content">
										<ul className="li_notice">
											<li>{i18n.translate('mew2')}</li>
											<li>{i18n.translate('mew3')}</li>
											<li>{i18n.translate('mew4')}</li>
											<li>{i18n.translate('mew5')}</li>
											<li>{i18n.translate('mew6')}</li>
											</ul>
								  </div>
								</div>
							  </div>

							</div>
						  </div>


					</div>

				  </main>

				</div>
      </div>

    )
  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

  }
}

export default withRouter(connect(mapStateToProps, {
  //sendAccountMyEthWithdraw
})(MyEthWithdraw))




