/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';

import '@material/react-select/dist/select.css'
import '../styles/mdc.css'
import Button from '@material-ui/core/Button';
import StyledMenu  from '@material-ui/core/Menu';
import StyledMenuItem from '@material-ui/core/MenuItem';

import i18n from 'i18n-react';

import {DEFAULT_NETWORK_FEE} from '../constants/ConfigValue.js';
import { getETHDisplayString, getAddressString2 }  from '../helper/displayHelper'

import { renderErrorMessage } from '../components/ErrorMessage'

import SideNav from '../components/SideNav';
import PreLoaderWidget from '../components/Loader';
import { withStyles } from '@material-ui/styles';

const cookies = new Cookies();

const StyledButton = withStyles({
  root: {
    backgroundColor: '#ffce61',
    textTransform: 'none',
  }
})(Button);

class MyEthTransfer extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorEl : false,
      //addresses : [],
      transferAmount: "",
      transferAddress: "",
      canRequest: false,
      errorMessage: "",
      windowWith: window.innerWidth,
      isMobile: window.innerWidth < 479,
      transferAddressHolder: i18n.translate('enteraddressholder'),
    }
    
  }

  componentDidMount() {
    //console.log("ych MyEthWithdraw");
    //window.addEventListener('resize', this.handleResize)
    document.title = "MyETH Withdraw - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    //console.log(this.props);

    window.addEventListener('resize', this.handleResize)

    const cookieSubmitError = cookies.get('submit_error');
    if ((cookieSubmitError!==undefined)&&(cookieSubmitError!=="")) {
        this.setState({errorMessage: i18n.translate(cookieSubmitError)})

        cookies.set('submit_error', "", {path: '/'});

        const cookieTransferAddress = cookies.get('transfer_address');
        const cookieTransferAmount = cookies.get('transfer_amount');

        this.setState({transferAddress: cookieTransferAddress});
        this.setState({transferAmount: cookieTransferAmount});
        this.setState({transferAddressHolder: ''});
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.handleResize);

  }

  handleResize = (event) => {
    this.setState({ windowWith: window.innerWidth });
    this.setState({isMobile: this.state.windowWith < 479});
  }


  handleMyEthTransferRequest() {
    //console.log("handleMyEthTransferRequest");
    this.setState({errorMessage: ""})

    if (this.state.canRequest===false) {
      this.setState({errorMessage: i18n.translate('invalid_amount')})
      //console.log("can not request");
      //return;
    } else if (this.state.transferAddress==="") {
      this.setState({errorMessage: i18n.translate('err_addressEmpty')})
    } else {
      if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {
        let user = this.props.globalUser.user;

        cookies.set('transfer_address', this.state.transferAddress, {path: '/'});
        cookies.set('transfer_amount', this.state.transferAmount, {path: '/'});

        if ((user.info!==undefined)&&(user.info.totpEnabled===0)) {
          this.props.history.push("/require2fa?ref=myeth_transfer")
        } else {
          this.props.history.push("/to2fa?ref=myeth_transfer")
        }
      } else {
        this.props.history.push("/myeth_wallet")
      }

    }


  }


  handleTransferAddressSelect = (index, item) => {

    //console.log("handleTransferAddressChange");
    this.setState({errorMessage: ""});

    if (item!==undefined){
      this.setState({transferAddress: item.getAttribute('data-value')})
      this.setState({transferAddressHolder: ""})
      //console.log("dddd: ", item);
    }

  }

  handleAccountClick (address) {  
    //console.log("handleTransferAddressChange");
    this.setState({errorMessage: ""});

    this.setState({transferAddressHolder: ""})
    this.setState({transferAddress: address})
    this.setState({anchorEl : !this.state.anchorEl});
  }

  handleTransferAddressChange (event) {  
    //console.log("handleTransferAddressChange");
    this.setState({errorMessage: ""});

    if (event.target.value!=="") {
      this.setState({transferAddressHolder: ""})
      
    } else {
      this.setState({transferAddressHolder: i18n.translate('enteraddressholder')})
    }
    this.setState({transferAddress: event.target.value})
  }

  handleTransferAmountChange(event)  {
    //console.log("handleTransferAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    var accountMyeth = {value: 0};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        accountMyeth.value = parseFloat(account.myeth.value);
      }
    }

    if (event!==undefined){
      this.setState({transferAmount: event.target.value})
      if (event.target.value!=="") {
        const transferAmount = parseFloat(event.target.value);
        if (transferAmount>accountMyeth.value) {
          //this.setState({errorMessage: i18n.translate('err_overWrexValue')})
          this.setState({canRequest: false})
        } else if (transferAmount<DEFAULT_NETWORK_FEE) {
          //this.setState({errorMessage: i18n.translate('err_lessThenNetworkFee')})
          this.setState({canRequest: false})

        } else {
          this.setState({canRequest: true})
        }
      } else {
        this.setState({canRequest: false})
      }
    }

  }

  
  handleSelectAddress = (event) => {
//console.log(this.state.anchorEl);
    this.setState({anchorEl : !this.state.anchorEl});
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("My Eth Transfer Error",this);
    }
    let self = this;

    // console.log("j: ", this.props.globalUser);
    //console.log(this.props.globalAccount);

   
    const addresses = [];
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if (user.addresses!==undefined) {
        for (let i in user.addresses) {
          if (user.addresses[i].enabled===true) {
            addresses.push(user.addresses[i]);
          }
        }
      }
    }

    // console.log("jamie addresses: ", addresses);
    //this.state.addresses = addresses;
    let myAddresses = addresses;
    //const optionNodes = this.state.addresses.map((myAddr,index) => {
    const optionNodes = myAddresses.map((myAddr,index) => {
        if ((myAddr.enabled !== undefined) && (myAddr.enabled === true)) {
          // console.log("jamie option: ", myAddr);
          if (self.state.isMobile===true) {
            return (
               <StyledMenuItem value={myAddr.address} onClick={()=>this.handleAccountClick(myAddr.address)}>{myAddr.name+ " : " + getAddressString2(myAddr.address)}</StyledMenuItem>
            );
          } else {
            return (
               <StyledMenuItem value={myAddr.address} onClick={()=>this.handleAccountClick(myAddr.address)}>{myAddr.name+ " : " + myAddr.address}</StyledMenuItem>
            );
          }
        }
    })

//console.log(optionNodes);

    var accountMyeth = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        accountMyeth = account.myeth;


      }
    }
    //console.log(addresses);

    //const optionNodes = addresses.map((myAddr,index) =>
      //<option key={index} value={myAddr.address}>{myAddr.name+ ", " + getAddressString2(myAddr.address)}</option>
    //)



    return (
      <div>
						{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="myeth_wallet"
                                        />

				

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="myeth_wallet">{i18n.translate('myethwallet')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('transfer')}</h1>
							</div>
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(accountMyeth.value)}<span>ETH</span></h1>
								</div>
							</div>
						</div>
		 

						<div className="mdc-layout-grid">
							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('met1')}</span>
								  </h2>
								</div>
							</div>
              </div>

							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-0-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
							        <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
											<p className="f_formtitle">{i18n.translate('amount')}</p>
											<div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
													<input type="text" id="withdraw-amount-text-field" className="mdc-text-field__input" 
                       value={this.state.transferAmount}
                       onChange={this.handleTransferAmountChange.bind(this)}
                     />
											</div>
                      </div>
                      <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div> 
                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                      <div className="mdc-layout-grid__inner f_mbottom25">
											<div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-2-phone">
											<p className="f_formtitle" style={{ 'margin-top': '5%'}}>{i18n.translate('transferto')}</p> 
                      </div>
											<div className="mdc-layout-grid__cell--span-7-desktop mdc-layout-grid__cell--span-5-tablet f_txtr mdc-layout-grid__cell--span-2-phone">
                      <StyledButton 
                      id="SelectButton"
                      variant="contained"
                      onClick={() => this.handleSelectAddress()}
                      > {i18n.translate('whitelist')}</StyledButton>
                      <StyledMenu
                          id="mymenu"
                          anchorEl={this.state.anchorEl}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: 500, horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleSelectAddress.bind(this)}
                      >
                      {optionNodes}
                      </StyledMenu>
                      </div></div>
                      <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                      <input type="text" 
                          id="withdraw-address-text-field" 
                          className="mdc-text-field__input" 
                          value={this.state.transferAddress} 
                          onChange={this.handleTransferAddressChange.bind(this)}
                      />
                      <label htmlFor="withdraw-address-text-field" className="mdc-floating-label">{this.state.transferAddressHolder}</label>
                      </div>
											</div>
                      <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet">
                      </div>

                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone f_btncenter ">
											<span className="mdc-button mdc-button--unelevated big-round-corner-button" 
                                                                                            onClick={() => this.handleMyEthTransferRequest()}
                                                                                          >
                                                                                            {i18n.translate('send')}
                                                                                          </span>
											</div>
                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											</div>
										</form>
									  </div>

									</div>
								  </div>
								</div>

                <div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_rg_ptop mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('transfernotices')}</span>
									<span className="crypto-widget__heading-border"></span>
								  </h2>
								  <div className="crypto-widget__content">
										<ul className="li_notice">
											<li>{i18n.translate('met2')}</li>
											</ul>
								  </div>
								</div>
							  </div>
							  </div>


							  </div>
              </div>

							
						</div>


					</div>

				  </main>

				</div>
      </div>

    )
  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

  }
}

export default withRouter(connect(mapStateToProps, {
  //sendAccountTransfer
})(MyEthTransfer))




