/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import i18n from 'i18n-react';

//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

import { isLogin } from '../helper/loginHelper';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class Google2fa11a extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      newAddress: "",
      newName: "",
    }
  }

  componentDidMount() {
    //console.log("ych Google 2FA");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Google 2FA - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    const cookieNewAddress = cookies.get('new_address');
    const cookieNewName = cookies.get('new_name');
    //console.log(cookieNewAddress);
    //console.log(cookieNewName);
    if ((cookieNewAddress!==undefined)&&(cookieNewName!==undefined)) {
      this.setState({newAddress: cookieNewAddress})
      this.setState({newName: cookieNewName})
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                <img src="assets/images/logo_wrex.svg" alt="WREX" />
              </h2>
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text"></span>
                <span className="crypto-widget__heading-border"></span>
              </h2>
    
              <h2 className="crypto-widget__heading2 mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('changesuccess')}</span>
              </h2>

              <p className="f_titleg">{this.state.newName}</p>
              <p className="f_titlec">{this.state.newAddress}</p>

              <div className="crypto-login-form">
                <Link to="/account_address" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('dismiss')}</Link>
              </div>

            </div>
          </div>
			  </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
})(Google2fa11a))




