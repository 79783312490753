/* eslint-disable no-undef */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Cookies from 'universal-cookie';
import PreLoaderWidget from '../components/Loader';
//import Topbar from '../components/Topbar';
//import Footer from '../components/Footer';
//import IndexPage from '../components/IndexPage';

import { withStyles } from "@material-ui/core/styles";
//import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
//import Dialog from '@material-ui/core/Dialog';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
//import CloseIcon from '@material-ui/icons/Close';
//import Slide from '@material-ui/core/Slide';
import i18n from 'i18n-react';
import * as T from '../i18n/translation'

import {getTokenWithoutLoginRedirect} from '../helper/loginHelper';


/*
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
*/

const styles = {
  root: {
    backgroundColor: "transparrent",
  },

  paper: {
  //  backgroundColor: "#111",
    boxShadow: "none",
    overflow: "hidden"
  },
};


const cookies = new Cookies();

class Index extends Component {

  static propTypes = {
    // Injected by React Redux
    inputValue: PropTypes.string.isRequired,
    // Injected by React Router
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({
      'selector': PropTypes.string,
      'content': PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
      ]).isRequired,
      'position': PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'center']),
      'action': PropTypes.func,
      'style': PropTypes.object,
      'stepInteraction': PropTypes.bool,
    })),
  }

  constructor(props) {
    super(props);
    const cookieWelcome = cookies.get('welcome');

    //const classes = useStyles();


    if (cookieWelcome!==undefined) {
      this.state = {
        switchToMyWalletPage: false,
        windowWith: window.innerWidth,
        isSmallDesktop: window.innerWidth < 1560,
        isMobile: window.innerWidth < 960,
        isTourOpen: false,
        isShowingMore: false,
        isShowingEventEN: false,
        isShowingEventKR: false,
        welcomeTourModal: false,
        languageSetting: "EN",
      };
    }
    else{
      this.state = {
        switchToMyWalletPage: false,
        windowWith: window.innerWidth,
        isSmallDesktop: window.innerWidth < 1560,
        isMobile: window.innerWidth < 960,
        isTourOpen: false,
        isShowingMore: false,
        isShowingEventEN: false,
        isShowingEventKR: false,
        welcomeTourModal: true,
        languageSetting: "EN",
      };
    }

    const cookieLanguageSetting = cookies.get('language_setting');
    if (cookieLanguageSetting!==undefined) {
      if (cookieLanguageSetting==="KR") {
        this.state.isShowingEventKR = true;
        this.state.languageSetting = "KR";
      } else if (cookieLanguageSetting==="ID") {
        this.state.isShowingEventEN = true;
        this.state.languageSetting = "ID";
      } else {
        this.state.isShowingEventEN = true;
        this.state.languageSetting = "EN";
      }
    } else {
      this.state.isShowingEventEN = true;
      this.state.languageSetting = "EN";
    }

  }

  handleClose() {
    this.setState({ isShowingEventEN: false });
    this.setState({ isShowingEventKR: false });
  }

  reloadLanguage() {
    //console.log("reloadLanguage()");
    const cookieLanguageSetting = cookies.get('language_setting');
    if (cookieLanguageSetting!==undefined) {
      if (cookieLanguageSetting==="KR") {
        this.setState({ isShowingEventKR: true });
      } else if (cookieLanguageSetting==="ID") {
        this.setState({ isShowingEventEN: true });
      } else {
        this.setState({ isShowingEventEN: true });
      }
    } else {
      this.setState({ isShowingEventEN: true });
    }
  }

  goTour= () => {
    this.setState({ 
      isTourOpen: true ,
      welcomeTourModal: !this.state.welcomeTourModal
    });
  };


  toggleWelcomeTour = () => {
    this.setState(prevState => ({
      welcomeTourModal: !this.state.welcomeTourModal
    }));
    cookies.set('welcome', 'done', {path: '/'});
  };

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  handleResize = (event) => {
    this.setState({ windowWith: window.innerWidth });
    this.setState({isSmallDesktop: this.state.windowWith < 1560});
    this.setState({isMobile: this.state.windowWith < 960});
//console.log(window);
//console.log("resize");
//console.log(this.state.isMobile);
//console.log(this.state.isSmallDesktop);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    //console.log("ych Index");

    document.title = "WREX Service"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient f_home";
    //document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient f_home";

    //console.log(this);

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const ref = params.get('ref'); // bar
    if (ref!==null) {
      cookies.set('affiliate_ref', ref, {path: '/'});
    }

    const lang = params.get('lang'); // bar
    if (lang!==null) {
//console.log(lang);
        this.setState({ languageSetting: lang });
        cookies.set('language_setting',lang, {path: '/'});
        i18n.setTexts(T.Translations(lang));
    }

/*
    const event = params.get('event'); // bar
    if (event!==null) {
      this.handleClose();
    }
*/

    if (getTokenWithoutLoginRedirect(this)!==null) {
       this.props.history.push("/my_assets")
    } else {
if (lang!=null) {
       if (lang==="EN") {
         window.location = "index_en.html";
         //this.props.history.push("/index_new.html")
       } else if (lang==="ID") {
         window.location = "index_id.html";
         //this.props.history.push("/index_new.html")
       } else {
         window.location = "index_kr.html";
         //this.props.history.push("/index_new.html")
       }
} else {
       if (this.state.languageSetting==="EN") {
         window.location = "index_en.html";
         //this.props.history.push("/index_new.html")
       } else if (this.state.languageSetting==="ID") {
         window.location = "index_id.html";
         //this.props.history.push("/index_new.html")
       } else {
         window.location = "index_kr.html";
         //this.props.history.push("/index_new.html")
       }
}
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleChange = nextValue => {
    this.props.history.push(`/${nextValue}`)
  }

  /*
  switchPage() {
    if (this.state.switchToMyWalletPage===true) {
      this.setState({
        switchToMyWalletPage: false
      });
    } else {
      this.setState({
        switchToMyWalletPage: true
      });
    }
  } */

  render() {
    const { classes } = this.props;

    return (
      <div>
        { /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}
{/* 
      <div className="crypto-wrapper">

        <main className="crypto-main">
        <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
          <Topbar reloadLanguage={this.reloadLanguage.bind(this)} /> 
          <IndexPage /> 
        </div>
        <Footer />
        </main>

      </div>
     <Dialog open={this.state.isShowingEventEN} 
        maxWidth="lg"
        fullScreen={this.state.isMobile}

        BackdropProps={{
          classes: {
           root: classes.root
          }
         }
        }
        PaperProps ={{
          classes: {
           root: classes.paper
          }
        }}

       onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>

        <div id="event_wrapper">
            <div className="row">
                <div className="event_close" onClick={this.handleClose.bind(this)}><img src="event/ic_close.svg" alt="Close" /></div>

                <div className="event_head">
                    <img src="event/content_head.svg"  alt=""/>
                </div>
                <div className="event_col">
                    <img src="event/content_col_left_en.svg"  alt=""/>
                </div>
                <div className="event_col">
                    <img src="event/content_col_right_en.svg"  alt=""/>
                    <a href="event_en.html#event1"><img src="event/btn_1_en.svg" alt="Event 1"/></a>
                    <a href="event_en.html#event2"><img src="event/btn_2_en.svg" alt="Event 2" /></a>
                </div>
            </div>
        </div>


      </Dialog>
     <Dialog open={this.state.isShowingEventKR} 
        maxWidth="lg"
        fullScreen={this.state.isMobile}
        BackdropProps={{
          classes: {
           root: classes.root
          }
         }
        }
        PaperProps ={{
          classes: {
           root: classes.paper
          }
        }}

       onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>

        <div id="event_wrapper">
            <div className="row">
                <div className="event_close" onClick={this.handleClose.bind(this)}><img src="event/ic_close.svg" alt="Close" /></div>
                <div className="event_head">
                    <img src="event/content_head.svg" alt="" />
                </div>
                <div className="event_col">
                    <img src="event/content_col_left_kr.svg" alt="" />
                </div>
                <div className="event_col">
                    <img src="event/content_col_right_kr.svg" alt="" />
                    <a href="event_kr.html#event1"><img src="event/btn_1_kr.svg" alt="Event 1" /></a>
                    <a href="event_kr.html#event2"><img src="event/btn_2_kr.svg" alt="Event 2" /></a>
                </div>
            </div>
        </div>

      </Dialog>
      */}
     </div>

    )
  }

  render_new() {

return (
<div>
<div id="layer-ad" style={{display:"none;"}}>
    <div class="content">
			<div class="event-box">
 				<h1>OPENING EVENT</h1>
				<div class="group">
					<h2>EVENT_one</h2>
					<p style={{ paddingBottom: "10px;" }}>Refer friends and receive free Ethereum <br/><strong>420 Ethereum for GIVEAWAY!</strong></p>
					<ul>
						<li>From Opening Day to December 20th, 2019</li>
						<li>Official Announcement on December 23rd, 2019</li>
						<li>Prize Payout on December 24th, 2019</li>
					</ul>
					<a href="https://www.w-rex.com/event_en.html#event1" class="btn">MORE DETAIL</a>
				</div>
				<div class="group">
					<h2>EVENT_two</h2>
					<p><strong>Top 3 Ethereum holders in WREX Account <br/>from Top 3 countries with registered Ethereum</strong></p>
					<p style={{ padding: "10px 0;"}}><span>[YOUR ETH in WREX Account + YOUR referred ETH in WREX Account]</span></p>
					<ul>
						<li>Official Announcement on December 23rd, 2019</li>
					</ul>
					<p style={{paddingTop: "20px;", fontSize: "17px;"}}>"Invitation to Private Event organized by WREX"<span>* Details of the event are disclosed to the invitees only</span></p>
					<a href="https://www.w-rex.com/event_en.html#event2" class="btn">MORE DETAIL</a>
				</div>
			</div>	
			<input type="submit" name="submit" value="" class="x-btn" onClick="popup_content('hide')" />
    </div>
</div>

<header>
	<div class="header-logo"> <a href="index.html">WREX</a></div>

	<select id="language" tabindex="1">
		<option value="UK" data-icon="images/UK.png">English</option>
		<option value="KO" data-icon="images/KO.png">Korean</option>
	</select>

	<ul class="btn-group">
		<li><a href="https://www.w-rex.com/login" class="btn txt">Sign In</a></li>
		<li><a href="https://www.w-rex.com/register" class="btn line">Sign Up</a></li>
	</ul>	
</header>
	
<section id="home">
	<div class="overlay"></div>		
	
	<video class="bg-video" autoplay="autoplay" muted="" loop="">
		<source src="video/mov.mp4" type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" />
		<source src="video/mov.webm" type="video/webm; codecs=&quot;vp9, vorbis&quot;" />
	</video>
  <div class="home-content-table">
    <div class="home-content-tablecell">
      <div class="row">
        <div class="col-twelve">
          <h1 class="animate-intro"><img src="images/logo.png" alt="WREX"/></h1>
					<h2 class="animate-intro">BIG DREAMS FOR A SMALL CHANGE</h2>
					
					<p class="animate-intro">"Great things happen to those who don't stop believing, trying, learning, and being grateful."<br/><span>- Roy T. Bennett, The Light in the Heart -</span></p>
        </div>
      </div>
    </div>
  </div>
  
  <div class="scrolldown"> <a href="#intro" class="scroll-icon smoothscroll"> Scroll Down</a> </div>
</section>

<section id="intro">
	<div class="row intro-wrap">
		<div class="col-full">
			<div class="intro">
				<h3 class="animate-this">INTRODUCTION</h3>
				<p class="small-tit animate-this">3-Layered Algorithm System</p>
				<p class="lead animate-this">
With WREX 3-Layered Algorithm System, regardless we are entering the bear market or the bull market of cryptocurrency, we perform and deliver monthly return anywhere between 5% to 80% a month. <br/><br/>We had never traded a single trade with a loss and this is the foundation why we never recorded a loss in a typical month from January 2018 when we completed our beta testing until today.
</p>	
			</div>   
		</div>
	</div>
</section>
	
<section id="service">
	<div class="row service-wrap">
		<div class="col-full">
			<div class="service">
				<h3 class="animate-this">SERVICE</h3>
				
				<iframe id="my-video" width="560" height="315" src="https://www.youtube.com/embed/v_TsbMojuxQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				
				<div class="download animate-this">
					<strong>- Guidebook Download -</strong>
					<a href="download/WREX-Guidebook(ENG).pdf" class="btn" target="_blank">ENG</a>
					<a href="download/WREX-Guidebook(KOR).pdf" class="btn" target="_blank">KOR</a>
					<span class="btn">CHN</span>
					<span class="btn">TWN</span>
					<a href="download/WREX-Guidebook(IDN).pdf" class="btn" target="_blank">IDN</a>
					<span class="btn">PHL</span>
				</div>
			</div>   
		</div>
	</div>
</section>

<section id="profit">
	<div class="row profit-wrap">
		<div class="col-full">
			<div class="profit">
				<h3 class="animate-this">PROFIT</h3>
				<p class="lead animate-this">Every month, when the monthly earning percentage is announced and confirmed by the monthly report, the full monthly profit is shared as follows; Subscriber 45%; Affiliate Program 5%; WREX 50%.</p>
				<p class="small-tit animate-this">"Assuming Average Monthly Profit of 15.8%"</p>
				<ul class="animate-this">
					<li>
						<img src="images/wrex.png" alt="WREX Account"/>
						<p>
							<strong>[WREX Account]</strong>
							If you start your WREX Account with 1 ETH, at the end of 40th month, your WREX Account is grown to 15.60273644 ETH<br/><br/>
							Also, if you have 1 ETH in your WREX account and 10ETH affiliates WREX account, you will get 61.63452352 ETH after 40th months.
						</p>
					</li>
					<li>
						<img src="images/100eth.png" alt="100 ETH Plan"/>
						<p>
							<strong>[100 ETH Plan]</strong>
							If you start your 100 ETH Plan with 1 ETH, at the end of 40th month, your WREX Account is grown to 102.01316865‬ ETH<br/><br/>
							Also, if you have 1 ETH in your 100 ETH Plan and 10ETH affiliates 100 ETH Plan, you will get 384.24186150 ETH after 40th months.
						</p>
					</li>
					<li>
						<img src="images/ap.png" alt="Affiliate Program"/>
						<p>
							<strong>[Affiliate Program]</strong>
							[# of Ethereum in affiliated WREX Accounts your referred] x 5% x Monthly Profit % <br/>+<br/>[# of Ethereum in all affiliated 100 ETH Plan you referred] x 5% x Monthly Profit %
						</p>
					</li>
				</ul>
				<div class="download animate-this">
					<strong>- Detail Document Download -</strong>
					<a href="download/WREX(ENG).pdf" class="btn" target="_blank">ENG</a>
					<a href="download/WREX(KOR).pdf" class="btn" target="_blank">KOR</a>
					<span class="btn">CHN</span>
					<span class="btn">TWN</span>
					<span class="btn">IDN</span>
					<span class="btn">PHL</span>
				</div>
			</div>   
		</div>
	</div>
</section>
	
<section id="app">
	<div class="row app-wrap">
		<div class="col-full">
			<div class="app">
				<h3 class="animate-this">APP</h3>
				<ul class="animate-this">
					<li><img src="images/smartphone.png" alt=""/></li>
					<li>
						<p>Our New App is Coming Soon!<br/><span>October 2019</span></p>
						<span class="btn ios">App Store</span>
						<span class="btn android">Google Play</span>
					</li>
				</ul>
			</div>   
		</div>
	</div>
</section>	
	
<section id="contact">
	<div class="row contact-wrap">
		<div class="col-full">
			<div class="contact">
				<h3 class="animate-this">CONTACT US</h3>
				<p class="lead animate-this">If you have any questions, please feel free to contact us.</p>
				<a href="mailto:support@w-rex.com" class="animate-this">support@w-rex.com</a>
			</div>
		</div>
	</div>
</section>
	
<footer>
	<div class="copyright">© 2019 WREX Holdings Inc. All rights reserved.</div>
  <div id="go-top"><a class="smoothscroll" title="Back to Top" href="#top"></a> </div>
</footer>

<div id="preloader">	
	<div class="dl">
		<div class="dl__container">
			<div class="dl__corner--top"></div>
			<div class="dl__corner--bottom"></div>
		</div>
		<div class="dl__square"></div>
	</div>
</div>	

</div>

)
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
  inputValue: ownProps.location.pathname.substring(1)
})

export default withStyles(styles)(withRouter(connect(mapStateToProps, {
})(Index)))


