/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import countryList from 'react-select-country-list'

//import { getAddressString }  from '../helper/displayHelper'

import { getFullTimeDisplayString }  from '../helper/displayHelper'

import { getToken } from '../helper/loginHelper';

import { renderErrorMessage } from '../components/ErrorMessage'


import i18n from 'i18n-react';

import { sendUserLoginHistory, API_SUCCESS } from '../actions'

import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class AccountSecurity extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
    }

  }

  componentDidMount() {
    //console.log("ych AccountSecurity");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Account Security - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    if ((this.props.globalUser===undefined)||(this.props.globalUser.user===undefined)||(this.props.globalUser.user.loginHistory===undefined)) { 

      let token = getToken(this);

      var self = this;
      this.props.sendUserLoginHistory(token, {
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.user.user;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            //self.setState({otpUrl: response.otpUrl})
            //self.setState({totpSecret: response.totpSecret})
            //self.setState({loginHistory: response.loginHistory})
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
          //self.props.history.push("/account_security")
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Account Security Error",this);
    }

    var userInfo = {totpEnabled: 0}
    var loginHistory = [];
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if (user.info!==undefined) {
        userInfo.totpEnabled = user.info.totpEnabled;
      }
      if (user.loginHistory!==undefined) {
        loginHistory=user.loginHistory;
      }
    }

    //console.log(loginHistory);

    const loginHistoryNodes = loginHistory.map((myHistory,index) =>
      <tbody key={index}>
        <tr className="crypto-transactions-list__item">
          <td className="mdl-data-table__cell--non-numeric">{getFullTimeDisplayString(myHistory.timeLogged)}</td>
          <td className="mdl-data-table__cell--non-numeric">{myHistory.ip}</td>
          <td className="mdl-data-table__cell--non-numeric">{myHistory.location}</td>
        </tr>
      </tbody>
    )


    const setupNode = userInfo.totpEnabled === 0 ? (
      <span className="crypto-border-list__text mdc-theme--text-primary-on-background fx_align_right">
      <Link to="setup_2fa" className="mdc-button mdc-button--outlined f_wrexnew">{i18n.translate('setup')}</Link></span>
    ) : (
      <span className="crypto-border-list__text mdc-theme--text-primary-on-background fx_align_right">
      <Link to="email_verify" className="mdc-button mdc-button--outlined f_wrexnew">{i18n.translate('reset')}</Link></span>
    )


    return (
      <div>
{ /*
        <div className="crypto-loader"></div>
*/ }
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="profile"
                                        />

		

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="profile">{i18n.translate('account')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('securitysettings')}</h1>
							  <h4 className="f_mode"> </h4>
							</div>
						</div>
		  
					  <div className="mdc-layout-grid fx_padding">
		  
						<div className="mdc-layout-grid__inner">

								<div className="mdc-layout-grid__cell--span-2-desktop"></div>

							<div className="f_formlarge mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
									  <div className="crypto-settings-form-panelxx">
										<ul className="crypto-border-list">
										  <li className="crypto-border-list__item fx_link_block_wrap">
											<Link to="profile_password" className="mdc-theme--on-surface fx_link_block"
                                                                                        >
											  <span className="crypto-border-list__text mdc-theme--text-primary-on-background fx_margin_top">{i18n.translate('resetpassword')}</span>
											  <span className="crypto-coin__border mdc-theme--primary-bg"></span>
											  <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
											</Link>
										  </li>
                      {/* 
										  <li className="crypto-border-list__item ">
											<div className="mdc-theme--on-surface fx_fullwidth">
											  <span className="crypto-border-list__text mdc-theme--text-primary-on-background fx_margin_right fx_margin_top">Google Authenticator</span>
												{setupNode}
											  <span className="crypto-coin__border mdc-theme--primary-bg"></span>
											  <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
											</div>
										  </li> */}
										</ul>

										  <div className="crypto-widget__content crypto-overflow-x-auto">
											<p>{i18n.translate('siginhistory')}</p>
											<table className="mdl-data-table mdc-typography--overline">
				
												<thead className="crypto-transactions-list__labels">
												<tr>
													<th className="mdl-data-table__cell--non-numeric">{i18n.translate('siginactivity')}</th>
													<th className="mdl-data-table__cell--non-numeric">{i18n.translate('ipaddress')}</th>
													<th className="mdl-data-table__cell--non-numeric">{i18n.translate('location')}</th>
												</tr>
												</thead>					
												{loginHistoryNodes}
											</table>
										  </div>


									  </div>
						</div>

						</div>


					  </div>


					</div>

				  </main>

				</div>

 			</div>


    )
  }









}




const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendUserLoginHistory
})(AccountSecurity))




