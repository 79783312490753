import React, { Component } from 'react';
import i18n from 'i18n-react';

// import LastEarns from './LastEarns';


/**
 * Renders the preloader
 */
class IndexLoginPage extends Component {

    render() {
        return (
          <div className="mdc-layout-grid f_padding0 f_homewidth">
          <div className="mdc-layout-grid__inner">

            <div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_home_kv"> 
              <img src="assets/images/kv_banner.jpg" alt="banner" />
            </div>

            <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24 f_home_1"> 
            <p className="quote">
              “Great things happen to those who don't stop believing, trying, learning, and being grateful.” <br/>
              ― Roy T. Bennett, The Light in the Heart
            </p>
            <h3>{i18n.translate('introduction')}</h3>
            <p>
            {i18n.translate('ip1')} <br/>
            <br />
            {i18n.translate('ip2')}  <br/>
            <br />
            {i18n.translate('ip3')}  <br/>
            <br />
            <br/> {i18n.translate('ip4')}<br/>
            </p>
            </div>


            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24 f_home_3">
            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('features')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <h3>{i18n.translate('ip5')}</h3>
                <p style={{paddingBottom:"120px"}}>
                {i18n.translate('ip6')}<br/>
                <br />
                {i18n.translate('ip7')}
               <br />
                <br />
                {i18n.translate('ip8')}
                <br/>
                
                </p>
              </div>
              </div>
              <div className="crypto-widget__content crypto-overflow-x-auto">
              </div>					  
            </div>					
            </div>

            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24 f_home_2">
            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text"> {i18n.translate('products')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <p style={{paddingBottom:"120px"}}>
                {i18n.translate('ip9')}
                 <br/>
                <br />
                {i18n.translate('ip10')} <br />
                </p>
              </div>
              </div>
              <div className="crypto-widget__content crypto-overflow-x-auto">
              </div>					  
            </div>	 
            </div>
      
            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24">

            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('howtogetstart')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <h3>{i18n.translate('ip11')} </h3>
                <ol>
                <li>{i18n.translate('ip12')} </li>

                <li>{i18n.translate('ip13')} </li>
                
                <li>{i18n.translate('ip14')} 
                  <br/>{i18n.translate('ip15')}</li>
                </ol>
              </div>
              </div>
        
            </div>	
              
            </div>

            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24 f_home_5">

            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('howitworks')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <h3>{i18n.translate('ip16')}</h3>
                <p style={{paddingBottom:"180px"}}>
                {i18n.translate('ip17')} 
                <br/>
                <br/>
                {i18n.translate('ip18')}
                <br/>
                <br/>
                {i18n.translate('ip19')}
                <br/>
                </p>
              </div>
              </div>
        
            </div>	
              
            </div>

            <div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24">

            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('profitsharing')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <p>
                {i18n.translate('ip20')}
                </p> 

                <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone fx_col_wrap">
                  <div className="fx_col">
                    <b>WREX Account</b><br/>
                      {i18n.translate('ip21')}
                      <br/><br/>
                      {i18n.translate('ip221')}[{i18n.translate('ip222')}] x 45% [{i18n.translate('ip224')}] x [{i18n.translate('ip225')}]
                      
                  </div>
                  <div className="fx_col">
                      <b>100 ETH Plan</b><br/>
                      {i18n.translate('ip23')}
                      <br/><br/>
                      {i18n.translate('ip221')}[{i18n.translate('ip241')}] x 90% [{i18n.translate('ip242')}] x [{i18n.translate('ip243')}]
                      <br/><br/>
                      {i18n.translate('ip25')}
                      <br/>
                      {i18n.translate('ip26')}
                      <br/>
                      
                  </div>
                </div>

          
              </div>
              </div>
        
            </div>	
              
            </div>
      
            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24">

            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('affiliateprogram')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                <p>{i18n.translate('ip27')}
                <br/>
                <br/></p>
                <h4>{i18n.translate('ip28')}</h4>
                {i18n.translate('ip29')} <br/><br/>
                {i18n.translate('ip221')}[{i18n.translate('ip30')}] x 5% x {i18n.translate('ip31')} %<br/>
                <br/>
                <h4>{i18n.translate('ip32')}</h4>
                <p>{i18n.translate('ip33')}<br/><br/>
                {i18n.translate('ip221')}[{i18n.translate('ip34')}] x 5% x {i18n.translate('ip31')} %<br/>
                <br/>
                {i18n.translate('ip35')}<br/>
                </p>
              </div>
              </div>
        
            </div>	
              
            </div>

            <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone f_padding24">

            <div className="crypto-widget">
              <div className="mdc-layout-grid__inner">
              <div className="crypto-word-warp mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 className="crypto-widget__heading mdc-typography--overline crypto-widget__heading--fullwidth mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('withdrawfee')}</span>
                <span className="crypto-widget__heading-border"></span>
                </h2>
                  <h4>{i18n.translate('ip36')}</h4>
                <p>
                {i18n.translate('ip37')} <br/>
                {i18n.translate('ip38')}<br/>
                {i18n.translate('ip39')} <br/>
                  <br/>
                  {i18n.translate('ip40')}   <br/>
                  </p>
                  <h4> {i18n.translate('ip41')} </h4>
                  <p>
                  {i18n.translate('ip42')}<br/></p>
                  <h4>{i18n.translate('ip43')}</h4>
                  <p>{i18n.translate('ip44')}<br/></p>
                  <h4>{i18n.translate('ip45')} </h4>
                  <p>{i18n.translate('ip46')} <br/>
                  <br/>
                  {i18n.translate('ip47')} <br/>                
                </p>
              </div>
              </div>
        
            </div>	
              
            </div>
      
          </div>
          </div>
        )
    }
}

export default IndexLoginPage;