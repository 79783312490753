/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import QRCode from 'react-qr-code';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

import { sendUserInit2FA, sendUserSetup2FA, sendUserInfo, API_SUCCESS } from '../actions'

import {getToken, isLogin} from '../helper/loginHelper';


//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Google2fa22 extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      otpUrl: "",
      totpSecret: "",
      otp: "",
      sourceLink: "",
    }
  }

  componentDidMount() {
    //console.log("ych Google 2FA");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Google 2FA - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if ((user.info!==undefined)&&(user.info.totpEnabled===0)) {
        let token = getToken(this);

        var self = this;

        this.props.sendUserInit2FA(token, { 
        })
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.response.response;
            //console.log(response);
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // self.setState({errorMessage: i18n.translate(response.message)})
              self.setState({otpUrl: response.otpUrl})
              self.setState({totpSecret: response.totpSecret})
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
            //self.props.history.push("/account_security")
          }

        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
          self.props.history.push("/account_security")
        })

      } else {
        this.props.history.push("/account_security")
      }
    } else {
        this.props.history.push("/account_security")
    }

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const ref = params.get('ref'); // bar
    if (ref!==null) {
      this.setState({sourceLink: "/to2fa?ref="+ref})
    } else {
      this.setState({sourceLink: "/account_security"})
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleOTPChange(event)  {
    //console.log("handleOTPChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({confirmPasswordPlaceHolder: ""})
      } else {
        //this.setState({confirmPasswordPlaceHolder: i18n.translate('confirmPasswordPlaceHolder')})
      }
      this.setState({otp: event.target.value})
    }
  }

  handleSetup2FA(otp) {
    //console.log("handleSetup2FA");
    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

    this.props.sendUserSetup2FA(token, { otp: otp
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
            //self.props.history.push("/account_security")
            self.props.history.push(self.state.sourceLink);
            self.props.sendUserInfo(token,{})

        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Google 2FA Error",this);
    }

    const myQRCode = this.state.otpUrl !== null ? (

      <div className="qrcode_border"><div className="f_qrcode"><QRCode value={this.state.otpUrl} /></div></div>

    ) : (
      <div>QRCode Failed</div>
    )

    return (
      <div>
        <div className="crypro-login-container rex_rg f_con_wider">
          <div className="crypro-login-container__box fix_box2">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text">Google Authenticator {i18n.translate('setup')}</span>
                <span className="crypto-widget__heading-border"></span>
              </h2>

              <p className="f_border crypto-word-warp">
              Step 1: {i18n.translate('g21')} <br/>
              {i18n.translate('g22')} 
              </p>

              <p className="f_border crypto-word-warp">
              Step 2: {i18n.translate('g23')}  <br/>
              {i18n.translate('g24')} 		
              </p>

              <div className="f_border f_alignc">
              {this.state.totpSecret}<br/>
              {myQRCode}
              </div>

              <p className="f_border crypto-word-warp">
              Step 3: {i18n.translate('g25')} <br/>
              {i18n.translate('g26')} 
              </p>



              <form className="crypto-login-form">
                <p className="f_formtitle">{i18n.translate('code')}</p>
                <div className="mdc-text-field mdc-text-field--fullwidth">
                  <input type="text" id="otp-text-field" className="mdc-text-field__input" 
                    value={this.state.otp}
                    onChange={this.handleOTPChange.bind(this)}
                  />
                  <div className="mdc-line-ripple"></div>
                </div>
                <p className="f_mbottom25 mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
                {i18n.translate('g2a')} 
                </p>

            
                <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                  onClick={() => this.handleSetup2FA(this.state.otp)}
                >{i18n.translate('continue')} </span>
              </form>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }
            </div>
          </div>
			  </div>              
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
    globalUser: state.entities.user,
})

export default withRouter(connect(mapStateToProps, {
  sendUserInit2FA, sendUserSetup2FA, sendUserInfo
})(Google2fa22))




