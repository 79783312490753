/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react'

import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

import { renderErrorMessage } from '../components/ErrorMessage'


import { sendUserLogout, sendUserResendEmail, sendUserInfo , API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();

class RegisterVerify extends Component {
  static propTypes = {
  }

  state = {
    errorMessage: "",
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych RegisterVerify");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Register - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

 
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleResendEmail() {
    //console.log("handleResendEmail");
    this.setState({errorMessage: ""})

    let token = getToken(this);
    //console.log(token);

    if (token!==null) {
      var self = this;
      this.props.sendUserResendEmail(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        let response = success.response.entities.response.response;

        if (success.type === API_SUCCESS) {
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            //self.props.history.push("/myeth_wallet")
          }
        }
  
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }

  }

  handleLogout() {
    //console.log("handleLogout");

    let token = getToken(this);
    //console.log(token);
    cookies.set('token',"logout", {path: '/'});
    cookies.set('expire',"logout", {path: '/'});
    if ((this.props.globalLogin!==undefined)&&(this.props.globalLogin.login!==undefined)) {
      this.props.globalLogin.login.success=false;
      this.props.globalLogin.login=undefined;
    }

    if (token!=null) {
      var self = this;
      this.props.sendUserLogout(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);

        self.props.history.push("/")
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);

        self.props.history.push("/")
      })
    } else {

      this.props.history.push("/")
    }
  }

  handleUserInfo() {
    //console.log("handleUserInfo");
    this.setState({errorMessage: ""})

    let token = getToken(this);
    //console.log(token);

    if (token!==null) {
      var self = this;
      this.props.sendUserInfo(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {

          let response = success.response.entities.user.user;
          if (response.success===false) {
//console.log(self.props);
            if (response.message==="email_not_confirmed") {
              //self.props.history.push("/register-verify")
            } else if (response.message==="login_required") {
              self.props.history.push("/login")
            } else {
              self.setState({errorMessage: i18n.translate(response.message)})
            }
          } else {
            if (self.props.globalLogin!==undefined) {
              self.props.globalLogin.login.success=true;
            }
            self.props.history.push("/myeth_wallet")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }
  
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }

  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Register Verify Error",this);
    }

    return (
      <div>


          
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
              <img src="assets/images/logo_wrex.svg" alt="WREX" />
            </h2>
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
              <span className="crypto-widget__heading-text">{i18n.translate('emailverification')}</span>
              <span className="crypto-widget__heading-border"></span>
            </h2>
            <p className="crypto-word-warp">{i18n.translate('rv1')}</p>
            <form className="crypto-login-form">
            <span className="mdc-button mdc-button--outlined big-round-corner-button" onClick={() => this.handleLogout({})} >{i18n.translate('logout')}</span>
                <div className="space_40"></div>
                <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                  onClick={() => this.handleUserInfo({})}
                >
                  {i18n.translate('continue')}
                </span>
            </form>
            </div>

{ /*
            <span className="alert-message">
              {this.state.errorMessage}
            </span>
*/ }
            <p className="mdc-typography--body2 mdc-theme--on-surface">{i18n.translate('rv2')} <span className="f_rgnew"  onClick={() => this.handleResendEmail({})}>{i18n.translate('resend')}</span></p>
          </div>
			  </div>

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalLogin: state.entities.login,
  }
}

export default withRouter(connect(mapStateToProps, {
  sendUserResendEmail, sendUserLogout, sendUserInfo
})(RegisterVerify))




