/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

import { renderErrorMessage } from '../components/ErrorMessage'

import { isLogin, getToken } from '../helper/loginHelper';

import { sendUserWithdrawAddressNew, sendUserWithdrawAddresses, API_SUCCESS } from '../actions'
import { sendAccountMyEthWithdraw, sendAccountInfo, sendAccountTransfer, sendAccountWrexTransfer } from '../actions'

import i18n from 'i18n-react';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class Google2fa1 extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      newAddress: "",
      newName: "",
      withdrawAddress: "",
      withdrawAmount: "",
      transferAddress: "",
      transferAmount: "",
      otpCode: "",
      ref: "",
      errorMessage: "",
    }
  }

  componentDidMount() {
    //console.log("ych Google 2FA");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Google 2FA - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const ref = params.get('ref'); // bar
    if (ref!==null) {
      this.setState({ref: ref})
      if (ref==="profile_address_new") {
        const cookieNewAddress = cookies.get('new_address');
        const cookieNewName = cookies.get('new_name');
        //console.log(cookieNewAddress);
        //console.log(cookieNewName);
        if ((cookieNewAddress!==undefined)&&(cookieNewName!==undefined)) {
          this.setState({newAddress: cookieNewAddress})
          this.setState({newName: cookieNewName})
        } else {
          this.props.history.push("/profile_address_new")
        }
      } else if (ref==="myeth_withdraw") {
        const cookieWithdrawAddress = cookies.get('withdraw_address');
        const cookieWithdrawAmount = cookies.get('withdraw_amount');
        //console.log(cookieWithdrawAddress);
        //console.log(cookieWithdrawAmount);
        if ((cookieWithdrawAddress!==undefined)&&(cookieWithdrawAmount!==undefined)) {
          this.setState({withdrawAddress: cookieWithdrawAddress})
          this.setState({withdrawAmount: cookieWithdrawAmount})
        } else {
          this.props.history.push("/myeth_wallet")
        }
      } else if (ref==="myeth_transfer") {
        const cookieTransferAddress = cookies.get('transfer_address');
        const cookieTransferAmount = cookies.get('transfer_amount');
        if ((cookieTransferAddress!==undefined)&&(cookieTransferAmount!==undefined)) {
          this.setState({transferAddress: cookieTransferAddress})
          this.setState({transferAmount: cookieTransferAmount})
        } else {
          this.props.history.push("/myeth_wallet")
        }
      } else if (ref==="wrex_transfer") {
        const cookieTransferAddress = cookies.get('transfer_address');
        const cookieTransferAmount = cookies.get('transfer_amount');
        if ((cookieTransferAddress!==undefined)&&(cookieTransferAmount!==undefined)) {
          this.setState({transferAddress: cookieTransferAddress})
          this.setState({transferAmount: cookieTransferAmount})
        } else {
          this.props.history.push("/my_assets")
        }
      }
    } else {
      this.props.history.push("/")
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleOtpCodeChange(event)  {
    //console.log("handleOtpCodeChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({addressPlaceHolder: ""})
      } else {
        //this.setState({addressPlaceHolder: i18n.translate('addressPlaceHolder')})
      }
      this.setState({otpCode: event.target.value})
    }

  }

  handleSubmit() {
    //console.log("handleSubmit");
    //console.log(this.state.ref);

    let token = getToken(this);
    var self = this;

    if (this.state.ref==="profile_address_new") {
      this.setState({sourceLink: "/editaddr_success"})
      //const cookieNewAddress = cookies.get('new_address');
      //const cookieNewName = cookies.get('new_name');
      //console.log(cookieNewAddress);
      //console.log(cookieNewName);
      this.props.sendUserWithdrawAddressNew( token, {
        address: this.state.newAddress,
        name: this.state.newName,
        otp: this.state.otpCode,
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            if (response.message!=="invalid_otp") {
                cookies.set('submit_error', response.message, {path: '/'});
                self.props.history.push("/profile_address_new")
            } else {
                self.setState({errorMessage: i18n.translate(response.message)})
            }
          } else {
            self.props.sendUserWithdrawAddresses(token,{});
            self.props.history.push("/editaddr_success")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    } else if (this.state.ref==="myeth_withdraw") {
      //this.props.history.push("/myeth_wallet")
      //console.log(this.state.withdrawAmount);
      //console.log(this.state.withdrawAddress);
      this.props.sendAccountMyEthWithdraw(token, { amount: this.state.withdrawAmount, address: this.state.withdrawAddress, 
        otp: this.state.otpCode
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            if (response.message!=="invalid_otp") {
                cookies.set('submit_error', response.message, {path: '/'});
                self.props.history.push("/myeth_withdraw")
            } else {
                self.setState({errorMessage: i18n.translate(response.message)})
            }
          } else {
            self.props.sendAccountInfo(token,{});
            self.props.history.push("/request_received")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }
  
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    } else if (this.state.ref==="myeth_transfer") {
      //this.props.history.push("/myeth_wallet")
      this.props.sendAccountTransfer(token, { amount: this.state.transferAmount, address: this.state.transferAddress, 
        otp: this.state.otpCode
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          console.log(response);
          if (response.success===false) {
            if (response.message!=="invalid_otp") {
                cookies.set('submit_error', response.message, {path: '/'});
                self.props.history.push("/myeth_transfer")
            } else {
                self.setState({errorMessage: i18n.translate(response.message)})
            }
          } else {
            self.props.sendAccountInfo(token,{});
            self.props.history.push("/transfer_success")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }
  
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    } else if (this.state.ref==="wrex_transfer") {
      //this.props.history.push("/myeth_wallet")
      this.props.sendAccountWrexTransfer(token, { amount: this.state.transferAmount, email: this.state.transferAddress, 
        otp: this.state.otpCode
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            if (response.message!=="invalid_otp") {
                cookies.set('submit_error', response.message, {path: '/'});
                self.props.history.push("/wrex_transfer")
            } else {
                self.setState({errorMessage: i18n.translate(response.message)})
            }
          } else {
            self.props.sendAccountInfo(token,{});
            self.props.history.push("/transfer_success")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }
  
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Request Error",this);
    }

    return (
      <div>
        <div className="crypro-login-container rex_rg">
				<div className="crypro-login-container__box">
				  <div className="crypro-login-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('googleauthentication')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<form className="crypto-login-form">
					 <p className="f_formtitle crypto-word-warp">{i18n.translate('inputcode')} Google Authenticator app</p>
					 <div className="mdc-text-field mdc-text-field--fullwidth">
						<input type="text" id="otpcode-text-field" className="mdc-text-field__input" 
                                                  value={this.state.otpCode}
                                                  onChange={this.handleOtpCodeChange.bind(this)}
                                                />
						<div className="mdc-line-ripple"></div>
					  </div>
					  <p className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
            {i18n.translate('g2')}
							  </p>

{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }

					  <div className="crypto-display-flex crypto-display-flex--space-between crypto-margin-bottom-big f_rg_ptop">
						<div className="mdc-form-field">
						  <Link to="account_security">{i18n.translate('g3')}</Link>
						</div>
					  </div>
		  
					  <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                                            onClick={() => this.handleSubmit()}
                                          >{i18n.translate('submit')}</span>
				   </form>
				  </div>
				</div>
			  </div>        
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendUserWithdrawAddressNew, sendUserWithdrawAddresses,
  sendAccountMyEthWithdraw, sendAccountInfo, sendAccountTransfer, sendAccountWrexTransfer
})(Google2fa1))




