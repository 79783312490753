export function Translations_en(language) {
  return {

    // Top bar 
    top1: 'BIG DREAMS FOR A SMALL CHANGE',
    Login: 'Log In',
    Register: 'Register',
    Language: 'Language',

    // SideNav
    wallet: 'WALLET',
    assets: 'ASSETS',
    affiliate: 'AFFILIATE',
    support: 'SUPPORT',
    account: 'ACCOUNT',
    logout: 'LOGOUT',

    // Index Page
    introduction: 'Introduction',
    ip1: 'Cryptocurrencies has grown from zero to what it is today. Yet, we see and know only a handful multi-billionaires in the industry. Why? Because most of us pretty much sold at premature stage for a good profit. Well at then, we thought it was a good profit.',
    ip2: 'However, the so-called multi-millionaires, today, still holds his/her crypto assets and you know what, we think they are going to be multi-billionaires soon or later.',
    ip3: 'At WREX, we are trying hard to build our own closed-community. A cyber spaced community where everyone is given equal opportunities of learning and earning experience that others could not provide. Our platform comes with a long-term commitment. We don’t believe one becoming a multi-millionaire overnight.',
    ip4: 'Welcome to WREX!',
    feaures: 'Features',
    ip5: '3-Layered Algorithm System',
    ip6: 'With WREX 3-Layered Algorithm System, regardless we are entering the bear market or the bull market of cryptocurrency, we perform and deliver monthly return anywhere between 5% to 80% a month.',
    ip7: 'Never recorded a loss in a month! For any single trade, we don’t sell at a loss.',
    ip8: 'We had never traded a single trade with a loss and this is the foundation why we never recorded a loss in a typical month from January 2018 when we completed our beta testing until today.',
    products: 'Products',
    ip9: 'WREX offers two programs based on your strategies and needs. The available programs are [WREX Account] and [100 ETH Plan].',
    ip10: 'Any Ethereum you choose to place in these accounts, WREX shall initiate trades to increase the number of Ethereum.',
    howtogetstart: 'How to get started',
    ip11: 'Big Dreams for a Small Change',
    ip12: 'Follow the link provided by your referrer. Create an account at WREX. Note: An affiliate code is needed to complete your registration.',
    ip13: 'Once you have created your account at WREX, you will be provided with [My ETH Wallet] with your personal Ethereum address at WREX. Please use this address to transfer your Ethereum from any of your external exchange or wallet.',
    ip14: 'When you confirm your Ethereum is transferred to [My ETH Wallet] at WREX, you can now initiate transfer to WREX account. Maintain one or more Ethereum in [WREX Account] at all time, you are now eligible to receive monthly Affiliate Bonus and create up to ten [100 ETH PLAN] accounts.',
    ip15: 'No Fee is charged when transferring any Ethereum from [My ETH Wallet] to [WREX Account] or [100 ETH PLAN].',
    howitworks: 'How it works',
    ip16: 'Creating Unlimited Future',
    ip17: 'Any Ethereum in the [WREX Account] or [100 ETH PLAN] are used as resources by our skilled professional traders at Ethereum pairing markets based on the WREX 3-Layered ETH Algorithm System. Please note, any Ethereum left in [My ETH Wallet] does not earn any monthly profit sharing. [My ETH Wallet] simply is an Ethereum Wallet used to deposit and withdraw between your external Ethereum wallet and WREX wallet.',
    ip18: 'Every month, one of the four major accounting & auditing firm in the world shall generate a monthly performance report verifying all of our trading transactions, accounts, and the monthly profits providing maximum transparency to our operation.',
    ip19: 'These monthly reports are accumulated in our website for our subscribers to view.',
    profitsharing: 'Profit Sharing',
    ip20: 'Every month, when the monthly earning percentage is announced and confirmed by the monthly report, the full monthly profit is shared as follows; Subscriber 45%; Affiliate Program 5%; WREX 50%.',
    ip21: 'If Auto Re-accumulation is selected as an option by the subscriber, the Subscriber’s share of 45% of monthly profit is auto transferred to your [WREX Account] and added to your principle. Once the principle grows every month, your monthly earnings thereafter will also grow as the monthly earnings of 45% is based on your principle.',
    ip221: 'Formula: ',
    ip222: '# of Ethereum in WREX Account',
    ip224: ' Subscriber’s Sharing ',
    ip225: ' Monthly Profit % ',
    ip23: 'In [100 ETH Plan], not only the subscriber\'s 45% is added to your principle but also WREX shall match your 45% as contribution to your principle. Therefore, your principle will grow at much higher rate compare to [WREX Account], thus the overall growth of your account is maximized.',
    ip241: ' # of Ethereum in 100 ETH PLAN ',
    ip242: ' Subscriber’s 45% + WREX 45% ',
    ip243: ' Monthly Profit % ',
    ip25: 'You can customize and add personalized names to all of the ten [100 ETH PLAN].',
    ip26: 'eg. 100 ETH PLAN #1 - My first Son’s Wedding Gift',
    affiliateprogram: 'Affiliate Program',
    ip27: 'WREX offers the most powerful Affiliate Program in the industry. It is 5% of monthly profit based on your total referred Ethereum number, every month!',
    ip28: 'WREX Account Affiliate Program',
    ip29: 'As long as you maintain 1 Ethereum in your [WREX Account] from the first day until the last day of the month, you are eligible to receive 5% of the monthly profit based on your referred Ethereum placements in the [WREX Accounts].',
    ip30: '# of Ethereum in affiliated WREX Accounts your referred',
    ip31: 'Monthly Profit',
    ip32: '100 ETH PLAN Affiliate Program',
    ip33: 'As long as you maintain one [100 ETH PLAN] from the first day until the last day of the month, you are eligible to receive 5% of the monthly profit based on your referred Ethereum placements of all [100 ETH Plans].',
    ip34: '# of Ethereum in all affiliated 100 ETH Plan you referred',
    ip35: 'Remember, when your total referred ETH accounts GROW every month, your affiliate bonus will grow faster and bigger every month as well.',
    withdrawfee: 'Withdrawals and Fees',
    ip36: 'Withdrawals - WREX Account',
    ip37: 'For [WREX Accounts], you have an option to choose between [AUTO WITHDRAWAL] and [AUTO ACCUMULATION]. By default, it will be on [AUTO ACCUMULATION] and you can change at any time in your personal settings.',
    ip38: 'If you choose [AUTO WITHDRAWAL], it means all your monthly profit from [WREX Account] shall be credited into your [My ETH Wallet]. This auto transfer shall be done every 10th day of the following month.',
    ip39: 'At any time, there is no limitation of executing manual withdrawal request from your [WREX Account]. Please note, a manual withdrawal from the [WREX Account] may take at minimum 72 hours up to maximum 4weeks.',
    ip40: 'Warnings: less than 1 Ethereum in [WREX Account] after execution of manual withdrawals results in forfeiting any affiliate bonus and auto termination of all of your [100 ETH PLAN] enrolled. In such cases, all the Ethereum from the auto terminated [100 ETH PLANs] are transferred to your [WREX Account].',
    ip41: 'Withdrawals - 100 ETH PLAN',
    ip42: 'Withdrawals from [100 ETH PLAN] is not possible. But if you wish you can terminate any or all of your [100 ETH PLAN] at any time. There is no termination penalty.',
    ip43: 'Fees - MANUAL WITHDRAWALS (WREX Account)',
    ip44: '1% of the total Ethereum withdrawal transfers from [WREX Account] to [My ETH Wallet]',
    ip45: 'Fees - Termination of 100 ETH PLAN',
    ip46: '1% of the total Ethereum termination transfers from [100 ETH PLAN] to [My ETH Wallet]',
    ip47: 'Network Fee may apply from any transfer from [My ETH Wallet] to your external Ethereum wallet.',




    // LastEarns.js
    lastmonthreturn: 'Last Month Return',
    totalamount: 'Total Amount',

    // AccountAddress.js 
    addressmanagement: 'Address Management',
    addnew: 'ADD NEW',
    label: 'Label',
    address: 'Address',
    edit: 'Edit',
    delete: 'Delete',

    // AccountSecurity.js
    securitysettings: 'Security Settings',
    setup: 'Setup',
    reset: 'Reset',
    resetpassword: 'Reset Password',
    siginhistory: 'Sign-in Histories',
    siginactivity: 'Sign-in Activity',
    ipaddress: 'IP Address',
    location: 'Location',

    // AffliliateProgram.js
    //affiliateprogram: 'Affiliate Program',
    email: 'Email',
    referralwrexamount: 'Referral WREX Amount',
    referralCount: 'Referral Friends',
    referal100epamount: 'Referral 100EP Amount',
    earnedcommisions: 'Earned Commissions',
    myreferalid: 'My Referral ID',
    sharereferalid: 'Share Referral ID',
    sharereferallink: 'Share Referral Link',
    referralfriends: 'Referral Friends',
    programdetail: 'Program Details',
    ap1: 'Your Affiliate Bonus shall be subject to how many Ethereum in total you referred in the respective [WREX Account] and [100 ETH PLAN]. Any Ethereum in [My ETH Wallet] would not count as Affiliate Ethereum.',
    ap2: 'If your affiliates’ number of Ethereum grow on monthly basis due to [Auto Reinvest] in their [WREX Account] and [100 ETH PLAN], your Affiliate Bonus would also GROW!',
    ap3: 'As long as your affiliates’ Ethereum are in [WREX Account] and [100 ETH PLAN], you would receive the Affiliate Bonus, forever!',
    ap4: 'Regardless of whether you are a big influencer or manage only your account without any affiliates introduced, everyone including your affiliates will thank you for introducing our site because everyone wins at WREX and we feel this is the most important part of this whole project.',
    ap5: 'Our site is a closed-community, it means you have to input an Affiliate Code in order to complete the registration. Once the code is registered, you cannot change it. This is to protect our active influencers to make sure they all get the credits.',
    ap6: 'Other sites, similar sites, offer Affiliate Programs but most of them are not as powerful as ours. Since our platform requires long term commitment, for that duration, you will be rewarded as long as your affiliates maintain their accounts. Also, we are proud to add features of sustainability. We pay out only from our monthly profit, therefore we are sustainable, forever. As long as we do not perform negatively, the principle of all of our members has only way to grow every month. If the principle grows every month, it means your monthly affiliate bonus shall grow every month as well as the bonus is based on the monthly principle. ',
    ap7: 'Share your link above within your network. If they click the link and register, they will be automatically registered using your affiliate code.',
    ap8: 'Your referral information is refreshed and updated at UTC 00:00. Only your “REFERRAL FRIENDS” are refreshed real-time.',


    // APStatus.js
    status: 'Status',
    myaffiliatestatus: 'My Affiliate Status',
    since: 'Since',
    as1: 'ACTIVE means the member has complete the registration, deposit 1 Ethereum in WREX Account and created at least one 100 ETH Plan.',
    active: 'ACTIVE',

    //EmailVerify.js
    emailverification: 'Email Verification',
    ev1: 'Please complete the email verification first. You will not be able to do anything until your account has been activated.',
    continue: 'Continue',
    ev2: 'Have not got a verification email?',
    resendemail: 'Resend Email',
    resend: 'Resend',

    // Eth100Create.js
    createnew100ep: 'Create A New 100 ETH Plan',
    namethisplan: 'You can customize the name of this plan.',
    create: 'Create',
    about100ep: 'About 100 ETH Plan',
    name: 'Name',
    amount_ep100: 'Amount (Minimum 1ETH ~ Maximum 100ETH)',
    ec1: 'You can customize a name for each of the 100 ETH Plan up to 40 characters.\n(eg. My first son’s Wedding Gift) You can change this name later.',
    ec2: 'You can only place 1~100 Ethereum per 100 ETH Plan. Once you press “CREATE” button, 1~100 Ethereum shall be automatically deducted from your My ETH Wallet to be transferred to create the 100 ETH Plan.',
    ec3: 'All 100 ETH Plan, the monthly profit is added to your principle automatically.',
    ec4: 'Monthly Profit is calculated as follows:\n[# of Ethereum in one 100 ETH Plan] x [Monthly Profit %] x 90% = Monthly Profit\nThis monthly profit is added to your principle. Every month, the newly calculated principle will grow, faster pace compared to WREX Account.',
    ec5: 'Maturity of this contract is 40 months and no partial withdrawal from 100 ETH Plan is allowed. However, if you wish you can terminate one or multiple 100 ETH Plan at any time. Once terminated, you shall receive the full amount displayed in the product on the 10th day of the following month.',
    ec6: 'This 100 ETH Plan contract, once created, will be effective from the 1st day of the following month',

    // Eth100Plan1.js
    ep100: '100 ETH Plan',
    oldep100: '(created before Sep 1st 2020)',
    terminate: 'Terminate',
    monthstomature: '(months to mature)',
    overview: 'Overview',
    sixmonthperformance: 'Last 6 months performance',
    accounthistory: 'Account History',


    // Eth100Update.js
    update: 'Update',

    // Eth100TerminateConfirm.js
    confirmterminate: 'Confirm the termination?',
    etc1: 'By confirmation, we will transfer the total amount back to your wallet on the 10th of the following month.',
    etc2: 'This termination shall occur an early termination penalty. An early termination penalty is 75% of YOUR profit gained. If you still wish to terminate please check below to confirm. To avoid the penalty please maintain your account until maturity which is 24 month after you created this 100 ETH PLAN.',
    lets_do_it: 'I concur. Let\'s do it!',
    yes: 'YES',

    // ForgotPassword.js
    forgotpassword: 'Forgot Password',
    fp1: 'To reset your password, you will be sent an email providing instructions to reset your password.',
    send: 'SEND',
    backtologin: 'Back to login',

    // ForgotPasswordResult.js
    fpr1: 'A password reset message has been sent to your email address.',
    ok: 'OK',
    fpr2: 'Not got an email yet?',

    // ForgotPasswordToReset.js
    newpassword: 'New Password',
    confirmpassword: 'Confirm Password',

    // Google2fa1.js
    inputcode: 'Input the 6-digit 2FA code in your',
    googleauthentication: 'Google Authentication',
    g2: 'Must be at least 6 digits long',
    g3: 'Cannot access Google Authenticator?',
    submit: 'Submit',

    // Google2fa2.js
    notsetupyet: 'Not enabled yet...',
    g4: 'Your Google Authenticator app is not synced correctly. Please click setup to enable this feature.',

    // Google2fa11a.js
    changesuccess: 'Change Success',
    dismiss: 'Dismiss',

    // Google2fa11b.js
    requestreceived: 'Request received',

    // Google2fa22.js 
    g21: 'Install Google Authenticator.',
    g22: 'Install the Google OTP app on your smart phone and open it.',
    g23: 'Click on ‘Add’ in the app and choose scan QR Code.',
    g24: 'Scan the following QR Code with your Google OTP app or add manually.',
    g25: 'Verify the code',
    g26: 'Fill in the 6-digit code provided by Google OTP to activate Two-Factor Authentication.',
    g2a: '',

    code: '6-digit Code',

    // invest.js
    // availablefunds: 'WREX Products - [WREX Account] and [100 ETH Plan]',
    eth100_plan: 'My Account',
    notices: 'Notices',
    wrex_account: 'WREX Account',
    totaleth: 'Total ETH in the account',
    total: 'Total',

    is1: 'You need to activate your WREX Account before you can create any of 100 ETH Plans.',
    is2: 'To activate WREX Account, you need to create WREX Account and maintain 1 Ethereum in WREX Account. For 100 ETH Plan, you can only place 1~100 Ethereum in each of the 100 ETH Plan.',
    is3: 'Before creating any of the products, please check how many Ethereum are available in your WREX Wallet.',
    is4: 'Then you will need to think about and decide how many Ethereum you want to place in WREX Account and how many 100 ETH Plans you want to create with the available Ethereum in your WREX Wallet.\n',
    is41: '*If you intend to create one or multiple 100 ETH Plan after you create WREX Account, please DO NOT deposit all of your Ethereum from your WREX Wallet to WREX Account.*',
    is42: ' If you deposit all Ethereum from your WREX Wallet to WREX Account and you have no Ethereum left in your WREX Wallet, then you will have no Ethereum left to create any 100 ETH Plan.',
    is5: 'IMPORTANT – TIME OF PLACEMENTS (DEPOSITS)\nThis is for WREX Account only.\nDepending on the time of placements in WREX Account, you are subject to receive different percentage of the monthly profit percentage only for the first month of placement.\nIf your placements of Ethereum happens from *the first day of the month to 10th day of the month*, then you are eligible to receive 50% of the monthly profit for the month.\nIf your placements of Ethereum happens from *the 11th day of the month to 20th day of the month*, then you are eligible to receive 25% of the monthly profit for the month.\nIf your placements of Ethereum happens from *the 21st day of the month to the last day of the month*, then you are eligible to receive 0% of the monthly profit for the month.\nFor all three above, all are eligible to receive 100% of the monthly profit percentage after the first month. (the placement date will only affect the same month for your newly placed Ethereum)\n\nFor 100 ETH Plan\nAll 100 ETH Plan, once created, regardless of the timing of the creation, the effective starting date would be the first day of the following month.',

    is6: 'Formula on how your WREX Account and 100 ETH PLAN would be growing;\n\n[WREX Account]\nEvery month, 45% of the monthly profit % is accumulated in the WREX Account.\nFormula: [ETH in WREX Account] x 45% x Monthly Profit % + WREX Account Affiliate Bonus (if applicable) = My Profit\n\n[100 ETH Plan]\nEvery month, 90% of the monthly profit % is accumulated in the 100 ETH Plan.\nFormula: [ETH in 100 ETH Plan] x 90% x Monthly Profit % + 100 ETH PLAN Affiliate Bonus (if applicable) = My Profit\n** Please NOTE: Even though at the backend, 90% is calculated; your 100 ETH PLAN shall display ONLY your portion (45%) of \nthe profit. So if you terminate the 100 ETH PLAN today, the number of ETH displayed shall be the ETH you will receive. ',


    is7: 'Before creating and subscribing to these products, please read the Notices first.',
    lastmonth: 'Last Month',
    lastmonth_profit: 'Last Month Profit',
    my_profit: 'My Profit',

    // Login.js
    signin: 'Sign In',
    notyetonwrex: 'If you are experiencing problems login in, please contact your Relationship Manager or JVSAKK',

    // MyEthDeposit.js
    myethwallet: 'My ETH Wallet',
    ch_deposit: 'Deposit',
    med1: 'USE THE FOLLOWING ADDRESS TO DEPOSIT FUNDS',
    med2: 'Please DO NOT deposit over the amount, we only accept total 10,000 Ethereum deposit',
    copy: 'Copy',
    availableamount: 'Available Amount',
    depositnotices: 'Deposit Notices',
    med3: 'This is your WREX Ethereum Wallet Address. Please send your Ethereum only! If you send coins or tokens other than Ethereum (ETH), shall result in the loss of your deposits.',
    med4: 'You can either scan the QR CODE or click on the “COPY” button and paste. If you do copy and paste, PLEASE ENSURE the address is copied and pasted correctly.',
    med5: 'Ethereum shall be deposited after 30 block confirmations.',
    med6: 'The Ethereum address above is only for your deposits in WREX My ETH Wallet.',

    // MyEthWallet.js
    withdraw: 'Withdraw',
    ca_withdraw: 'WITHDRAW',
    youhavedeposited: 'You have deposited',
    youhaveroom: 'You have room to deposit another',
    history: 'History',
    filter: 'Filter',
    gains: 'Gains',
    bonus: 'Bonus',
    ca_create: 'CREATE',
    ca_gains: 'GAINS',
    ca_bonus: 'BONUS',
    ca_mature: 'MATURE',
    ca_terminate: 'TERMINATE',
    ca_fee: 'FEE',
    not_available: 'Not Available',
    my_eth_definition: '[My ETH Wallet] – This is your WREX Ethereum Wallet.',
    def1: 'By clicking “WITHDRAW”, you can transfer your Ethereum in WREX Wallet to your external wallets/exchanges.',
    def2: 'By clicking “DEPOSIT”, you can transfer your Ethereum from your external wallets/exchanges to this WREX Wallet.',
    def3: 'IMPORTANT: Any Ethereum left in this WREX My ETH Wallet shall not earn any Monthly Profit %. To be part of the monthly profit-sharing plan, your Ethereum need to be placed in either [WREX Account] or [100 ETH Plan]. So, once you have any Ethereum in this wallet, please go to “ASSETS” page to enroll in our products.',
    externaladdress: 'Address',
    datetime: 'Date/Time',
    action: 'Action',
    unknown: 'External ETH Wallet',




    // MyEthWithdraw.js
    mew1: 'WITHDRAW TO EXTERNAL WALLET',
    amount: 'Amount',
    withdrawto: 'Withdraw To',
    selectaddress: 'Select Address',
    networkfee: 'Network Fee',
    youwillget: 'You will get',
    withdrawnotices: 'Withdraw Notices',
    mew2: 'Please check whether your intended amount of your Ethereum to withdraw is in [My ETH Wallet]',
    mew3: 'Key in your intended withdrawal amount in the “Amount”.',
    mew4: 'Click on the “Select Address” and select your intended destination Ethereum Address in the “Withdraw To”.\nIMPORTANT: For security and convenience, at WREX, you have to pre-register your external Ethereum address. If the “Select Address” shows no address or your intended destination address is not to be found in the list, please go to your “ACCOUNT” page – Address Management, and click on “ADD NEW” to add a new address.\nPlease note, also for security reasons, any newly added address will appear only after 24 hours.',
    mew5: 'Once you have successfully completed your withdrawal request, your withdrawal request shall be executed within 24 hours as WREX keep all the Ethereum in cold storage for security reasons.',
    mew6: 'For the withdrawal, an Ethereum Network Fee shall be charged from your total withdrawn amount.',

    // MyEthTransfer.js
    met1: 'Transfer to internal user',
    transfer: "Transfer",
    transferto: 'Transfer To',
    whitelist: 'Whitelist Select',
    enteraddressholder: 'User\'s deposit address',
    transfernotices: 'Internal Transfer Notices',
    met2: 'Please check whether your intended amount of your Ethereum to transfer is in [My ETH Wallet]',
    internaluser: 'Internal User',
    external: 'External',
    internal: 'Internal',
    transferB: "Transfer",
    withdrawB: "Withdraw",

    // WrexTransfer.js
    wrextransfer: 'Wrex',
    wt1: 'Transfer to Wrex Account',
    transferWrexAccountHolder: 'Wrex Account(Email address)',
    wrextransfernotices: 'Wrex Account Transfer Notices',
    wt2: 'Please check whether your intended amount of your Wrex Account to transfer.',


    // Google2fa11c.js
    transfersuccess: 'Transfer Success',


    // MyEthWithdrawRequest.js 
    mewr1: 'Your request is received and processing!',

    // profile.js
    myinfo: 'My Info',
    userlevel: 'User Level',
    usertype: 'User Type',
    nationality: 'Nationality',

    // profilepassword.js 
    oldpassword: 'Old Password',

    // profileAddressNew.js
    addnewaddress: 'New Address',
    pan1: 'Up to 40 characters.',
    add: 'ADD',


    // profileAddress.js
    editaddress: 'Edit the label',


    // Register.js
    selectone: 'Select One',
    signup: 'Sign Up',
    registerTermsCheck: "By proceeding I agree to the terms and privacy policy and certify that I'm age 18+. Check out ",
    termsofuse: 'Terms of Use',
    next: 'Next',
    alreadyhaveaaccount: 'Already have an account?',
    passwordvalidate: 'At least 8 characters long. Must contain at least one lower case letter, one upper case letter, one digit.',
    back: 'Back',
    congrates: 'Congratulations',
    thankyouregister: 'Thank you for signing up with us!  Please follow the instruction to activate your account through the verification email we\'ve just sent to you.',

    // RegisterVerify.js
    rv1: 'Please complete the email verification first. You will not be able to do anything until your account has been activated.',
    rv2: 'Have not got a verification email?',
    resetemail: 'Resend email',

    // EmailConfirmed.js 
    emailconfirmed: 'Email Confirmed',
    emailconfirmationfail: 'Email Confirmation Fail',
    eec1: 'Your request has been confirmed',
    eec2: 'Incorrect confirmation link',
    eec3: 'Your 2FA setting has been disabled',

    // Support.js
    monthlyreports: 'Monthly Reports',
    month: 'Month',
    monthlyprofit: 'Monthly Profit',
    viewreport: 'View Report',
    s1: 'For any questions, please check the FAQ first and if FAQ does not help to answer your inquiries, please write us to',
    s2: 'Transparency\n Baker Tilly, one of the ten major Accounting and Auditing Firm in the world, is verifying all our numbers by visiting our office between 1st day to 5th day of every month.\n They verify (1) all trades happened the month before (2) all Ethereum account status and based on those two verifications they will come up with the monthly profit percentage.\n The report shall be completed by the 8th day of the month and uploaded for our members to view.\n All distribution of the profit shall only be executed on the 10th day of every month based on the monthly profit percentage provided by the monthly report.',

    // WrexAccount.js
    auto_withdrawal: 'Auto Withdrawal',
    auto_reinvest: 'Auto Reinvest',
    performance: 'Performance',
    profitshare: 'Profit Share',
    affiliatebonus: 'Affiliate Bonus',
    wrex: 'WREX Account',
    setting: 'Setting',
    aboutwrexaccount: 'About WREX Account',
    wa1: 'If [Auto Withdrawal] is chosen, all monthly profit generated shall go to [My ETH Account] every 10th of the following month.',
    wa2: 'If [Auto Reinvest] is chosen, all monthly profit generated shall go to [WREX Account] and reinvested into the system.',
    wa3: 'All affiliate bonus ([AB]) every month is considered as Monthly Profit, so if the subscriber chooses [Auto Withdrawal], the AB shall go to [My ETH Account].',
    wa4: 'If the subscriber chooses [Auto Reinvest], the affiliate bonus goes to [WREX Account] and added to your previous principle.',
    wa5: 'Auto Withdrawals happen every 10th day of the following month from [WREX Account]and has 0.5% withdrawal fee.',
    wa6: 'Manual Withdrawals shall be executed within minimum 72 hours to maximum 4weeks of your request from [WREX Account] and has 1.0% withdrawal fee.',


    // WrexDeposit.js
    availablebalance: 'Available Balance',
    deposit: 'DEPOSIT',
    wrexdepositpolices: 'WREX DEPOSIT POLICIES',
    wd1: 'To create and activate WREX Account, please key in the “1 Ethereum" you want to place in the WREX Account.',
    wd2: 'Please choose between [Auto Reinvest] or [Auto Withdrawal]. You can change this setting later, even after the WREX is created.\nFormula: [# of Ethereum in WREX Account] x Monthly Profit % x 45% = WREX Account *Monthly Profit*\nIf you choose [Auto Reinvest], this *Monthly Profit* is added to your principle automatically every month. Your [# of Ethereum in WREX Account] will grow every month.\nIf you choose [Auto Withdrawal], this *Monthly Profit* shall be transferred automatically to your [My ETH Wallet] on the 10th day of the following month. Your [#of Ethereum in WREX Account] will not grow every month and shall remain unchanged.',
    wd3: 'Regardless of your choice, you can also initiate manual withdrawal at any time.',
    wd4: 'All your WREX Account Affiliate Bonus shall be considered as Monthly Profit, so if you choose [Auto Reinvest], your WREX Account Affiliate Bonus shall be added to your principle. If [Auto Withdrawal] is chosen, then the WREX Account Affiliate Bonus shall be transferred in your [My ETH Wallet] on the 10th day of the following month.',

    // WrexDepositCreate.js 
    ethmin: '1 Ethereum',
    autoaccumulation: 'Auto Reinvest',
    autowithdrawal: 'Auto Withdrawal',
    wrex_create: 'CREATE',

    // WrexReturnSetting.js 
    selectmode: 'Select Mode',
    resetrules: 'Reset Rules',
    wrs1: 'Reset shall be effective from the first day of the following month.',
    wrs2: 'Please note: WREX charges 0.5% withdrawal fee for Auto Withdrawal mode.',

    // WrexWithdraw.js
    sendtomyethwallet: 'Send to My ETH Wallet',
    request: 'Request',
    transferfee: 'Transaction Fee',
    manualwithdrawpolices: 'Manual Withdraw Policies',
    ww1: 'Please allow minimum 72 hours to maximum 4weeks from execution to actual withdrawal * Please refer to FAQ why it takes up to maximum 4weeks.',
    ww2: 'Withdrawal fee of 1.0% is deducted from the amount selected to be withdrawn.',
    ww3: 'Must have 1 Ethereum maintained in the [WREX Account] after withdrawal request.',
    ww4: 'Or you may want to withdraw all outstanding balance. Zero Ethereum in [WREX Account] shall terminate your account and all affiliates are lost and not recoverable.',

    wwr1: 'We are processing your request now, you may see the requested amount in your wallet in minimum 72 hours to maximum 4weeks',

    emailAddressPlaceHolder: 'Email Address',
    passwordPlaceHolder: 'Password',
    confirmPasswordPlaceHolder: 'Confirm Password*',
    referralCodePlaceHolder: 'Referral Code',
    addressPlaceHolder: 'Address',
    labelPlaceHolder: 'Label',

    err_emailFormatError: 'Invalid email address.',
    err_emailEmpty: 'Please input email address.',
    err_passwordEmpty: 'Please input password.',
    err_oldPasswordEmpty: 'Please input old password.',
    err_newPasswordEmpty: 'Please input new password.',
    err_confirmPasswordEmpty: 'Please input confirm password.',
    err_passwordMissmatch: 'Password does not match',
    err_referralCodeEmpty: 'Please input referral code.',
    err_countryCodeEmpty: 'Please select country.',
    err_addressEmpty: 'Please input address.',
    err_labelEmpty: 'Please input label.',
    err_addressInvalid: 'This is not a valid address. Please check again.',
    err_overWrexValue: 'Over WREX Value.',
    err_lessThenNetworkFee: 'Less then network fee.',
    err_password_too_simple: 'password is too simple',
    err_name_too_long: 'name is too long',


    // API Error Messages
    two_fa_not_enabled: '2FA not enabled',
    login_required: 'login first',
    invalid_old_password: 'invalid old password',
    address_duplicated: 'The address is duplicated',
    address_not_specified: 'address not specified',
    already_confirmed: 'already confirmed',
    already_enabled: 'already enabled',
    duplicated_email: 'duplicated email',
    email_duplicated: 'This email has registered',
    email_not_confirmed: 'email not confirmed',
    incorrect_device: 'Incorrect device',
    insufficient_amount: 'Insufficient fund',
    invalid_address: 'Invalid address',
    invalid_affilate_code: 'Invalid affiliate code',
    invalid_email_or_password: 'Invalid email or password',
    invalid_email: 'Invalid email',
    invalid_otp: 'Invalid Code',
    invalid_reinvest_value: 'Invalid reinvest value',
    invalid_reset_link: 'invalid reset link',
    invalid_subscription_id: 'invalid subscription id',
    left_amount_less_than_1eth: 'left amount less than 1 eth',
    missing_address: 'missing address',
    missing_parameter: 'missing parameter',
    nothing_to_update: 'nothing to update',
    amount_larger_than_one_eth: 'amount larger than one eth',
    should_accept_penalty: 'should accept penalty',
    reinvest_not_specified: 'reinvest not specified',
    subscription_not_active: 'subscription not active',
    too_many_codes: 'too many codes',

    not_updatable: 'You are not allowed to change the settings!', // 新增的
    amount_not_specified: 'Amount not specified', // 新增的
    invalid_wrex_withdraw_amount: 'Invalid withdrawal amount, please refer the manual withdrawal polices',
    invalid_amount: 'Invalid withdraw amount, please refer the manual withdrawl polices below',
    invalid_wrex_transfer_amount: 'Invalid transfer amount, please refer the manual transfer polices',
    invalid_100eth_create_amount: 'Invalid transfer amount, please refer the manual transfer polices',
    amount_less_than_one_eth: 'To create WREX account, you must deposit at 1 ETH',
    not_withdrawable: 'not withdrawable',
    not_terminatable: 'not terminatable',
    wrex_transfer_error: 'Transfer went wrong',


    unknown_error: 'Unknown Error',

    error: 'Eror',

    confirmedLogoutDesc: 'Confirm to logout?',
    cancel: 'CANCEL',

    ep100_plan_still_active: 'You must terminate all 100 ETH Plan(s) before withdraw all WREX Account and close it.',
    wrex_not_created: 'You must have WREX Account activated first and maintain in order to create any 100 ETH Plan.',

    // 2019.09.05 
    whytrust: 'Why YOU can TRUST WREX?',



    notice_title: 'Announcement for August',
    notice_message: '1.	Our July performance was recorded to be 3.71% for the monthly profit. Since COVID19, we have been recording much below expected "double digit" for the monthly profit level due to large sums of withdrawals. Since April, some members have been withdrawing larger ETH, their principles and their profits. We suspect that due to COVID19 their monthly income had been reduced and by cashing out WREX investment to support living costs was one of the major reasons for such withdrawals',
    notice_date: 'Aug. 20th, 2020',
    notice_footer1: 'Read more...',
    notice_support: 'SUPPORT',
    notice_footer2: '" page for our latest news and annoucements.',



    availablefunds: 'My Investment',

  }
}
