/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { getETHDisplayString }  from '../helper/displayHelper'

import { sendAccountWREXDeposit, sendAccountInfo, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';

import {getToken} from '../helper/loginHelper';
import { renderErrorMessage } from '../components/ErrorMessage'


//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class WrexDeposit extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      errorMessage: "",
      isClickable: true,

    }
  }

  componentDidMount() {
    //console.log("ych WrexDeposit");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Deposit - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleAmountChange(event)  {
    //console.log("handleAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({emailAddressPlaceHolder: ""})
      } else {
        //this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({amount: event.target.value})
    }

  }

  handleAccountWREXDeposit() {
    //console.log("handleAccountWREXDeposit");
    if (this.state.isClickable===false) {
      //console.log("isClickable===false");
      return;
    }

    this.setState({errorMessage: ""})
    var self = this;

    if ((this.state.amount==="")||(this.state.amount<=0)) {
      this.setState({errorMessage: i18n.translate("amount_not_specified")})
      return;
    }
    let token = getToken(this);

    this.setState({isClickable: false})

    this.props.sendAccountWREXDeposit(token, { amount: this.state.amount
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.sendAccountInfo(token,{});
          self.props.history.push("/my_assets")
        }

      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }
      self.setState({isClickable: true})


    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
      self.setState({isClickable: true})
    })
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Wrex Deposit Error",this);
    }

    //console.log(this.props.globalAccount);
    var myeth = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {  
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        myeth.value = parseFloat(account.myeth.value);
      }
    }


    return (
			<div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="wrex"
                                        />



				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><a href="wrex_account">{i18n.translate('wrex')}</a><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('deposit')}</h1>
							</div>
						</div>
		  

						  <div className="mdc-layout-grid">
							<div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
									<div className="crypto-widget">
										<h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
										<span className="crypto-widget__heading-text">{i18n.translate('availablebalance')}: {getETHDisplayString(myeth.value)} ETH</span>
										</h2>
									</div>
							  </div>
              </div>
              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
                        <p className="f_formtitle">{i18n.translate('amount')}</p>
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="amount-text-field" className="mdc-text-field__input" 
													value={this.state.amount}
               						onChange={this.handleAmountChange.bind(this)}
												/>
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>
								
											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span href="wrex_account" className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                                                                            onClick={() => this.handleAccountWREXDeposit()}
											  >{i18n.translate('deposit')}</span>
											</div>
										  </div>
										</form>
									  </div>

{ /*
 		<span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }


									</div>
								  </div>
								</div>
							  </div>
              </div>
              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
										<h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
											<span className="crypto-widget__heading-text crypto-word-warp">{i18n.translate('wrexdepositpolices')}</span>
											<span className="crypto-widget__heading-border"></span>
										</h2>
									<div className="crypto-widget__content">
											<ul className="li_notice">
												<li>{i18n.translate('wd1')} </li>
												<p className="crypto-word-warp">{i18n.translate('wd2')}</p>
												<p className="crypto-word-warp">{i18n.translate('wd3')}</p>
												<p className="crypto-word-warp">{i18n.translate('wd4')}</p>
											</ul>
										</div>
								</div>
							  </div>
							</div>

					</div>
					</div>
				  </main>

				</div>			

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendAccountWREXDeposit, sendAccountInfo
})(WrexDeposit))




