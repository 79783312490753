/* eslint-disable no-undef */

import React, { Component } from 'react'
//import ReactDOM from 'react-dom'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react'

import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';
import SupportHeader from '../components/SupportHeader';
import FaqPageEN from '../components/FaqPage_en';
import FaqPageKR from '../components/FaqPage_kr';
import FaqPageID from '../components/FaqPage_id';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class Support extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      LanguageSetting: "",
refe1: 1,
    }

    const cookieLanguageSetting = cookies.get('language_setting');
    if (cookieLanguageSetting!==undefined) {
      if (cookieLanguageSetting==="KR") {
        this.state.LanguageSetting = "KR";
      } else if (cookieLanguageSetting==="ID") {
        this.state.LanguageSetting = "ID";
      } else {
        this.state.LanguageSetting = "EN";
      }
    } else {
      this.state.LanguageSetting = "EN";
    }
  }

  componentDidMount() {
    //console.log("ych Support");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Support - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

//console.log(this.props.location.hash);
    let hash = this.props.location.hash.replace('#', '');
    if (hash) {
      var element = document.getElementById(hash);
      console.log(element);
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
      element.click();
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
           { /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}
                    
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="support"
                                        />

				  <main className="crypto-main">
					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
 
          <div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone">
                <h6 className="f_breadcrumb">
                </h6>
                <h1 className="crypto-card__header--title">{i18n.translate('support')} </h1>
                <h4 className="f_mode">
                </h4>
            </div>

          </div>



					<div className="mdc-layout-grid">
						<div className="mdc-layout-grid__inner f_rfl_num">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1-desktop"></div>
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"> 

              <div className="crypto-widget__content crypto-overflow-x-inherit fx_mb_nopadding">

                <SupportHeader />

                {this.state.LanguageSetting==="EN" && <FaqPageEN refe1={this.state.refe1} />}
                {this.state.LanguageSetting==="KR" && <FaqPageKR />}
                {this.state.LanguageSetting==="ID" && <FaqPageID />}


              </div>

						  </div>
						</div>
					  </div>

					</div>
				  </main>

				</div>  
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(Support))




