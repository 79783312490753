/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import i18n from 'i18n-react'
//import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import QRCode from 'react-qr-code';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import SideNav from '../components/SideNav';
import { getETHDisplayString }  from '../helper/displayHelper'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class MyEthDeposit extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);


    this.state = {
      copied: false,

    }

  }

  componentDidMount() {
    //console.log("ych MyEthDeposit");
    //window.addEventListener('resize', this.handleResize)
    document.title = "MyETH Deposit - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    //console.log(this.props);

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    var myeth = {value: "", valueUsd: "", availableAmount: ""}
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth!==undefined) {
        //myeth = account.myeth;
        myeth.value = parseFloat(account.myeth.value);
        myeth.valueUsd = parseFloat(account.myeth.valueUsd);
        myeth.availableAmount = 100-myeth.value;
      }
    }

    var userInfo = {ethDepositAddress: "", depositQuotaLeft: ""};
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {
      let user = this.props.globalUser.user;
      if (user.info!==undefined) {
        userInfo = user.info;
      }
    }

    const myQRCode = userInfo.ethDepositAddress !== null ? (
      <QRCode value={userInfo.ethDepositAddress} />
    ) : (
      <div></div>
    )

    return (
      <div>
{ /*
        <div className="crypto-loader"></div>
*/ }
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="myeth_wallet"
                                        />

				

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="myeth_wallet">{i18n.translate('myethwallet')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('deposit')}</h1>
							</div>
						</div>
		 

					  <div className="mdc-layout-grid">
						<div className="mdc-layout-grid__inner f_rfl">
              <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text crypto-word-warp">{i18n.translate('med1')}</span>
								  </h2>
								</div>
							</div>
            </div>
						<div className="mdc-layout-grid__inner f_rfl">
              <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
						<div className="f_rfl qrcode_border">
							<div className="f_qrcode">
  							{myQRCode}
							</div>
            </div>
            <div className="mdc-layout-grid__inner f_rfl fx_a">
             <div className="f_control mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-phone">
                <div className="mdc-text-field mdc-text-field--disabled fx_inputnone">
                    <label htmlFor="tf-box-trailing" className="fx_txtcenter">{userInfo.ethDepositAddress}</label>
                    <div className="mdc-line-ripple"></div>
                </div>

<CopyToClipboard 
text={userInfo.ethDepositAddress}
          onCopy={() => this.setState({copied: true})}>
									<div className="div_center"><span className="f_ybtn mdc-button">{i18n.translate('copy')}</span></div>	
        </CopyToClipboard>

							</div>
						</div>
            </div>
            </div>

            <div className="mdc-layout-grid__inner f_rfl">
              <div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"></div>
              <div className="f_rg_ptop mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                <br/>
								<span className="crypto-widget__heading-text">{i18n.translate('availableamount')} : {getETHDisplayString(userInfo.depositQuotaLeft)} ETH</span>
								<p className="crypto-word-warp">* {i18n.translate('med2')}</p> 
              </div>
            </div>
            <div className="mdc-layout-grid__inner f_rfl">
              <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							<div className="f_rg_ptop mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('depositnotices')}</span>
									<span className="crypto-widget__heading-border"></span>
								  </h2>
								  <div className="crypto-widget__content">
										<ul className="li_notice"><li>{i18n.translate('med3')}</li>
										<li>{i18n.translate('med4')}</li>
										<li>{i18n.translate('med5')}</li>
										<li>{i18n.translate('med6')}</li>
										</ul>
									</div>
								</div>
							  </div>

							</div>
						</div>


					</div>

				  </main>

				</div>        
      </div>

    )
  }



}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
  }
}

export default withRouter(connect(mapStateToProps, {
})(MyEthDeposit))




