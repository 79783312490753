/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import i18n from 'i18n-react';

//import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import SideNav from '../components/SideNav';
import PreLoaderWidget from '../components/Loader';

//const cookies = new Cookies();
class IndexLogin extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.state = {
      pdf: "",
    }
  }

  componentDidMount() {
    //console.log("ych IndexLogin");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Read PDF - WREX"

    document.body.className = "mdc-typography mdc-theme--background crypro-theme-gradient f_home";

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const pdf = params.get('pdf'); // bar
    if (pdf!==null) {
      this.setState({pdf: "https://docs.google.com/gview?embedded=true&url="+pdf})
//console.log(pdf);
    }


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  renderErrorMessage() {
    const { errorMessage } = this.props
    if (!errorMessage) {
      return null
    }

    return (
      <p style={{ backgroundColor: '#e99', padding: 10 }}>
        <b>{errorMessage}</b>
        {' '}
        <button onClick={this.handleDismissClick}>
          Dismiss
        </button>
      </p>
    )
  }


  render() {

    return (
      <div>
        { /* preloader */}
        {this.props.loading && <PreLoaderWidget />}

        <div className="crypto-wrapper">
          <main className="crypto-main">
            <div><Link to="wrex_support" className="back-btn mdc-button mdc-button--outlined">{i18n.translate('back')}</Link>PDF File Loaded. </div>
            <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0 content--display">

              <iframe title="" src={this.state.pdf} height="100%" witdth="100%" frameborder="0" ></iframe>

            </div>

          </main>

        </div>

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalUser: state.entities.user,
  globalAccount: state.entities.account
})

export default withRouter(connect(mapStateToProps, {
})(IndexLogin))




