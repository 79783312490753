/* eslint-disable no-undef */

//import React, { Component } from 'react'
import React from 'react'
import i18n from 'i18n-react'

export const renderErrorMessage = (title,reference) => {
    //const { errorMessage } = this.props
		// console.log("renderErrorMessage()");
		var errorMessage;
		if (title === "Wrex Transfer Error") {
			errorMessage='wrex_transfer_error';
		} else {
			errorMessage=reference.state.errorMessage;	
		}
    //if (!errorMessage) {
      //return null
    //}

    return (
      <div>
       			  <div className="crypro-login-container rex_rg">
				<div className="crypro-error-container__box">
				  <div className="crypro-error-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">

					 <span className="crypto-widget__heading-text">{title}</span> 
					 { /* <span className="crypto-widget__heading-text">{i18n.translate('error')}</span> */ }
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<p className="crypto-word-warp">{i18n.translate(errorMessage)}</p>
					<br/>
					<form className="crypto-login-form">
					  <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button" onClick={() => reference.setState({errorMessage:""})} >{i18n.translate('ok')}</span>
					</form>
				  </div>
				</div>
			  </div>
      </div>
    )
}

