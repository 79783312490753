export function Translations_id(language) {
    return {

        // Top bar 
        top1: 'MIMPI BESAR DENGAN PERUBAHAN KECIL',
        Login: 'Log In',
        Register: 'Daftar',
        Language: 'Bahasa',

        // SideNav
        wallet: 'WALLET',
        assets: 'ASSETS',
        affiliate: 'AFFILIATE',
        support: 'SUPPORT',
        account: 'ACCOUNT',
        logout: 'LOGOUT',

        // Index Page
        introduction: 'Kata Pengantar',
        ip1: 'Mata uang Digital telah berkembang dari nol menjadi seperti sekarang ini. Namun, kami hanya melihat dan mengetahui hanya sedikit miliarder dalam industri ini. Mengapa? Karena kebanyakan dari kita cukup banyak menjual pada tahap prematur demi keuntungan yang bagus. Nah pada saat itu, kami pikir itu adalah keuntungan yang bagus.',
        ip2: 'Namun, yang disebut multijutawan, pada hari ini masih memegang aset kriptonya dan Anda tahu? Kami pikir mereka akan menjadi multimiliarder cepat atau lambat.',
        ip3: 'Di WREX, kami berusaha keras untuk membangun komunitas tertutup kami sendiri. Komunitas dengan ruang cyber, di mana setiap orang diberi kesempatan yang sama untuk belajar dan mendapatkan pengalaman yang tidak bisa diberikan orang lain. Platform kami hadir dengan komitmen jangka panjang. Kami tidak percaya seseorang menjadi multijutawan dalam semalam.',
        ip4: 'Selamat Datang di WREX!',
        feaures: 'Fitur',
        ip5: '3-Layered Algorithm System',
        ip6: 'Dengan WREX 3-Layered Algorithm System, terlepas dari kami memasuki pasar “bear” atau pasar “bull” pada mata uang digital, kami melakukan dan memberikan laba bulanan dimana saja antara 5% hingga 80% sebulan.',
        ip7: 'Tidak pernah mencatat kerugian dalam sebulan! Untuk setiap perdagangan tunggal, kami tidak menjual pada saat rugi.',
        ip8: 'Kami tidak pernah memperdagangkan satu pun perdagangan dengan kerugian, dan ini adalah dasar mengapa kami tidak pernah mencatat kerugian pada bulan tertentu sejak Januari 2018 ketika kami menyelesaikan beta testing hingga hari ini.',
        products: 'Produk',
        ip9: 'WREX menawarkan dua program berdasarkan strategi dan kebutuhan Anda. Program yang tersedia adalah [WREX Account] dan [100 ETH Plan].',
        ip10: 'Setiap Ethereum yang Anda pilih untuk ditempatkan di akun ini, WREX akan memulai perdagangan untuk meningkatkan jumlah Ethereum.',
        howtogetstart: 'Cara Memulai',
        ip11: 'Mimpi Besar dengan Perubahan Kecil',
        ip12: 'Ikuti tautan yang disediakan oleh referrer Anda. Buat akun di WREX. Catatan: Kode afiliasi diperlukan untuk melengkapi pendaftaran Anda.',
        ip13: 'Setelah membuat akun di WREX, Anda akan diberikan [My ETH Wallet] dengan alamat Ethereum pribadi Anda di WREX. Silahkan gunakan alamat ini untuk mentransfer Ethereum dari tempat penukaran atau dompet eksternal Anda.',
        ip14: 'Saat Anda mengkonfirmasi Ethereum Anda ditransfer ke [My ETH Wallet] di WREX, Anda sekarang dapat melakukan transfer ke akun WREX. Pertahankan satu atau lebih Ethereum di [WREX ACCOUNT] setiap saat, Anda sekarang berhak menerima Bonus Afiliasi bulanan dan membuat hingga sepuluh akun [100 ETH PLAN].',
        ip15: 'Tidak ada biaya yang dikenakan saat mentransfer Ethereum dari [My ETH Wallet] ke [WREX Account] atau [100 ETH PLAN].',
        howitworks: 'Cara Bekerja',
        ip16: 'Menciptakan Masa Depan Tanpa Batas',
        ip17: 'Setiap Ethereum di [WREX Account] atau [100 ETH PLAN] digunakan sebagai sumber daya oleh pedagang profesional kami yang ahli di pasar pairing Ethereum berdasarkan WREX 3-Layered ETH Algorithm System. Harap dicatat, setiap Ethereum yang tersisa di [My ETH Wallet] tidak menghasilkan pembagian laba bulanan. [My ETH Wallet] hanyalah sebuah Dompet Ethereum yang digunakan untuk menyimpan dan menarik antara dompet eksternal dan dompet WREX Anda.',
        ip18: 'Setiap bulan, salah satu dari empat firma akuntansi & audit utama di dunia akan menghasilkan laporan kinerja bulanan untuk memverifikasi semua transaksi perdagangan, akun, dan laba bulanan sehingga menyediakan transparansi maksimum untuk operasi kami.',
        ip19: 'Laporan bulanan ini diakumulasikan di situs web kami untuk dilihat oleh pelanggan kami.',
        profitsharing: 'Pembagian Laba',
        ip20: 'Setiap bulan, ketika persentase penghasilan bulanan diumumkan dan dikonfirmasi pada laporan bulanan, laba bulanan penuh akan dibagikan sebagai berikut; Pelanggan 45%; Program Afiliasi 5%; WREX 50%.',
        ip21: 'Jika Akumulasi Ulang Otomatis dipilih sebagai opsi oleh pelanggan, bagian Pelanggan sebesar 45% dari laba bulanan ditransfer secara otomatis ke [WREX Account] Anda dan ditambahkan ke modal dasar Anda. Setelah modal dasar tumbuh setiap bulan, penghasilan bulanan Anda sesudahnya juga akan tumbuh karena penghasilan bulanan 45% didasarkan pada modal dasar Anda.',
        ip221: 'Formula: ',
        ip222: '# Ethereum di Akun WREX',
        ip224: ' Sharing Pelanggan ',
        ip225: ' Laba Bulanan % ',
        ip23: 'Pada [100 ETH Plan], tidak hanya 45% laba pelanggan ditambahkan ke modal dasar Anda, tetapi juga WREX akan mencocokkan 45% sebagai kontribusi pada modal dasar Anda. Karena itu, modal Anda akan tumbuh pada tingkat yang jauh lebih tinggi dibandingkan dengan [WREX Account], sehingga pertumbuhan keseluruhan akun Anda dimaksimalkan.',
        ip241: ' # Ethereum di 100 ETH PLAN ',
        ip242: ' Pelanggan 45% + WREX 45% ',
        ip243: ' Laba Bulanan % ',
        ip25: 'Anda dapat menyesuaikan dan menambahkan nama yang dipersonalisasi ke semua dari sepuluh [100 ETH PLAN].',
        ip26: 'mis. 100 ETH PLAN #1 - Hadiah Pernikahan Anak Laki-laki Saya',
        affiliateprogram: 'Program Afiliasi',
        ip27: 'WREX menawarkan Program Afiliasi paling kuat di industri. Yaitu 5% dari laba bulanan berdasarkan total jumlah Ethereum yang Anda referensikan, setiap bulan!',
        ip28: 'Program Afiliasi Akun WREX',
        ip29: 'Selama Anda mempertahankan 1 Ethereum di [WREX Account] Anda dari hari pertama hingga hari terakhir di bulan itu, Anda berhak menerima 5% dari laba bulanan berdasarkan penempatan Ethereum yang Anda tunjuk di [WREX Account].',
        ip30: '# Ethereum di Akun WREX terafiliasi yang Anda referensikan',
        ip31: 'Laba Bulanan',
        ip32: 'Program Afiliasi 100 ETH PLAN',
        ip33: 'Selama Anda mempertahankan satu [100 ETH PLAN] dari hari pertama hingga hari terakhir bulan itu, Anda berhak menerima 5% dari laba bulanan berdasarkan penempatan Ethereum yang Anda referensikan dari semua [100 ETH Plans].',
        ip34: '# dari Ethereum di semua 100 ETH PLAN terafiliasi yang Anda referensikan',
        ip35: 'Ingat, ketika total akun ETH yang Anda referensikan TUMBUH setiap bulan, bonus afiliasi Anda akan tumbuh lebih cepat dan lebih besar setiap bulan juga.',
        withdrawfee: 'Penarikan dan Biaya',
        ip36: 'Penarikan - WREX Account',
        ip37: 'Untuk [WREX Accounts], Anda harus memilih antara [AUTO WITHDRAWAL] dan [AUTO ACCUMULATION]. Secara default, ini akan berada di [AUTO ACCUMULATION] dan Anda dapat mengubahnya kapan saja di pengaturan pribadi Anda.',
        ip38: 'Jika Anda memilih [AUTO WITHDRAWAL], itu berarti semua keuntungan bulanan Anda dari [WREX Accounts] akan dikreditkan ke [My ETH Wallet]. Transfer otomatis ini akan dilakukan setiap tanggal 10 di bulan berikutnya.',
        ip39: 'Kapan saja, tidak ada batasan untuk melakukan permintaan penarikan manual dari [WREX Account] Anda. Harap dicatat, penarikan manual dari [WREX Account] bisa memakan waktu minimum 72 jam hingga maksimum 4 minggu.',
        ip40: 'Peringatan: kurang dari 1 Ethereum di [WREX Account] setelah eksekusi penarikan manual mengakibatkan kehilangan bonus afiliasi dan penghentian otomatis semua [100 ETH PLAN] Anda yang terdaftar. Dalam kasus seperti itu, semua Ethereum dari [100 ETH PLAN] yang dihentikan akan otomatis ditransfer ke [WREX Account].',
        ip41: 'Penarikan - 100 ETH PLAN',
        ip42: 'Penarikan dari [100 ETH PLAN] tidak memungkinkan. Tetapi jika Anda mau, Anda dapat mengakhiri salah satu atau semua dari [100 ETH PLAN] Anda kapan saja. Tidak ada penalti pemberhentian.',
        ip43: 'Biaya - PENARIKAN MANUAL (WREX Account)',
        ip44: '1% dari total transfer penarikan Ethereum dari [WREX Account] ke [My ETH Wallet]',
        ip45: 'Biaya - Pemberhentian 100 ETH PLAN',
        ip46: '1% dari total transfer pemberhentian Ethereum dari [100 ETH PLAN] ke [My ETH Wallet]',
        ip47: 'Biaya Jaringan dapat berlaku untuk transfer apa pun dari [My ETH Wallet] ke dompet eksternal Ethereum Anda.',




        // LastEarns.js
        lastmonthreturn: 'Laba Bulan Lalu',
        totalamount: 'Total Jumlah',

        // AccountAddress.js 
        addressmanagement: 'Address Management',
        addnew: 'TAMBAH BARU',
        label: 'Label',
        address: 'Alamat',
        edit: 'Ubah',
        delete: 'Hapus',

        // AccountSecurity.js
        securitysettings: 'Pengaturan Keamanan',
        setup: 'Pengaturan',
        reset: 'Buat ulang',
        resetpassword: 'Buat Ulang Password',
        siginhistory: 'Sign-in Histories',
        siginactivity: 'Sign-in Activity',
        ipaddress: 'Alamat IP',
        location: 'Lokasi',

        // AffliliateProgram.js
        //affiliateprogram: 'Program Afiliasi',
        email: 'Email',
        referralwrexamount: 'Jumlah Referal WREX',
        referralCount: 'Teman Referal',
        referal100epamount: 'Jumlah Referal 100EP',
        earnedcommisions: 'Komisi yang Didapatkan',
        myreferalid: 'Referal ID Saya',
        sharereferalid: 'Bagikan Referal ID',
        sharereferallink: 'Bagikan Referal Link',
        referralfriends: 'Teman Referal',
        programdetail: 'Program Details',
        ap1: 'Bonus Afiliasi Anda akan tergantung pada berapa banyak total Ethereum yang Anda referensikan dalam masing-masing [WREX Account] dan [100 ETH PLAN]. Setiap Ethereum di [My ETH Wallet] tidak akan dihitung sebagai Afiliasi Ethereum.',
        ap2: 'Jika jumlah Ethereum milik afiliasi Anda bertambah setiap bulan karena [Auto Reinvest] di [WREX Account] dan [100 ETH PLAN] mereka, Bonus Afiliasi Anda juga akan TUMBUH!',
        ap3: 'Selama Ethereum milik afiliasi Anda berada di [ WREX Account] dan [100 ETH PLAN], Anda akan menerima Bonus Afiliasi, selamanya!',
        ap4: 'Terlepas dari apakah Anda seorang influencer besar atau hanya mengelola akun Anda tanpa afiliasi yang diperkenalkan, semua orang termasuk afiliasi Anda akan berterima kasih karena telah memperkenalkan situs kami karena semua orang menang di WREX dan kami merasa ini adalah bagian terpenting dari seluruh proyek ini.',
        ap5: 'Situs kami adalah komunitas tertutup, artinya Anda harus memasukkan Kode Afiliasi untuk menyelesaikan pendaftaran. Setelah kode terdaftar, Anda tidak dapat mengubahnya. Hal ini demi melindungi influencer aktif kami untuk memastikan mereka semua mendapatkan kredit.',
        ap6: 'Situs lain, situs serupa, menawarkan Program Afiliasi, tetapi kebanyakan tidak sekuat milik kami. Karena platform kami membutuhkan komitmen jangka panjang, untuk durasi itu, Anda akan diberi hadiah selama afiliasi Anda mempertahankan akun mereka. Kami juga bangga menambahkan fitur keberlanjutan. Kami membayar hanya dari laba bulanan kami, oleh karena itu kami berlanjut terus, selamanya. Selama kami tidak berkinerja negatif, modal dasar semua anggota kami hanya terus bertumbuh setiap bulan. Jika modal bertumbuh setiap bulan, itu berarti bonus afiliasi bulanan Anda akan tumbuh setiap bulan sebagaimana bonus didasarkan pada modal dasar bulanan. ',
        ap7: 'Bagikan tautan Anda di atas dalam jaringan Anda. Jika mereka mengklik tautan dan mendaftar, mereka akan didaftarkan secara otomatis menggunakan kode afiliasi Anda.',
        ap8: 'Informasi rujukan Anda direfresh dan diupdate pada UTC 00:00. Hanya "TEMAN REFERAL" Anda yang diperbarui secara real-time.',


        // APStatus.js
        status: 'Status',
        myaffiliatestatus: 'Status Afiliasi Saya',
        since: 'Sejak',
        as1: 'AKTIF berarti anggota telah menyelesaikan pendaftaran, menyetor 1 Ethereum di Akun WREX dan membuat setidaknya satu 100 ETH Plan.',
        active: 'AKTIF',

        //EmailVerify.js
        emailverification: 'Verivikasi Email',
        ev1: 'Harap melengkapi verifikasi email terlebih dahulu. Anda tidak akan dapat melakukan apapun sampai akun Anda diaktifkan.',
        continue: 'Lanjut',
        ev2: 'Belum mendapat email verifikasi?',
        resendemail: 'Kirim Ulang Email',
        resend: 'Kirim Ulang',

        // Eth100Create.js
        createnew100ep: 'Membuat 100 ETH Plan Baru',
        namethisplan: 'Anda dapat membuat sendiri nama paket ini.',
        create: 'Buat',
        about100ep: 'Mengenai 100 ETH Plan',
        name: 'Nama',
        amount_ep100: 'Jumlah (Minimum 1ETH ~ Maksimum 100ETH)',
        ec1: 'Anda dapat membuat nama untuk masing-masing Paket 100 ETH hingga 40 karakter. \n(mis. Hadiah Pernikahan putra pertama saya) Anda dapat mengubah nama ini nanti.',
        ec2: 'Anda hanya dapat menempatkan 1~100 Ethereum per 100 ETH Plan. Setelah Anda menekan tombol "BUAT", 1~100 Ethereum akan secara otomatis dikurangkan dari My ETH Wallet dan ditransfer untuk membuat 100 ETH Plan.',
        ec3: 'Untuk Semua 100 ETH Plan, laba bulanan akan ditambahkan ke modal dasar Anda secara otomatis.',
        ec4: 'Laba Bulanan dihitung sebagai berikut:\n[# dari Ethereum dalam satu 100 ETH Plan] x [Laba Bulanan%] x 90% = Laba Bulanan.\nLaba Bulan ini ditambahkan ke modal dasar Anda. Setiap bulan, perhitungan modal dasar yang baru akan bertumbuh, dengan kecepatan yang lebih cepat dibandingkan dengan Akun WREX.',
        ec5: 'Jatuh tempo kontrak ini adalah 40 bulan dan tidak diperbolehkan untuk menarik sebagian dari 100 ETH Plan. Namun jika Anda mau, Anda dapat mengakhiri satu atau beberapa 100 ETH Plan kapan saja. Setelah dihentikan, Anda akan menerima jumlah penuh yang ditampilkan pada produk di hari ke 10 bulan berikutnya.',
        ec6: 'Kontrak 100 ETH Plan ini, setelah dibuat, akan berlaku mulai dari hari pertama bulan berikutnya',

        // Eth100Plan1.js
        ep100: '100 ETH Plan',
        oldep100: '(Dibuat sebelum 1 Sept 2020)',
        terminate: 'Pemberhentian',
        monthstomature: '(bulan sampai jatuh tempo)',
        overview: 'Overview',
        sixmonthperformance: 'Kinerja 6 bulan terakhir',
        accounthistory: 'Account History',


        // Eth100Update.js
        update: 'Perbarui',

        // Eth100TerminateConfirm.js
        confirmterminate: 'Konfirmasi Pemberhentian?',
        etc1: 'Dengan konfirmasi, kami akan mentransfer jumlah total kembali ke Wallet Anda pada tanggal 10 bulan berikutnya.',
        etc2: 'Pemberhentian ini akan memunculkan pinalti atas penghentian dini. Pinalti penghentian dini adalah 75% dari laba yang ANDA peroleh. Jika Anda masih ingin menghentikan, mohon centang di bawah untuk konfirmasi. Untuk menghindari penalti, harap pertahankan akun Anda hingga jatuh tempo yaitu 24 bulan setelah Anda membuat 100 ETH PLAN ini.',
        lets_do_it: 'Saya setuju. Silahkan lakukan.',
        yes: 'YA',

        // ForgotPassword.js
        forgotpassword: 'Lupa Password',
        fp1: 'Untuk mengatur ulang password Anda, Anda akan dikirimi email berisi instruksi untuk mengatur ulang password Anda.',
        send: 'KIRIM',
        backtologin: 'Kembali ke login',

        // ForgotPasswordResult.js
        fpr1: 'Pesan pengaturan ulang password telah dikirim ke alamat email Anda.',
        ok: 'OK',
        fpr2: 'Belum menerima email?',

        // ForgotPasswordToReset.js
        newpassword: 'Password Baru',
        confirmpassword: 'Konfirmasi Password',

        // Google2fa1.js
        inputcode: 'Masukkan 6 digit kode 2FA di',
        googleauthentication: 'Google Authentication',
        g2: 'Harus minimal 6 digit',
        g3: 'Tidak dapat mengakses Google Authenticator?',
        submit: 'Submit',

        // Google2fa2.js
        notsetupyet: 'Belum diaktifkan...',
        g4: 'Aplikasi Google Authenticator Anda tidak disinkronkan dengan benar. Silahkan klik pengaturan untuk mengaktifkan fitur ini.',


        // Google2fa11a.js
        changesuccess: 'Perubahan Berhasil',
        dismiss: 'Tutup',

        // Google2fa11b.js
        requestreceived: 'Permintaan Diterima',

        // Google2fa22.js 
        g21: 'Install Google Authenticator.',
        g22: 'Install aplikasi Google OTP di ponsel Anda kemudian buka.',
        g23: 'Klik di ‘Add’ pada app dan pilih scan QR Code.',
        g24: 'Scan QR Code berikut ini dengan Google OTP app atau ketik secara manual.',
        g25: 'Verifikasi kodenya',
        g26: 'Isi kode 6 digit yang disediakan oleh Google OTP untuk mengaktifkan Otentikasi Dua Faktor.',
        g2a: '',

        code: 'Kode 6-digit',

        // invest.js
        availablefunds: 'Produk WREX - [WREX Account] dan [100 ETH Plan]',
        notices: 'Pemberitahuan',
        wrex_account: 'WREX Account',
        eth100_plan: '100 ETH Plan',
        totaleth: 'Total ETH pada Akun',
        total: 'Total',

        is1: 'Anda harus mengaktifkan Akun WREX sebelum dapat membuat 100 ETH Plan.',
        is2: 'Untuk mengaktifkan Akun WREX, Anda harus membuat Akun WREX dan mempertahankan 1 Ethereum di Akun WREX. Untuk 100 ETH Plan, Anda hanya dapat menempatkan 1~100 Ethereum di masing-masing 100 ETH Plan.',
        is3: 'Sebelum membuat salah satu produk, mohon periksa berapa banyak Ethereum yang tersedia di WREX Wallet Anda.',
        is4: 'Kemudian Anda perlu memikirkan dan memutuskan berapa banyak Ethereum yang ingin Anda tempatkan di Akun WREX dan berapa banyak 100 ETH Plan yang ingin Anda buat dengan Ethereum yang tersedia di WREX Wallet Anda. \n',
        is41: '*Jika Anda bermaksud membuat satu atau beberapa 100 ETH Plan setelah Anda membuat Akun WREX, JANGAN setorkan semua Ethereum Anda dari WREX Wallet ke Akun WREX.*',
        is42: 'Jika Anda menyimpan semua Ethereum dari WREX Wallet Anda ke Akun WREX dan Anda tidak memiliki Ethereum tersisa di WREX Wallet Anda, maka Anda tidak akan memiliki Ethereum tersisa untuk membuat 100 ETH Plan.',
        is5: 'PENTING - WAKTU PENEMPATAN (SETORAN) \nIni hanya untuk Akun WREX saja. \nTergantung pada waktu penempatan di Akun WREX, Anda akan menerima persentase laba bulanan yang berbeda hanya untuk bulan pertama penempatan. \nJika penempatan Ethereum Anda terjadi dari *hari pertama hingga hari ke-10 bulan itu*, maka Anda berhak menerima 50% dari laba bulanan untuk bulan itu. \nJika penempatan Ethereum Anda terjadi dari *hari ke-11 hingga hari ke-20 dalam bulan itu*, maka Anda berhak menerima 25% dari laba bulanan untuk bulan itu. \nJika penempatan Ethereum Anda terjadi dari *hari ke-21 hingga hari terakhir bulan itu*, maka Anda berhak menerima 0% dari laba bulanan untuk bulan itu. \nUntuk ketiganya di atas, semua berhak menerima 100% dari persentase laba bulanan setelah bulan pertama. (tanggal penempatan hanya akan mempengaruhi bulan yang sama pada saat Anda menempatkan Ethereum yang baru) \n\nUntuk 100 ETH Plan \nSemua 100 ETH Plan, setelah dibuat, terlepas dari waktu pembuatan, tanggal mulai efektif adalah hari pertama bulan berikutnya.',
        is6: 'Formula tentang bagaimana pertumbuhan Akun WREX Anda dan 100 ETH PLAN;\n\n[WREX Account]\nSetiap bulan, 45% dari laba bulanan% diakumulasikan di Akun WREX.\nFormula: [ETH di Akun WREX] x 45% x Laba Bulanan% + Bonus Afiliasi Akun WREX (jika ada) = Laba Saya\n\n[100 ETH Plan]\nSetiap bulan, 90% dari laba bulanan% diakumulasikan dalam 100 ETH PLAN.\nFormula: [ETH dalam 100 ETH PLAN] x 90% x Laba Bulanan% + 100 ETH PLAN Bonus Afiliasi (jika ada) = Laba Saya\n** Harap PERHATIKAN: Meskipun di belakang, 90% dihitung; 100 ETH PLAN Anda akan menampilkan HANYA porsi Anda (45%) dari\nkeuntungan. Jadi jika Anda mengakhiri 100 ETH PLAN hari ini, jumlah ETH yang ditampilkan adalah ETH yang akan Anda terima.',


        is7: 'Sebelum membuat dan berlangganan produk-produk ini, harap baca Pemberitahuan terlebih dahulu.',
        lastmonth: 'Bulan Lalu',
        lastmonth_profit: 'Laba Bulan Lalu',
        my_profit: 'Laba Saya',

        // Login.js
        signin: 'Sign In',
        notyetonwrex: 'Belum terdaftar di WREX?',

        // MyEthDeposit.js
        myethwallet: 'My ETH Wallet',
        ch_deposit: 'Setoran',
        med1: 'GUNAKAN ALAMAT BERIKUT UNTUK MENYETORKAN DANA',
        med2: 'Mohon JANGAN menyetor melebihi jumlah, kami hanya menerima setoran total 10.000 Ethereum',
        copy: 'Salin',
        availableamount: 'Jumlah Tersedia',
        depositnotices: 'Pemberitahuan Setoran',
        med3: 'Ini adalah Alamat WREX Wallet Ethereum Anda. Silahkan kirim hanya Ethereum saja! Jika Anda mengirim koin atau token selain Ethereum (ETH), akan mengakibatkan hilangnya setoran Anda.',
        med4: 'Anda dapat memindai QR CODE atau mengklik tombol "SALIN" dan menempelkannya. Jika Anda benar-benar menyalin dan menempel, PASTIKAN alamatnya disalin dan ditempelkan dengan benar.',
        med5: 'Ethereum akan disetorkan setelah konfirmasi 30 blok.',
        med6: 'Alamat Ethereum di atas hanya untuk setoran Anda di WREX My ETH Wallet.',

        // MyEthWallet.js
        withdraw: 'Penarikan',
        ca_withdraw: 'PENARIKAN',
        youhavedeposited: 'Anda telah menyetor',
        youhaveroom: 'Anda memiliki ruang untuk menyetor yang lain',
        history: 'History',
        filter: 'Filter',
        gains: 'Keuntungan',
        bonus: 'Bonus',
        ca_create: 'BUAT',
        ca_gains: 'KEUNTUNGAN',
        ca_bonus: 'BONUS',
        ca_mature: 'JATUH TEMPO',
        ca_terminate: 'PEMBERHENTIAN',
        ca_fee: 'BIAYA',
        not_available: 'Tidak Tersedia',
        my_eth_definition: '[My ETH Wallet] – Ini adalah WREX Ethereum Wallet Anda.',
        def1: 'Dengan mengklik “PENARIKAN”, Anda dapat mentransfer Ethereum Anda di WREX Wallet ke tempat penukaran/dompet eksternal Anda.',
        def2: 'Dengan mengklik "SETORAN", Anda dapat mentransfer Ethereum Anda dari dompet /tempat penukaran eksternal Anda ke WREX Wallet ini.',
        def3: 'PENTING: Setiap Ethereum yang tersisa di WREX My ETH Wallet ini tidak akan mendapat % Laba Bulanan. Untuk menjadi bagian dari rencana pembagian keuntungan bulanan, Ethereum Anda harus ditempatkan di [WREX Account] atau [100 ETH Plan]. Jadi, setelah Anda memiliki Ethereum di dompet ini, silahkan buka halaman "ASET" untuk mendaftar di produk kami.',
        externaladdress: 'Alamat',
        datetime: 'Tanggal/Jam',
        action: 'Action',
        unknown: 'External ETH Wallet',



        // MyEthWithdraw.js
        mew1: 'PENARIKAN KE EXTERNAL WALLET',
        amount: 'Jumlah',
        withdrawto: 'Penarikan ke',
        selectaddress: 'Pilih Alamat',
        networkfee: 'Biaya Jaringan',
        youwillget: 'Anda akan mendapat',
        withdrawnotices: 'Pembeitahuan Penarikan',
        mew2: 'Silahkan periksa apakah jumlah Ethereum yang Anda tarik sudah masuk [My ETH Wallet]',
        mew3: 'Masukkan jumlah penarikan yang diinginkan di “Jumlah”.',
        mew4: 'Klik pada “Pilih Alamat” dan pilih Alamat Ethereum yang Anda tuju di “Penarikan ke”.\nPENTING: Untuk keamanan dan kenyamanan, di WREX Anda harus melakukan pra-registrasi alamat Ethereum eksternal Anda. Jika "Pilih Alamat" tidak menunjukkan alamat atau alamat tujuan yang Anda inginkan tidak ditemukan dalam daftar, silahkan buka halaman "ACCOUNT" - Manajemen Alamat, dan klik "TAMBAH BARU" untuk menambahkan alamat baru.\nHarap diperhatikan, juga untuk alasan keamanan, alamat yang baru ditambahkan akan muncul hanya setelah 24 jam.',
        mew5: 'Setelah Anda berhasil menyelesaikan permintaan penarikan Anda, permintaan penarikan Anda akan dieksekusi dalam waktu 24 jam karena WREX menyimpan semua Ethereum di cold storage untuk alasan keamanan.',
        mew6: 'Untuk penarikan, Biaya Jaringan Ethereum akan dibebankan dari jumlah total penarikan Anda.',

        // MyEthTransfer.js
        met1: 'Transfer ke pengguna internal',
        transfer: "Tranfer",
        transferto: 'Transfer Ke',
        whitelist: 'Pilih Whitelist',
        enteraddressholder: 'Alamat deposit pengguna',
        transfernotices: 'Pemberitahuan Transfer Internal',
        met2: 'Mohon periksa apakah jumlah Ethereum yang Anda inginkan untuk ditransfer ada di [My ETH Wallet]',
        internaluser: 'Pengguna Internal',
        external: 'EKSTERNAL',
        internal: 'Internal',
        transferB: "Transfer",
        withdrawB: "Penarikan",

        // WrexTransfer.js
        wrextransfer: 'Wrex',
        wt1: 'Transfer ke Akun Wrex',
        transferWrexAccountHolder: 'Akun Wrex (Alamat email)',
        wrextransfernotices: 'Pemberitahuan Transfer Akun Wrex',
        wt2: 'Mohon periksa jumlah akun Wrex yang Anda inginkan untuk ditransfer',
        invalid_wrex_transfer_amount: 'Invalid withdrawal amount, please refer the manual withdrawal polices',

        // Google2fa11c.js
        transfersuccess: 'Transfer Berhasil',


        // MyEthWithdrawRequest.js 
        mewr1: 'Permintaan Anda sudah diterima dan sedang diproses!',

        // profile.js
        myinfo: 'Info Saya',
        userlevel: 'Level Pengguna',
        usertype: 'Tipe Pengguna',
        nationality: 'Kebangsaan',

        // profilepassword.js
        oldpassword: 'Password Lama',

        // profileAddressNew.js
        addnewaddress: 'Alamat Baru',
        pan1: 'Hingga 40 karakter.',
        add: 'TAMBAH',


        // profileAddress.js
        editaddress: 'Ubah Label',


        // Register.js
        selectone: 'Pilih Satu',
        signup: 'Sign Up',
        registerTermsCheck: "Dengan melanjutkan, saya menyetujui persyaratan dan kebijakan privasi dan menyatakan bahwa saya berusia 18+. Check out ",
        termsofuse: 'Syarat Penggunaan',
        next: 'Lanjut',
        alreadyhaveaaccount: 'Sudah memiliki Akun?',
        passwordvalidate: 'Setidaknya 8 karakter. Harus mengandung setidaknya satu huruf kecil, satu huruf besar, satu angka.',
        back: 'Kembali',
        congrates: 'Selamat',
        thankyouregister: 'Terima kasih telah mendaftar bersama kami! Silahkan ikuti instruksi untuk mengaktifkan akun Anda melalui email verifikasi yang baru saja kami kirimkan kepada Anda.',

        // RegisterVerify.js
        rv1: 'Silahkan lengkapi verifikasi email terlebih dahulu. Anda tidak akan dapat melakukan apapun sampai akun Anda diaktifkan.',
        rv2: 'Belum mendapat email verifikasi?',
        resetemail: 'Kirim ulang email',

        // EmailConfirmed.js 
        emailconfirmed: 'Email telah Dikonfirmasi',
        emailconfirmationfail: 'Konfirmasi Email Gagal',
        eec1: 'Permintaan Anda telah dikonfirmasi',
        eec2: 'Link konfirmasi salah',
        eec3: 'Pengaturan 2FA Anda telah dinonaktifkan',

        // Support.js
        monthlyreports: 'Laporan Bulanan',
        month: 'Bulan',
        monthlyprofit: 'Laba Bulanan',
        viewreport: 'Lihat Laporan',
        s1: 'Untuk pertanyaan apapun, silahkan periksa FAQ terlebih dahulu dan jika FAQ tidak membantu menjawab pertanyaan Anda, silahkan hubungi kami',
        s2: 'Transparansi\n Baker Tilly, salah satu dari sepuluh kantor Kantor Akuntan dan Audit utama di dunia, memverifikasi semua perhitungan dengan mengunjungi kantor kami antara hari pertama hingga hari kelima setiap bulan.\n Mereka memverifikasi (1) semua perdagangan yang terjadi bulan sebelumnya (2) semua status akun Ethereum, dan berdasarkan pada dua verifikasi itu mereka akan menghasilkan persentase keuntungan bulanan.\n Laporan akan selesai pada hari ke 8 pada bulan itu dan diunggah untuk dilihat oleh anggota kami.\n Semua distribusi keuntungan hanya akan dieksekusi pada hari ke 10 setiap bulan berdasarkan persentase laba bulanan yang disediakan oleh laporan bulanan.',

        // WrexAccount.js
        auto_withdrawal: 'Auto Withdrawal',
        auto_reinvest: 'Auto Reinvest',
        performance: 'Performance',
        profitshare: 'Pembagian Laba',
        affiliatebonus: 'Bonus Afiliasi',
        wrex: 'Akun WREX',
        setting: 'Pengaturan',
        aboutwrexaccount: 'Tentang Akun WREX',
        wa1: 'Jika [Auto Withdrawal] dipilih, semua laba bulanan yang dihasilkan akan masuk ke [My ETH Account] setiap tanggal 10 pada bulan berikutnya.',
        wa2: 'Jika [Auto Reinvest] dipilih, semua laba bulanan yang dihasilkan akan masuk ke [WREX Account] dan diinvestasikan kembali ke dalam sistem.',
        wa3: 'Semua bonus afiliasi ([AB]) setiap bulan dianggap sebagai Laba Bulanan, jadi jika pelanggan memilih [Auto Withdrawal], AB akan pergi ke [My ETH Account].',
        wa4: 'Jika pelanggan memilih [Auto Reinvest], bonus afiliasi masuk ke [WREX Account] dan ditambahkan ke modal dasar Anda sebelumnya.',
        wa5: 'Penarikan Otomatis/Auto Withdrawal terjadi setiap tanggal 10 pada bulan berikutnya dari [WREX Account] dan memiliki biaya penarikan 0,5%.',
        wa6: 'Penarikan Manual akan dilakukan dalam minimal 72 jam hingga maksimal 4 minggu setelah permintaan Anda dari [WREX Account] dan memiliki biaya penarikan 1,0%.',


        // WrexDeposit.js
        availablebalance: 'Saldo Tersedia',
        deposit: 'SETORAN',
        wrexdepositpolices: 'KEBIJAKAN SETORAN WREX',
        wd1: 'Untuk membuat dan mengaktifkan Akun WREX, silahkan masukkan "1 Ethereum" yang ingin Anda tempatkan di Akun WREX.',
        wd2: 'Silakan pilih antara [Auto Reinvest] atau [Auto Withdrawal]. Anda dapat mengubah pengaturan ini nanti, bahkan setelah WREX dibuat. \nFormula: [# of Ethereum in WREX Account] x Laba Bulanan% x 45% = Akun WREX * Laba Bulanan * \nJika Anda memilih [Auto Reinvest], * Laba Bulanan * ditambahkan ke modal dasar Anda secara otomatis setiap bulan. [# of Ethereum in WREX Account] akan bertambah setiap bulan. \nJika Anda memilih [Auto Withdrawal], * Laba Bulanan * akan ditransfer secara otomatis ke [My ETH Wallet] Anda pada hari ke 10 pada bulan berikutnya. [# of Ethereum in WREX Account] tidak akan bertambah setiap bulan dan tidak akan berubah.',
        wd3: 'Apa pun pilihan Anda, Anda juga dapat melakukan penarikan manual kapan saja.',
        wd4: 'Semua Bonus Afiliasi Akun WREX Anda akan dianggap sebagai Laba Bulanan, jadi jika Anda memilih [Auto Reinvest], Bonus Afiliasi Akun WREX Anda akan ditambahkan ke modal dasar Anda. Jika [Auto Withdrawal] dipilih, maka Bonus Afiliasi Akun WREX akan ditransfer di [My ETH Wallet] pada hari ke 10 pada bulan berikutnya.',

        // WrexDepositCreate.js 
        ethmin: '1 Ethereum',
        autoaccumulation: 'Auto Reinvest',
        autowithdrawal: 'Auto Withdrawal',
        wrex_create: 'BUAT',

        // WrexReturnSetting.js 
        selectmode: 'Pilih Mode',
        resetrules: 'Pengaturan Ulang',
        wrs1: 'Pengaturan ulang berlaku mulai hari pertama bulan berikutnya.',
        wrs2: 'Harap dicatat: WREX memungut biaya penarikan 0,5% untuk mode Auto Withdrawal.',

        // WrexWithdraw.js
        sendtomyethwallet: 'Kirim ke My ETH Wallet',
        request: 'Permintaan',
        transferfee: 'Biaya Transaksi',
        manualwithdrawpolices: 'Kebijakan Penarikan Manual',
        ww1: 'Harap menunggu minimum 72 jam hingga maksimum 4 minggu sejak eksekusi hingga aktual penarikan * Silahkan lihat FAQ mengapa diperlukan hingga maksimum 4 minggu.',
        ww2: 'Biaya penarikan 1,0% dikurangi dari jumlah yang dipilih untuk ditarik.',
        ww3: 'Harus memiliki 1 Ethereum yang dipertahankan di [WREX Account] setelah permintaan penarikan.',
        ww4: 'Atau Anda mungkin ingin menarik semua saldo terutang. Nol Ethereum di [WREX Account] akan memberhentikan akun Anda dan semua afiliasi akan hilang dan tidak dapat dipulihkan.',

        wwr1: 'Kami sedang memproses permintaan Anda sekarang, Anda dapat melihat jumlah yang diminta di dompet Anda dalam minimal 72 jam hingga maksimal 4 minggu.',

        emailAddressPlaceHolder: 'Alamat Email',
        passwordPlaceHolder: 'Password',
        confirmPasswordPlaceHolder: 'Konfirmasi Password*',
        referralCodePlaceHolder: 'Kode Referal',
        addressPlaceHolder: 'Alamat',
        labelPlaceHolder: 'Label',

        err_emailFormatError: 'Alamat email salah.',
        err_emailEmpty: 'Silahkan masukkan alamat email.',
        err_passwordEmpty: 'Silahkan masukkan password.',
        err_oldPasswordEmpty: 'Silahkan masukkan password lama.',
        err_newPasswordEmpty: 'Silahkan masukkan password baru.',
        err_confirmPasswordEmpty: 'Silahkan masukkan konfirmasi password.',
        err_passwordMissmatch: 'Password tidak cocok',
        err_referralCodeEmpty: 'Silahkan masukkan kode referal.',
        err_countryCodeEmpty: 'Silahkan pilih negara.',
        err_addressEmpty: 'Silahkan masukkan alamat.',
        err_labelEmpty: 'Silahkan masukkan label.',
        err_addressInvalid: 'Ini bukan alamat yang benar. Silahkan periksa kembali.',
        err_overWrexValue: 'Lebih dari Nilai WREX.',
        err_lessThenNetworkFee: 'Kurang dari biaya jaringan.',
        err_password_too_simple: 'password terlalu sederhana',
        err_name_too_long: 'nama terlalu panjang',


        // API Error Messages
        two_fa_not_enabled: '2FA tidak diaktifkan',
        login_required: 'login terlebih dahulu',
        invalid_old_password: 'password lama salah',
        address_duplicated: 'alamat duplikat',
        address_not_specified: 'alamat tidak ditentukan',
        already_confirmed: 'sudah terkonfirmasi',
        already_enabled: 'sudah aktif',
        duplicated_email: 'email duplikat',
        email_duplicated: 'Email ini sudah terdaftar',
        email_not_confirmed: 'email tidak dikonfirmasi',
        incorrect_device: 'Perangkat salah',
        insufficient_amount: 'Dana tidak mencukupi',
        invalid_address: 'alamat salah',
        invalid_affilate_code: 'kode afiliasi salah',
        invalid_email_or_password: 'email atau password salah',
        invalid_email: 'invalid email',
        invalid_otp: 'kode salah',
        invalid_reinvest_value: 'nilai reinvest salah',
        invalid_reset_link: 'link reset salah',
        invalid_subscription_id: 'ID berlangganan salah',
        left_amount_less_than_1eth: 'jumlah tersisa kurang dari 1 eth',
        missing_address: 'alamat tidak ada',
        missing_parameter: 'parameter tidak ada',
        nothing_to_update: 'tidak ada yang diperbarui',
        amount_larger_than_one_eth: 'jumlah lebih dari satu eth',
        should_accept_penalty: 'akan menerima pinalti',
        reinvest_not_specified: 'reinvest tidak ditentukan',
        subscription_not_active: 'berlangganan tidak aktif',
        too_many_codes: 'terlalu banyak kode',

        not_updatable: 'Anda tidak diizinkan mengubah pengaturan!', // 新增的
        amount_not_specified: 'Jumlah tidak ditentukan', // 新增的
        invalid_wrex_withdraw_amount: 'Jumlah penarikan salah, silahkan lihat kebijakan penarikan manual',
        invalid_amount: 'Jumlah penarikan salah, silahkan lihat kebijakan penarikan manual di bawah ini',
        invalid_100eth_create_amount: 'Invalid transfer amount, please refer the manual transfer polices',
        amount_less_than_one_eth: 'Untuk membuat akun WREX, Anda harus menyetor 1 ETH',
        not_withdrawable: 'tidak dapat ditarik',
        not_terminatable: 'tidak dapat dihentikan',


        unknown_error: 'Unknown Error',

        error: 'Error',

        confirmedLogoutDesc: 'Konfirmasi untuk logout?',
        cancel: 'BATAL',

        ep100_plan_still_active: 'Anda harus menghentikan semua 100 ETH Plan sebelum menarik Akun WREX dan menutupnya.',
        wrex_not_created: 'Anda harus mengaktifkan dan memelihara Akun WREX terlebih dahulu untuk membuat 100 ETH Plan.',

        // 2019.09.05 
        whytrust: 'Kenapa ANDA bisa PERCAYA WREX?',

        notice_title: 'Pengumuman untuk Agustus',
        notice_message: 'Kinerja bulan Juli kami tercatat sebesar 3.71% untuk laba bulanan. Sejak COVID19, kami telah mencatat "digit ganda" yang jauh di bawah yang diharapkan untuk laba bulanan disebabkan karena jumlah penarikan yang besar. Sejak April, beberapa anggota telah menarik ETH yang besar, baik modal dasar maupun laba mereka. Kami menduga karena COVID19, pendapatan bulanan mereka berkurang dan dengan menguangkan investasi WREX untuk mendukung biaya hidup adalah salah satu alasan utama penarikan tersebut.',
        notice_date: 'Aug. 20th, 2020',
        notice_footer1: 'Read more...',
        notice_support: 'SUPPORT',
        notice_footer2: '" untuk berita dan informasi terbaru.'


    }
}
