import React, { Component } from 'react';

/**
 * Renders the preloader
 */
class NewsKR extends Component {

	render() {
		return (
			<div>
				<div className="f_single fx_single_tbl">
					<div className="tabset">
						<label htmlFor="tab2">
							<span className="mdc-tab__content fx_titlefont">
								<span className="mdc-tab__text-label">Announcements & News</span>
							</span>
						</label>
					</div>
				</div>

				<div className='swanky_wrapper faq'>

				<input id='nr16' name='radio' type='checkbox' />
					<label htmlFor='nr16'>
						<span className="th_faq">8월 공지사항, 2020-08-20 </span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								WREX 회원 여러분!
								</p>

								<ol>
									<li>
									2020년 7월 수익률은 회계법인을 통해 3.71%로 확정되었고, 이미 10일에 반영되었습니다. 코로나 사태를 기점으로 WREX의 수익률이 저조한 것을 의아해하고 계시리라 생각되어, 저희 운영팀에서는 어떻게 하면 수익률을 만족할 만한 수준으로 끌어 올리고, 그리고 안정적으로 운영할 수 있는지에 대해 고민을 하였고 다음과 같이 공지 드립니다.
									<br />
									문제점: 월 수익률 저조<br/>
									이유: 코로나 사태로 인한 신규 입금 보다 인출이 많아 지면서 중장기 투자 플랫폼인 WREX에 수익률을 지속적으로 악화시키고 있음<br/>
									또한, 차후 다른 외부 요인으로 인한 다량의 이더리움 출금으로 이어질 경우, 또 수익률에 영향을 끼칠 것으로 판단되어, 해결책으로 신규 가입 회원들의 약관을 아래와 같이 수정하여 시행할 계획입니다. 
									<br/>
									<table className="simpeltable">
											<tbody>
												<tr>
													<td colspan="2" scope="Ranking">코로나 발생 이전</td>
													<td colspan="2" scope="Prize">코로나 발생 이후</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 9월</td>
													<td data-label="Prize">16.52%</td>
													<td data-label="Ranking">2020년 2월</td>
													<td data-label="Prize">3.94%</td>	
												</tr>
												<tr>
													<td data-label="Ranking">2019년 10월</td>
													<td data-label="Prize">11.72%</td>
													<td data-label="Ranking">2020년 3월</td>
													<td data-label="Prize">4.71%</td>	
												</tr>
												<tr>
													<td data-label="Ranking">2019년 11월</td>
													<td data-label="Prize">10.07%</td>
													<td data-label="Ranking">2020년 4월</td>
													<td data-label="Prize">3.31%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 12월</td>
													<td data-label="Prize">6.80%</td>
													<td data-label="Ranking">2020년 5월</td>
													<td data-label="Prize">3.48%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Jan 2020</td>
													<td data-label="Prize">9.92</td>
													<td data-label="Ranking">2020년 6월</td>
													<td data-label="Prize">5.29%</td>
												</tr>
												<tr>
													<td data-label="Ranking"></td>
													<td data-label="Prize"></td>
													<td data-label="Ranking">2020년 7월</td>
													<td data-label="Prize">3.71%</td>
												</tr>
											</tbody>
										</table><br/>
									</li>
									<li>
									저희 플랫폼은 중·장기적인 계획을 가지고 운영하여 매월 수익을 창출하여 [수익]을 배분하는 목적을 가지고 있습니다. 절대, 어떠한 경우도, 다른 회사들과 달리, 투자금에 일부를 투자유치 성과금 또는 수당으로 배분하지 않음을 다시 한번 말씀드립니다. 투자금이 원금이 되어, 운영하고 되고, 운영으로 인한 수익을 배분하여, 다음 달 배분된 수익이 포함된 총 이더리움 수량이 다시 ‘원금’이 되어 운영되는 시스템입니다. 
									<br />
									저희가 매월 수익을 창출한다면, 저희는 매달 성장하는 시스템입니다. 지난 40개월을 운영하면서 단 한번도 마이너스 수익을 기록한 적이 없습니다. 그리고 매월 세계 10대 회계법인을 통해 감사를 받아 저희 사업이 안정적으로 진행되는지를 확인하실 수 있습니다.
									<br/><br/>
									</li>
									<li>
									저희는 중·장기적인 투자의 관점을 두고 운영을 하는 회사이기에, 급작스럽게 많은 량의 투자금이 인출되면, 인출되는 것에 대해 문제가 없으나, 기존 포트폴리오의 수익률에 영향을 주고 있으며, 남은 투자가들이 그 피해를 고스란히 떠안고 있는 실정입니다.
									<br /><br/>
									이에 저희는 급작스러운 출금을 막기 위해 기존 [72시간 출금]에서 [최대 한달]이 걸릴 수 있다고 과거 공지를 통해 고지하고 약관을 수정했으며,
									<br/>
									코로나 사태로 인해 또는 향후 있을 수 있는 집단 인출에 기존의 포트폴리오와 회원들의 수익을 보호하고자 추가적인 약관 수정을 안내해 드리겠습니다.
									<br/><br/>
									</li>
									<li>
									수정될 내용은 다음과 같으나, 기존의 회원들에게는 적용되지 않으며, 2020년 8월 1일부로 개설된 회원 또는 상품에만 적용이 됩니다.
									<br />
									<ol>
									<li>
									Wrex 계정 
									<table className="simpeltable">
											<tbody>
												<tr>
													<td className="emphsize boldfont" scope="Ranking">수정 전</td>
													<td className="emphsize boldfont" scope="Ranking">수정 후</td>
												</tr>	
												<tr>
													<td scope="Ranking">WREX 계정에 최소 1 이더리움 투자</td>
													<td scope="Ranking">WREX 계정은 이더리움 오직 1개만 투자 가능</td>
												</tr>	
										</tbody>
									</table>
									** 중요: 기존 회원들의 계정 역시, 9월 1일부로 WREX 계정에 신규 이더리움을 추가 입금하실 수 없습니다. 
									</li>
									<li>
									100 이더리움 플랜
									<table className="simpeltable">
											<tbody>
												<tr>
													<td className="emphsize boldfont" scope="Ranking">수정 전</td>
													<td className="emphsize boldfont" scope="Ranking">수정 후 (2020년 8월 1일부)</td>
												</tr>	
												<tr>
													<td scope="Ranking">100 이더리움 플랜은 오직 1개의 이더리움만 투자 가능. 생성 후 추가 입금은 불가능</td>
													<td scope="Ranking">100 이더리움 플랜 생성시 최소 1개에서 최대 100개까지 투자 가능하나, 생성후 추가 입금은 불가능</td>
												</tr>	
												<tr>
													<td scope="Ranking">40개월 만기</td>
													<td scope="Ranking">24개월 만기</td>
												</tr>	
												<tr>
													<td scope="Ranking">조기 해지 패널티 없음</td>
													<td scope="Ranking">조기 해지 패널티 있음<br/> (패널티: 회원이 받는 수익의 75%) </td>
												</tr>	
										</tbody>
									</table>
									</li>
									</ol>
									<br/>
									앞으로도 좋은 수익률로 찾아 뵐 수 있도록 항상 노력하는 회사가 되겠습니다.
									</li>
								</ol>
								<br/>
								감사합니다.
								<br />
								<br />
								WREX 경영진 올림<br />
							</li></ul>
						</div>
					</label>

					<input id='nr15' name='radio' type='checkbox' />
					<label htmlFor='nr15'>
						<span className="th_faq">2020년 6월 10일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Wrex 회원 여러분,
								</p>

								<ul>
									<li>
										드디어 금주 월요일부터 지난 3개월간 코로나 바이러스 확산 방지로 인한 사회적 거리두기 및 이동금지령이 해제된 후 진행되고 있습니다. 오늘 중으로 끝날 것으로 예상했으나, 하루 정도 지연될 예정이어서 이렇게 공지사항을 통해, 5월 수익 배분이 11일경에 집행될 것임을 알려드립니다.
									<br />
									</li>
									<li>
										저희 WREX는 공정하고 투명한 운영을 위해 매달 외부 회계법인의 감사를 진행하고 있으며, 이 과정에서 (1) 입출금 계정에 대한 감사 (2) 거래 내역 확인 및 감사 (3) 매달 수익률 확정 의 절차를 진행하고 있습니다. 코로나 사태로 인해 못한 감사 및 감사보고서는 11일 경 5월 수익 배분과 함께 사이트 내의 지원 페이지에 업로드 될 예정입니다.
									<br />
									</li>
								</ul>
								하루 지연 예정에 대해 송구스럽게 생각합니다. 아울러 회원 여러분의 많은 양해를 구합니다.
								<br />
								감사합니다.
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr14' name='radio' type='checkbox' />
					<label htmlFor='nr14'>
						<span className="th_faq">5월 공지사항, 2020-05-11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									W-Rex 회원님들께,
								</p>

								<ul>
									<li>
										항상 W-REX 팀은 회원님과 회원님의 가족들의 건강과 행복을 기원드립니다. 지난달 공지에도 현재 COVID19 (코로나)의 여파로 인해 3월 수익률에 대해 회계 감사를 못 받고 있다고 전달드렸고,, 이번 달 역시 회계감사 없이 4월 수익률을 공지를 올리게 되었습니다. 그나마 다행인 것은 본사가 위치한 말레이시아에 “이동금지령”이 다소 완화되었고, 앞으로도 점차 적으로 완화 조치가 확대될 것으로 기대되고 있습니다. 그렇다고 이동의 자유가 100% 해소된 것은 아니지만, 이 정도 분위기 라면 6월에는 이동금지령이 완전히 철회되고 예전처럼 돌아갈 것으로 기대하고 있습니다.
									<br />
									</li>
									<li>
										현재 저희는 회계법인과 함께 3월과 4월 회계 감사를 진행하고 보고서가 나오는 대로 따로 공지와 함께 홈페이지에 개제할 것을 약속드립니다.
									<br />
									</li>
									<li>
										4월의 월 수익률은 3.31%를 기록했습니다. 이는 저희가 목표로 하고 있는 월평균 수익률 15%에 못 미치는 수치로 코로나 이전에 평균 10%를 웃돌던 평균이 코로나 사태 이후 3~5%대를 기록하며 전체적으로 부진한 실적으로 나타나고 있습니다. 이는 코로나 사태가 저희 투자팀 업무에 직 간접적 영향을 주고 있는 점과 둔화된 시장 환경에도 이유가 있는 것으로 파악되고 있습니다. 이는 코로나 사태를 극복하고, 이동 제한이 해제되어, 정상적인 일상 업무 복귀가 가능한 시점과 시장의 재활성화를 통해 만회할 수 있도록 최선을 다하겠습니다.
									<br />
									</li>
									<li>
										지금까지 보여주신 응원과 성원을 앞으로 몇 달간 지켜봐 주시기를 부탁드립니다.
									<br />
									** 이번 주 목요일에 저희가 준비하고 있는 WOTA 코인에 대한 전체적인 공지를 할 예정입니다.
									</li>
								</ul>
								감사합니다.
								<br />
								<br />
								WREX 팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr13' name='radio' type='checkbox' />
					<label htmlFor='nr13'>
						<span className="th_faq">긴급 공지 사항, 2020-04-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								</p>

								<ol>
									<li>
										코로나19 사태에 항상 건강하시길 기원 드립니다.
									<br />
									</li>
									<li>
										지난 번 공지에서 말씀드린 바와 같이, 이번 달은 회계법인의 회사 방문 및 감사 없이 일단 내부 수익률을 계산해서 금일 23시59분 전에 반영할 예정이며, 본사의 국가 비상시국 “이동 금지령”이 해제되면 감사를 통해 감사보고서를 올리도록 하겠습니다.
										<br />
									</li>
									<li>
										금일 반영되는 3월 수익률은 4.71%입니다.
									<br />
									지난 달과 같이 저희 기준에서는 수익률이 저조한 편입니다. 이는 실제 코로나19가 시작한 후부터 직원들의 위축 그리고 지금의 이동금지령까지 이어지면서 효율적인 거래가 이루어지기 힘들었던 부분도 있었음을 말씀드립니다.
									이 점은 점차 개선되고 있으며, 4월 수익율은 훨씬 좋은 성적으로 찾아 뵐 수 있도록 노력하겠습니다.
									<br />
									참고로, 2월과 3월에 저희 수익률을 제외하고 타 시장 (증권, 금, 석유 등 거의 모든 상품 시장)은 막대한 마이너스 성장을 기록하며 제자리 걸음 또는 손실을 초래했습니다. 저희 자산은 그나마 매달 5%내외의 성장율을 기록하고 있다는 점을 알려드립니다.
									<br />
									</li>
								</ol>
								감사합니다.
								Best Regards,
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr12' name='radio' type='checkbox' />
					<label htmlFor='nr12'>
						<span className="th_faq">4월 공지사항, 2020-04-06</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원님께,
								</p>
								<br />
								안녕하십니까? WREX 회원님
								<ol>
									<li>
										코로나바이러스로 인하여 세계 여러 지역으로 확산되고 있는 지금 현재 말레이시아는 신종 코로나바이러스 확산 방지를 위해 엄격한 이동제한 명령이 내려졌습니다.
										[소방, 경찰, 군, 보건 관련 공무원 외 전부 자택근무, 모든 사기업 자택근무, 병원, 약국, 슈퍼마켓을 제외한 모든 소상공인 매장 폐쇄 등]
									<br />

									</li>
									<li>
										이번 이동 제한 조치로 인해 (1) 직접 계정 감사를 위해 저희 사무실 내방이 필수인 Baker Tilly의 회계 감사 결과 보고서 및 월 수익률 산정이 지연될 예정이며,
										(2) 수익률은 내부 산정치로 먼저 10일 반영하여 배분하고, 추후 이동제한 조치가 해제되어 회계 감사가 실행되면 그때 보고서를 기준으로 조정이 필요하면 조치하도록 하겠습니다.
									<br />
									코로나바이러스(코로나19) 때문에 걱정이 많아지는 요즘 비록 힘들고 어려운 시기이지만 이러한 위기들도 철저한 예방과 지침으로 잘 이겨낼 수 있을 것입니다.
									<br />
									</li>
									<li>
										코로나바이러스(코로나19) 때문에 걱정이 많아지는 요즘 비록 힘들고 어려운 시기이지만 이러한 위기들도 철저한 예방과 지침으로 잘 이겨낼 수 있을 것입니다.
									<br />
									감사합니다.
									<br />
									아래 링크는 말레이시아 이동제한 명령에 대한 기사입니다.
									<br />
										<a href="https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown">
											https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown</a>
										<br />
									</li>
								</ol>
								<br />
								W-REX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr11' name='radio' type='checkbox' />
					<label htmlFor='nr11'>
						<span className="th_faq">3월 공지사항, 2020-03-17</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원님께,
								</p>
								<ol>
									<li>
										2019년 9월 1일로 시작한 저희 WREX 서비스는 2020년 2월에 월 수익률 3.94%를 기록하였습니다. 이는 지금까지의 월수익을 비교하면 최저치입니다.
										<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">WREX 기간</td>
													<td scope="Prize">월 수익률 </td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 9월</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 10월</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 11월</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 12월</td>
													<td data-label="Prize">6.80%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2020년 1월</td>
													<td data-label="Prize">9.92</td>
												</tr>
												<tr>
													<td data-label="Ranking">2020년 2월</td>
													<td data-label="Prize">3.94%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											평균적으로는 6개월 동안, 매달 9.828%를 기록했습니다.
										</p>
									</li>
									<li>
										위 월평균 수익률 수치는 회원님께서 1 이더리움을 WREX 계정에 적립하여 48개월 (4년)동안 유지하시면, 7.6이더리움으로 증가되는 수치입니다. (누적수익률 760%)
										<br />
										100이더리움 계정에 적립하여 48개월 (4년)을 유지하시면 2,100%의 누적 수익률입니다.
										<br />
										다른 그 어떤 투자와 비교할 수 없는 수치입니다.
										<br />
									</li>
									<li>
										비록 2월 한달 저희 기대 수익률에 못 미치는 수치를 기록했지만, 앞으로 좀 더 향상된 수익률로 찾아 뵐 수 있도록 노력하겠습니다.
									<br />
									</li>
								</ol>
								감사합니다. <br />
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr10' name='radio' type='checkbox' />
					<label htmlFor='nr10'>
						<span className="th_faq">2월 공지사항, 2020-02-12</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										안녕하십니까 WREX 회원님!<br />
									</p>
									<ol>
										<li>
											Baker Tilly의 회계 감사 결과 1월 월수익률은 9.92%로 기록되었습니다. 금일 Baker Tilly는 월 수익률 보고에 대한 발표가 늦어진 것에 대해 사과를 했고 공식 보고서는 Baker Tilly로부터 보고를 받는 대로 게재될 것 입니다.
										<br /><br />
										</li>
										<li>
											2월 첫째 주에 홍콩 신탁 기금의 첫 번째 자금이 집행되었습니다. 이제 우리는 귀중한 회원님의 자금 뿐만 아니라 홍콩에 기반을 둔 신탁 기금의 자금도 관리하게 되었습니다. 홍콩 신탁 기금은 주 단위로 최대 1억 달러 상당의 이더리움으로 입금될 예정입니다.
										<br /><br />
										</li>
										<li>
											변경된 정책 발표입니다. WREX가 출시 된 이래로 WREX는 단 6번의 출금 요청만 있었습니다. 변경 전 출금은 모두 72 시간 내에 처리되었습니다. 그러나 우리는 거래 부서로부터 이러한 출금으로 인해 월별 실적이 떨어질 수 있다는 우려를 받았으며,
											이러한 출금이 전체 월별 실적에 부정적인 영향을 미치지 않도록 하고자 합니다. 따라서 내부적으로 출금 정책을 논의하고 변경을 진행하도록 할 것입니다.
										<br />
										</li>
									</ol>
									<br />변경 전: WREX 상품 또는 100ETH 플랜 상품에서 나의 이더리움 지갑으로의 출금 요청은 최대 72 시간이 소요될 수 있습니다.<br />
									<br />변경 후: WREX 상품 또는 100ETH 플랜 상품에서 나의 이더리움 지갑으로의 출금 요청은 최대 4주가 소요될 수 있습니다.<br />
									<br />그러나 회원님의 출금액이 50이더리움 미만인 경우 72시간 내에 출금이 가능하다는 것을 강조하고 싶습니다. 단일 출금 요청으로 50이더리움 이상일 경우 또는 일주일에 100이더리움 이상일 경우,
									최대 4주가 소요될 수 있습니다. 보통은 1주일 내에 출금 준비가 되어야 하지만, 솔직히 말해서 2월 첫 2주 같은 경우에는 갑작스러운 출금 요청으로 인한 월별 수익률에 부정적인 영향이 있었습니다.
									따라서 출금 정책 변경을 통해 WREX의 모든 회원님의 손해를 최소화하고 수익을 극대화하려고 하는 방향입니다.
									<br />
									<br />출금 요청 시 1일 - 7일 사이 업데이트되는 출금 요청 정보를 받아 고객 지원팀에서 이메일을 통해 연락을 드릴 것입니다. 신뢰는 회원님과 WREX 사이의 가장 중요한 가치입니다.
									<br />
									<br />감사합니다.<br />
									<br />
									WREX 운영팀
									<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr9' name='radio' type='checkbox' />
					<label htmlFor='nr9'>
						<span className="th_faq">공지사항, 2020-02-11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										안녕하십니까? WREX 회원님 <br />
									</p>
									회계감사 담당자의 개인 사정상 감사 보고서 및 월 수익률 산정이 지연되고 있습니다. 월 수익률은 내일쯤 따로 공지 예정이며 보고서는 몇일 지연되어 업로드 될 예정입니다.
									다시 한번 불편을 끼쳐드려 죄송합니다.

									<br />
									W-REX 운영팀<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr8' name='radio' type='checkbox' />
					<label htmlFor='nr8'>
						<span className="th_faq">2020년 1월 WREX 공지사항, 2020-01-15</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									2019년을 뒤로하고 2020년 향한 첫 발걸음을 저희 W-REX와 함께!<br /><br />
									먼저 저희 W-REX 팀원들을 대신하여 모든 회원님들께 새해 인사 올립니다. 지난 2019년도는 저희에게 크나큰 도약의 해였습니다. 9월 1일에 프리런칭을 성공적으로 시행했으며, 오늘까지 오면서 작고 큰 고난과 어려움이 있었으나 회사의 발전과 회원님들의 성공을 생각하며 힘차게 매진하여 왔습니다.  <br />
									2020년은 정말 저희 W-REX와 회원 여러분들께는 잊을 수 없는 한 해로 만들도록 하겠습니다. 일단, 2월 첫째주부터 홍콩의 신탁펀드로부터 자금을 위탁 받아 운영할 예정이며, 앞으로도 많은 투자회사들과 마주 앉아 자금 운영에 대해 적극적으로 영업을 할 예정입니다. 저희는 앞으로 투입될 풍요한 자금력을 바탕으로 한 회사 계획이 회원님들께도 이득이 돌아갈 수 있도록 노력할 것입니다. <br />
								</p>
								<ol>
									<li>
										W-REX 투자 수익률, <br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">월</td>
													<td scope="Prize">월 수익률 % </td>
												</tr>
												<tr>
													<td data-label="Ranking">9월</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">10월</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">11월</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">12월</td>
													<td data-label="Prize">6.80%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											위 수익률에 의하면, 회원님께서 [WREX 계정]에 1 이더리움을 9월 1일부로 투자하셨다면, 현재 약 1.182252 이더리움으로 증액되어 18%의 최종 수익을 얻으셨고;<br />
											[100 이더리움 플랜]에 1 이더리움을 9월 1일부로 투자하셨다면, 현재 약 1.384929 이더리움으로 증액되어 38%의 수익을 얻으셨습니다.<br />
										</p>
									</li>
									<li>
										편리한 모바일 앱 출시<br />
										구글 플레이 스토어(안드로이드)나 앱 스토어(애플 아이폰)에 가셔서 WREX로 검색하시면 모바일 앱을 다운로드 받으실 수 있습니다.<br />
									</li>
									<li>
										WOTA 코인 출시 예정!<br />
										저희 WOTA/WREX는 올해에 75만개의 이더리움을 운영할 예정이며, 막대한 자금력과 수익률을 기반으로 WOTA 코인을 출시하고, 바로 세계 3대 거래소에 상장할 예정입니다.<br />
										저희 WREX 회원님들께만, 세계 3대 거래소 상장이 확정되고 상장 직전에 프리세일 기회를 드릴 예정입니다. 이에 앞서 WREX에 WOTA 코인 지갑 서비스를 시작할 예정이며, 동시에 현재 이더리움으로만 제공하던 저희 투자 상품을 WOTA 코인으로도 확대하고, 신규 플랜 (예. 369 플랜 등)을 통해 보다 파격적인 상품들을 기존 WREX 회원님들에게만 제고할 예정입니다. <br />
										동 프로모션에 대해서는 앞으로 공지를 통해 추가 안내하도록 하겠습니다.<br />
									</li>
								</ol>
								감사합니다. <br />
								<br />
								WOTA/WREX 운영진<br />
							</li></ul>
						</div>
					</label>

					<input id='nr7' name='radio' type='checkbox' />
					<label htmlFor='nr7'>
						<span className="th_faq">오프닝 이벤트 당첨자, 2020-01-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>

								<div className="row result-wrap">
									<div className="col-full">
										<div className="result">
											<p className="crypto-word-warp">[이벤트 하나!] 추천 수 상위 100명에게 드리는 특별 이벤트 총 420개 이더리움 에어드랍!<br /><span>* 실제 당첨 상품 지급일은 24일로 예정되었으나, 내부 시스템 사정으로 인하여 2020년 1월 10일 WREX 계정으로 일괄 지급됨을 양해 말씀드립니다.</span></p>

											<table className="simpeltable">
												<thead>
													<tr>
														<th scope="col">순위 </th>
														<th scope="col">상품 </th>
														<th scope="col">당첨자 (추천수) </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td data-label="순위 ">TOP 1</td>
														<td data-label="상품 ">100이더리움</td>
														<td data-label="당첨자 (추천수) ">mil***************@y****.com (51)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 2</td>
														<td data-label="상품 ">50이더리움</td>
														<td data-label="당첨자 (추천수) ">pau*******@g****.com (48)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 3</td>
														<td data-label="상품 ">30이더리움</td>
														<td data-label="당첨자 (추천수) ">chs***@g****.com (46)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 4~5</td>
														<td data-label="상품 ">각각 20이더리움</td>
														<td data-label="당첨자 (추천수) ">
															biz********@n****.com (42)<br />
															yse****@g****.com (42)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 6~10</td>
														<td data-label="상품 ">각각 10이더리움</td>
														<td data-label="당첨자 (추천수) ">
															sea*******@s*******.ca (40)<br />
															jju****@h******.net (39)<br />
															mif*****@y****.com (37)<br />
															sal*******@g****.com (37)<br />
															sam*****@h******.com (37)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 11~25</td>
														<td data-label="상품 ">각각 5이더리움</td>
														<td data-label="당첨자 (추천수) ">
															coo*******@g****.com	(36)<br />
															mid*********@y****.com	(30)<br />
															ste******@a**.com	(28)<br />
															seb***********@h******.com	(26)<br />
															pat*********@y****.com	(26)<br />
															nes*******@y****.com	(25)<br />
															pat**********@y****.com	(25)<br />
															rjh*******@y****.ca	(24)<br />
															rya*****@l****.com	(24)<br />
															s_h****@h******.com	(24)<br />
															uni******@g****.com	(23)<br />
															ost*****@y****.com	(22)<br />
															pic*********@g****.com	(22)<br />
															des*********@g****.com	(22)<br />
															sar*@h******.com	(21)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 26~100</td>
														<td data-label="상품 ">각각 1이더리움</td>
														<td data-label="당첨자 (추천수) ">
															myf*****@h******.com	(19)<br />
															raz*@a**********.com	(18)<br />
															mic********@y****.com	(18)<br />
															chu*********@n****.com	(17)<br />
															por*******@y****.com	(17)<br />
															raq*@y****.com	(15)<br />
															ozc*****@n****.com	(15)<br />
															bli*********@y****.com	(14)<br />
															pau*****@h******.com	(14)<br />
															sdw*****@h******.com	(14)<br />
															air******@h******.net	(13)<br />
															ea-****@d***.net	(13)<br />
															dms*****@n****.com	(13)<br />
															mig************@y****.com	(13)<br />
															ren**********@g****.com	(12)<br />
															jrk**@m**.com	(12)<br />
															jes*********@g****.com	(11)<br />
															sno******@g****.com	(11)<br />
															Lil*********@g****.com	(10)<br />
															jhl**@g****.com	(10)<br />
															jom*****@d***.net 	(9)<br />
															kws****@n****.com	(8)<br />
															ssd******@g****.com	(7)<br />
															pro**************@g****.com	(7)<br />
															han*********@h******.net	(7)<br />
															sha********@g****.com	(7)<br />
															kig*******@n****.com	(6)<br />
															jin*****@n****.com	(6)<br />
															mia******@g****.com	(6)<br />
															mil*******@a**.com	(6)<br />
															sen***@y****.com	(6)<br />
															esp*******@g****.com	(6)<br />
															mot*******@g****.com	(6)<br />
															rit**********@h******.com	(6)<br />
															sal***********@y****.com	(5)<br />
															lsi**@n***.com	(5)<br />
															hk6***@n***.com	(5)<br />
															dec****@n****.com	(5)<br />
															pen*******@g****.com	(5)<br />
															mie*******@y****.com	(5)<br />
															rba*******@p******.net	(5)<br />
															sai*********@c******.net	(5)<br />
															pel******@g****.com	(5)<br />
															bor*******@n****.com	(5)<br />
															suu****@n***.com	(4)<br />
															hap*******@n***.com	(4)<br />
															ste******@a**.com	(4)<br />
															seb****@m**.com	(4)<br />
															ray********@m**.com	(4)<br />
															sam********@g****.com	(4)<br />
															win****@n****.com	(4)<br />
															kim****@g****.com	(4)<br />
															yom*******@g****.com	(4)<br />
															spa******@k****.ac.kr	(4)<br />
															sha********@g****.com	(4)<br />
															jam************@y****.com	(4)<br />
															mil************@m**.com	(3)<br />
															dba******@d***.net 	(3)<br />
															tnz*******@d***.net	(3)<br />
															osc**********@h******.com	(3)<br />
															sfl*****@y****.com	(3)<br />
															mik********@h******.com	(3)<br />
															zin***********@g****.com	(3)<br />
															ton********@n****.com	(3)<br />
															mid*****@g****.com	(3)<br />
															jho*****@h******.net	(3)<br />
															sal******@g****.com	(3)<br />
															sc5***@h******.net	(3)<br />
															snu***@r*****.com	(3)<br />
															nel*****@s********.net	(3)<br />
															reg*********@a**.com	(3)<br />
															nam******@h******.net	(3)<br />
															seo*****@n****.com	(3)<br />
															bro*********@n****.com	(3)<br />
															h-p*********@h******.net	(3)
								</td>
													</tr>
												</tbody>
											</table>
											<p className="lead animate-this">[이벤트 둘!] WREX 계정에 위탁된 이더리움 수량 기준 상위 3개국의 최상위 3분께 드리는 특별 이벤트<br /><span>* 이벤트 일정 및 주요 내용은 초대 받는 분들께 이메일로 개별 전송해드립니다.</span></p>
										</div>
									</div>
								</div>

							</ul>
						</div>
					</label>

					<input id='nr6' name='radio' type='checkbox' />
					<label htmlFor='nr6'>
						<span className="th_faq">WREX - 모바일 앱 출시, 2020-01-02</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께, <br />
									</p>
									모바일로도 편리하게 이용할 수 있는 WREX 앱이 출시되었습니다.
									아래 버튼을 클릭하셔서 WREX 앱을 다운로드 받아보세요.
<br />
									App Store (IOS)
<br />
									<a href='https://apps.apple.com/us/app/wrex/id1491276520?ls=1'>https://apps.apple.com/us/app/wrex/id1491276520?ls=1</a>
									<br />
									Google Play (안드로이드)
<br />
									<a href='https://play.google.com/store/apps/details?id=com.wrex'>https://play.google.com/store/apps/details?id=com.wrex</a>
									<br />
									현재 버전의 WREX 앱은 영어로만 서비스가 되며, 빠른 시일 내에 한국어와 인도네시아어가 추가 될 예정입니다.
<br />
									감사합니다.
<br />
									WOTA/WREX 운영팀
<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr5' name='radio' type='checkbox' />
					<label htmlFor='nr5'>
						<span className="th_faq">12월 중대 발표, 2019년 12월 24일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									지난 주 금요일 WOTA/WREX는 홍콩의 한 신탁펀드회사와의 [펀드 위탁에 관한 계약서]를 체결하였습니다. 이 계약서의 주요 내용은;
<ul>
										<li>신탁펀드사는 WOTA/WREX 사업에 최대 미화 1억불 (한화 1,200억원)의 내부 자금 운용을 위탁한다 (총 740,000 이더리움)</li>
										<li>신탁펀드사는 2020년 2월부터 자금을 투입한다</li>
										<li>운용 펀드는 본계약에 따라 24개월간 운용하며 상호 필요시 연장할 수 있다</li>
									</ul>
									<br />
									<br />
									홍콩의 신탁펀드사와 계약을 통해 WOTA/WREX는 한단계 도약할 수 있는 발판을 마련했습니다. 이는 드디어 개인 투자가가 아닌 한 기관의 투자회사가 여러 차례 방문과 감사를 통해 저희 WOTA 플랫폼의 장점을 인정하고, WREX 알고리즘 트레이딩의 실체를 파악한 후, 수익성이 충분하게 보장된 다는 점을 높이 평가하여 투자를 결정했다는 점에서 저희 역시 열광하지 않을 수 없는 상황입니다.
<br />
									<br />
									이와 관련, 초기 WREX 플랫폼 기획 단계에서 내부에서 정한, [최대 1백만개의 이더리움]을 선착순으로 위탁 운영한다는 내부 방침에 따라, 본 계약을 통해 이 시점이 한층 앞당겨질 수 있음을 동시에 공지할 수 밖에 없는 상황에 대해 회원님들의 양해를 미리 구합니다. 저희 WREX는 위탁 운용하는 이더리움 수량이 1백만개가 되는 시점부터 부득이하게 신규 회원을 더 이상 받지 않을 것을 공지합니다. 이는 저희가 초기에 약속한 매달 평균 두 자리 수 수익률을 유지하기 위해서는 너무 많은 수량의 이더리움을 운용하기 보다는 적정한 수량과 매달 평균 수익률을 유지하기 위해서 내부에서 사전에 정한 수량 (1백만 이더리움)을 달성하면 부득이하게 신규 회원을 제한하여 기존 회원들의 수익율을 보장하기 위함임을 양해해 주시기 바랍니다.
<br />
									<br />
									감사합니다.
<br />
									<br />
									WOTA/WREX 운영팀
<br />
								</li>
							</ul>
						</div>
					</label>


					<input id='nr4' name='radio' type='checkbox' />
					<label htmlFor='nr4'>
						<span className="th_faq">12월 공지사항, 2019년 12월 5일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											11월 월수익율 <br />
											Tilly Baker사의 회계감사가 완료되었고 11월 월수익률은 10.07%로 집계되었으며, 12월 10일에 11월 월수익률 분이 적용될 예정입니다. 다만, 이번 회계감사 보고서는 회계법인의 휴가 계획으로 통상 8일이 아닌 17일에 홈페이지게 개제될 것 입니다. 이점 양해를 바랍니다.
<br />
											<br />
										</li>
										<li>
											오프닝 이벤트 이더리움 에어드랍<br />
											오프닝 이벤트에 참여해 주신 회원님들에게 감사드리며, 2주 정도 후에 공식으로 오프닝 이벤트에 대한 결과를 공지하도록 하겠습니다.
<br />
											<br />
										</li>
										<li>
											이스탄불 하드포크로 인한 입금/출금 지연 안내<br />
											이더리움 재단은 12월 7일경 이스탄불 하드포크를 진행할 예정으로 공시하였으며, 저희 내부 이더리움 지갑 역시 그 시점에 맞추어 업그레이드를 진행할 예정입니다. 고로, 12월 6일부터 9일 사이에 입금 또는 출금 요청을 하실 경우에 통상적인 네트워크 보다 전송시간이 지연될 것으로 예상되며, 이는 일시적인 현상인 것을 미리 사전 공지 드립니다.
<br />
											<br />
										</li>
										<li>
											369 플랜 상품<br />
											저희 W-REX는 회원님들의 중단기 상품이 필요하다는 지적에 2020년 1사분기 이내에 새로운 상품인 369 플랜 상품을 출시할 예정입니다.
<br />
											369 플랜 상품은 100 이더리움 상품과 유사한 방법으로 구상되었으며, 다만 1계정당 1개의 상품만을 생성하실 수 있습니다. 입금하실 이더리움 수량은 제한이 없으나, 만기일을 3개월, 6개월 또는 9개월 중에 선택하실 수 있습니다.
<br />
											100 이더리움 플랜처럼 회사의 수익분을 회원님의 369플랜과 함께 투자되며, 100 이더리움 플랜의 경우 해지 또는 만기 시 5대 5로 배분하던 것을, 369플랜에서는 만기 시 7대 3으로 배분하여 회원님들의 수익을 극대화 하였습니다.
<br />
											100 이더리움 플랜의 경우 만기까지 40개월이 걸려 다소 지루하실 수 있는 점을 최단 3개월 그리고 최장 9개월 플랜을 통해 중단기적 투자 계획도 세워보실 수 있도록 만들었습니다. 자세한 사항은 상품의 최종 개발 및 출시에 맞추어 다시 공지하도록 하겠습니다.
<br />
											<br />
											감사합니다.
<br />
											W-REX 운영팀
<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr3' name='radio' type='checkbox' />
					<label htmlFor='nr3'>
						<span className="th_faq">2019년 11월 WREX 소식, 2019-11-08</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									<ol>
										<li>
											<br />
											PWC 회계법인이 WREX 계정에 대한 감사를 진행하기 하루 전날인 2019년 9월 30일에 저희에게 공식 이메일을 보내와 저희 계정에 대한 감사를 진행하지 못함을 알린 봐 있습니다. 이유는 PWC 인터네셔널 (본사)에서 암호화화폐 관련 회사에 대한 감사는 마치 PWC가 암호화폐 산업에 대해 지지의사를 밝히는 인상을 줄 수 있기 때문에 암호화폐에 대한 법과 규정이 아직 확립이 안된 국가에서의 관련 산업에 대한 회계 감사를 자제해 달라는 지시를 받았기 때문이라고 밝혔습니다. <br />
											저희는 당시 PWC와 계약을 완료하여 대안책이 없는 상태에서 큰 실망감을 감출 수 없었습니다. 하지만 그 이후 바로 PWC를 대체하여 기존 계약 업무를 진행할 수 있는 다수의 회계법인과의 협의를 진행했고, 다행히 세계 10대 회계법인 중 하나인 Tilly Baker 회계감사법인과 계약을 진행했습니다. <br />
											Tilly Baker 회계감사법인은 이미 저희 9월 실적 및 회계 감사를 진행했으며, [지원] 페이지에서 영문 원본 감사보고서를 보실 수 있습니다. 어제까지 10월분에 대한 감사 역시 진행했으며, 10월 보고서는 다음 주 초에 홈페이지에 추가 업로드하도록 하겠습니다.<br />
											9월 보고서는 9월에 진행된 저희 ETH 계정의 모든 활동을 감사하며, 9월의 모든 거래내역을 전수 검사하여 입금된 ETH 수량, 9월 수익 ETH 수량을 산출하여 9월 수익률인 16.52%를 확인하는 감사 보고서입니다. <br />
											<br />[지원] 페이지에서 9월 감사 보고서를 다운로드 하실 수 있습니다.<br />
											<br />
										</li>
										<li>
											10월 거래 실적 관련<br />
											현재 Tilly Baker에서 10월 실적에 대한 감사를 마쳤으며, 보고서가 나오기 전이지만 확인된 바로는 10월 실적은 11.72% 입니다.<br />
											10월 감사 보고서는 10일 경에 홈페이지에 개제될 예정입니다.<br />
											<br />
											그리고 10일 경에 회원님의 계정에 10월 실적이 반영될 예정입니다. <br />
											<br />
											WREX 9월 실적: 16.52%<br />
											WREX 10월 실적: 11.72%<br />
											WREX 평균 실적: 14.12%<br />
											<br />
										</li>
										<li>
											추천인 보너스 실적 샘플<br />
											저희 WREX 회원 중 가장 많은 추천인 보너스를 수령한 탑5 계정 중 한 회원님께서 허락해 주셔서 그분의 수익률을 공개해 드립니다. 9월의 월 수익률 16.52%를 기록했을 때, 그리고 대부분의 회원들이 최대 16.52%의 수익을 받았을 때, 이 회원의 경우, <br />
											WREX 계정 – 593.60%<br />
											100 이더리움 플랜 – 88.36%<br />
											의 수익률을 기록했습니다.<br />
											어떻게 저러한 수익을 얻을 수 있는지 궁금하시면, [지원] 페이지에서 이분의 샘플 페이지를 참고해 주시기 바랍니다.<br />
											<br />다운로드: <a href="/download/AffiliateBonusSample(KOR).pdf" target="_blank">AffiliateBonusSample(KOR).pdf</a><br />
											<br />감사합니다.<br />
											WREX 지원팀 <br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr2' name='radio' type='checkbox' />
					<label htmlFor='nr2'>
						<span className="th_faq">WREX Announces First Payouts, 2019-10-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									<ol>
										<li>
											첫 달 수익률 배분이 이루어졌습니다! <br />
											앞으로도 매월 10일에 수익률이 반영됩니다. 오늘이 첫 수익의 배분이 이루어진 날로 궁금한 것들이 많으실 겁니다.<br />
											먼저 말씀 올리고자 하는 것은, 모든 계산은 프로그래밍에 의해 자동 정산되며, 이 의미는 “한 계정의 계산이 맞는다면 모든 계산이 맞을 것이고, 한 계정의 계산이 틀렸다면 모든 계정의 연산이 잘못 되었다”는 것입니다.<br />
											첫 달이 계산이 가장 복잡한 이유는 회원님께서 9월 어느 시점에 WREX 상품과 100 이더리움 플랜 상품에 가입하셨냐에 따라 월수익률의 배분 비율이 조금씩 상이하기 때문입니다. (<font className="emphsize">첫 달만 그렇고, 다음 달 부터는 정상적인 배분 비율이 적용됩니다</font>)  <br />
											<br />
											<font className="boldfont">만약 회원님께서 WREX 상품을;</font><br />
											9월1일부터 10일 사이에 가입하셨다면, 9월 수익률의 50%가 반영됩니다.<br />
											9월 11일부터 20일 사이에 가입하셨다면, 9월 수익률의 25%가 반영됩니다.<br />
											9월 21일부터 30일 사이에 가입하셨다면, 9월 수익률의 0%가 반영됩니다.<br />
											<br />
											<font className="boldfont">만약 회원님께서 100 이더리움 플랜에;</font><br />
											9월 1일부터 30일 사이에 가입하셨다면, 9월 수익률에 대해서는 받으실 수 없습니다. <br />
											왜냐하면, 모든 100 이더리움 플랜은 가입시점과 관계없이 다음 달 1일부터 계약 효력을 발생하기 때문입니다. <br />
											<br />
											만약 회원님께서 WREX 상품과 100 이더리움 플랜을 8월에 가입 완료하셨다면, 9월 월 수익률인 16.52%가 100% 반영될 것입니다. <br />
											9월에 위 상품에 가입하신 회원분들은 가입시점에 따른 월수익률 배분 비율을 적용하시면 됩니다. 10월부터는 배분 비율 없이 월수익률 그대로 적용됩니다. <br />
										</li>
										<li>
											추천인 제도<br />
											<font className="boldfont">WREX 상품 추천인 제도</font><br />
											(1)	회원님께서 최소 1 이더리움을 월 1일부터 마지막날까지 유지해야만 추천 제도 혜택을 받으실 수 있습니다.<br />
											(2)	회원님께서 추천하신 회원분들 역시 최소 1 이더리움을 월 1일부터 마지막날까지 유지해야만 회원님께서 추천 보너스를 받으실 수 있습니다.<br />
											<font className="boldfont">100 이더리움 플랜 추천인 제도</font><br />
											(1)	회원님께서 최소 한 개의 100 이더리움 플랜을 1일부터 마지막 달까지 유지해야 추천인 보너스를 수령할 수 있습니다.<br />
											(2)	회원님께서 추천한 회원들 역시 100 이더리움 플랜을 1일부터 마지막 달까지 유지해야 회원님께서 추천인 보너스를 수령할 수 있습니다.<br />
											(3)	유의: 모든 100 이더리움 플랜의 추천인 보너스는 회원님의 가장 오래된 100 이더리움 플랜에 입금됩니다.<br />
											<br />
											<font className="boldfont">저희 홈페이지 시작페이지 중단 부분에 국문 가이드북이 있으니 참조해 주시기 바랍니다.</font>
										</li>
										<li>
											회계법인의 첫 달 운영계정 감사 리포트<br />
											현재 운영과 상관없는 내부의 문제로 인해 첫 달 운영 계정 감사 리포트가 지연되고 있는 점은 깊이 사과 드립니다. 하지만 이 것은 운영과 상관없는 문제임을 확인 드리며, 일단 내부에서 몇 번을 거듭해서 산출한 월수익률로 변동이 없을 것을 약속 드립니다. 또한, 최대한 빠른 시일내에 보고서 공개를 통해 투명하게 운영될 것임을 첨언합니다. <br />
											보고서 지연 말고는 모든 계정 운영이 정상적으로 진행되고 있으며, 참고로 말씀드리자면, 10월 실적 역시 순조롭게 진행되고 있으니 불필요한 확대해석 내지는 걱정하지 않으시 길 부탁드립니다.<br />
											<br />
											항상 여러분들의 응원에 감사드리며 궁금하신 사항이 있으시면, <a href="mailto:support@w-rex.com">support@w-rex.com</a> 으로 남겨 주시기 바랍니다.<br />
											<br />
											<br />
											WREX 운영팀 올림<br />

										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr1' name='radio' type='checkbox' />
					<label htmlFor='nr1'>
						<span className="th_faq">Monthly Profit Notice, 2019-10-01</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										회원님들께<br />
										9월 비공식 수익률이 집계되어 공개해드립니다. 9월실적: 16.52%
										저희를 믿고 저희 서비스를 처음부터 이용해주신 여러분들은 곧 본인 계정에 "믿기 힘든, 하지만 WREX 기준으로는 평균보다 조금 높은" 수익률이 반영될 예정입니다.
<br />
										수익률에 대한 질문이나 또한 여러가지 의문사항 있으시면, 언제든지 <a href="mailto:support@w-rex.com">support@w-rex.com</a>으로 연락주시면 답변 드리도록 하겠습니다.<br />
										감사합니다.
			<br /><br />
									</p>
								</li>
							</ul>
						</div>
					</label>

				</div>

			</div>

		)
	}
}

export default NewsKR;
