/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import { withRouter } from 'react-router-dom'
import i18n from 'i18n-react'

//import { sendUserResendEmail, sendUserInfo , API_SUCCESS } from '../actions'

//import {getToken} from '../helper/loginHelper';

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();

class EmailConfirmed extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych EmailConfirmed");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Email Confirmed - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

 
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  
  render() {

    return (
      <div>
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
              <img src="assets/images/logo_wrex.svg" alt="WREX" />
            </h2>
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
              <span className="crypto-widget__heading-text">{i18n.translate('emailconfirmed')}</span>
              <span className="crypto-widget__heading-border"></span>
            </h2>
            <p className="crypto-word-warp">{i18n.translate('eec1')}</p>
            </div>
                <Link to="/" className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                > 
                  {i18n.translate('ok')}
                </Link>
          </div>
			  </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, {
})(EmailConfirmed))




