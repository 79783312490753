/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import QRCode from 'react-qr-code';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { getETHDisplayString }  from '../helper/displayHelper'

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { sendUserAffiliateRanking, API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';

import {REFERRAL_LINK_PREFIX} from '../constants/ConfigValue.js';

import i18n from 'i18n-react';

//const cookies = new Cookies();
class AffiliateProgram extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      copied1: false,
      copied2: false,
    }
  }

  componentDidMount() {
    //console.log("ych AffiliateProgram");
    //window.addEventListener('resize', this.handleResize)
    document.title = "AffiliateProgram - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)&&(this.props.globalUser.user.ranking!==undefined)) {
    } else {
      var self=this;
      let token = getToken(this);
      this.props.sendUserAffiliateRanking(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.user.user;
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {

    //console.log(this.props.globalUser);
    var affiliate_ranking = [];

    var affiliate = {code: "", referrerURL: "", earnedAmount : "", 
      referral100epAmount: "", referralCount: "", referralWrexAmount:""}
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
      let user = this.props.globalUser.user;
      if (user.affiliate!==undefined) {
        affiliate = user.affiliate;
        affiliate.referrerURL = REFERRAL_LINK_PREFIX+affiliate.code.toString();
      }
      if (user.ranking!==undefined) {
        affiliate_ranking = user.ranking;
      }
    }

//console.log(affiliate_ranking);

    const myQRCode = affiliate.referrerURL !== "" ? (
      <QRCode value={affiliate.referrerURL} />
    ) : (
      <div></div>
    )

/*
    const affiliateRankingNodes = affiliate_ranking.map((myRanking,index) =>
      <label key={index+1} htmlFor='tr{index+1}'>
        <span className="topth_0">No.{myRanking.rank}</span>
        <span className="topth_1">{myRanking.email}</span>
        <span className="topth_2">{myRanking.referralCount}</span>
{ 
//        <span className="th_4">{getETHDisplayString(myRanking.earnedAmount)} ETH</span>
 }
        <div className='bar'></div>
      </label>
    ) 
*/

    return (
      <div>

        { /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	

				<div className="crypto-wrapper">
          <SideNav
              ActiveTag="referral"
          />

				  <main className="crypto-main">

<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
  <div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
    <div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
        <h6 className="f_breadcrumb">
        </h6>
        <h1 className="crypto-card__header--title">{i18n.translate('affiliateprogram')}</h1>
        <h4 className="f_mode">
        </h4>
    </div>
    <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
        { /* <img src="assets/images/banner.svg" className="img-responsive" alt="Banner" /> */}
    </div>
  </div>



  <div className="mdc-layout-grid">
  <div className="mdc-layout-grid__inner f_rfl_num">
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop"></div>
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"> 

    <div className="crypto-widget__content crypto-overflow-x-inherit fx_mb_nopadding">
{ /* 
      <div className="f_single fx_single_tbl">
        <div className="tabset">        
          <label htmlFor="tab2">
          <span className="mdc-tab__content fx_titlefont">
            <span className="mdc-tab__icon material-icons">person</span>
            <span className="mdc-tab__text-label">Top 25</span>
          </span>
          </label>
         </div>
      </div>
*/}
{ /*

      <div className='swanky_wrapper f_flag'>
        <label htmlFor='heder'>
          <span className="topth_0"></span>
          <span className="topth_1">{i18n.translate('email')}</span>
          <span className="topth_2">{i18n.translate('referralCount')}</span>
          <span className="th_4">{i18n.translate('earnedcommisions')}</span>
          <div className='bar'></div>
        </label>
{affiliateRankingNodes}
      </div>	
*/ }
      </div>  
    </div>


  </div>

  <div className="mdc-layout-grid__inner f_rfl">
    <div className="f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <div className="crypto-widget">
        <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
        <span className="crypto-widget__heading-text">{i18n.translate('myreferalid')}</span>
        </h2>
      </div>
    </div>
    <div className="f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <h4>
      {i18n.translate('sharereferalid')}
      </h4>
    </div>
    <div className="f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <div className="wx_txtbox">{affiliate.code}
        <CopyToClipboard
          text={affiliate.code}
            onCopy={() => this.setState({copied1: true})}>
          <i className="material-icons" tabIndex="0">file_copy</i>
        </CopyToClipboard>
      </div>

    </div>
    <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-1-phone">
      <div className="mdc-layout-grid__cell--span-12 f_btncenter">
      </div>
    </div>



    <div className="f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <h4>
      {i18n.translate('sharereferallink')}
      </h4>
      <div id="tf-box-trailing-example"></div>
      <div className="wx_txtbox">
        {affiliate.referrerURL}
        <CopyToClipboard
          text={affiliate.referrerURL}
            onCopy={() => this.setState({copied2: true})}>
          <i className="material-icons mdc-text-field__icon" tabIndex="0">file_copy</i>
        </CopyToClipboard>
      </div>
    </div>
  </div>

	<div className="f_rfl qrcode_border">
							<div className="f_centerqrcode mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-2-phone f_qrcode">
  							{myQRCode}
							</div>
  </div>
  

  <div className="mdc-layout-grid__inner f_rfl_num fx_affiliate_align">
  <div className="f_centerbox2 mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">		  
    <fieldset>
      <legend className="mdc-typography--subheading2">{i18n.translate('referralfriends')}</legend>
      <div>
      <Link to="my_affiliate_status" className="mdc-button">
        {affiliate.referralCount}
      </Link>
      </div>
    </fieldset>
  </div>
  <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">		  
    <fieldset>
      <legend className="mdc-typography--subheading2">{i18n.translate('earnedcommisions')}</legend>
      <div>
<span className="mdc-button">
        {getETHDisplayString(affiliate.earnedAmount)} ETH
</span>
{ /*
      <Link to="my_affiliate_status" className="mdc-button">
        {getETHDisplayString(affiliate.earnedAmount)} ETH
      </Link>
*/ }
      </div>
    </fieldset>
  </div>
  <div className="f_centerbox2 mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">		  
    <fieldset>
      <legend className="mdc-typography--subheading2">{i18n.translate('referralwrexamount')}</legend>
      <div>
      <Link to="my_affiliate_status?ref=wota" className="mdc-button">
        {getETHDisplayString(affiliate.referralWrexAmount)} ETH 
      </Link>
      </div>
    </fieldset>
  </div>
  <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">		  
    <fieldset>
      <legend className="mdc-typography--subheading2">{i18n.translate('referal100epamount')}</legend>
      <div>
      <Link to="my_affiliate_status?ref=ep100" className="mdc-button">
        {getETHDisplayString(affiliate.referral100epAmount)} ETH 
      </Link>
      </div>
    </fieldset>
  </div>
 
  </div>

  <div className="f_rg_ptop mdc-layout-grid__inner f_p">
  <div className="f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-6-phone">		  
    <div className="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    <div className="crypto-widget">
      <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
      <span className="crypto-widget__heading-text">{i18n.translate('programdetail')} </span>
      <span className="crypto-widget__heading-border"></span>
      </h2>
    </div>
    </div>

    <div className="crypto-widget__content">
      <ul className="li_notice">
      <li>{i18n.translate('ap7')}</li>
      <li>{i18n.translate('ap8')}</li>
      </ul>
    </div>

<br/>
  <p className="crypto-word-warp f_centerbox mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    {i18n.translate('ap1')} <br/><br/>
    {i18n.translate('ap2')}  <br/><br/>
    {i18n.translate('ap3')} <br/><br/>
    {i18n.translate('ap4')} <br/><br/>
    {i18n.translate('ap5')}  <br/><br/>
    {i18n.translate('ap6')} <br/><br/>
  </p>
  </div>

  </div>

  </div>

  </div>

</main>

</div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
    sendUserAffiliateRanking
})(AffiliateProgram))




