/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

import i18n from 'i18n-react';

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class ForgotPasswordResult extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych ForgotPasswordResult");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Forgot password - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
        <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                <img src="assets/images/logo_wrex.svg" alt="WREX" />
              </h2>
              <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                <span className="crypto-widget__heading-text">{i18n.translate('resetpassword')}</span>
                <span className="crypto-widget__heading-border"></span>
              </h2>
              <form className="crypto-login-form">
                <p className="crypto-word-warp crypto-text-align-left">{i18n.translate('fpr1')}</p>
                <div className="crypto-display-flex crypto-display-flex--space-between">
                <div className="mdc-form-field">
                  <Link to="login" className="mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('ok')}</Link>
                </div>
                <div className="mdc-form-field">
                {i18n.translate('fpr2')}
                  <Link to="forgot-password" className="f_rgnew">{i18n.translate('resend')}</Link>
                </div>
                </div>
              </form>
            </div>

          </div>
			  </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
})

export default withRouter(connect(mapStateToProps, {
})(ForgotPasswordResult))




