import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'

import Index from './Index'
import Login from './Login'
import LoginJVSAKK from './LoginJVSAKK'
import EmailVerify from './EmailVerify'
import EmailConfirmed from './EmailConfirmed'
import EmailReset2FAConfirmed from './EmailReset2FAConfirmed'
import EmailConfirmationFail from './EmailConfirmationFail'
import EmailReset2FAConfirmationFail from './EmailReset2FAConfirmationFail'
import ForgotPassword from './ForgotPassword'
import ForgotPasswordResult from './ForgotPasswordResult'
import ForgotPasswordToReset from './ForgotPasswordToReset'

import MyEthWallet from './MyEthWallet'
import MyEthDeposit from './MyEthDeposit'
import MyEthWithdraw from './MyEthWithdraw'
import MyEthTransfer from './MyEthTransfer'
import MyEthWithdrawRequest from './MyEthWithdrawRequest'

import Register from './Register'
import RegisterVerify from './RegisterVerify'

import Support from './Support'
import SupportNotLogin from './SupportNotLogin'
import ReadPdf from './ReadPdf'
import Google2fa1 from './Google2fa1'
import Google2fa11a from './Google2fa11a'
import Google2fa11b from './Google2fa11b'
import Google2fa11c from './Google2fa11c'
//import Google2fa1Withdraw from './Google2fa1Withdraw'
import Google2fa2 from './Google2fa2'
import Google2fa22 from './Google2fa22'

import AffiliateProgram from './AffiliateProgram'
import APStatus from './APStatus'

import IndexLogin from './IndexLogin'

import Profile from './Profile'
import ProfileAddress from './ProfileAddress'
import ProfileAddressNew from './ProfileAddressNew'
import ProfilePassword from './ProfilePassword'

import Invest from './Invest'

import WrexAccount from './WrexAccount'
import WrexTransfer from './WrexTransfer'
import WrexDeposit from './WrexDeposit'
import WrexDepositCreate from './WrexDepositCreate'
import WrexReturnSetting from './WrexReturnSetting'
import WrexWithdraw from './WrexWithdraw'
import WrexWithdrawRequest from './WrexWithdrawRequest'

import Eth100Create from './Eth100Create'
import Eth100Update from './Eth100Update'
import Eth100Plan1 from './Eth100Plan1'
import Eth100TerminateConfirm from './Eth100TerminateConfirm'
import Eth100TerminateConfirmWithPenalty from './Eth100TerminateConfirmWithPenalty'

import AccountAddress from './AccountAddress'
import AccountSecurity from './AccountSecurity'

const Root = ({ store }) => (
  <Provider store={store}>
    <div>
        <Route path="/" exact component={Index} />
        <Route path="/login" component={Login} />
        <Route path="/login-jvsakk" component={LoginJVSAKK} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/forgot-password-result" component={ForgotPasswordResult} />
        <Route path="/reset-password" component={ForgotPasswordToReset} />
        <Route path="/email_verify" component={EmailVerify} />
        <Route path="/email_confirmed" component={EmailConfirmed} />
        <Route path="/email_reset_2fa_confirmed" component={EmailReset2FAConfirmed} />
        <Route path="/email_confirmation_fail" component={EmailConfirmationFail} />
        <Route path="/email_reset_2fa_confirmation_fail" component={EmailReset2FAConfirmationFail} />
        <Route path="/myeth_wallet" component={MyEthWallet} />
        <Route path="/myeth_deposit" component={MyEthDeposit} />
        <Route path="/myeth_withdraw" component={MyEthWithdraw} />
        <Route path="/myeth_transfer" component={MyEthTransfer} />
        <Route path="/myeth_withdraw_request" component={MyEthWithdrawRequest} />
        <Route path="/register" component={Register} />
        <Route path="/register-verify" component={RegisterVerify} />
        <Route path="/support" component={Support} />
        <Route path="/wrex_support" component={SupportNotLogin} />
        <Route path="/read_pdf" component={ReadPdf} />
        <Route path="/to2fa" component={Google2fa1} />
        <Route path="/editaddr_success" component={Google2fa11a} />
        <Route path="/request_received" component={Google2fa11b} />
        <Route path="/transfer_success" component={Google2fa11c} />
        <Route path="/require2fa" component={Google2fa2} />
        <Route path="/setup_2fa" component={Google2fa22} />
        <Route path="/affiliate_program" component={AffiliateProgram} />
        <Route path="/my_affiliate_status" component={APStatus} />
        <Route path="/index_login" component={IndexLogin} />
        <Route path="/my_assets" component={Invest} />
        <Route path="/profile" component={Profile} />
        <Route path="/profile_address" component={ProfileAddress} />
        <Route path="/profile_address_new" component={ProfileAddressNew} />
        <Route path="/profile_password" component={ProfilePassword} /> 
        <Route path="/wrex_account" component={WrexAccount} />
        <Route path="/wrex_transfer" component={WrexTransfer} />
        <Route path="/wrex_deposit_create" component={WrexDepositCreate} />
        <Route path="/wrex_deposit" component={WrexDeposit} />
        <Route path="/wrex_return_setting" component={WrexReturnSetting} />
        <Route path="/wrex_withdraw_request" component={WrexWithdrawRequest} />
        <Route path="/wrex_withdraw" component={WrexWithdraw} />
        <Route path="/100ETH_create" component={Eth100Create} />
        <Route path="/100ETH_update" component={Eth100Update} />
        <Route path="/100ETH_plan_1" component={Eth100Plan1} />
        <Route path="/100ETH_terminate_confirm" component={Eth100TerminateConfirm} />
        <Route path="/100ETH_terminate_confirm_with_penalty" component={Eth100TerminateConfirmWithPenalty} />
        <Route path="/account_address" component={AccountAddress} />
        <Route path="/account_security" component={AccountSecurity} />
    </div>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root

