/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {isLogin, getToken} from '../helper/loginHelper';

import { sendUserWithdrawAddressUpdate, sendUserWithdrawAddresses, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';


import { renderErrorMessage } from '../components/ErrorMessage'

//import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class ProfileAddress extends Component {
  static propTypes = {
    params: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      editAddress: "",
      editName: "",
      errorMessage: "",
    }
  }

  componentDidMount() {

    //console.log("ych ProfileAddress");
    //console.log(this.props);
    //console.log(this);


    //window.addEventListener('resize', this.handleResize)
    document.title = "Address Edit - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    const cookieEditAddress = cookies.get('edit_address');
    const cookieEditName = cookies.get('edit_name');
    //console.log(cookieEditAddress);
    //console.log(cookieEditName);
    if ((cookieEditAddress!==undefined)&&(cookieEditName!==undefined)) {
      this.setState({editAddress: cookieEditAddress})
      this.setState({editName: cookieEditName})
    } else {
      this.props.history.push("/account_address")
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleLabelChange(event)  {
    //console.log("handleLabelChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({labelPlaceHolder: ""})
      } else {
        //this.setState({labelPlaceHolder: i18n.translate('labelPlaceHolder')})
      }
      this.setState({editName: event.target.value})
    }

  }

  handleSubmit() {
    //console.log("handleSubmit");
    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

    if (this.state.editName==="") {
      //this.setState({errorMessage: i18n.translate('labelEmpty')})
    } else {
      this.props.sendUserWithdrawAddressUpdate( token, {
        address: this.state.editAddress,
        name: this.state.editName
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            self.props.sendUserWithdrawAddresses(token,{});
            self.props.history.push("/account_address")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Account Address Error",this);
    }

    return (
      <div>
       <div className="crypro-login-container rex_rg">
				<div className="crypro-login-container__box">
				  <div className="crypro-login-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('editaddress')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<form className="crypto-login-form">
					 <p className="f_formtitle">{i18n.translate('label')}</p>
					 <div className="mdc-text-field mdc-text-field--fullwidth">
						<input type="text" id="name-text-field" className="mdc-text-field__input" 
                                                  value={this.state.editName}
                                                  onChange={this.handleLabelChange.bind(this)}
                                                />
						<div className="mdc-line-ripple"></div>
					  </div>
					  <p className="f_mbottom30 mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
						{i18n.translate('pan1')}
					  </p>

					 <p className="f_formtitle">{i18n.translate('address')}</p>
					 <div className="mdc-text-field mdc-text-field--fullwidth">
						<input type="text" id="username-text-field" className="mdc-text-field__input" value={this.state.editAddress} disabled />
						<div className="mdc-line-ripple"></div>
					  </div>
					  <p className="f_mbottom30 mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
					  </p>

		  
					  <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                                            onClick={() => this.handleSubmit()}
                                          >{i18n.translate('submit')}</span>
				   </form>
				  </div>
				</div>
			  </div>
      </div>

    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    globalLogin: state.entities.login,
  }
}

export default withRouter(connect(mapStateToProps, {
  sendUserWithdrawAddressUpdate, sendUserWithdrawAddresses
})(ProfileAddress))




