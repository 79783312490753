/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//import { withRouter, Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

import { sendReset2FA, sendUserInfo, API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';


//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class EmailVerify extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
    }
  }

  componentDidMount() {
    //console.log("ych EmailVerify");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Email Verification - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    this.handleReset2FAEmailVerify();

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleUserInfo() {
    //console.log("handleUserInfo");
    this.setState({errorMessage: ""})

    let token = getToken(this);
    //console.log(token);

    if (token!==null) {
      var self = this;
      this.props.sendUserInfo(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.user.user;
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
            //if (response.message==="email_not_confirmed") {
              //self.props.history.push("/register-verify")
            //}
            if (response.message==="login_required") {
              self.props.history.push("/login")
            }
          } else {
            if (response.info.totpEnabled===0) {
              self.props.history.push("/setup_2fa")
            }
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
    }

  }






  handleReset2FAEmailVerify() {
    //console.log("handleReset2FAEmailVerify");
    this.setState({errorMessage: ""})
    var self = this;
    
    let token = getToken(this);
    
    this.props.sendReset2FA(token, { 
    })  
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Email Verify Error",this);
    }

    return (
      <div className="crypro-login-container rex_rg">
        <div className="crypro-login-container__box">
          <div className="crypro-login-container__box-inner">
          <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
            <img src="assets/images/logo_wrex.svg" alt="WREX" />
          </h2>
          <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
            <span className="crypto-widget__heading-text">{i18n.translate('emailverification')}</span>
            <span className="crypto-widget__heading-border"></span>
          </h2>
          <p className="crypto-word-warp">{i18n.translate('ev1')}</p>
          <form className="crypto-login-form">
              <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                onClick={() => this.handleUserInfo({})}
              > 
                {i18n.translate('continue')}
              </span>

          </form>
          </div>
          <p className="mdc-typography--body2 mdc-theme--on-surface"> {i18n.translate('ev2')} <span className="f_rgnew" onClick={() => this.handleReset2FAEmailVerify({})}>{i18n.translate('resendemail')}</span></p>
                <span className="alert-message">
{this.state.errorMessage}
</span>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,

})

export default withRouter(connect(mapStateToProps, {
  sendReset2FA, sendUserInfo
})(EmailVerify))




