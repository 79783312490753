/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import i18n from 'i18n-react';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

import { renderErrorMessage } from '../components/ErrorMessage'

import { sendAccount100EPUpdate, sendAccount100EPList, API_SUCCESS } from '../actions'

import {getToken} from '../helper/loginHelper';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class Eth100Update extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      subscriptionName: "",
      subscriptionId: "",
      errorMessage: "",
    }

  }

  componentDidMount() {
    //console.log("ych Eth100Update");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Update - 100 ETH Plan"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    const cookieEditId = cookies.get('subscription_edit_id');
    const cookieEditName = cookies.get('subscription_edit_name');

    if ((cookieEditId!==undefined)&&(cookieEditName!==undefined)) {
      this.setState({subscriptionId: parseInt(cookieEditId)})
      this.setState({subscriptionName: cookieEditName})
    } else {
      this.props.history.push("/my_assets")
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleSubscriptionNameChange(event)  {
    //console.log("handleSubscriptionNameChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      this.setState({subscriptionName: event.target.value})
      //console.log(event.target.value);
    }

  }

  handleUpdate() {
    //console.log("handleUpdate");
    //console.log(this.state.subscriptionId);
    //console.log(this.state.subscriptionName);

    if (this.state.subscriptionName.length>40) {
      this.setState({errorMessage: i18n.translate('err_name_too_long')})
      return;
    }

    let token = getToken(this);
    var self = this;

    this.props.sendAccount100EPUpdate( token, {
      subscription_id: this.state.subscriptionId,
      name: this.state.subscriptionName
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.sendAccount100EPList(token,{});
          self.props.history.push("/my_assets")
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Eth100 Update Error",this);
    }

/*
    var subscriptions = [];

    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.subscriptions!==undefined) {
        subscriptions = account.subscriptions;
      }
    }
    //console.log(subscriptions);
    //console.log(this.state.subscriptionId);
    var mySubscription = { name: "" }
    for (let i in subscriptions) {
      if (subscriptions[i].id===this.state.subscriptionId) {
        mySubscription = subscriptions[i];
      } 
    }
*/

    return (
      <div>
				 { /* preloader */}
										{this.props.loading && <PreLoaderWidget />}

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="invest"
                                        />




				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
							  <h6 className="f_breadcrumb"><Link to="my_assets">{i18n.translate('assets')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('ep100')}</h1>
							  <h4 className="f_mode"> </h4>
							</div>
						</div>
		  

						  <div className="mdc-layout-grid">

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('namethisplan')}</span>
								  </h2>
								</div>
							</div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="first-name-text-field" className="mdc-text-field__input"
                                                                                                value={this.state.subscriptionName}
                                                                                                onChange={this.handleSubscriptionNameChange.bind(this)} />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>


											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                                                                            onClick={() => this.handleUpdate()}
                                                                                          >
                                                                                          {i18n.translate('update')}</span>
											</div>
										  </div>
										</form>
									  </div>
									</div>
								  </div>
								</div>
							  </div>
							</div>

						</div>
					</div>
				  </main>


				</div>           
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

})

export default withRouter(connect(mapStateToProps, {
  sendAccount100EPUpdate, sendAccount100EPList
})(Eth100Update))




