/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import SideNav from '../components/SideNav';
import PreLoaderWidget from '../components/Loader';
import IndexLoginPage from '../components/IndexLoginPage';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class IndexLogin extends Component {
  static propTypes = {
  }

  //constructor(props) {
    //super(props);
  //}

  componentDidMount() {
    //console.log("ych IndexLogin");
    //window.addEventListener('resize', this.handleResize)
    document.title = "100ETH Plan - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient f_home";


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  renderErrorMessage() {
    const { errorMessage } = this.props
    if (!errorMessage) {
      return null
    }

    return (
      <p style={{ backgroundColor: '#e99', padding: 10 }}>
        <b>{errorMessage}</b>
        {' '}
        <button onClick={this.handleDismissClick}>
          Dismiss
        </button>
      </p>
    )
  }


  render() {

    return (
      <div>
         { /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}
                    
				<div className="crypto-wrapper">

				  <SideNav
					ActiveTag="index_login"
					/>
				<main className="crypto-main">

				<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
				<IndexLoginPage /> 
				</div>

				</main>

				</div>

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalAccount: state.entities.account
})

export default withRouter(connect(mapStateToProps, {
})(IndexLogin))




