/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import { isLogin, getToken } from '../helper/loginHelper';
import { isLogin } from '../helper/loginHelper';

//import { sendUserWithdrawAddressNew, sendUserWithdrawAddresses, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';

import { renderErrorMessage } from '../components/ErrorMessage'

//import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();
class ProfileAddressNew extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      address: "",
      label: "",
      addressPlaceHolder: i18n.translate('addressPlaceHolder'),
      labelPlaceHolder: i18n.translate('labelPlaceHolder'),
      errorMessage: "",
    }
  }

  componentDidMount() {
    //console.log("ych ProfileAddressNew");
    //window.addEventListener('resize', this.handleResize)


    document.title = "Edit Address - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    const cookieSubmitError = cookies.get('submit_error');
    if ((cookieSubmitError!==undefined)&&(cookieSubmitError!=="")) {
        this.setState({errorMessage: i18n.translate(cookieSubmitError)})

        cookies.set('submit_error', "", {path: '/'});

        const cookieNewAddress = cookies.get('new_address');
        const cookieNewName = cookies.get('new_name');
        this.setState({address: cookieNewAddress});
        this.setState({label: cookieNewName});
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  validateAddress(address) {
    var re = /^0x[a-fA-F0-9]{40}$/;
    return re.test(String(address).toLowerCase());
  }

  handleAddressChange(event)  {
    //console.log("handleAddressChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({addressPlaceHolder: ""})
      } else {
        this.setState({addressPlaceHolder: i18n.translate('addressPlaceHolder')})
      }

      //if (!this.validateAddress(event.target.value)) {
        //this.setState({errorMessage: i18n.translate('addressInvalid')})
      //} else {
        //this.setState({errorMessage: ""})
      //}

      this.setState({address: event.target.value})
    }

  }

  handleLabelChange(event)  {
    //console.log("handleLabelChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({labelPlaceHolder: ""})
      } else {
        this.setState({labelPlaceHolder: i18n.translate('labelPlaceHolder')})
      }
      this.setState({label: event.target.value})
    }

  }

  handleAdd() {
    //console.log("handleAdd");
    this.setState({errorMessage: ""})
    //var self = this;

    //let token = getToken(this);

    if (this.state.address==="") {
      this.setState({errorMessage: i18n.translate('err_addressEmpty')})
    } else if (this.state.label==="") {
      this.setState({errorMessage: i18n.translate('err_labelEmpty')})
    } else if (this.state.label.length>40) {
      this.setState({errorMessage: i18n.translate('err_name_too_long')})
    } else if (!this.validateAddress(this.state.address)) {
      this.setState({errorMessage: i18n.translate('err_addressInvalid')})
    } else {


      if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) { 
        let user = this.props.globalUser.user;

        cookies.set('new_address', this.state.address, {path: '/'});
        cookies.set('new_name', this.state.label, {path: '/'});
//console.log(user);

        if ((user.info!==undefined)&&(user.info.totpEnabled===0)) {
          this.props.history.push("/require2fa?ref=profile_address_new")
        } else {
          this.props.history.push("/to2fa?ref=profile_address_new")
        }
      } else {
        this.props.history.push("/account_address")
      }

/*
      this.props.sendUserWithdrawAddressNew( token, { 
        address: this.state.address,
        name: this.state.label,
        otp: "123456",
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            self.props.sendUserWithdrawAddresses(token,{});
            self.props.history.push("/account_address")
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })
*/

    }
  }


  render() {
    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Account Address Error",this);
    }

    return (
      <div>
			  <div className="crypro-login-container rex_rg">
				<div className="crypro-login-container__box">
				  <div className="crypro-login-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('addnewaddress')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
					<form className="crypto-login-form">
					 <p className="f_formtitle">{this.state.labelPlaceHolder}</p>
					 <div className="mdc-text-field mdc-text-field--fullwidth">
						<input type="text" id="label-text-field" className="mdc-text-field__input" 
                                                  value={this.state.label}
                                                  onChange={this.handleLabelChange.bind(this)}
                                                />
						<div className="mdc-line-ripple"></div>
					  </div>
					  <p className="f_mbottom30 mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
						{i18n.translate('pan1')}
					  </p>

					 <p className="f_formtitle">{this.state.addressPlaceHolder}</p>
					 <div className="mdc-text-field mdc-text-field--fullwidth">
						<input type="text" id="address-text-field" className="mdc-text-field__input" 
                                                  value={this.state.address}
                                                  onChange={this.handleAddressChange.bind(this)}
                                                />
						<div className="mdc-line-ripple"></div>
					  </div>
					  <p className="f_mbottom30 mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg f_txtr">
						
					  </p>

                			<span className="alert-message">
                  			{this.state.errorMessage}
                			</span>
		  
					  <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                                            onClick={() => this.handleAdd()}

                                          >{i18n.translate('add')}</span>
				   </form>
				  </div>
				</div>
			  </div>  
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalLogin: state.entities.login,
    globalUser: state.entities.user,
  }
}

export default withRouter(connect(mapStateToProps, {
  //sendUserWithdrawAddressNew, sendUserWithdrawAddresses
})(ProfileAddressNew))




