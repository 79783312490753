/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import countryList from 'react-select-country-list'

import i18n from 'i18n-react';

import passwordValidator from 'password-validator';

import { renderErrorMessage } from '../components/ErrorMessage'

//import { getAddressString }  from '../helper/displayHelper'

import {getToken } from '../helper/loginHelper';

import { sendUserUpdate, API_SUCCESS } from '../actions'

import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(40)                                  // Maximum length 100
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                                 // Must have digits
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['password', '123123', 'abc123', '121212', 'secret', 'wrex']); // Blacklist these values


const cookies = new Cookies();
class ProfilePassword extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
    }

  }

  componentDidMount() {
    //console.log("ych ProfilePassword");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Profile - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";


  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleOldPasswordChange(event)  {
    //console.log("handleOldPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({passwordPlaceHolder: ""})
      } else {
        //this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({oldPassword: event.target.value})
    }
  }

  handleNewPasswordChange(event)  {
    //console.log("handleNewPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({passwordPlaceHolder: ""})
      } else {
        //this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({newPassword: event.target.value})
      //if (schema.validate(this.state.password)===false) {
        //this.setState({errorMessage: i18n.translate('err_password_too_simple')})
      //}
    }
  }

  handleConfirmPasswordChange(event)  {
    //console.log("handleConfirmPasswordChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({confirmPasswordPlaceHolder: ""})
      } else {
        //this.setState({confirmPasswordPlaceHolder: i18n.translate('confirmPasswordPlaceHolder')})
      }
      this.setState({confirmPassword: event.target.value})
    }
  }

  handleReset() {
    //console.log("handleReset");
    this.setState({errorMessage: ""})

    
    var self = this;

    if ((this.props.globalUser===undefined)||(this.props.globalUser.user===undefined)||(this.props.globalUser.user.info===undefined)) {
      this.setState({errorMessage: i18n.translate('unknown_error')})
    } else if (this.state.oldPassword==="") {
      this.setState({errorMessage: i18n.translate('err_oldPasswordEmpty')})
    } else if (this.state.newPassword==="") {
      this.setState({errorMessage: i18n.translate('err_newPasswordEmpty')})
    } else if (this.state.confirmPassword==="") {
      this.setState({errorMessage: i18n.translate('err_confirmPasswordEmpty')})
    } else if (this.state.confirmPassword!==this.state.newPassword) {
      this.setState({errorMessage: i18n.translate('err_passwordMissmatch')})
    } else if (schema.validate(this.state.newPassword)===false) {
      this.setState({errorMessage: i18n.translate('err_password_too_simple')})
    } else {
      let token = getToken(this);

      this.props.sendUserUpdate(token,{ 
        old_password: this.state.oldPassword,
        password: this.state.newPassword,
      })
      .then (function (success) {
        //console.log("success");
        //console.log(success);
        if (success.type === API_SUCCESS) {
          let response = success.response.entities.response.response;
          //console.log(response);
          if (response.success===false) {
            self.setState({errorMessage: i18n.translate(response.message)})
          } else {
            cookies.set('token',"logout", {path: '/'});
            cookies.set('expire',"logout", {path: '/'});
            if ((self.props.globalLogin!==undefined)&&(self.props.globalLogin.login!==undefined)) {
              self.props.globalLogin.login.success=false;
              self.props.globalLogin.login=undefined;
            }
            if ((self.props.globalUser!==undefined)&&(self.props.globalUser.user!==undefined)) {
              self.props.globalUser.user=undefined;
            }
            if ((self.props.globalAccount!==undefined)&&(self.props.globalAccount.account!==undefined)) {
              self.props.globalAccount.account=undefined;
            }

            self.props.history.push("/login");
          }
        } else {
          self.setState({errorMessage: i18n.translate('unknown_error')})
        }

      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        self.setState({errorMessage: i18n.translate('unknown_error')})
      })

    }
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Reset Password Error",this);
    }

    return (
      <div>
{ /*
        <div className="crypto-loader"></div>
*/ }
        <div className="crypto-wrapper">
          <SideNav
            ActiveTag="profile"
          />
          <main className="crypto-main">
            <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
              <div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
                  <h6 className="f_breadcrumb"><Link to="profile">{i18n.translate('account')} </Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
                  <h1 className="crypto-card__header--title">{i18n.translate('resetpassword')} </h1>
                </div>
              </div>
              <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">

                  <div className="f_centerbox2 mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
                    <div className="crypto-widget">
                      <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth  mdc-theme--primary">
                      </h2>
                    </div>
                  </div>
                  <div className="f_centerbox3 f_formlarge mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
                    <div className="crypto-widget">
                      <div className="crypto-widget__content">
                        <form className="crypto-general-form">
                          <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell--span-12">
                              <p className="f_formtitle">{i18n.translate('oldpassword')} </p>
                              <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                                <input type="password" id="old-password-text-field" className="mdc-text-field__input" 
                                  value={this.state.oldPassword}
                                  onChange={this.handleOldPasswordChange.bind(this)}
                                />
                                <div className="mdc-line-ripple"></div>
                              </div>
                            </div>

                            <div className="mdc-layout-grid__cell--span-12">
                              <p className="f_formtitle">{i18n.translate('newpassword')}</p>
                              <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                                <input required pattern=".{8,}"
                                  id="new-password-text-field"
                                  type="password"
                                  className="mdc-text-field__input"
                                  value={this.state.newPassword}
                                  onChange={this.handleNewPasswordChange.bind(this)}
                                  autoComplete="current-password" />
                                <div className="mdc-line-ripple"></div>
                              </div>
                            </div>

                            <div className="mdc-layout-grid__cell--span-12">
                              <p className="f_formtitle">{i18n.translate('confirmpassword')}</p>
                              <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                                <input required pattern=".{8,}"
                                  id="confirm-password-text-field"
                                  type="password"
                                  className="mdc-text-field__input"
                                  value={this.state.confirmPassword}
                                  onChange={this.handleConfirmPasswordChange.bind(this)}
                                  autoComplete="current-password" />
                                <div className="mdc-line-ripple"></div>
                              </div>
                            <p className="crypto-text-align-left crypto-word-warp mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg crypto-margin-bottom-big" id="pw-validation-msg">{i18n.translate('passwordvalidate')}</p>
                            </div>
{ /*
                <span className="alert-message mdc-layout-grid__cell--span-12">
                  {this.state.errorMessage}
                </span>
*/ }

                            <div className="mdc-layout-grid__cell--span-12 f_btncenter">
                              <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
                                onClick={() => this.handleReset()}

                              >{i18n.translate('reset')}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>				 
    )
  }





}



const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
    globalLogin: state.entities.login,

})


export default withRouter(connect(mapStateToProps, {
  sendUserUpdate
})(ProfilePassword))




