import React, { Component } from 'react';
//import i18n from 'i18n-react';

// import LastEarns from './LastEarns';


/**
 * Renders the preloader
 */
class FaqPageKR extends Component {

  render() {
    return (
      <div>


        <div className="f_single fx_single_tbl">
          <div className="tabset">
            <label htmlFor="tab2">
              <span className="mdc-tab__content fx_titlefont">
                <span className="mdc-tab__text-label">FAQ</span>
              </span>
            </label>
          </div>
        </div>

        <div className="faq_tag">
          <a href="#general">기본 설명</a>
          <a href="#subscription">가입 절차와 입금에 관한 자주 묻는 질문</a>
          <a href="#wrex">WREX 플랫폼에 대해</a>
          <a href="#affiliate">추천인 제도에 대해서</a>
          <a href="#withdraw">출금과 출금 수수료에 대해</a>
        </div>


        <p id="general" className="faq_tit">기본 설명</p>
        <div className='swanky_wrapper faq'>
          <input id='pr1' name='radio' type='checkbox' />
          <label htmlFor='pr1'>
            <span className="th_faq">1. WREX에 대해 설명해 주세요!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>저희는 고객의 이더리움 자산을 운영합니다. WREX 플랫폼에 위탁하신 이더리움을 세계에서 가장 큰 이더리움 페어링 시장에서 매수와 매도를 통해 고객의 이더리움 자산을 증식하는 목적을 가지고 있습니다.</li>
                    <li>저희가 개발한 WREX 3단계 이더리움 알고리즘은 빅데이터 분석을 통해 특정 알트 코인을 특정 가격에 매수 주문하고, 숙련되고 경험이 많은 자체 트레이더들이 수익을 내는 시스템입니다.</li>
                    <li>저희 시스템을 로봇 트레이딩이 아닙니다. 알고리즘 분석을 통해 매수 시점을 같으나, 매도 시점은 각 트레이더들의 개인의 판단에 의해 행해집니다.</li>
                    <li>저희 시스템을 통해 2018년 1월부터 단 한번도 손실이 발생한 달이 없으며, 매달 5%에서 80% 사이의 수익율을 기록하고 있습니다. 저희가 단 한번도 손실을 기록한 달이 없는 이유는 아주 간단합니다. 단 하나의 거래도 손실을 발생하며 매각하지 않기 때문입니다.</li>
                    <li>투명성을 극대화 하기 위해, 전세계 10대 회계 및 감사법인 중 하나인 Baker Tilly에서 한달에 한 번 저희 트레이딩 회사에 방문하여, 모든 거래 내역, 모든 계정 거래 내역, 그리고 월 수익율을 검수하고 감사하여 월 보고서를 작성하여 저희 홈페이지를 통해 고객께서 확인하실 수 있습니다.  </li>
                    <li>Baker Tilly를 통해 월수익율이 확정되면, 저희는 다음과 같이 월수익을 배분합니다: <br />45% - 고객<br />5% - 추천인제도 프로그램<br />50% - WREX 수수료</li>
                    <li><b>월 수익에서만 배분</b>하고 그리고 단 <b>한번도 월 손실이 발생하지 않는다면</b>, 고객이 위탁한 이더리움 자산은 절대 보존될 것입니다. 위 두 가지만 지켜지면 저희 WREX 시스템은 영원하게 운영될 수 있으며, 자산을 영원하게 증식될 수 있습니다. </li>
                    <li>WREX에는 두 가지의 상품이 있으며, 상황과 전략에 맞게 선택하여 이더리움을 두상품에 위탁하시면 됩니다.</li>
                  </ol>
                  <h3>WREX 계정</h3>
                  <ul>
                    <li>WREX 계정 상품에는 최소 1이더리움부터 최대 100개까지 입금하실 수 있습니다. (최대 100개의 이더리움 입금 한도가 있습니다. 다만, 이 것은 입금 한도이고, 운영에 의해 100 이더리움 이상이 되는 것은 한도와는 상관없습니다. 만약 100 이더리움 이상 입금을 원하시면 <a href="mailto:support@w-rex.com">support@w-rex.com</a> 으로 연락 주시기 바랍니다)</li>
                    <li>WREX 계정의 추천인 보너스 수령을 위해서는 반드시 해당 월 1일부터 마지막날까지 WREX계정에 최소 1 이더리움을 유지해야 합니다. </li>
                    <li>그리고 WREX 계정에 최소 1 이더리움을 유지해야 최대 10개의 100 이더리움 플랜 계정을 가입하실 수 있습니다.</li>
                    <li>
                      WREX 계정은 [월수익 자동인출]과 [월수익 자동입금]을 선택하실 수 있습니다. (어떤 옵션을 택하시던 중간에 언제든지 수동으로 인출 요청을 하실 수 있습니다)<br />
                      * [월수익 자동출금] – WREX 계정의 모든 월수익 중 투자자의 몫인 45%가 자동 출금되어, 매월 10일 WREX My ETH Wallet (WREX 이더리움 지갑)으로 전송됩니다.<br />
                      * [월수익 자동입금] – WREX 계정의 모든 월수익 중 투자자의 몫인 45%가 자동으로 WREX 계정에 입금됩니다. 매월 입금되는 이더리움 만큼 원금이 커집니다.
                          </li>
                    <li>[월수익 자동출금]과 수동 출금에는 각각 다른 출금 수수료율이 적용됩니다. 수수료율은 ‘자주묻는질문’에 ‘출금과 출금수수료’ 란을 참고하시기 바랍니다.</li>
                    <li>만약 출금이후 WREX 계정에 1 이더리움보다 적은 이더리움이 남으면, 해달 월의 추천인 보너스를 수령하실 수 없고, 유지하시던 모든 100 이더리움 플랜은 자동해지 되고, 모든 이더리움은 WREX my ETH 지갑으로 자동 이체 됩니다.</li>
                  </ul>
									<br/>
									<p>* 9월 1일 부터 신규회원의 경우 WREX 상품에는 1ETH만 입금이 가능합니다.<br/>기존 회원은 변경 적용되는 내용은 없으나 2ETH 이상 보유하고 계신 회원은 1ETH를 제외한 나머지 ETH에 대해 출금 후 재입금이 불가함을 알려드립니다.</p>
									<br/>
                  <h3>100 이더리움 플랜</h3>
                  <ul>
                    <li>WREX 계정에 최소 1 이더리움을 유지하신 상태에서 최대 10개까지의 100 이더리움 플랜을 생성하실 수 있습니다.</li>
                    <li>각각의 100 이더리움 플랜에는 오직 1개의 이더리움만 입금하실 수 있습니다. WREX My ETH 지갑에서 전송해야 합니다.</li>
                    <li>100 이더리움 플랜에 대한 추천인 보너스를 수령하시기 위해서는 최소 1개의 100 이더리움 플랜을 유지해야 합니다. 귀하의 모든 100 이더리움 플랜의 추천인 보너스는 귀하의 첫 번째 100 이더리움 플랜으로 매달 지급됩니다.</li>
                    <li>모든 100 이더리움 플랜은 [월수익 자동입금]으로 설정 되어 있으며 수정할 수 없습니다. WREX 계정의 경우, 매월 전체 월수익의 투자자의 몫인 45%가 재적립되는 것과 달리 모든 100 이더리움 플랜에는 회사가 회사 몫인 45%를 추가 적립하여 월수익의 총 90%가 재적립되어 운영됩니다. WREX 계정에 비해 성장 속도가 빠릅니다. </li>
                    <li>100 이더리움 플랜의 만기는 통상적으로 40개월 입니다.</li>
                    <li>100 이더리움 플랜의 일부 이더리움을 중도 출금할 수 없습니다. 언제든지 고객의 판단으로 중도 해지할 수는 있습니다. 조기해약 수수료는 없습니다. 해지한 100 이더리움 플랜의 모든 이더리움은 해지 이후 다음 달 10일에 고객의 My ETH 지갑으로 전송됩니다.</li>
                  </ul>
									<br/>
                  <p>* WREX My ETH 지갑에서 WREX 계정 또는 100 이더리움 플랜으로 전송하실 때 입금 수수료는 없습니다. 다만, WREX 계정 또는 100 이더리움 플랜에서 My ETH 지갑으로 출금하실 때에는 출금 수수료가 발생합니다.</p>
									<br/>
									<p>* 8월 1일 이후 개설 된 모든 100이더리움 플랜 상품은 아래와 같이 변경됨을 알려드립니다.<br/>
											기존:<br/>
											- 1개의 100이더리움 상품당 1ETH만 입금 가능함.<br/>
											- 조기 해지 시 위약금 없음.<br/>
											변경:<br/>
											- 1개의 100이더리움 상품당 최소1ETH에서 최대 100ETH까지 입금 가능함.<br/>
											- 최소 유지 기간이 24개월 이며, 조기 해지 시 원금을 제외한 본인 수익 50%에서 25%만 지급함.
									</p>
									<br/>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2' name='radio' type='checkbox' />
          <label htmlFor='pr2'>
            <span className="th_faq">2.	어떻게 시작하면 되죠?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>이더리움을 보유하고 있어야 합니다. 최소 1 이더리움과 전송수수료(GAS, 약0.001 이더리움)이 필요합니다. </li>
                    <li>www.w-rex.com으로 접속하셔서 가입 후 고유의 이더리움 지갑 주소를 수령하시기 바랍니다.</li>
                    <li>수령하신 이더리움 지갑 주소로 이더리움 자산을 보내시면 됩니다. </li>
                    <li>WREX My 이더리움 지갑에 최소 1개 이상의 이더리움을 가지고 계시면, 일부 이더리움을 WREX 계정으로 옮기면 됩니다. (주의: 최대 100 이더리움) </li>
                    <li>또한, 1개 또는 다수 (최대 10개)의 100 이더리움 플랜을 설정하시고, 각각의 플랜에 1개의 이더리움을 보내주시면 됩니다.<br />* WREX My 이더리움 지갑에서 WREX 계정 또는 100 이더리움 플랜에 보내시는 이더리움에는 이체 수수료가 없습니다.</li>
                    <li>이제 매월 접속하셔서 고객님의 계정, 월 보고서 및 자산 현황을 웹 또는 앱을 통해서 확인하시면 됩니다.<br />* 월1회 발간되는 운영보고서는 매월 8일에 제공됩니다. 매월 10일에는 고객님의 계정이 업데이트 되고, 매월 수익이 반영된 신규 현황을 확인하실 수 있습니다. 그리고 [월수익 자동출금]을 선택하신 고객은 매월 10일에 WREX My 이더리움 지갑을 확인하시면 됩니다. </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3' name='radio' type='checkbox' />
          <label htmlFor='pr3'>
            <span className="th_faq">3.	추천인 제도에 대해서 설명해 주세요!</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <ol>
                    <li>WREX에 가입을 하시면, 회원마다 고유의 추천인 코드를 수령하십니다. 이 코드를 주위에 가입에 관심있는 친구, 가족 그리고 지인들에게 주시면 됩니다. 신규 가입자는 반드시 누군가의 추천인 코드를 기입해야 WREX에 가입이 완성됩니다.</li>
                    <li>추천인 제도 메뉴에는 추천인 관련된 많은 고유 정보를 담고 있습니다. 메뉴에서 고객님이 추천한 친구 수, WREX계정에 가입된 총 이더리움 수량, 100 이더리움 플랜에 가입된 총 이더리움 수량 등에 대한 정보를 보실 수 있습니다.</li>
                    <li>
                      추천인 보너스의 예시<br />
                      회원 A는 2 이더리움 등록 (WREX계정에 1개의 이더리움과 100 이더리움 플랜에 1개의 이더리움)<br />
                      회원 B/C/D/E가 회원 A의 추천 코드로 WREX에 가입<br />
                      회원 B는 총 11개의 이더리움을 등록 (WREX 계정에 1개의 이더리움과 10개의 100 이더리움 플랜 생성 후 각각 1 이더리움)<br />
                      회원 C는 총 5개의 이더리움을 등록 (WREX 계정에 1개의 이더리움과 4개의 100 이더리움 플랜 생성 후 각각 1 이더리움)<br />
                      회원 D는 총 100개의 이더리움을 등록 (WREX 계정에 90개의 이더리움과 10개의 100 이더리움 플랜 생성 후 각각 1이더리움)<br />
                      회원 E는 총 2개의 이더리움을 등록 (WREX 계정에 1개의 이더리움과 1개의 100 이더리움 플랜 생성 후 각각 1이더리움)<br />
                      위 실적에 의해 회원 A는 WREX 계정에 총 93개의 이더리움과 100 이더리움 플랜에 총 25개의 이더리움을 추천했으며, 매월 발생하는 월수익율 (이하 “MP%”)의 추천인 보너스인 5%를 각각 다음과 같이 수령합니다.<br />
                      <b>WREX 계정의 추천인 보너스 계산법: <br />
                        [WREX 계정 추천 이더리움 수량] x [MP%] x 5% = 93 x 월수익률 x 5% 가 회원 A의 WREX 계정으로 입금. (만약 회원 A의 WREX 계정이 [월수익 자동출금]으로 선택되어 있을 경우, 이 추천인 보너스는 회원 A의 WREX My 이더리움 지갑으로 지급)<br /></b>
                      <b>100 이더리움 플랜 추천인 보너스 계산법: <br />
                        [100 이더리움 추천 이더리움 수량] x [MP%] x 5% = 25 x 월수익률 x 5% 가 회원 A의 첫번째 100 이더리움 플랜으로 입금<br /></b>
                    </li>
                    <li>
                      WREX의 추천인 제도가 왜 강력한지<br />
                      * 지속 가능성 매우 높음 – WREX의 추천인 보너스는 매월 발생하는 수익의 5%입니다. 즉, 원금(회원들의 납입금)에서 나눠주는 것이 아닙니다. <br />
                      * 매월 WREX 계정의 모두 또는 일부가 [월수익 자동입금]을 선택했다면, 월수익의 45%의 전부 또는 일부가 추천한 이더리움 원금에 더해집니다. 즉, 원금이 매월 증가된다는 이야기입니다. 즉, 매월 수령하는 추천인 보너스도 원금 증액되는 만큼 증가됩니다.<br />
                      * WREX에서 45%를 매칭하기 때문에, 모든 회원의 100 이더리움 플랜의 성장속도는 WREX 계정의 이더리움 보다 빠릅니다. 회원들의 100 이더리움 플랜 추천인 보너스 역시 빠르게 커집니다. <br />
                      * 계산법: [추천한 모든 100 이더리움 플랜의 이더리움 수량] x [MP%] x 5%  <br />
                      * WREX에 가입한 모든 회원들은 가입시기와 상관없이 모두 평등한 월수익율을 수령합니다. WREX 계정에는 월수익율의 45%가 재적립 또는 출금되며, 모든 100 이더리움 플랜에는 월수익율의 90%가 재적립 됩니다. 고로, WREX를 주변에 널리 소개하고 추천하는데 두려움을 갖지 마세요. 모든 피추천인들이 고객님께 감사하는 날이 반드시 올 것입니다.<br />
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="subscription" className="faq_tit">가입 절차와 입금에 관한 자주 묻는 질문</p>
        <div className='swanky_wrapper faq'>
          <input id='pr2_1' name='radio' type='checkbox' />
          <label htmlFor='pr2_1'>
            <span className="th_faq">1.	이더리움은 어디서 어떻게 구매하나요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    거주하는 지역에 따라 구매할 수 있는 방법은 다릅니다. 기본적으로 거주 지역의 화폐를 입금 받을 수 있는 암호화폐 거래소에서 구매하시는 것이 일반적인 사례입니다. <br />
                    자세한 이더리움 구매 방법은 귀하를 추천한 회원에게 물어보시기 바랍니다. <br />
                    WREX는 이더리움을 구매대행 하지 않습니다.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_2' name='radio' type='checkbox' />
          <label htmlFor='pr2_2'>
            <span className="th_faq">2.	이더리움 네트워크 비용(GAS)는 무엇입니까?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    이더리움을 귀하가 이더리움을 구매한 거래소 지갑에서 WREX 이더리움 지갑으로 송금하기 위해서는 비용이 발생하는데, 이것을 GAS라고 표현합니다. <br />
                    그래서 일차적으로 이더리움을 구매하실 때 송금수수료 만큼 추가 구매를 해야 합니다. GAS 비용은 통상적으로 0.001 이더리움 미만입니다. <br />
                    고로, 구매하실 때는 WREX에 입금할 이더리움 수량에 송금수수료를 추가 구매해야 합니다. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_3' name='radio' type='checkbox' />
          <label htmlFor='pr2_3'>
            <span className="th_faq">3.	추천인 코드는 무엇입니까? 추천인 코드를 받지 못했습니다.</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    귀하 역시 가입이 완료되시면, 귀하의 고유 추천인 코드를 부여 받습니다. 이 추천인 코드를 가입을 희망하는 지인에게 알려드리면 됩니다. 한번 등록된 추천인 코드는 수정이 불가능 합니다. 그리고 반드시 추천인 코드를 입력해야지만 가입이 완성됩니다.<br />
                    이 정책은 WREX를 홍보하고 널리 알리는 회원들에게 추천인 보너스가 돌아가게 만들어 드립니다. WREX는 자체적으로 회원 모집에 홍보를 하지 않기 때문에 회원이 되길 희망하는 신규 회원이라면 어디선가 누군가에게서 저희 사이트에 대한 홍보를 받으셨을 것이고, 그 회원에게 혜택이 돌아가는 것은 당연한 결과입니다.<br />
                    혹시 우연히 저희 사이트를 접하시게 되셨고, 가입을 희망하 는데 추천인 코드가 없어 가입이 안된다면, <a href="mailto:support@w-rex.com">support@w-rex.com</a>으로 연락 주십시오.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_4' name='radio' type='checkbox' />
          <label htmlFor='pr2_4'>
            <span className="th_faq">4. 가입시 등록했던 이메일 주소를 더 이상 사용이 불가능 합니다.</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    WREX의 아이디는 귀하의 이메일 주소입니다. 만약 어떠한 이유로 가입시 사용한 이메일 접속이 더 이상 안될 경우 <a href="mailto:support@w-rex.com">support@w-rex.com</a>
                    연락 주시기 바랍니다. <br />가입시 특히 회사 이메일 주소를 사용하지 마시기 바랍니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_5' name='radio' type='checkbox' />
          <label htmlFor='pr2_5'>
            <span className="th_faq">5.	구글 2FA는 무엇인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    은행 OTP와 같은 개념입니다. 출금시 반드시 OTP 번호를 기입 해야 합니다. 구글 OPT는 스마폰 전용으로 구글 앱스토어나 애플 스토어에서 다운 받으실 수 있습니다.
                        앱을 실행하시고 추가 버튼 누른 후 WREX에서 제공하는 QR CODE를 스캔하시면 언제든지 6자리 OTP 번호를 받으실 수 있습니다.  <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_6' name='radio' type='checkbox' />
          <label htmlFor='pr2_6'>
            <span className="th_faq">6.	6.	구글 2FA으로 사용하던 스마폰을 분실했습니다. 어떻게 다시 재설정 할 수 있나요? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    구글 OTP 앱을 다운 받으시고 재설정 하시면 됩니다. 재설정 시에는 이메일로 재설정 확인 이메일이 전송됩니다. 귀하의 추천인에게 연락하여 확인 작업이 진행 되니 재설정 시간이 다소 소요될 수 있습니다. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_7' name='radio' type='checkbox' />
          <label htmlFor='pr2_7'>
            <span className="th_faq">7.	비밀번호는 분실했습니다.</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    비밀번호 분실은 복구를 원하시면  <a href="mailto:support@w-rex.com">support@w-rex.com</a>으로 이메일을 보내주십시오. 이메일으로 재설정 확인 이메일이 전송됩니다. 또한, 구글 2FA 분실때와 같이 귀하의 추천인에게 연락하여 확인 작업이 진행 되니 재설정 시간이 다소 소요될 수 있습니다.  <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_8' name='radio' type='checkbox' />
          <label htmlFor='pr2_8'>
            <span className="th_faq">8.	100 이더리움 이상 입금을 원하면 어떻게 해야 하나요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    100 이더리움이 넘는 수량을 입금하시려면 별도의 신분 확인 절차를 반드시 진행해야 합니다.  <a href="mailto:support@w-rex.com">support@w-rex.com</a> 으로 연락 주시기 바랍니다.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr2_9' name='radio' type='checkbox' />
          <label htmlFor='pr2_9'>
            <span className="th_faq">9.	10 이더리움을 지난 달에 입금했는데 왜 전체 월수익율의 50%만 입금되었죠?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    WREX 계정에 첫 달만 생길 수 있는 현상입니다. WREX는 입금 시점에 따라 첫 달에만 차등 지급합니다. 두번째 달부터는 정상적 입금이 됩니다. 아래 표에 따라, 입금 시점에 따라 첫 달의 수익률 배분이 상이할 수 있습니다.<br />
                    <table className="tabset">
                      <tr><th>WREX계정 입금시점</th><th><span className="span_mr3"></span></th><th>WREX 계정 월수익률 </th> </tr>
                      <tr><td>1일 ~ 10일</td><td>  </td><td>50%</td></tr>
                      <tr><td>11일 ~ 20일</td><td>  </td><td>25%</td></tr>
                      <tr><td>21일 ~ 마지막 날</td><td>  </td><td>0%</td></tr>
                    </table><br />
                    100 이더리움 플랜의 경우 모든 입금은 다음 달 1일부터 계약이 유효합니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="wrex" className="faq_tit">WREX 플랫폼에 대해</p>
        <div className='swanky_wrapper faq'>
          <input id='pr3_1' name='radio' type='checkbox' />
          <label htmlFor='pr3_1'>
            <span className="th_faq">1.	유사한 상품을 제공하는 사이트를 많이 봤습니다. 무엇이 다른가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    그렇습니다. 실제 많은 사이트들이 저희와 유사한 상품을 제공하고 있습니다.
                            </p>
                  <ol>
                    <li>
                      “원금 보장”을 제안하는 사이트<br />
                      저희는 원천적으로 원금을 보장하지 않습니다. 많은 국가에서 원금을 보장하는 상품을 법에서 금지하고 있습니다. 많은 사이트에서 제공하는 원금을 보장한다는 문구는 결국 잘 못된 홍보 전략입니다.<br />
                      그러나, 저희의 모든 수익 배분은 말 그대로 월 수익에 대해서만 배분을 합니다. 월수익이 발생하면, 저희 거래 수익 수수료를 제하고 배분됩니다. 만약 수익율이 0%라면 저희는 수수료를 받지 않습니다. 저희 프로젝트를 시작한 이래에 단 한번도 마이너스 수익율을 기록하지 않았지만, 혹시라도 마이너스 수익이 난다면, 마이너스 수익율 만큼 과거의 수수료에서 충당해서 원금이 손실 나지 않도록 최선을 다하겠습니다.
                              </li>
                    <li>
                      “특정 수익율”을 제공하는 사이트<br />
                      역시 특정 수익율을 명시하는 것은 많은 국가에서 법으로 금지하고 있습니다. 저희 역시 특정 수익율을 보증하고 있지 않습니다. 다만, 과거의 실적이 월별 5%에서 80%이며, 단 한번도 마이너스 수익을 기록하지 않았다는 점이 저희 사이트의 장점입니다.
                              </li>
                    <li>
                      Baker Tilly 세계 10대 회계법인의 월별 거래내역, 계좌내역 그리고 월 수익률 검증<br />
                      매월 1일에서 5일 사이에 Baker Tilly의 담당자들이 저희 사무실을 내방하여 모든 거래 내역과 계좌 내역을 100% 검수합니다. 이를 바탕으로 월 수익률 역시 확정됩니다. 매월 8일에는 이러한 검증 절차를 통해 작성된 월 단위 운영 보고서가 작성되고 웹사이트에 개제됩니다.
                              </li>
                    <li>
                      WREX 거래 수수료 – 월수익의 50%<br />
                      네, 저희 역시 저희 수수료가 매우 높다는 것을 잘 알고 있습니다. 하지만 저희 수수료를 제하고도 회원들에게 배분하는 수익률은 업계에서 가장 높은 편에 속한다고 자신하고 있습니다. 그리고 저희의 높은 수수료는 저희가 연속성을 가지고 사업에 임할 수 있는 큰 동기부여이자, 일부 자금은 유사시에 가용할 수 있는 자금과 이 사업에 관련된 협력업체 서비스 비용 및 운영비로 사용됩니다.
                              </li>
                    <li>
                      절대 어떠한 경우도 원금은 보존되고, 매월 증식합니다<br />
                      많은 유사 사이트들이 비슷한 상품을 출시하고도 실패하는 경우는 바로 원금 손실에서 비롯됩니다. 투자를 유치하기 위해 마케팅 비용으로 사용하는 경우원금에서 커미션을 제공하기 때문에 처음부터 원금이 보너스로 지출되거나, 운영에 있어서 마이너스 손실이 발생하는 경우가 그렇습니다. <br />
                      저희 WREX는 원금에서 절대 비용 처리를 하지 않습니다. 모든 원금 100%는 거래에 사용되며, 보너스, 비용 등의 회사에서 지출하거나 배분하는 것 모두가 월수익금에서 배분됩니다. 즉, 원금은 절대 줄어들지 않습니다. <br />
                      또한, 거래를 통해 절대 마이너스 나는 거래는 청산하지 않습니다. 이러한 노력들은 Baker Tilly의 월보고서에서도 투명하게 공개됩니다. <br />
                      고로, 마이너스 수익률을 기록하지 않는 이상, 절대 원금이 줄어들지 않습니다. 최악의 경우, 수익이 날 만한 거래가 월별로 단 한차례도 없을 경우 수익률 0%는 기록할 수 있을지 언정 절대 마이너스 수익률은 없습니다.<br />
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_2' name='radio' type='checkbox' />
          <label htmlFor='pr3_2'>
            <span className="th_faq">2. WREX는 정말 내 자산에 대한 손실을 보지 않는가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    거래 규정 1조, 모든 거래는 수익이 날 때만 청산할 수 있다. 즉, 마이너스 수익률에는 절대 매각하여 중산 청산하지 않는다가 저희 거래의 첫 규칙입니다. 과거에도 없었고, 미래에도 없을 것입니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_3' name='radio' type='checkbox' />
          <label htmlFor='pr3_3'>
            <span className="th_faq">3.	내 자산에 대해 보증을 해주나요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    법적으로는 원금 보증을 해드릴 수 없습니다. 다만, 위에서 언급한 것처럼 월 마이너스 수익이 날 경우, 기존에 받았던 월별 거래 수수료로 손실 보존을 할 수 있습니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_4' name='radio' type='checkbox' />
          <label htmlFor='pr3_4'>
            <span className="th_faq">4.	월수익율은 얼마나 기대할 수 있나요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    2018년부터 2019년 8월까지 매월 5%~80%의 수익률을 기록했습니다. 하지만, 이 것은 가이드라인일 뿐 보장은 아닙니다.<br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_5' name='radio' type='checkbox' />
          <label htmlFor='pr3_5'>
            <span className="th_faq">5.	WREX가 제공하는 상품은 무엇이 있나요? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    현재 저희는 WREX 계정 상품과 100 이더리움 플랜 상품을 제공하고 있습니다. <br />
                    WREX 계정 상품<br />
                  </p>
                  <ol>
                    <li>WREX 계정에는 최소 1 이더리움에서 최대 100 이더리움까지 입금하실 수 있습니다. 만약 100 이더리움 이상을 입금하시려면 <a href="mailto:support@w-rex.com">support@w-rex.com</a>으로 한도상향을 요청하시기 바랍니다. </li>
                    <li>WREX 계정을 생성하시면 [월수익 자동출금] 또는 [월수익 자동재입금]을 선택하실 수 있습니다. 옵션 선택과 상관없이 언제든지 수동으로 출금을 요청할 수 있습니다.</li>
                    <li>옵션 선택에 따라 [WREX계정에 나의 이더리움] x 45% x 월수익률% 가 자동 출금되어 WREX 내 이더리움 지갑에 이체되거나 아니면 WREX 계정에 입금되어 다음 달 원금이 됩니다.</li>
                    <li>WREX 계정에 1일부터 월 마지막 날까지 최소 1 이더리움이 유지되어야 해당 월의 모든 WREX 계정 추천인 보너스를 수령할 수 있습니다.</li>
                    <li>WREX에 최소 1 이더리움을 유지하면 언제든지 10개의 100 이더리움 플랜을 생성할 수 있습니다.</li>
                  </ol>
                  <p>100 이더리움 플랜</p>
                  <ol>
                    <li>계정당 총 10개의 100 이더리움 플랜을 생성할 수 있습니다.</li>
                    <li>WREX 계정에 최소 1개의 이더리움을 유지해야 합니다.</li>
                    <li>모든 100 이더리움 계정에는 단 1개의 이더리움만 입금할 수 있습니다.</li>
                    <li>100 이더리움 계정은 생성 후 다음 달 1일부터 시작됩니다.</li>
                    <li>모든 100 이더리움 플랜의 추천인 보너스는 추천인의 첫 번째 생성된 100 이더리움 플랜에 입금됩니다. 100 이더리움 플랜이 없을 경우 100 이더리움 플랜 추천인 보너스를 수령할 수 없습니다.</li>
                    <li>모든 100 이더리움 플랜은 [100 이더리움 플랜에 가입된 추천 이더리움] x 90% x 월수익% 가 다음 달 시작 원금이 됩니다. </li>
                    <li>100 이더리움 플랜의 정상적인 만기는 40개월입니다.</li>
                    <li>100 이더리움 플랜은 중간에 출금할 수 없습니다. 대신 조기 해약할 수 있습니다. 해약 패널티는 없습니다만 다시 시작하게 되면 1 이더리움으로 시작할 수 있습니다. </li>
                  </ol>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_6' name='radio' type='checkbox' />
          <label htmlFor='pr3_6'>
            <span className="th_faq">6.	WREX 계정 상품과 100 이더리움 플랜의 차이점은 무엇인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>WREX 계정에 최소 1 이더리움 이상을 유지하면 다음과 같은 혜택이 있습니다</p>
                  <ol>
                    <li>최대 10개의 100 이더리움 플랜을 즉시 생성할 수 있습니다.<br /></li>
                    <li>WREX 계정의 모든 추천 이더리움에 대한 추천인 보너스를 수령할 수 있습니다. (1일~마지막날까지 최소 1이더리움이 유지되면 수령 가능)<br /></li>
                  </ol>
                  <p>
                    최소한 1개의 100 이더리움 플랜이 유지되면 다음과 같은 혜택이 있습니다.  </p>
                  <ol><li>모든 100 이더리움 플랜에 추천한 이더리움에 대한 추천인 보너스 수령할 수 있습니다. </li></ol>

                  <p>WREX 계정과 100 이더리움 플랜의 가장 큰 차이점은 성장률입니다. 위에서 설명 드린 것과 같이 1 이더리움을 WREX 계정과 100 이더리움 플랜에 각각 유지되고 월평균 수익률이 15.8%를 기록한다면 40개월 후 WREX 계정의 1 이더리움은 15.6 이더리움으로 성장하고 100 이더리움 플랜은 102 이더리움으로 성장합니다.
이유는 WREX 계정은 매달 월수익률의 45%가 자동 재적립되며, 100 이더리움 플랜은 총 90% (회사에서 45% 매칭)가 자동 재적립되기 때문입니다.
</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_7' name='radio' type='checkbox' />
          <label htmlFor='pr3_7'>
            <span className="th_faq">7.	2개의 이더리움을 투자하면 얼마의 수익을 기대할 수 있을까요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    만약 WREX 계정에 1개의 이더리움 그리고 1개의 100 이더리움 플랜을 유지한다고 가정하면, 두 가지의 추천인 보너스 수령 조건을 만족시킵니다. <br />
                    또한, 평균 월수익률 15.8%를 달성하고, [월수익 자동재적립]을 선택했다면, 40개월 후 WREX 계정은 총 15.6개의 이더리움으로 그리고 100 이더리움 플랜은 총 112개 이더리움으로 성장하여 총 117.6개의 이더리움의 자산을 보유하게 됩니다.<br />
                    위 자산 예상은 추천인 보너스를 계산하지 않은 수치입니다. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr3_8' name='radio' type='checkbox' />
          <label htmlFor='pr3_8'>
            <span className="th_faq">8.	Baker Tilly 회계법인의 역할은 무엇인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    투명성을 극대화 하기 위함 입니다. 즉, 우리 사업의 영속성을 보장합니다. Baker Tilly는 전세계 10대 회계법인 중 하나로 매월 1일에서 5일 사이에 우리 사무실에 내방해서 모든 거래에 대해 전수 검수를 하게 됩니다. 또한, 계정에 대한 검수 그리고 월수익률을 확정하게 되며, 월보고서가 8일에 웹사이트에 개제되어 회원들이 열람할 수 있게 할 예정입니다. <br />
                  </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="affiliate" className="faq_tit">추천인 제도에 대해서</p>
        <div className='swanky_wrapper faq'>
          <input id='pr4_1' name='radio' type='checkbox' />
          <label htmlFor='pr4_1'>
            <span className="th_faq">1.	정말 내가 추천에 쏟아 붇는 노력에 대해 전부 보상 받을 수 있는겁니까?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    네 그렇습니다. 모든 신규 가입자는 반드시 추천인 코드를 기입해야 가입이 완료됩니다. WREX는 신규 가입자를 얻기 위한 홍보를 따로 하지 않기 때문에 모든 가입자는 추천인의 코드를 기입하게 될 것입니다. WREX는 노력과 시간을 투입하여 WREX를 홍보하는 추천인들이 모든 보상을 받을 수 있는 시스템 구축에 노력하고 있습니다.
                          </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_2' name='radio' type='checkbox' />
          <label htmlFor='pr4_2'>
            <span className="th_faq">2.	다른 유사 사이트 추천인 보상제도와 무엇이 다른가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    많은 유사 사이트에는 저희와 비슷한 추천인 제도를 운영하고 있습니다. 다른 사이트는;
                        </p>
                  <ol>
                    <li>추천인 코드를 기입하지 않아도 가입이 가능합니다. 실수이던 고의이던 코드가 누락되면 누락된 코드에 대해서는 보너스를 수령할 수 없습니다.</li>
                    <li>만약 추천한 회원이 그만 두게 되면 더 이상 보너스를 수령할 수 없습니다. 보통 타 추천인 제도가 있는 사이트는 영속성이 낮습니다.</li>
                  </ol>
                </li>
              </ul>
              <p>
                이와 반면, WREX는 반드시 추천인 코드를 기입해야만 가입이 완성됩니다. 한번 기입된 코드는 변경할 수 없습니다. 또한, 통상적으로 우리 사이트에 가입한 회원들은 오랫동안 계정을 유지하게 될 것입니다. 유지하는 동안 추천인 보너스는 계속 수령할 수 있습니다. <br />
                또한, WREX는 WREX 계정과 100 이더리움 플랜의 추천 계정 이더리움이 매월 증가 됩니다. 모든 추천 보너스는 추천인의 계정의 이더리움 수량에 기반하므로 매월 기준 이더리움 수량이 증가하면 추천 보너스 역시 증가합니다.
                    </p>
            </div>
          </label>
          <input id='pr4_3' name='radio' type='checkbox' />
          <label htmlFor='pr4_3'>
            <span className="th_faq">3.	WREX는 다단계 마케팅인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    아닙니다. WREX나 WREX의 자금 조달 방식은 다단계 방식이 아닙니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_4' name='radio' type='checkbox' />
          <label htmlFor='pr4_4'>
            <span className="th_faq">4. 만약 100명 추천을 했다면, 받을 수 있는 추천 보너스가 어느 정도인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    예시<br />
                    만약 한 회원이 100명을 WREX에 추천했고, 각각 2 이더리움씩을 (이더리움 1개는 WREX 계정에, 1개의 100 이더리움 플랜) 유지하고 있다면, 해당 회원은 100개의 이더리움을 WREX 계정에 추천했고, 100개의 이더리움 플랜을 추천 한 것입니다.<br />
                    만약 WREX가 월평균 수익을 15.8%를 달성한다면,<br />
                    회원의 추천 보너스는 다음과 같이 계산됩니다.<br /><br />
                  </p>
                  <ol>
                    <li>WREX 계정 – 추천인 보너스<br />[WREX 계정에 추천 이더리움 수량] x 5% x [Monthly Profit %]<br />[100 이더리움] x 5% x 15.8% = 0.79 이더리움<br /></li>
                    <li>100 이더리움 플랜 – 추천인 보너스<br />[100 이더리움 플랜에 추천 이더리움 수량] x 5% x [Monthly Profit %]<br />[100 이더리움] x 5% x 15.8% = 0.79 Ethereum</li>
                  </ol>
                  <p>	고로, 첫 달 추천보너스는 1.58 이더리움 입니다. 만약 계정 성장율과 추천 보너스로 원금이 증가되고 40개월 유지한다면 수천개의 이더리움으로 성장할 것입니다.</p>
                </li>
              </ul>
            </div>
          </label>
          <input id='pr4_5' name='radio' type='checkbox' />
          <label htmlFor='pr4_5'>
            <span className="th_faq">5. 많은 추천을 했습니다, 하지만 이번 달 추천인 보너스를 못 받았습니다. 어떻게 된것인가요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  <p>
                    두가지 경우가 있을 수 있습니다.<br />
                    첫 번째는 회원님 본인 계정이 추천보너스를 받을 수 있는 자격 요건이 되는지를 확인해 주시기 바랍니다.<br />
                    WREX 상품 추천 보너스 수령 자격 요건은, 본인의 WREX 상품에 지난 달 1일부터 마지막 날까지 최소 1 이더리움을 계속 유지해야 합니다.<br />
                    100 이더리움 플랜 상품 추천 보너스 수령 자격 요건은, 지난 달 1일부터 마지막 날까지 계약이 유효했어야 합니다.<br /><br />
                    두 번째는 추천하신 회원들 역시 위 조건을 만족해야 합니다. <br /><br />
                    그래도 계산이 잘 못 되었다 생각되시면 <a href="mailto:support@w-rex.com">support@w-rex.com</a> 으로 문의 부탁 드립니다.
                        </p>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p id="withdraw" className="faq_tit">출금과 출금 수수료에 대해</p>
        <div className='swanky_wrapper faq'>
          <input id='pr5_1' name='radio' type='checkbox' />
          <label htmlFor='pr5_1'>
            <span className="th_faq">1.	출금 방법은 어떤 것이 있습니까? 출금 수수료는 어떻게 되나요?</span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  만약 WREX 계정에 [월수익 자동출금]을 선택했다면, WREX 계정의 월수익이 익월 10일 WREX 나의 이더리움 지갑으로 자동 출금됩니다. <br />
                  모든 자동 출금 수수료는 출금 이더리움 수량의 0.5% 입니다.
                        </li>
                        <li>
                  수동 출금은 언제든지 요청할 수 있습니다. 수동 출금을 하게 되면 출금한 이더리움 수량에 따라 최소 72시간에서 최대 4주 이내에 WREX 나의 이더리움 지갑에 입금됩니다.  <br />
                  * 최대 4주의 기간은 갑작스러운 출금 요청으로 인한 월별 수익률에 부정적인 영향이 있을 수 있으므로 WREX의 모든 회원님의 손해를 최소화하고 수익을 극대화하려고 함입니다.<br />
                  모든 수동 출금 수수료는 출금 이더리움 수량의 1.0% 입니다.
                        </li>
                <li>
                  100 이더리움 플랜은 출금할 수 없습니다.<br />
                  조기해지만 가능합니다. 만약 해지를 요청하시면 익월 10일에 WREX 나의 이더리움 지갑으로 출금 됩니다. (해지를 요청한 달의 수익률은 포함되지 않습니다)<br />
                  모든 100 이더리움 플랜의 조기해지 또는 만기 출금시 출금 수수료는 출금 이더리움 수량의 1.0% 입니다.
                        </li>
              </ul>
            </div>
          </label>
          <input id='pr5_2' name='radio' type='checkbox' />
          <label htmlFor='pr5_2'>
            <span className="th_faq">2.	출금 방식이 다른 많은 사이트와 틀립니다. 어떻게 하면 되나요? </span>
            <div className='lil_arrow'></div>
            <div className='bar'></div>
            <div className='swanky_wrapper__content'>
              <ul>
                <li>
                  먼저 “계정설정” – “보안설정”에 가셔서 구글 OTP (2FA)를 설정해야 합니다.<br />
                  다음 “계정설정” – “이더리움 주소 관리”에 가셔서 “신규 주소 등록”을 클릭해 주십시오. <br />
                  라벨에는 주소 제목을 기입하시면 됩니다. 예 – 코인원 이더리움 입금 주소<br />
                  주소에는 코인원 이더리움 입금 주소를 복사 붙이기를 해주십시요.<br />

                </li>
                <li>
                  “ADD” 버튼을 누르시면 됩니다. <br />
                  다음은 구글 OTP 앱을 실행해서 계정과 동기화 된 OTP 6자리 숫자를 입력해 주십시오. <br />
                  등록이 완료되었습니다.<br /><br />
                  저희가 출금 주소를 입력대신 선택할 수 있게 한 것은, 입력할 때마다 잘 못된 주소를 넣을 수 있는 문제도 있고, 또한 보안상 훨씬 안전하기 때문입니다.<br /><br />
                  선택하신 주소가 본인 주소임을 전송 전에 반드시 확인하시기 바랍니다.
                        </li>
              </ul>
            </div>
          </label>
        </div>


      </div>

    )
  }
}

export default FaqPageKR;
