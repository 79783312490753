

export function Translations_kr() {
  return {

    Language: '언어',


    eec2: '링크 오류',
    eec3: '회원님의 2FA 보안이 비활성화 되었습니다.',

    err_name_too_long: '제목이 너무 깁니다.',

    not_updatable: '이 설정은 바꿀 수 없습니다.',   // 新增的
    invalid_wrex_withdraw_amount: '출금 수량 오류: 출금 규정을 참고해 주시거나, support@w-rex.com으로 문의 주시기 바랍니다.',
    invalid_amount: '수량 오류: 출금 규정을 참고해 주시거나, support@w-rex.com으로 문의 주시기 바랍니다.',
    amount_less_than_one_eth: 'WREX 상품을 신규 생성하시려면 1 이더리움을 입금해야 합니다.',

    invalid_old_password: '기존 비밀번호 입력 오류',

    // WrexTransfer.js
    wrextransfer: 'Wrex',
    wt1: 'WREX 상품 계정으로 송금',
    transferWrexAccountHolder: '받으시는 분 (WREX 계정 이메일 주소)',
    wrextransfernotices: 'WREX 상품 계정 송금 메모',
    wt2: '[WREX 상품]에 송금할 이더리움 수량이 충분한지 확인해 주시기 바랍니다',
    invalid_wrex_transfer_amount: 'Invalid withdrawal amount, please refer the manual withdrawal polices.',

    "top1": "BIG DREAMS FOR A SMALL CHANGE",
    "Login": "로그인",
    "Register": "가입하기",
    "wallet": "지갑",
    "assets": "자산",
    "affiliate": "추천제도",
    "support": "지원",
    "account": "계정설정",
    "logout": "로그아웃",
    "introduction": "WREX 소개",
    "ip1": "오늘 날 암호화폐 시장가치는 불과 10년 만에 무에서 수백조까지 성장하였습니다. 그런데 실제로 수백억 단위로 돈을 번 사람은 생각보다 많지 않습니다. 대부분 조금 오르면 팔고, 다시 사고 팔고를 반복하는 단기적 접근 방식 때문이 아닐까 생각합니다.",
    "ip2": "반면 세계적인 부자들은 대부분 자사주식을 가지고 있습니다. 주당 가격이 어떻게 되던 그들은 과거에도, 오늘도, 그리고 내일도 팔지 않을 것입니다. 장기적인 보유, 그들이 오늘 날 세계 10대 부자들인 이유라 할 수 있습니다.",
    "ip3": "WREX는 그들처럼 장기적인 투자 성향을 가진 플랫폼입니다. WREX의 높은 월수익률과 장기적인 플랜을 활용하여 최소한 그들과 같은 방식으로 자산을 증식하는 시스템입니다.",
    "ip4": "WREX에 오신 것을 환영합니다!",
    "features": "WREX 알고리즘",
    "ip5": "WREX 이더리움 3단계 알고리즘 시스템",
    "ip6": "저희는 이더리움 3단계 알고리즘 시스템을 통해 이더리움 시장에서 알트 코인을 사고 팔아 이더리움의 시세 차익을 실현하는 회사입니다. 암호화폐 시장이 상승 또는 하락과는 상관없이 2018년 1월부터 2019년 8월까지 단 한번도 월기준 마이너스 성장을 기록한바 없고, 꾸준하게 매월 5%에서 최대 80%대 수익율을 기록했습니다.",
    "ip7": "저희 첫 번째 트레이딩 규칙은 절대 손실을 보고 팔지 않는다는 것입니다. 과거에도 손실을 본 적이 없으며, 앞으로도 그렇게 할 것입니다.",
    "ip8": "저희 운영에 투명성을 더하기 위해 세계 4대 회계 법인 중 하나인 P사에서 매월 저희 회사를 내방하여 모든 거래 내역을 전수 검사할 예정입니다. 거래계정에 대한 검증 절차도 함께 이루어지고, 이를 바탕으로 월 수익률이 확정될 예정입니다. 이는 월별 검증 보고서로 회원님들께 제공됩니다.",
    "products": "WREX 상품",
    "ip9": "WREX는 회원들의 투자 전략에 따라 두가지 상품을 제공하고 있습니다. WREX 상품과 100 이더리움 플랜 상품입니다.",
    "ip10": "위 상품에 이더리움이 이체되면, 상품 특성에 따라 이더리움 수량을 늘리는 운영이 비로서 시작됩니다.",
    "howtogetstart": "WREX 시작하기 ",
    "ip11": "Big Dreams for a Small Change",
    "ip12": "추천인이 제공한 링크를 따라 WREX에 접속하고, 가입하기를 눌러주세요. 주의: 가입이 완성되려면 반드시 추천인 코드를 입력해야 합니다.",
    "ip13": "WREX 가입이 완료되면 회원님의 고유 이더리움 주소와 함께 [나의 이더리움 지갑] 이 제공됩니다. 이 지갑의 이더리움 주소로 외부 지갑에서 이더리움을 이체하면 됩니다.",
    "ip14": "[나의 이더리움 지갑]에 송금한 이더리움이 도착하면, 이제 본인에게 맞는 운영 전략에 따라 WREX 상품과 100 이더리움 플랜 상품에는 알맞은 이더리움 수량을 분산해 생성하시면 됩니다. 운영 전략에 대해 궁금하시다면 먼저 두 가지 상품의 운영 특성에 대해 숙지하기 바랍니다.",
    "ip15": "나의 이더리움 지갑에서 WREX 상품 또는 100 이더리움 플랜 상품으로 이체하는 모든 이더리움에 대해서는 이체 수수료가 발생하지 않습니다.",
    "howitworks": "WREX 운영방식",
    "ip16": "Creating Unlimited Future",
    "ip17": "WREX 상품이나 100 이더리움 플랜 상품의 모든 이더리움은 WREX 이더리움 3단계 알고리즘 시스템과 숙련된 트레이더들에 의해 이더리움 페어링 시장에서 다른 알트 코인들을 사고 파는 거래를 진행합니다. 데이터 분석을 통해 상승장에서는 이더리움 보다 더 오를 만한 코인을, 그리고 하락장에서는 이더리움 보다 덜 하락할 만한 알트 코인들을 선별하여 이더리움 매수 가격을 산출해 트레이들에게 제공합니다..",
    "ip18": "주의: 나의 이더리움 지갑에 남아있는 이더리움은 거래되지 않기 때문에 수익이 발생하지 않는 다는 점에 주의하시기 바랍니다. 나의 이더리움 지갑은 외부의 지갑에 이더리움을 전송하거나 입금을 받는 창구 역할을 합니다.",
    "ip19": "그러므로 WREX 지갑에는 바로 출금할 수량만 남겨두시고, 잔량은 두 가지 상품 중 하나에 입금하면 수익률을 극대화할 수 있습니다.",

    auto_withdrawal: '월수익 자동 출금',
    auto_reinvest: '월수익 자동 재입금',
    "performance": "수익실적",
    "profitsharing": "월수익 배분",
    "ip20": "매월 8일경 회계법인 P사에서 발행하는 WREX 월보고서를 통해 월수익률이 확정되면, 모든 월수익으로 획득한 이더리움은 다음과 같은 비율로 배분됩니다. 회원 45%, 추천인 보너스 5%, WREX 50%.",
    "ip21": "만약 월수익 자동재적립을 선택하셨다면, 회원님의 월수익인 45%는 자동으로 WREX 상품으로 추가 입금되어, 기존 이더리움 수량에 더해져 새로운 원금이 됩니다. 이렇게 매월 월수익이 발생한다면 원금은 회를 거듭할수록 큰 폭으로 증가할 것입니다.",
    "ip221": "수식: ",
    "ip222": "WREX 상품의 이더리움 수량",
    "ip224": " 회원 월수익 배분률 ",
    "ip225": " 월수익률 ",
    "ip23": "100 이더리움 플랜의 경우, 회원 월수익 배분 비율인 45% 뿐만 아니라 WREX에서 매칭 개념으로 추가 45%를 회원의 상품에 함께 적립합니다. 월수익률의 총 90%가 재적립 되는 100 이더리움 플랜 상품은 WREX 상품과 비교하면 훨씬 빠르게 증식될 것입니다.",
    "ip241": " 100 이더리움 플랜의 이더리움 수량 ",
    "ip242": " 회원 월수익 배분률 (45%) + WREX 월수익 배분률 (45%) ",
    "ip243": " 월수익률 ",
    "ip25": "각각의 100 이더리움 플랜 상품 생성시 또는 추후라도 플랜의 목적을 직접 기입할 수 있습니다.",
    "ip26": "예시: 100 이더리움 플랜 #1 - 우리 첫 아들 결혼 선물",
    "affiliateprogram": "추천 보너스 프로그램",
    "ip27": "WREX는 업계에서 가장 강력한 추천 보너스 프로그램을 운영하고 있습니다. 매달 회원이 추천한 이더리움 수량에 대해 5%와 매달 월수익률을 곱한 이더리움 수량을 적립해 드립니다. 추천한 이더리움 수량은 매달 월수익률에 따라 증가할 것이고, 증가하는 이더리움 수량의 5%와 매달 월수익률을 곱한 이더리움을 추천인 보너스로 매달 받는 것입니다.",
    "ip28": "WREX 상품 추천 보너스",
    "ip29": "WREX 상품의 추천 보너스를 수령하기 위해서는, 반드시 회원님의 WREX 상품에는 1개의 이더리움을 해당 월 첫 날부터 마지막 날까지 유지해야 합니다.",
    "ip30": "모든 추천 회원의 WREX 상품에 있는 이더리움 수량",
    "ip31": "월수익률",
    "ip32": "100 이더리움 플랜 상품 추천 보너스",
    "ip33": "100 이더리움 플랜 상품의 추천 보너스를 수령하기 위해서는 반드시 1개의 100 이더리움 플랜 상품을 해당 월 첫 날부터 마지막 날까지 유지해야 합니다.",
    "ip34": "모든 추천 회원의 100 이더리움 플랜 상품에 있는 이더리움 수량 ",
    "ip35": "WREX 상품과 마찬가지로 추천한 100 이더리움 플랜 상품의 모든 이더리움은 WREX 상품보다 더 빠른 속도로 매월 증가하게 되고, 회를 거듭할수록 증식된 이더리움 수량에 대해 매월 추천 보너스를 받게 됩니다.",
    "withdrawfee": "출금과 출금수수료",
    "ip36": "WREX 상품 출금",
    "ip37": "WREX 상품은 생성시 [월수익 자동출금]과 [월수익 자동재적립] 중 한가지 옵션을 선택할 수 있습니다. 선택하지 않으시면 초기 설정은 [월수익 자동재적립]으로 되어 있으며, 차후 언제든지 이 옵션은 변경할 수 있습니다.",
    "ip38": "만약 [월수익 자동출금]을 선택했다면, 익월 10일 모든 월수익은 자동으로 회원의 [나의 이더리움 지갑]으로 출금을 위해 이동됩니다.",
    "ip39": "자동 출금 옵션 외에도 언제든지 수동으로 출금을 요청할 수 있습니다. 다만, 수동 출금은 최소 72시간에서 최대 4주가 소요될 수 있습니다.",
    "ip40": "주의: 수동 출금 이후 WREX 상품에 1이더리움 보다 적은 양의 이더리움이 남아 있을 경우, 차후 발생할 수 있는 추천 보너스 수령이 불가능하며, 기존 생성된 100 이더리움 플랜 상품들이 자동 해지될 수 있습니다.",
    "ip41": "100 이더리움 플랜 출금",
    "ip42": "100 이더리움 플랜의 출금은 불가능 합니다. 출금은 불가능 하지만 언제든지 1개의 또는 다수의 100 이더리움 플랜을 수동으로 해지할 수는 있습니다. 조기 해지 패널티는 없습니다. 역시 해지로 인해 모든 100 이더리움 플랜이 종료될 경우, 100 이더리움 플랜의 추천 보너스를 받을 수 없게 됩니다.",
    "ip43": "WREX 상품의 수동 출금 수수료",
    "ip44": "WREX 상품에서의 모든 수동 출금에 대한 수수료는 출금 요청한 이더리움 수량의 1.0% 입니다. 또한, 수동 출금 요청이후 실제 출금까지는 최소 72시간에서 최대 4주가 소요될 수 있습니다.",
    "ip45": "100 이더리움 플랜의 해지 및 출금 수수료",
    "ip46": "100 이더리움 플랜 상품에서의 모든 수동 해지 후 발생하는 출금 수수료는 해지된 상품의 이더리움 수량의 1.0% 입니다.",
    "ip47": "회원님의 [나의 이더리움 지갑]에서 외부 이더리움 지갑으로 전송할 경우 이더리움 네트워크 수수료가 추가 발생할 수 있습니다.",
    "lastmonthreturn": "전달 월수익",

    "totalamount": "총 보유 이더리움",
    "addressmanagement": "이더리움 주소 관리",
    "addnew": "신규 주소 등록",
    "label": "주소 라벨",
    "address": "이더리움 주소",
    "edit": "수정",
    "delete": "삭제",
    "securitysettings": "보안 설정",
    "setup": "설정",
    "reset": "재설정",
    "resetpassword": "비밀번호 재설정",
    "siginhistory": "WREX 계정 접속 기록",
    "siginactivity": "WREX 계정 접속 일시",
    "ipaddress": "IP 주소",
    "location": "접속 지역",
    "email": "이메일",
    "referralwrexamount": "WREX 상품의 추천 이더리움 ",
    referralCount: "총 추천인 수",
    "referal100epamount": "100 이더리움 플랜 상품의 추천 이더리움",
    "earnedcommisions": "누적 추천 보너스",
    "myreferalid": "나의 추천 코드",
    "sharereferalid": "나의 추천 코드 ID",
    "sharereferallink": " 나의 추천 코드 링크",
    "referralfriends": "총 추천인 수",
    "programdetail": "추천보너스 세부사항",
    "ap1": "모든 추천 보너스는 [WREX 상품]과 [100 이더리움 플랜 상품]에 적립된 추천 이더리움 수량에 대해 수령할 수 있습니다. 또한, 기타 추천 보너스 수령 조건을 만족시켜야 합니다. [나의 이더리움 지갑]에 남아 있는 이더리움에 대해 추천 보너스를 받을 수 없습니다.",
    "ap2": "모든 또는 일부 추천한 회원님들이 WREX 상품에 [월수익 자동재적립]을 선택했다면, 회원님들의 WREX 상품은 매월 원금이 월수익률에 따라 증가할 것이고, 추천인의 추천 보너스 역시 원금을 기준으로 매월 정산되기 때문에 매월 증가할 것입니다.",
    "ap3": "또한 추천인의 모든 이더리움이 WREX 상품과 100 이더리움 플랜 상품에 계속 유지되는 동안에는 추천인도 지속적으로 매월 추천인 보너스를 받게 됩니다.",
    "ap4": "수많은 지인들을 추천한 회원이던 아니면 내 계정만 유지하는 회원이던 상관없이 매월 수령하는 월수익률은 추천인 보너스에서만 차이가 납니다. 모든 회원이 평등합니다. 모든 회원들의 상품은 매월 해당 월수익 만큼 성장하기 때문에, 모든 회원들이 추천인에게 감사하는 마음을 가질 것입니다. 그래서 더더욱 지인들에게 WREX를 소개하는데 적극적일 수 있습니다.",
    "ap5": "WREX는 따로 신규 회원 유치를 위해 홍보하지 않습니다. 회원님들이 직접 홍보하고, 그 홍보하는 시간과 노력에 대한 대가를 확실하게 받을 수 있습니다. 그래서 추천 코드 없이는 등록이 불가능합니다. 저희는 회원님들이 WREX를 홍보하는 시간과 노력이 반드시 보상 받을 수 있도록 설계하였습니다.",
    "ap6": "타 웹사이트나 유사한 플랫폼에서도 비슷한 추천 제도를 운영하고 있으나, 저희 프로그램 보다 강력하지는 못할 것입니다. 가장 큰 이유로는 영속성입니다. 저희 상품은 원금이 100% 운영되고, 월수익만은 배분합니다. 마이너스 성장을 하지 않는 다면, 매월 수익에 따라서 원금이 증가됩니다. 그리고 추천인 제도 역시 월수익으로만 지급되기 때문에, 원금 손실 없이, 매월 원금이 증가하여 영원하게 그리고 안전하게 운영될 수 있습니다.",
    "ap7": "위 추천코드 링크를 지인들에게 복사하여 전송하면 됩니다. 링크를 클릭해서 WREX에 접속하고, 가입하게 되면 자동으로 회원님의 추천코드로 등록이 됩니다.",
    "ap8": "상위 추천관련 정보 창들은 세계 표준시 기준 00시에 업데이트 됩니다. 유일하게 “총 추천인 수”만 실시간으로 업데이트 되고 있습니다.",

    "status": "현황",
    "myaffiliatestatus": "나의 추천 현황",
    "since": "가입일",
    as1: '“활성화”의 의미는 추천하신 회원이 등록을 완료했고, 1 이더리움을 WREX 상품에 그리고 최소 1개의 100 이더리움 플랜 상품을 생성한 것을 의미합니다.',
    active: '활성화',

    "emailverification": "이메일 확인",
    "ev1": "회원님의 이메일을 확인하시어, 이메일 주소 확인 절차를 먼저 완료해 주시기 바랍니다. 기입하신 이메일 주소가 확인되지 않으면 다음 단계로 넘어갈 수 없습니다.",
    "continue": "확인",
    "ev2": "이메일을 수령하지 못하셨다면",
    "resendemail": "이메일 다시 보내기",
    "createnew100ep": "신규 100 이더리움 플랜 상품 생성하기",
    "namethisplan": "100 이더리움 플랜 이름 짓기",
    "create": "가입",
    "about100ep": "100 이더리움 플랜 설명",
    "name": '이름',
    "amount_ep100": '수량 (최소 1ETH ~ 최대 100ETH)',

    "ec1": "각 100 이더리움 플랜 상품에는 목적에 따라 이름을 따로 기입할 수 있습니다. (예. 첫 아들 결혼 비용)",
    "ec2": "각 100 이더리움 플랜 상품에는 1~100 이더리움만 적립할 수 있습니다. “생성하기” 버튼을 클릭하면, 회원님의 [나의 이더리움 지갑]에 이더리움 1~100개가 자동으로 이체됩니다. 생성 후 추가 입금은 허용되지 않습니다.",
    "ec3": "모든 100 이더리움 플랜 상품은 모든 월수익이 자동 재적립 됩니다.",
    "ec4": "자동 재적립되는 수식은 다음과 같습니다.\n[100 이더리움 플랜 상품의 이더리움] x [월수익%] x 90% = 월수익\n모든 월수익은 각각의 100 이더리움 플랜의 전월 원금에 더해집니다. 매월 증액된 원금은 또다시 새로운 월수익이 더해져 원금이 증액됩니다. WREX 상품과 비교하여 더욱 빨리 증액됩니다.",
    "ec5": "이 상품의 만기는 40개월입니다. 중간에 추가 입금도 허용되지 않지만, 출금도 허용되지 않습니다. 다만 한 개의 또는 다수의 100 이더리움 플랜 상품을 조기 해지를 요청하실 수 있습니다. 해지 요청하시면 각각의 100 이더리움 플랜 상품에 표기된 금액은 다음 달 10일에 [나의 이더리움 지갑]으로 전송됩니다.",
    "ec6": "“생성하기”를 누르시고 100 이더리움 플랜을 생성하면, 생성일과 상관없이 다음 달 1일부터 효력이 발생합니다.",

    "ep100": "100 이더리움 플랜",
    "oldep100": '(2020년 9월 1일 이전 생성)',
    "terminate": "해지",
    "monthstomature": "만기까지 잔여 개월 수",
    "overview": "이더리움 자산 현황",
    sixmonthperformance: '지난 6개월 동안의 실적',
    "accounthistory": "운용  기록",
    "update": "적용",
    "confirmterminate": "정말 해지하시겠습니까? 해지 후 다시 생성할 경우, 처음부터 다시 시작하며, 기존 계약으로는 복귀할 수 없습니다.",
    "etc1": "해지를 확정하면 해당 상품의 이더리움은 다음 달 10일 회원님의 [나의 이더리움 지갑]으로 자동 전송됩니다.",
    "etc2": "조기 해지는 패널티가 부과됩니다. 패널티는 수익의 75 %입니다. 그래도 해지를 원하시면 아래 “동의합니다” 체크 후 확인을 클릭해주세요. 패널티 부과를 원하지 않으시면, 24개월 만기까지 계정을 유지하십시오.",
    "lets_do_it": "동의합니다.",
    "yes": "확인",
    "forgotpassword": "비밀번호 분실",
    "fp1": "비밀번호 재설정을 위해 회원님의 등록된 이메일 주소로 비밀번호 재설정 관련 이메일이 전송됩니다.",
    "send": "보내기",
    "backtologin": "로그인으로 돌아가기",
    "fpr1": "비밀번호 변경 절차 관련 이메일이 전송되었습니다.",
    "ok": "확인",
    "fpr2": "이메일을 받지 못하셨나요?",
    "resend": "다시 보내기",
    "newpassword": "신규 비밀번호",
    "confirmpassword": "비밀번호 재입력",
    "inputcode": "6자리 2FA 인증번호를 입력",
    "googleauthentication": "구글 2FA 인증번호",
    "g2": "6자리 숫자를 입력하시오.",
    "g3": "구글 2FA 인증번호 확인 불가",
    "submit": "인증",
    "notsetupyet": "구글 2FA 인증이 설정되지 않았습니다.",
    "g4": "회원님의 구글 2FA 인증이 제대로 설정되지 않았습니다. 설정을 다시 해 주십시오.",
    "changesuccess": "설정 성공",
    "dismiss": "완료",
    "requestreceived": "요청 접수",
    "g21": "구글 2FA 인증 앱 설치",
    "g22": "구글 2FA 인증 앱을 회원님의 스마트폰에 설치를 위해 플레이스토어에서 Google OTP를 검색하여 설치한다.",
    "g23": "Google OTP앱을 실행, 추가 버튼을 누른 다음 QR 코드 스캔을 선택한다.",
    "g24": "핸드폰을 움직여 QR 코드를 읽는다.",
    "g25": "Google OTP에 등록된 WREX OTP를 확인한다.",
    "g26": "구글 2FA 인증을 최종 활성화하기 위해 WREX OTP의 일회성 6자리 숫자 코드를 아래에 입력한다.",
    "g2a": "",
    "code": "6 자리 코드 입력",
    "availablefunds": "WREX 상품 – [WREX 상품]과 [100 이더리움 플랜]",
    "notices": "주의사항",
    wrex_account: 'WREX 상품',
    eth100_plan: '100 이더리움 플랜',
    totaleth: '전체 운용 실제 수량',
    total: '전체 운용 실제 수익률',

    "is1": '100 이더리움 플랜 상품을 생성하기 위해서는 반드시 WREX 상품을 먼저 생성하고 유지해야만 합니다.',
    "is2": 'WREX 상품을 생성하려면 1 이더리움이 필요합니다. 생성이후 WREX 상품에 추가 입금은 수량 제한이 없습니다. 각각의 100 이더리움 상품 플랜에는 오직 1~100개의 이더리움만을 입금할 수 있습니다.',
    "is3": '상품을 생성하기 전에, 먼저 [나의 이더리움 지갑]에 가용할 수 있는 이더리움 수량을 먼저 확인해 주시기 바랍니다.',
    "is4": '확인 후, 이제 WREX 상품에 몇 개의 이더리움를 입금하고, 또 몇 개의 100 이더리움 플랜 상품을 운영할지를 결정해야 합니다.\n',
    "is41": ' 100 이더리움 플랜 상품에 필요한 이더리움을 제외한 이더리움을 WREX 상품 생성할 때 입금하시면 됩니다.\n',
    "is42": ' 만약, 모든 이더리움을 WREX 상품에 입금하시게 되면, 100 이더리움 플랜 상품을 생성하실 이더리움이 없게 되며, 이런 경우, 이미 WREX 상품에 입금하신 이더리움을 출금해야 합니다. 출금 요청할 경우, 출금 수수료 (1%)가 발생되며, 실제 출금까지 최소 72시간에서 최대 4주가 소요될 수 있으니 반드시 주의하시기 바랍니다.',
    "is5": '입금 시점에 따른 첫 달 월수익 차등 지급 규정\nWREX 상품의 경우, 생성 또는 추가 입금한 이더리움에 대해, 그 입금일에 따라 월수익이 아래와 같이 차등 지급됩니다.\n입금일이 1일 ~ 10일 사이일 경우, 해당 월수익의 50% 지급\n입금일이 11일 ~ 20일 사이일 경우, 해당 월수익의 25% 지급\n입금일이 21일 ~ 마지막날 사이일 경우, 해당 월수익의 0% 지급\n위 차등 지급일은 입금관련 첫 달에만 해당되며, 익월부터는 월수익의 100%가 반영됩니다.\n\n100 이더리움 플랜 상품의 경우, 생성일과 관계없이, 생성 다음 달 1일부터 계약 효력이 발생합니다.',
    "is6": 'WREX 상품과 100 이더리움 플랜 성장 계산 수식\n\n[WREX 상품]\n매월 전체 수익률의 45%가 WREX 상품에 적립이 됩니다.\n수식: [WREX 상품의 이더리움 수량] x 45% x 월 수익률 % + 추천 보너스 (해당사항이 있을 경우) = 나의 수익\n\n[100 이더리움 플랜 상품]\n매월 전체 수익률의 90%가 100 이더리움 플랜 상품에 적립이 됩니다. \n수식: [100 이더리움 플랜 상품의 이더리움 수량] x 90% x 월 수익률 % + 추천 보너스 (해당사항이 있을 경우) = 나의 수익\n** 중요: 시스템 계산상 90%가 적립되어 복리식으로 계산되지만, 실제 표기된 수량은 회원님의 수익(45%)만을 표기합니다. \n즉, 위 표기된 이더리움 수량은 회원님께서 오늘 청산하면 받을 수 있는 이더리움 수량 입니다.',

    "is7": "상품을 생성하기 전에 반드시 아래 주의사항을 필독하여 주시기 바랍니다.",


    "lastmonth": "지난달",
    "lastmonth_profit": '지난달 수익률',
    "my_profit": '나의 수익률',

    "signin": "로그인",
    "notyetonwrex": "WREX에 가입하기",
    "myethwallet": "나의 이더리움 지갑",
    "deposit": "입금",
    "med1": "아래의 이더리움 주소를 사용하여 이더리움을 입금하시기 바랍니다.",
    "med2": "위 입금 잔여 한도를 초과하는 이더리움을 입금하지 마십시오.",
    "copy": "복사하기",
    "availableamount": "입금 가능 한도",
    "depositnotices": "입금 관련 주의 사항",

    "med3": '위 주소는 회원님의 WREX 이더리움 지갑 입금 주소입니다. 반드시 이더리움만이 입금 가능합니다. 만약 이더리움 (ETH)외 다른 암호화폐를 입금하시게 되면 복구가 불가능 합니다.',
    "med4": '위 QR CODE를 스캔하시거나, 아래 “복사하기” 버튼을 클릭하시고, ‘붙이기’ 기능을 사용하시면 편리합니다. 복사와 붙이기 기능을 사용하셨다면, 사용 후 위 주소와 붙이기 한 주소가 동일한지를 반드시 확인해 주시기 바랍니다.',
    "med5": '30번의 CONFIRMATION이 발생한 이후, 입금이 진행되며, 통상적으로 10분 정도가 소요되나 이더리움 네트워크 사정상 지연될 수도 있습니다.',
    "med6": '위 이더리움 주소는 회원님의 WREX 나의 이더리움 지갑에 입금할 수 있는 입금 전용 주소입니다.',

    "withdraw": "출금",
    "ca_withdraw": "출금",
    "youhavedeposited": "총 입금한 이더리움",
    "youhaveroom": "추가 입금 가능한 이더리움",
    "history": "기록",
    "filter": "선택(전체)",
    "gains": "월수익",
    "bonus": "보너스",
    ca_create: '생성',
    ca_gains: '월수익',
    ca_bonus: '보너스',
    ca_mature: '만기',
    ca_terminate: '해지',
    ca_fee: '수수료',
    "not_available": "사용할 수 없음",
    my_eth_definition: '[나의 이더리움 지갑] – 회원님의 고유 WREX 이더리움 지갑입니다.',
    def1: '위 노란 버튼 "외부출금"을 누르시면, WREX 지갑에서 이더리움을 외부 이더리움 지갑/거래소로 보낼 수 있습니다.',
    def2: '위 노란 버튼 "입금"을 누르시면, 외부 이더리움 지갑/거래소에 보유 중이신 이더리움을 WREX 지갑으로 보낼 수 있습니다.',
    def3: '주의: [나의 이더리움 지갑]에 남아 있는 이더리움은 매월 발생하는 수익에 대해 배분 받을 수 없습니다. 월수익 배분을 받으시려면, 반드시 [WREX 상품]이나 [100 이더플랜 상품]에 가입되어 있어야 합니다. WREX 지갑에 이더리움이 있으면, 다음 "자산" 페이지에 가셔서 두 상품 중 선택하셔서 이더리움을 예치하시면 됩니다.',
    externaladdress: '주소',
    datetime: '날짜/시간',
    action: '상태',
    unknown: '외부 ETH 지갑',

    "mew1": "외부 이더리움 지갑으로 출금",
    "amount": "수량",
    "withdrawto": "출금",
    "selectaddress": "주소 선택하기",
    "networkfee": "네트워크 수수료",
    "youwillget": "수령 이더리움 수량",
    "withdrawnotices": "출금 주의사항",

    "mew2": '가장 먼저 오른쪽 상단에 위치한 이더리움 수량을 확인하시기 바랍니다. 이 수량은 회원님의 [나의 이더리움 지갑]에 보관 중인 이더리움 수량입니다.',
    "mew3": '“수량”란에 출금을 원하시는 이더리움 수량을 기입해 주시기 바랍니다.',
    "mew4": '“출금”란에 “주소 선택하기”를 클릭하시고, 출금을 원하시는 이더리움 주소를 선택하여 주시기 바랍니다.\n주의사항: 회원님의 보안과 편리성을 위해 회원님이 사전에 등록한 이더리움 출금 주소로만 출금을 하실 수 있습니다. 만약, “주소 선택하기”가 비어있거나, 출금을 원하는 주소가 등록되어 있지 않다면, “계정설정” 페이지 안에 “이더리움 주소관리”에 가셔서 “신규 주소 등록” 버튼을 클릭해 주소를 등록해 주시기 바랍니다.\n보안을 이유로 신규 등록하신 주소를 “주소 선택하기” 란에서 선택하시려면 신규 등록 후 24시간이 소요됩니다. 이 점을 유의하셔서, 반드시 사전에 출금 주소를 등록하시기 바랍니다.',
    "mew5": '출금 요청이 성공적으로 완료 되면, WREX는 접수된 출금요청을 처리하게 되나, 최장 24시간이 걸릴 수 있습니다. 이유는 역시 보안상, [나의 이더리움 지갑]의 보관된 모든 이더리움은 콜드스토리지에 보관하기 때문입니다.',
    "mew6": '출금이 진행되면 출금하신 금액에 이더리움 네트워크 수수료가 부과됩니다.',

    // MyEthTransfer.js
    met1: 'WREX 내부 이더리움 지갑으로 송금',
    transfer: "송금",
    transferto: '수취계정',
    whitelist: '사전등록리스트',
    enteraddressholder: '회원 입금 주소',
    transfernotices: 'WREX 내부 이더리움 지갑 송금 메모',
    met2: '[나의 이더리움 지갑]에 송금할 이더리움 수량이 충분한지 확인해 주시기 바랍니다.',
    internaluser: '내부 계정',

    external: '',
    internal: '',
    transferB: "내부 송금",
    withdrawB: "외부 출금",

    // Google2fa11c.js
    transfersuccess: '송금이 성공적으로 완료되었습니다',

    "mewr1": "회원님의 요청은 접수 완료되었고 준비 중에 있습니다.",
    "myinfo": "나의 정보",
    "userlevel": "회원 레벨",
    "usertype": "회원 구분",
    "nationality": "국적",
    "oldpassword": "이전 비밀번호",
    "addnewaddress": "신규 주소",
    "pan1": "40자까지 가능",
    add: "등록",
    "editaddress": "라벨 수정",
    "selectone": "선택하기",
    "signup": "가입하기",
    "registerTermsCheck": "다음으로 진행하는 것으로 나는 18세 이상이며, 회사가 제공하는 이용약관과 개인정보 수집에 동의하는 것으로 서명합니다.",
    "termsofuse": "이용 약관 보기",
    "next": "다음",
    "alreadyhaveaaccount": "이미 등록하셨나요?",
    "passwordvalidate": "비밀번호는 최소 8자로 소문자, 대문자 그리고 숫자를 최소 1개씩 포함해야 합니다.",
    "back": "이전",
    "congrates": "축하합니다.",
    "thankyouregister": "가입해 주셔서 진심으로 감사드립니다. 계정을 활성화를 위해서 이메일 주소 검증 절차를 완료해 주시기 바랍니다.",
    "rv1": "이메일을 확인하시어, 이메일 주소 검증 절차를 먼저 완료해 주십시오. 계정이 활성화 되기 전에는 로그인이나 계정을 사용하실 수 없습니다.",
    "rv2": "계정 활성화를 위한 이메일을 못 받으셨나요?",
    "resetemail": "이메일 다시 보내기",
    "emailconfirmed": "이메일 주소 확인 완료",
    "eec1": "회원님의 요청이 확인되었습니다.",
    "monthlyreports": "월보고서",
    "month": "월",
    "monthlyprofit": "월수익률",
    "viewreport": "보고서 보기",
    "s1": "문의사항이 있으면 먼저 [자주묻는질문]을 확인하시고 추가 답변이 필요한 경우 다음 이메일 주소로 문의사항을 보내주시기 바랍니다",
    "s2": '투명성 때문입니다. 전세계 10대 회계법인 중 하나인 Tilly Baker에서 매달 1일에서 5일 사이에 저희 사무실을 방문합니다.\n 그들은 (1) 전달 실행된 모든 거래내역을 감사하며 (2) 모든 이더리움 계정의 이더리움 수량을 확인하고, 이 정보를 바탕으로 월수익률을 확정해 줍니다.\n 위 보고서는 매월 8일에 완성이 되고, WREX를 통해 매월 회원님들에게 공개가 됩니다.\n 모든 수익 배분은 보고서의 월수익률을 기준으로 매월 10일에 실행됩니다.',

    "profitshare": "수익 배분",
    "affiliatebonus": "추천 보너스",
    "wrex": "WREX 상품",
    "setting": "설정",
    "aboutwrexaccount": "WREX 상품 설명",
    "wa1": "만약 [월수익 자동출금]으로 설정되어 있다면, 매월 발생하는 월수익은 익월 10일에 [나의 이더리움 지갑]으로 자동 이체됩니다.",
    "wa2": "만약 [월수익 자동재적립]으로 설정되어 있다면, 매월 발생하는 월수익은 WREX 상품에 재적립 됩니다.",
    "wa3": "모든 WREX 상품의 추천 보너스는 월수익으로 간주되며 [월수익 자동출금]으로 설정되어 있으면 [나의 이더리움 지갑]으로 익월 10일에 이체됩니다.",
    "wa4": "[월수익 자동재적립]으로 설정되어 있으면 WREX 상품으로 재적립 됩니다.",
    "wa5": "월수익에 대한 자동출금은 익월 10일에 자동으로 이체되며, 출금되는 이더리움 총량의 0.5%의 출금 수수료가 부과됩니다.",
    "wa6": "수동 출금은 요청 후 최소 72시간에서 최대 4주 이내에 [나의 이더리움 지갑]으로 이체되며, 출금 요청한 이더리움 수량의 1.0%가 출금 수수료로 부과됩니다.",
    "availablebalance": "가능 잔고",
    ch_deposit: '입금',
    "wrexdepositpolices": "WREX 입금 및 해당 월수익률 배분",

    "wd1": "WREX 상품을 생성하려면, “수량”란에 입금하시려는 이더리움 수량을 기입해 주시기 바랍니다. 생성을 위해서는 1개의 이더리움을 기입해야 합니다.",
    "wd2": "[월수익 자동 재입금] 또는 [월수익 자동 출금] 중 하나를 선택해 주시기 바랍니다. 이 설정은 추후 아무 때나 변경하실 수 있습니다.\n수식: [WREX 상품의 이더리움 수량] x [월수익 %] x 45% = *WREX 상품의 월수익*\n만약 [월수익 자동 재입금]을 선택하시면, *WREX 상품의 월수익*은 자동으로 매월 WREX 상품에 합산되어 원금이 증액됩니다.\n만약 [월수익 자동 출금]을 선택하시면, *WREX 상품의 월수익*은 자동으로 매월 10일 [나의 이더리움 지갑]으로 입금됩니다. 월수익이 포함되지 않기 때문에 WREX 상품의 초기 원금은 변동이 없습니다.",
    "wd3": "위 옵션 선택과 상관없이, 언제든지 수동으로 출금을 요청할 수 있습니다.",
    "wd4": "WREX 상품의 추천 보너스는 월수익으로 간주되므로, [월수익 자동 재입금]을 선택하면, 추천 보너스 역시 월수익과 함께 원금에 포함되어 원금이 증액됩니다. 만약 [월수익 자동 출금]을 선택하면, 월수익과 함께 추천 보너스도 매월 10일 정산되어 [나의 이더리움 지갑]으로 입금됩니다.",

    "ethmin": "1 이더리움",
    "autoaccumulation": "월수익 자동 재입금",
    "autowithdrawal": "월수익 자동 출금",
    wrex_create: "생성",
    "selectmode": "모드 선택",
    "resetrules": "재설정 규정",
    "wrs1": "재설정은 다음 달 1일부터 효력을 발생합니다.",
    "wrs2": "주의사항: WREX 상품에서 [월수익 자동출금]을 선택하여 출금된 모든 이더리움에 대해 0.5%의 출금 수수료가 부과 됩니다.",
    "sendtomyethwallet": "[나의 이더리움 지갑]으로 보내기",
    "request": "요청",
    "transferfee": "수수료",
    "manualwithdrawpolices": "수동 출금 규정",
    "ww1": "수동 출금 요청 접수 후 실제 이체까지 최소 72시간에서 최대 4주가 걸릴 수 있습니다. 최대 4주의 시간이 소요되는 이유는 자주묻는질문을 확인해 주십시오.",
    "ww2": "수동 출금의 경우, 출금되는 모든 이더리움 수량의 1.0%의 출금 수수료가 부과됩니다.",
    "ww3": "출금 이후 WREX 상품에 1개의 이더리움이 남아 있어야 정상적인 WREX상품 및 100 이더리움 플랜 상품이 유지됩니다.",
    "ww4": "또는 WREX 상품의 모든 잔금을 출금하시면 됩니다. 모든 잔금이 출금되면 100 이더리움 플랜 상품을 포함한 모든 계정이 계약 해지되며 익월 10일 자동으로 [나의 이더리움 지갑]에 입금됩니다. 또한, 기존에 구축한 추천인 계정들은 복구가 되지 않습니다.",
    "wwr1": "회원님의 출금 요청은 접수된 상태이며 처리 중에 있습니다. 최소 72시간에서 최대 4주 안에 출금 수수료를 제외한 이더리움 수량이 회원님의 [나의 이더리움 지갑]으로 이체될 예정입니다.",


    confirmedLogoutTitle: '로그아웃 하기',
    confirmedLogoutDesc: '로그아웃 하시겠습니까?',
    cancel: '취소',

    "emailAddressPlaceHolder": "이메일 주소",
    "passwordPlaceHolder": "비밀번호",
    "confirmPasswordPlaceHolder": "비밀번호 재입력*",
    "referralCodePlaceHolder": "추천 코드",
    "addressPlaceHolder": "주소",
    "labelPlaceHolder": "라벨",
    "err_emailFormatError": "이메일 주소 오류",
    "err_emailEmpty": "이메일 주소 입력",
    "err_passwordEmpty": "비밀번호 입력",
    "err_oldPasswordEmpty": "이전 비밀번호 입력",
    "err_newPasswordEmpty": "신규 비밀번호 입력",
    "err_confirmPasswordEmpty": "비밀번호 재입력",
    "err_passwordMissmatch": "비밀번호가 일치하지 않습니다.",
    "err_referralCodeEmpty": "추천 코드 입력",
    "err_countryCodeEmpty": "거주 국가 선택",
    "err_addressEmpty": "주소 입력",
    "err_labelEmpty": "라벨 입력",
    "err_addressInvalid": "유효한 주소가 아닙니다. 다시 확인해 주시기 바랍니다.",
    "err_overWrexValue": "잔고 부족",
    "err_lessThenNetworkFee": "네트워크 수수료보다 적습니다.",
    "err_password_too_simple": "비밀번호의 보안성이 낮습니다.",
    "two_fa_not_enabled": "2FA가 비활성화 되어 있습니다.",
    "login_required": "로그인을 먼저 해야 합니다.",
    "address_duplicated": "주소가 복사되었습니다.",
    "address_not_specified": "주소란이 비었습니다.",
    "already_confirmed": "이미 확인 되었습니다.",
    "already_enabled": "이미 활성화 되었습니다.",
    "amount_not_specified": "수량이 기입되지 않았습니다.",
    "duplicated_email": "이메일이 복사되었습니다.",
    "not_withdrawable": "출금이 불가능 합니다.",
    "not_terminatable": "해지가 불가능 합니다.",
    "email_duplicated": "이미 가입된 이메일 주소입니다.",
    "email_not_confirmed": "이메일이 확인되지 않았습니다.",
    "incorrect_device": "터미널 오류",
    "insufficient_amount": "잔고 불충분",
    "invalid_address": "주소 오류",
    "invalid_affilate_code": "추천 코드 오류",
    "invalid_email_or_password": "이메일 주소 또는 비밀번호 오류",
    "invalid_email": '이메일 주소 오류',
    "invalid_otp": "코드 오류",
    "invalid_reinvest_value": "재적립 수량 오류",
    "invalid_reset_link": "재설정 링크 오류",
    "invalid_subscription_id": "회원 ID 오류",
    "left_amount_less_than_1eth": "잔고가 1 이더리움 미만입니다.",
    "missing_address": "주소가 입력되지 않았습니다.",
    "missing_parameter": "정보가 입력되지 않았습니다.",
    "nothing_to_update": "업데이트 전입니다.",
    "amount_larger_than_one_eth": "1이더리움을 초과할 수 없습니다",
    "should_accept_penalty": "패널티를 부과합니다",
    "reinvest_not_specified": "자동 재적립이 선택되지 않았습니다.",
    "subscription_not_active": "비활성화",
    "too_many_codes": "코드 오류",
    "unknown_error": "알 수 없는 오류",

    error: '오류',

    // 2019.08.29
    ep100_plan_still_active: 'WREX 상품을 종료하시거나 탈퇴를 원하시려면, 먼저 모든 100 이더리움 플랜 상품을 해지하셔야 합니다. WREX 상품에 1 이더리움을 유지해야 회원 자격이 유지됩니다.',
    wrex_not_created: '100 이더리움 상품 생성 전에 반드시 WREX 상품을 생성하셔야 하며, 1개의 이더리움을 유지하셔야 합니다.',

    // 2019.09.05 
    whytrust: '왜 WREX는 믿을 수 있다는 거죠?',

    notice_title: '8월 공지사항',
    notice_message: '1.	2020년 7월 수익률은 회계법인을 통해 3.71%로 확정되었고, 이미 10일에 반영되었습니다. 코로나 사태를 기점으로 WREX의 수익률이 저조한 것을 의아해하고 계시리라 생각되어, 저희 운영팀에서는 어떻게 하면 수익률을 만족할 만한 수준으로 끌어 올리고, 그리고 안정적으로 운영할 수 있는지에 대해 고민을 하였고 다음과 같이 공지 드립니다. \n ',
    notice_date: '2020년 8월 20일',
    notice_footer1: 'Read more...',
    notice_support: '지원',
    notice_footer2: '" 페이지를 방문하십시오.',

    invalid_100eth_create_amount: '송금 금액이 잘못 입력되었습니다. (최소 1ETH ~ 최대 100ETH)',

  }
}



