import { normalize, schema } from 'normalizr'
import { camelizeKeys } from 'humps'

import {API_ROOT} from '../constants/ConfigValue.js';

//let bInitialFlag=false;

// Extracts the next page URL from Github API response.
const getNextPageUrl = response => {
  const link = response.headers.get('link')
  if (!link) {
    return null
  }

  const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1)
  if (!nextLink) {
    return null
  }

  return nextLink.trim().split(';')[0].slice(1, -1)
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callGetApi = (endpoint, schema, token) => {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint
  //console.log(endpoint);
  //console.log(token);
  let method = 'get';

  if (token===undefined) {
    return fetch(fullUrl,
      {
        method: method
      })
      .then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json)
          }

          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)

          return Object.assign({},
            normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
      )
  } else {
    return fetch(fullUrl,
      {
        method: method,
        headers: new Headers({
          'Authorization': 'Bearer '+token,
        }), 
      })
      .then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json)
          }

          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)

          return Object.assign({},
            normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
      )
  }
}


// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callPostApi = (endpoint, schema, args, token) => {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint
  //console.log("callPostAPI");
  //console.log(endpoint);
  //console.log(args);
  //console.log(token);
  let method = 'POST';

  //if (token!==undefined) return;

  if (token===undefined) {
    //console.log("token = undefined");
    return fetch(fullUrl,
      {
        method: method,
        headers: new Headers({
          'Content-Type': 'application/json',
        }), 
        body: JSON.stringify(args)
      })
      .then(response =>
        response.json().then(json => {
          //console.log(json);
          if (!response.ok) {
            return Promise.reject(json)
          }
  
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)
  
          return Object.assign({},
            normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
      )
  } else {
    //console.log("with token");
    //console.log(fullUrl);
    //console.log(method);
    return fetch(fullUrl,
      {
        method: method,
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token,
        }), 
        body: JSON.stringify(args)
      })
      .then(response =>
        response.json().then(json => {
    //console.log(fullUrl);
          //console.log(json);
          if (!response.ok) {
            return Promise.reject(json)
          }
  
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)
  
          return Object.assign({},
            normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
      )
  }
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

const userSchema = new schema.Entity('user', {}, {
  idAttribute: user => 'user',
})

const accountSchema = new schema.Entity('account', {}, {
  idAttribute: account => 'account',
})

const loginSchema = new schema.Entity('login', {}, {
  idAttribute: login => 'login',
})

const responseSchema = new schema.Entity('response', {}, {
  idAttribute: response => 'response',
})

/*
const tokenSchema = new schema.Entity('tokeninfo', {}, {
  idAttribute: tokens => 'tokens'
})

const preProcessSchema = new schema.Entity('orders', {}, {
  idAttribute: order => 'order',
})

const preLoginSchema = new schema.Entity('login', {}, {
  idAttribute: prelogin => 'prelogin',
})

const cancelOrderSchema = new schema.Entity('cancelorder', {}, {
  idAttribute: order => 'order',
})

const submitSchema = new schema.Entity('submit', {}, {
  idAttribute: order => 'order',
})

const USDExchangeRateSchema = new schema.Entity('price', {}, {
  idAttribute: usdExchangeRate => 'usdExchangeRate',
})

const orderBookSchema = new schema.Entity('orderbook', {}, {
  idAttribute: orders => 'orders'
})

const myOrderSchema = new schema.Entity('myorder', {}, {
  idAttribute: orders => 'orders'
})

const myOrderOpenSchema = new schema.Entity('myorder', {}, {
  idAttribute: orders => 'ordersOpen'
})

const myOrderHistorySchema = new schema.Entity('myorder', {}, {
  idAttribute: orders => 'ordersHistory'
})

const walletLockSchema = new schema.Entity('walletlock', {}, {
  idAttribute: walletLock => 'walletLock'
})

const claimsSchema = new schema.Entity('claims', {}, {
  idAttribute: claims => 'claims'
})

const tradeHistorySchema = new schema.Entity('tradehistory', {}, {
  idAttribute: trades => 'trades'
})

*/

/*
const userSchema = new schema.Entity('users', {}, {
  idAttribute: user => user.login.toLowerCase()
})

const repoSchema = new schema.Entity('repos', {
  owner: userSchema
}, {
  idAttribute: repo => repo.fullName.toLowerCase()
})
*/

// Schemas for Github API responses.
export const Schemas = {
  LOGIN: loginSchema,
  USER: userSchema,
  RESPONSE: responseSchema,
  ACCOUNT: accountSchema,


  /*
  TOKEN: tokenSchema,
  ORDER_BOOK: orderBookSchema,
  MY_ORDER: myOrderSchema,
  MY_ORDER_OPEN: myOrderOpenSchema,
  MY_ORDER_HISTORY: myOrderHistorySchema,
  WALLET_LOCK: walletLockSchema,
  CLAIMS: claimsSchema,
  PREPROCESS: preProcessSchema,
  CANCEL_ORDER: cancelOrderSchema,
  SUBMIT: submitSchema,
  USD_EXCHANGE_RATE: USDExchangeRateSchema,
  PRE_LOGIN: preLoginSchema,
  TRADE_HISTORY: tradeHistorySchema,
  //TOKEN_ARRAY: [tokenSchema]
  USER_ARRAY: [userSchema],
  REPO: repoSchema,
  REPO_ARRAY: [repoSchema]
  */
}


// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

//console.log(callAPI);
//console.log("ych");
  const { target } = callAPI;

  if (target === "WREX API") {
    let { endpoint } = callAPI
    const { method, schema, types, token } = callAPI

//console.log(endpoint);
//console.log(method);
//console.log(schema);
//console.log(types);
//console.log(token);

    if (typeof endpoint === 'function') {
      endpoint = endpoint(store.getState())
    }

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.')
    }
    if (!schema) {
      throw new Error('Specify one of the exported Schemas.')
    }
    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.')
    }
    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.')
    }

    const actionWith = data => {
      const finalAction = Object.assign({}, action, data)
      delete finalAction[CALL_API]
      return finalAction
    }

    const [ requestType, successType, failureType ] = types
    next(actionWith({ type: requestType }))

    //let method = 'get';

/*
    if (endpoint === 'user/login') {
      method = 'post';
    } else if (endpoint === 'user/signup') {
      method = 'post';





    } else if (endpoint === 'pdex/order/preprocess') {
      method = 'post';
    } else if (endpoint === 'pdex/order/submit') {
      method = 'post';
    } else if (endpoint === 'pdex/cancel-order') {
      method = 'post';
    } else if (endpoint === 'pdex/prelogin') {
      method = 'post';
    } else if (endpoint === 'pdex/challenge') {
      method = 'post';
    } else if (endpoint === 'pdex/login') {
      method = 'post';
    } else if (endpoint === 'pdex/claim') {
      method = 'post';
    } else if (endpoint === 'pdex/verify') {
      method = 'post';
    } else if (endpoint === 'usd/exchange') {
      method = 'get_chart';
    }
*/

    if (method === 'get') {
      return callGetApi(endpoint, schema, token).then(
        response => next(actionWith({
          response,
          type: successType
        })),
        error => next(actionWith({
          type: failureType,
          error: error.message || 'Something bad happened'
        }))
      )
    } else {
      let { args } = callAPI;
      return callPostApi(endpoint, schema, args, token).then(
        response => next(actionWith({
          response,
          type: successType
        })),
        error => next(actionWith({
          type: failureType,
          error: error.message || error
        }))
      )
    }
  }
}

